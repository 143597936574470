import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolUpdatePassword) {
      return state.schoolUpdatePassword;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolPasswordForm = createSelector(
  [selectDomain],
  (state) => state.SchoolPasswordFormData
);
export const selectSchoolPasswordListData = createSelector(
  [selectDomain],
  (state) => state.SchoolPasswordList
);
export const SelectShowPassword = createSelector(
  [selectDomain],
  (state) => state.showPassword
);
export const SelectShowCurrentPassword = createSelector(
  [selectDomain],
  (state) => state.currentPassword
);
export const SelectShowNewPassword = createSelector(
  [selectDomain],
  (state) => state.newPassword
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
