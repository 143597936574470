import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { AdminLanguagesState, LanguagesInterface } from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: AdminLanguagesState = {
  loading: false,
  LanguagesFormData: {
    code: "",
    name: "",
    status: 1,
  },
  AllActiveLanguagesList: [],
  LanguagesList: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  dissableCheck: false,
};

export const useClinicSlice = createSlice({
  name: "adminLanguage",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },
    setLanguageList: (state, action: PayloadAction<Array<any>>) => {
      state.LanguagesList = action.payload;
    },
    setALlActiveLanguageList: (state, action: PayloadAction<Array<any>>) => {
      state.AllActiveLanguagesList = action.payload;
    },
    doGetALLLanguageList: (state) => {},
    doGetAllActivelanguageList: (state) => {},
    setLanguageForEdit: (state, action: PayloadAction<LanguagesInterface>) => {
      state.LanguagesFormData = action.payload;
    },
    getLanguageByIDForEdit: (state, action: PayloadAction<string>) => {},
    updateLanguageFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `LanguagesFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: AdminLanguageSliceKey } = useClinicSlice;
