import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { landingPageDataRequest } from "../../../../utils/request";
import { getDescriptionFaqRequest, PopularRetreatActivityEnum } from "../types";
import { selectLandingForm } from "./selector";
import { actions } from "./slice";

// export function* getLandingDataRequest(action: {
//   payload: {
//     name: string;
//     activity: string;
//     callback: any;
//   };
// }) {
//   yield delay(500);
//   const form: getDescriptionFaqRequest = yield select(selectLandingForm);
//   const data = {
//     search: action.payload.name,
//     activity:
//       action.payload.activity.length > 0
//         ? action.payload.activity
//         : PopularRetreatActivityEnum.YOGA_RETREAT,
//   };
//   try {
//     // const response: AxiosResponse = yield call(landingPageDataRequest, data);
//     const response: AxiosResponse = yield call(
//       landingPageDataRequest,
//       `?search=${data.search}&activity=${data.activity}`,
//       data
//     );
//     if (response && response.data.status == false) {
//       CustomToast(response.data.message, "ERROR");
//       return;
//     }
//     yield put(
//       actions.setlandingDataList(response.data[0].data.popularRetreatData[0])
//     );
//     yield put(
//       actions.setlandingPopularRetreatsData(response.data[0].data.buttonsData)
//     );
//     yield put(
//       actions.setlandingPopularDestinationsData(
//         response.data[0].data.popularDestinations
//       )
//     );
//     yield put(actions.setCityList(response.data[0].data.cityData));
//     yield put(actions.setCountryList(response.data[0].data.countyData));
//     yield call(action?.payload?.callback());
//   } catch (error: any) {
//     CatchBlockFunction(error);
//   }
// }
export function* landingPagesRepoSaga() {
  // yield takeLatest(actions.getLandingData, getLandingDataRequest);
}
