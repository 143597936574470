import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  getAllActiveLanguageListRequest,
  getAllLanguageRequest,
  getLanguageByIdRequest,
  UpdateLanguageRequest,
} from "../../../../../utils/request";
import { LanguagesInterface } from "../types";
import { selectAdminLanguageForm } from "./selector";

import { actions } from "./slice";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
import { log } from "../../../../../utils/logger";

export function* getALLLanguageListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAllLanguageRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setLanguageList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getActiveLanguageListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAllActiveLanguageListRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setALlActiveLanguageList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getLanguageByidRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getLanguageByIdRequest,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setLanguageForEdit(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateData(action: { payload: any }) {
  yield delay(500);

  const form: LanguagesInterface = yield select(selectAdminLanguageForm);
  log(form, "formupdatevalue");

  try {
    const response: AxiosResponse = yield call(UpdateLanguageRequest, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.doGetALLLanguageList());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminLanguageRepoSaga() {
  yield takeLatest(actions.doGetALLLanguageList, getALLLanguageListRequest);
  yield takeLatest(
    actions.doGetAllActivelanguageList,
    getActiveLanguageListRequest
  );
  yield takeLatest(actions.getLanguageByIDForEdit, getLanguageByidRequest);
  yield takeLatest(actions.updateDataHelp, updateData);
}
