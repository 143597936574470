import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { SchoolProfileHostState, SchoolProfileInterface } from "../types";
import { log } from "../../../../utils/logger";

export const initialState: SchoolProfileHostState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 3,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  wishListIcon: false,
  schoolProfileList: [],
  schoolProfileYogaRetreatsList: [],
  schoolProfileInstructorList: [],
  totalWishlist: 0,
  schoolProfileOnlineRetreatsList: [],
  schoolProfileTeacherTrainingList: [],
  schoolReviewList: [],
  reviewStatus: false,
  totalReview: 0,
};

export const useClinicSlice = createSlice({
  name: "schoolProfileHost",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },

    doGetdoGetSchoolprofileHost: (state, action: PayloadAction<string>) => {},
    doGetdoGetSchoolprofileHostYogaRetreats: (
      state,
      action: PayloadAction<string>
    ) => {},
    doGetdoGetSchoolprofileHostOnlineRetreats: (
      state,
      action: PayloadAction<string>
    ) => {},
    doGetdoGetSchoolprofileHostTeacherTraining: (
      state,
      action: PayloadAction<string>
    ) => {},
    doGetSchoolprofileHostInstrucors: (
      state,
      action: PayloadAction<string>
    ) => {},
    doGetSchoolHostReview: (state, action: PayloadAction<string>) => {},
    setViewProfileHostData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolProfileList = action.payload;
    },
    setViewProfileHostRetreatsData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolProfileYogaRetreatsList = action.payload;
    },
    setViewProfileHostOnlineRetreatsData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolProfileOnlineRetreatsList = action.payload;
    },
    setViewProfileHostTeacherTrainingData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolProfileTeacherTrainingList = action.payload;
    },
    setTotalReview: (state, action: PayloadAction<number>) => {
      state.totalReview = action.payload;
    },

    setViewProfileHostInstructorData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolProfileInstructorList = action.payload;
    },
    schoolReviewListData: (
      state,
      action: PayloadAction<Array<SchoolProfileInterface>>
    ) => {
      state.schoolReviewList = action.payload;
    },
    toggleShowReview: (state) => {
      state.reviewStatus = !state.reviewStatus;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
