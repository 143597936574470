import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  SelectCurrencyFilterFilter,
  SelectDiscountPageNo,
  SelectDiscountPageSize,
  SelectEarningReportPageNo,
  SelectEarningReportPageSize,
  SelectListingsListFilter,
  SelectPaymentMethodFilter,
  SelectPaymentStatusFilter,
  SelectPaymentsPageNo,
  SelectPaymentsPageSize,
  SelectSchoolListFilter,
  SelectSearch,
  SelectStatusFilter,
  SelectVoucherCodesFormData,
  SelectVoucherPageNo,
  SelectVoucherPageSize,
  SelectendDate,
  Selectstartdate,
  SelectSortDirEarning,
  SelectSortColumnEarning,
  SelectSortDirVoucher,
  SelectSortColumnVoucher,
  SelectSortDirDiscount,
  SelectSortColumnDiscount,
  SelectSortDir,
  SelectSortColumn,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AddDiscountCode,
  AddVoucherCode,
  deleteVoucher,
  getAllAffliateListRequest,
  getAllDiscountCodeListRequest,
  getAllEarningReportListRequest,
  getAllPaymentsListRequest,
  GetAlluserListRequest,
  getAllVoucherCodeListRequest,
  GetEarningByIdRequest,
  GetEarningReportRequest,
  getUpdateRecordPayments,
  GetVoucherDataByIdRequest,
  GetVoucherForuserRequest,
} from "../../../../utils/request";
import { DiscountCodesInterface, VoucherCodesInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { BASEURL, BASE_URL, BASE_URLAPI } from "../../../../environment";

export function* getAllPaymentsListDataRequest(action: { payload: any }) {
  yield delay(500);

  const PageNo: number = yield select(SelectPaymentsPageNo);
  const pageSize: number = yield select(SelectPaymentsPageSize);
  const search: string = yield select(SelectSearch);
  const school: [] = yield select(SelectSchoolListFilter);
  const status: number = yield select(SelectStatusFilter);
  const paymentStatus: string[] = yield select(SelectPaymentStatusFilter);
  const paymentMethod: string[] = yield select(SelectPaymentMethodFilter);
  const currencyFilter: string[] = yield select(SelectCurrencyFilterFilter);
  const SortColumn: string = yield select(SelectSortColumn);
  const SortDir: string = yield select(SelectSortDir);

  try {
    const data = {
      schoolFilter: school,
      search: search,
      status: paymentStatus,
      currencyFilter: currencyFilter,
      paymentMethodFilter: paymentMethod,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllPaymentsListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalPaymentList(response.data[0].meta.total));
    yield put(actions.setAllPaymentsList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getEarningReportsListRequest(action: { payload: any }) {
  yield delay(500);

  const PageNo: number = yield select(SelectEarningReportPageNo);
  const pageSize: number = yield select(SelectEarningReportPageSize);
  const startdate: string = yield select(Selectstartdate);
  const endDate: string = yield select(SelectendDate);
  const school: [] = yield select(SelectSchoolListFilter);
  const SortColumn: string = yield select(SelectSortColumnEarning);
  const SortDir: string = yield select(SelectSortDirEarning);
  try {
    const data = {
      schoolFilter: school,
      startDate: startdate,
      endDate: endDate,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllEarningReportListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.settopData(response.data[0].counts[0]));
    yield put(actions.setTotalEarningReportList(response.data[0].meta.total));
    yield put(actions.setEarningReportsList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* addVoucherCodeDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: VoucherCodesInterface = yield select(SelectVoucherCodesFormData);
  if (form.voucherName.length === 0) {
    CustomToast("Please Enter Voucher Name", "ERROR");
    return;
  }
  if (form.amount.length === 0) {
    CustomToast("Please Enter Amount", "ERROR");
    return;
  }

  if (form.email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (form.note.length === 0) {
    CustomToast("Please Enter Note", "ERROR");
    return;
  }
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(AddVoucherCode, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAllDiscontCodeListDataRequest(action: { payload: any }) {
  yield delay(500);

  const PageNo: number = yield select(SelectDiscountPageNo);
  const pageSize: number = yield select(SelectDiscountPageSize);
  const search: string = yield select(SelectSearch);
  const listing: [] = yield select(SelectListingsListFilter);
  const school: [] = yield select(SelectSchoolListFilter);
  const SortColumn: string = yield select(SelectSortColumnDiscount);
  const SortDir: string = yield select(SelectSortDirDiscount);
  try {
    const data = {
      schoolFilter: school,
      retreatFilter: listing,
      search: search,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllDiscountCodeListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalDiscountList(response.data.data[0].meta.total));
    yield put(actions.setAllDiscountList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAllVoucherListDataRequest(action: { payload: number }) {
  yield delay(500);

  const PageNo: number = yield select(SelectVoucherPageNo);
  const pageSize: number = yield select(SelectVoucherPageSize);
  const search: string = yield select(SelectSearch);
  const SortColumn: string = yield select(SelectSortColumnVoucher);
  const SortDir: string = yield select(SelectSortDirVoucher);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllVoucherCodeListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}&search=${search}&status=${
        action.payload
      }${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""}${
        SortDir.length > 0 ? "&sortDir=" + SortDir : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
    }
    yield put(actions.setTotalVoucherList(response.data.data[0].meta.total));
    yield put(
      actions.setAllVoucherCodesListList(response.data.data[0].results)
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* deleteVoucherDataRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.id == "") {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      deleteVoucher,
      action.payload.id
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getVoucherByIdDataRequest(action: {
  payload: { id: string; callback: () => void };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetVoucherDataByIdRequest,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setVoucherCodesFormData(response.data.data[0]));
    yield call(action.payload.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAllUserListDataRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetAlluserListRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      // CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setAlluserList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* deleteEarningReportRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.id == "") {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      deleteVoucher,
      action.payload.id
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UpdateStatusVoucherListDataRequest(action: {
  payload: { key: string; value: any; callback: any };
}) {
  yield delay(500);

  try {
    yield call(action.payload.callback(action.payload.value));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getVoucherForuserDataRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetVoucherForuserRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setVoucherForUserData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* dogetdoExportBYID(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetEarningByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    window.open(BASEURL + "/csv/" + response.data.filename, "_blank");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* dogetdoExportReport() {
  yield delay(500);
  const startdate: string = yield select(Selectstartdate);
  const endDate: string = yield select(SelectendDate);
  const school: [] = yield select(SelectSchoolListFilter);
  try {
    const data = {
      schoolFilter: school,
      startDate: startdate,
      endDate: endDate,
    };
    const response: AxiosResponse = yield call(GetEarningReportRequest, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    window.open(BASEURL + "/csv/" + response.data.filename, "_blank");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRecordPaymentIdRequest(action: {
  payload: { id: string; status: any; callback: any };
}) {
  yield delay(500);
  try {
    const data = {
      id: action.payload.id,
      status: action.payload.status,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getUpdateRecordPayments, data);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* adminPaymentsRepoSaga() {
  yield takeLatest(actions.doGetPaymentsList, getAllPaymentsListDataRequest);
  yield takeLatest(
    actions.doGetDiscountCodeList,
    getAllDiscontCodeListDataRequest
  );
  yield takeLatest(
    actions.doGetEarningReportsList,
    getEarningReportsListRequest
  );
  yield takeLatest(actions.AddVoucherCode, addVoucherCodeDataRequest);
  yield takeLatest(actions.doGetVoucherDataById, getVoucherByIdDataRequest);
  yield takeLatest(actions.deleteVoucher, deleteVoucherDataRequest);
  yield takeLatest(actions.doGetVoucherList, getAllVoucherListDataRequest);
  yield takeLatest(actions.doGetUserList, getAllUserListDataRequest);
  yield takeLatest(
    actions.updateStatusFilterFormValue,
    UpdateStatusVoucherListDataRequest
  );
  yield takeLatest(
    actions.DeleteAdminEarningReports,
    deleteEarningReportRequest
  );
  yield takeLatest(actions.doGetVoucherUserList, getVoucherForuserDataRequest);
  yield takeLatest(actions.doExportBYID, dogetdoExportBYID);
  yield takeLatest(actions.doExportReport, dogetdoExportReport);
  yield takeLatest(
    actions.doUpdateRecordPaymentId,
    doUpdateRecordPaymentIdRequest
  );
}
