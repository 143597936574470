import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { CustomerSupportState } from "../types";
import { log } from "../../../../utils/logger";

export const initialState: CustomerSupportState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  customerSupportList: [],
  customerSupportFormData: {
    id: "",
    fullName: "",
    email: "",
    query: "",
  },
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
};

export const useClinicSlice = createSlice({
  name: "customerSupport",
  initialState,
  reducers: {
    updateSchoolCustomerSupportFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `customerSupportFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.customerSupportFormData.id = "";
      state.customerSupportFormData.fullName = "";
      state.customerSupportFormData.email = "";
      state.customerSupportFormData.query = "";
    },

    AddQuery: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your language add");
      // state.SchoolPaymentsList.push(state.SchoolPaymentsFormData);
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: SchoolCustomerSupportSliceKey,
} = useClinicSlice;
