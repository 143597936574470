export interface UserBookingState {
  ItinararyData: any;
  contactDetail: any;
  ItinararyForm: ItinararyFormInterface;
  contactDetailForm: ContactDetailInterface;
  priceDetailForm: GetPriceInterface;
  totalPriceData: {};
  depositType: PaymentDepositTypeEnum;
  AddCard: AddCardInterface;
  BookingId: string;
  PrivateKey: string;
  showAddCard: boolean;
  upcomingBookingList: [];
  pastBookingList: [];
  TotalpastBookingLength: number | 0;
  TotalUpComingBookingLength: number | 0;
  BookingsListPagination: PaginationInterface;
  TransactionDetail: TransactionDetailInterface;
  progressBar: number;
  visitTab: any;
  SendVerificationOtpRequest: SendVerificationOtpRequest;
  isCouponAppliedOrNot: boolean;
  Loader : boolean;
  paySuccessLoader:boolean
  contactLoader:boolean
}

export interface TransactionDetailInterface {
  price: string;
  transactionId: String;
  bookingId: String;
  currencyCode: string;
}
export interface PaginationInterface {
  PastBookingsPageNo: number;
  UpComingBookingsPageNo: number;
}
export interface GetPriceInterface {
  inquiryId: string;
  packageId: string;
  couponCode: string;
}
export enum PaymentDepositTypeEnum {
  PARTIALLY_DEPOSIT = 1,
  TOTAL_PRICE = 2,
}
export enum PaymentMethodEnum {
  CREDIT_CARD = 1,
  PAYPAL = 2,
}
export enum OtpTypeEnum {
  WHATSAPP = 1,
  TEXT_MSG = 2,
}
export interface AddCardInterface {
  bookingId: string;
  paymentMethod: PaymentMethodEnum;
  accountHolderName: string;
  billingAddress: string;
  postalCode: string;
  country: string;
  cardNumber: string;
  cardExpiryDate: string;
  cardCVV: string;
}
export interface SendVerificationOtpRequest {
  name: string | "";
  email: string | "";
  phoneNo: string | "";
  otpType: OtpTypeEnum;
}

export interface ItinararyFormInterface {
  id: string;
  userId: string;
  schoolId: string;
  status: number;
  retreatId: string;
  arrivalDate: string;
  couponCode: string;
  acceptOrDeclineAvailablity: number;
  packageId: string;
  packageIdFirst: string;
  noOfPeople: number;
  price: number;
  roomAccommodationName: string;
  roomAccommodationType: string;
  roomAccommodation: string;
  requiredDeposit: number;
  remainingAmount: number;
  cancellationPolicy: number;
  newPolicy: "";
  paymentSchedule: number;
  paymentDaysBeforeArrival: number;
}
export interface ContactDetailInterface {
  email: string;
  emailVerify: false,
 emailCheck :string;
  phoneNoVerify: boolean;
  id: string;
  phoneNo: string;
  VerificationPhoneNumber :string;
  whatsAppNumber: string;

}

export type InitialCustomerSupportState = UserBookingState;
