export enum RetreatsTypeEnum {
  Online = 1,
  Offline = 2,
  All = 3,
}
export enum RetreatSortTypeEnum {
  All = 0,
  Recommended = 1,
  Popularity = 2,
  DurationShortToLong = 3,
  DurationLongToShort = 4,
  PriceLowToHigh = 5,
  PriceHighToLow = 6,
  ReviewScore = 7,
}
export enum ExpertiseLevelEnum {
  BIGINNER = 1,
  INTERMEDIATE = 2,
  ADVANCED = 3,
}
export enum CancellationPolicyTypeEnum {
  FULLY_REFUNDABLE = 1,
  PARTIALLY_REFUNDABLE = 2,
  ADD_YOUR_OWN = 3,
  NON_REFUNDABLE = 4,
}
export interface price {
  minprice: number;
  maxprice: number;
}
export interface listingCountryInterface {
  _id: string;
  name: string;
  id: string;
  total: number;
  state: string;
}
export interface ListingFormState {
  search: string;
  minprice: number;
  maxprice: number;
  minduration: number;
  maxduration: number;
  retreatFeatured: number;
  cancellationPolicyFilter: [];
  activityFilter: string[];
  retreatStyleFilter: [];
  foodFilter: [];
  durationFilter: [];
  expertiseLevelFilter: [];
  pageSize: number;
  pageNo: number;
  userId: string;
  sortData: RetreatSortTypeEnum;
  retreatTypeFilter: RetreatsTypeEnum;
  Loading: boolean;
  date: any;
  country: string;
  // state: string;
  city: string;
}
export interface HomeSearchFormState {
  activity: [];
  defaultSearch: [];
  listings: [];
  locations: [];
  search: [];
}

export interface ListingFilterState {
  ListingForm: ListingFormState;
  RetreatList: any;
  Fooddata: [];
  search: string;
  Styledata: [];
  skillLevel: [];
  ActivityData: [];
  feature: [];
  cancellationPolicy: [];
  ProgramDuration: [];
  SearchLog: HomeSearchFormState;
  location: [];
  countryList: listingCountryInterface[];
  stateList: listingCountryInterface[];
  cityList: listingCountryInterface[];
  totalListing: number;
  handelChange: number;
}

export type InitialListingFilterStatee = ListingFilterState;
