import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import { actions } from "./slice";
import {
  GetFoodStyleActivityData,
  GetSearchLogListData,
  IndexFilterRequest,
  ListingCityDataRequest,
  ListingCountryDataRequest,
  ListingStateDataRequest,
} from "../../../../utils/request";
import { ListingFormState, RetreatSortTypeEnum } from "../types";
import { SelectListingForm, SelectSearchLogSearch } from "./selector";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* getFilterRequest(action: { payload: ListingFormState }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    yield put(actions.setListingForm(action.payload));
    const response: AxiosResponse = yield call(
      IndexFilterRequest,
      action.payload,
      ""
    );
    yield put(actions.setLoading(false));
    if (response && !response.data.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setFilterFormValues(response.data));
    yield put(actions.setTotalListinglist(response.data.data.meta.total));
    yield put(actions.setRetreatList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getFoodStyleActivtyData(action: { payload: { qa: any } }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const data = {
      minprice: Number(action.payload.qa.minprice) || 0,
      durationFilter: action.payload.qa.durationFilter || [],
      maxprice: Number(action.payload.qa.maxprice) || 0,
      maxduration: Number(action.payload.qa.maxduration) || 0,
      minduration: Number(action.payload.qa.minduration) || 0,
      foodFilter: action.payload.qa.foodFilter || [],
      expertiseLevelFilter: action.payload.qa.expertiseLevelFilter || [],
      activityFilter: action.payload.qa.activityFilter || [],
      retreatFeatured: action.payload.qa.retreatFeatured || 0,
      retreatStyleFilter: action.payload.qa.retreatStyleFilter || [],
      retreatTypeFilter: Number(action.payload.qa.retreatTypeFilter) || 3,
      search: action.payload.qa.search || "",
      cancellationPolicyFilter:
        action.payload.qa.cancellationPolicyFilter || [],
      date: action.payload.qa.date || "",
      country: action.payload.qa.country || "",
      city: action.payload.qa.city || "",
    };
    const response: AxiosResponse = yield call(GetFoodStyleActivityData, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setFoodActivityAndStyleData({
        activity: response.data.activity,
        food: response.data.meals,
        style: response.data.retreatstyle,
        skillLevel: response.data.skillLevel,
        feature: response.data.featured,
        cancellationPolicy: response.data.cancellationPolicy,
        ProgramDuration: response.data.programDuration,
        location: response.data.location,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getSearchLogList() {
  yield delay(500);

  try {
    const searhlogsearch: string = yield select(SelectSearchLogSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetSearchLogListData,
      `?search=${searhlogsearch}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSearchLogList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* clearFilterFormCallablck(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* clearLocationFilterFormCallback(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getListingCountryDataRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(ListingCountryDataRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setListingCountryList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getListingStateDataRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      ListingStateDataRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setListingStateList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getListingCityDataRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      ListingCityDataRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setListingCityList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* ListingFilterRepoSaga() {
  yield takeLatest(actions.doFilter, getFilterRequest);
  yield takeLatest(actions.dogetfoodActivityStyleData, getFoodStyleActivtyData);
  yield takeLatest(actions.DoGetSearchLogList, getSearchLogList);
  yield takeLatest(actions.clearFilterForm, clearFilterFormCallablck);
  yield takeLatest(
    actions.clearLocationFilterForm,
    clearLocationFilterFormCallback
  );
  yield takeLatest(
    actions.doGetListingCountryData,
    getListingCountryDataRequest
  );
  yield takeLatest(actions.doGetListingStateData, getListingStateDataRequest);
  yield takeLatest(actions.doGetListingCityData, getListingCityDataRequest);
}
