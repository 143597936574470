import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  OnlineExperienceState,
  OnlineTeacherTrainingInterface,
  OnlineRetreatsInterface,
} from "../types";

export const initialState: OnlineExperienceState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  onlineYogaRetreatsList: [],
  onlineTeacherTrainingList: [],
};

export const useClinicSlice = createSlice({
  name: "onlineExperience",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    doGetOnlineYogaRetreatsList: (state) => {},
    doGetOnlineTeacherTrainingList: (state) => {},

    setOnlineYogaRetreatsListData: (
      state,
      action: PayloadAction<Array<OnlineRetreatsInterface>>
    ) => {
      state.onlineYogaRetreatsList = action.payload;
    },
    setOnlineTeacherTrainingList: (
      state,
      action: PayloadAction<Array<OnlineTeacherTrainingInterface>>
    ) => {
      state.onlineTeacherTrainingList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
