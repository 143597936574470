export enum RetreatsTypeEnum {
  Online = 1,
  Offline = 2,
  ALL = 3,
}
export interface CreateListingForm {
  id: string;
  retreatType: RetreatsTypeEnum;
  activity: string;
  country: string;
  language: string;
  websiteUrl: string;
  file: any[];
}
export enum RetreatStyleTypeEnum {
  FIXED = 1,
  TEMPORARY = 2,
}
export enum RetreatOverviewTypeEnum {
  YOGA_RETREAT = 1,
  YOGA_TEACHER_TRAINING = 2,
  AYURVEDA_RETREAT = 3,
  THERAPY = 4,
  AYURVEDA = 5,
  WELLNESS_RETREAT = 6,
  SURFING = 7,
}
export interface RetreatStyleModel {
  name: string;
  id: string;
  listingType: ListingTypeEnum;
  type: RetreatStyleTypeEnum;
  schoolId: string;
  retreatId: string;
}
export enum ExpertiseLevelEnum {
  BIGINNER = 1,
  INTERMEDIATE = 2,
  ADVANCED = 3,
}
export interface OverViewInterface {
  id: string;
  websiteUrl: string;
  file: any[];
  retreatTitle: string;
  retreatType: RetreatOverviewTypeEnum;
  retreatStylesForm: RetreatStyleModel;
  retreatStyles: RetreatStyleModel[];
  expertiseLevel: ExpertiseLevelEnum;
  overview: string;
  retreatId: string;
  activity: string;
  isDraft: boolean;
  count: number;
}
export interface dailyScheduleModel {
  time: string;
  schedule: string;
}
export interface ItineraryInterface {
  id: string;
  programDuration: number;
  days_instruction: number;
  maxGroupSize: number;
  minGroupSize: number;
  itinerary: any;
  retreatId: string;
  dailyScheduleForm: dailyScheduleModel;
  dailySchedule: dailyScheduleModel[];
  isDraft: boolean;
  count: number;
}
export interface LocationInterface {
  id: string;
}
export interface AllActivityInterface {
  name: string;
  id: string;
  listingType: ListingTypeEnum;
}
export enum RequestTypeEnum {
  PENDING = 1,
  APPROVED = 2, //approve
  REJECTED = 3, // rejected
  REQUEST_TO_PUBLISH = 4, //pending
  ALL = 5,
}
export interface VisitedLinksInterface {
  overview: boolean; //working
  location: boolean; //working
  itinarary: boolean;
  foodAndDrinks: boolean; //working
  inclushionAndExclushion: boolean;
  grabTheAttenshion: boolean;
  instructor: boolean;
  arrivalInformation: boolean;
  price: boolean;
  retreatCalender: boolean;
  ranking: boolean;
  bookingPolicy: boolean;
  photos: boolean;
  video: boolean;
  autoReply: boolean;
  cleanAndSafe: boolean;
  faq: boolean;
}
export interface API_ResponceInterface {
  overview_API_Responce: boolean; // done
  location_API_Responce: boolean; // done
  itinarary_API_Responce: boolean; // done
  foodDrink_API_Responce: boolean; //done
  inclushionExclushioin_API_Responce: boolean; // done
  grabTheAtten_API_Responce: boolean; //done
  instructor_API_Responce: boolean; //done
  arrivalInfo_API_Responce: boolean; // done
  price_API_Responce: boolean;
  calender_API_Responce: boolean;
  ranking_API_Responce: boolean; //done
  bookingPolicy_API_Responce: boolean; // done
  photo_API_Responce: boolean; // done
  video_API_Responce: boolean; // done
  autoReply_API_Responce: boolean; // done
  // cleanMeasure_API_Responce: boolean;
  faq_API_Responce: boolean;
}
export enum ListingTypeEnum {
  ONLINE = 1,
  OFFLINE = 2,
  BOTH = 3,
}
export interface SchoolListingState {
  CreateListingForm: CreateListingForm;
  VisitedLinks: VisitedLinksInterface;
  ListingList: CreateListingForm[];
  AllMainActivityList: AllActivityInterface[];
  OverviewForm: OverViewInterface;
  OverViewList: OverViewInterface[];
  LocationForm: LocationInterface;
  LocationList: LocationInterface[];
  ItineraryFormData: ItineraryInterface;
  ItineraryList: ItineraryInterface[];
  pageNo: number;
  overviewModalType: number;
  itineraryModalType: number;
  OverviewRetreatStyleFixedAdmin: RetreatStyleModel[];
  OverviewRetreatStyleTempSchool: RetreatStyleModel[];
  ListingsId: string;
  statusFilter: RequestTypeEnum;
  totalRetreats: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  offlineOrOnline: any;
  progressBarModal: boolean;
  loading: boolean;
  Itinararyloading: boolean;
  apiResponce: API_ResponceInterface;
  isAdmin: string | null;
}

export type InitialMileStoneStata = SchoolListingState;
