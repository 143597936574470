import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolPayments) {
      return state.schoolPayments;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolPaymentsForm = createSelector(
  [selectDomain],
  (state) => state.SchoolPaymentsFormData
);
export const selectAllCountriesListData = createSelector(
  [selectDomain],
  (state) => state.allCountriesList
);
export const selectAllStateListData = createSelector(
  [selectDomain],
  (state) => state.allStateList
);
export const selectAllCityListData = createSelector(
  [selectDomain],
  (state) => state.allCityList
);
export const selectAllCurrencyListData = createSelector(
  [selectDomain],
  (state) => state.allCurrencyList
);
export const selectAllActiveCurrencyListData = createSelector(
  [selectDomain],
  (state) => state.allActiveCurrencyList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
