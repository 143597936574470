import { FileUploadItems } from "../Media/types";

export enum CancellationPolicyTypeEnum {
  FULLY_REFUNDABLE = 1,
  PARTIALLY_REFUNDABLE = 2,
  ADD_YOUR_OWN = 3,
  NON_REFUNDABLE = 4,
}
export enum BookingPaymentScheduleEnum {
  ON_ARRIVAL = 1,
  ON_DEPARTURE = 2,
  DAYS_BEFORE_ARRIVAL = 3,
}

export interface ListingBookingPolicyInterface {
  id: string;
  requiredDeposit: number;
  remainingAmount: number;
  paymentSchedule: BookingPaymentScheduleEnum;
  paymentDaysBeforeArrival: number | 0;
  cancellationPolicy: CancellationPolicyTypeEnum;
  newPolicy: string | "";
  retreatId: string;
  isDraft: boolean;
  count: number;
}
export interface listingPhotoModel {
  photo: string;
  fileName: string;
}
export interface ListingPriceInterface {
  id: string;
  packageId: string;
  roomAccommodation: string;
  roomAccommodationType: string;
  noOfPeople: number;
  curName: string;
  curCode: string;
  currencyCode: string;
  price: any;
  note: string;
  instantlyBookable: boolean | false;
  listingPhoto: listingPhotoModel[];
  roomAccommodationName: string;
  photos: listingPhotoModel[];
  isDraft: boolean;
  count: number;
}
export interface ListingRankingInterface {
  id: string;
  rankingScore: number;
  retreatId: string;
  isDraft: boolean;
  count: number;
}
export interface ListingPackagePriceInterface {
  maximumPrice: string;
  minimumPrice: string;
}

export interface SchoolListingPackagesState {
  bookingPolicyList: ListingBookingPolicyInterface[];
  bookingPolicyFormData: ListingBookingPolicyInterface;
  bookingPolicyData: ListingBookingPolicyInterface[];
  priceFormData: ListingPriceInterface;
  priceList: ListingPriceInterface[];
  rankingList: ListingRankingInterface[];
  rankingData: ListingRankingInterface[];
  rankingFormData: ListingRankingInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  BookingModalType: number;
  PriceModalType: number;
  CropModal: boolean;
  RankingModalType: number;
  imageArray: string[];
  listingPackagePrice: ListingPackagePriceInterface;
  priceLoading: boolean;
  mainPagePriceLoader: boolean;
  bookingLoader: boolean;
  uploadFiles: FileUploadItems[];
  uploadingCurrentIndex: number;
}

export type InitialSchoolListingPackagesState = SchoolListingPackagesState;
