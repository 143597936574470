import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetAdminDashboardData,
  GetSchoolDashboardOverallClicksListingsRequest,
  GetSchoolDashboarddataRequest,
  getHelpContextRequest,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import {
  SelectCreatedOnFilter,
  SelectDateFilter,
  selectoverAllClicksListingsFilter,
} from "./selector";
import queryString from "query-string";
import { DateInterface } from "../types";

export function* doGetSchoolDashboardDetailsRequest(action: {
  payload: { schoolId: string; callback: () => void };
}) {
  yield delay(500);
  const DateFilter: { value: DateInterface } = yield select(SelectDateFilter);
  const data: any = queryString.stringify({
    startDate: DateFilter ? DateFilter.value.start : "",
    endDate: DateFilter ? DateFilter.value.end : "",
  });
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  try {
    const response: AxiosResponse = yield call(
      GetSchoolDashboarddataRequest,
      `?schoolId=${action.payload.schoolId}&dateFilter=${createdOnFilter}${
        data !== "endDate=&startDate=" ? "&" + data : ""
      }`
    );
  
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setbookingGraph(response.data.bookingGraph));
    yield put(actions.setrevenueGraph(response.data.revenue));
    yield put(actions.setclicksGraph(response.data.overallClicks));
    yield put(actions.setlabelGraph(response.data.dataType));
    yield put(actions.setAverageReplyScore(response.data.averageReplyScore));
    yield put(actions.setAverageReplyTime(response.data.averageReplyTime));

    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetOverAllClicksonListingRequest(action: {
  payload: { schoolId: string; callback: () => void };
}) {
  yield delay(500);
  const DateFilter: { value: DateInterface } = yield select(SelectDateFilter);
  const data: any = queryString.stringify({
    startDate: DateFilter ? DateFilter.value.start : "",
    endDate: DateFilter ? DateFilter.value.end : "",
  });
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const filter: number = yield select(selectoverAllClicksListingsFilter);
  try {
    const response: AxiosResponse = yield call(
      GetSchoolDashboardOverallClicksListingsRequest,
      `?schoolId=${
        action.payload.schoolId
      }&filter=${filter}&dateFilter=${createdOnFilter}${
        data !== "endDate=&startDate=" ? "&" + data : ""
      }`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* SchoolDashboardRepoSaga() {
  yield takeLatest(
    actions.doGetSchoolDashboardDetails,
    doGetSchoolDashboardDetailsRequest
  );
  yield takeLatest(
    actions.doGetTotalListingsClicksList,
    doGetOverAllClicksonListingRequest
  );
}
