import { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import { SchoolUpdatePasswordState } from "../types";
import { log } from "../../../../utils/logger";

export const initialState: SchoolUpdatePasswordState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  SchoolPasswordList: [],
  SchoolPasswordFormData: {
    id: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
  showPassword: false,
  currentPassword: false,
  newPassword: false,
};

export const useClinicSlice = createSlice({
  name: "schoolUpdatePassword",
  initialState,
  reducers: {
    updateSchoolPaymentsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `SchoolPasswordFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.SchoolPasswordFormData.id = "";
      state.SchoolPasswordFormData.currentPassword = "";
      state.SchoolPasswordFormData.newPassword = "";
      state.SchoolPasswordFormData.confirmNewPassword = "";
    },

    UpdateSchoolPassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },

    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggalShowPassword: (state) => {
      state.showPassword = !state.showPassword;
    },
    toggalShowCurrentPassword: (state) => {
      state.currentPassword = !state.currentPassword;
    },
    toggalShowNewPassword: (state) => {
      state.newPassword = !state.newPassword;
    },
  },
});

export const {
  reducer,
  actions,
  name: SchoolUpdatePasswordSliceKey,
} = useClinicSlice;
