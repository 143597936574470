import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.requestToReservation) {
      return state.requestToReservation;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectRequestToReservationForm = createSelector(
  [selectDomain],
  (state) => state.requestToReservationFormData
);
export const selectRequestToReservationListData = createSelector(
  [selectDomain],
  (state) => state.requestToReservationList
);
export const selectContextListListData = createSelector(
  [selectDomain],
  (state) => state.contextList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const SelectPackageId = createSelector(
  [selectDomain],
  (state) => state.packageId
);
export const selectRetreatId = createSelector(
  [selectDomain],
  (state) => state.retreatId
);
export const selectPackageDetailsList = createSelector(
  [selectDomain],
  (state) => state.packageDetailsList
);
export const selectpackageDetailsListRetreat = createSelector(
  [selectDomain],
  (state) => state.packageDetailsListRetreat
);
export const selectEnquiryID = createSelector(
  [selectDomain],
  (state) => state.EnquiryID
);
export const selectOpenModal = createSelector(
  [selectDomain],
  (state) => state.openModal
);
export const selectEnquiryLoading = createSelector(
  [selectDomain],
  (state) => state.enquiryLoading
);
