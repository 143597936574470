import config from "../config.json";
import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer, forceReducerReload } from "redux-injectors";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createReducer } from "./reducers";

// import { createReducer } from "./reducers";
const IS_DEVELOPEMENT = config.LOG;

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  let middlewares: any = [sagaMiddleware];

  // LOG ONLY IN DEVELOPMENT/STAGING PRODUCTION OPTIMIZATIONS
  if (!IS_DEVELOPEMENT) {
    middlewares = [...middlewares];
  }
  if (IS_DEVELOPEMENT) {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true,
        timestamp: true,
      })
    );
  }
  const enhancers: any = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ["schoolListingCalenderState/setSavedDateRange"],
          // Ignore these field paths in all actions
          ignoredActionPaths: [
            "meta.arg",
            "payload.callback",
            "payload.data",
            "adminSchool.Registration.expireDate",
          ],
          // Ignore these paths in the state
        },
      }).concat(middlewares),
    devTools: true,
    // IS_PRODUCTION || process.env.PUBLIC_URL.length > 0,
    enhancers,
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept("./reducers", () => {
      forceReducerReload(store);
    });
  }

  return store;
}
