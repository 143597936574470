import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";

import { AxiosResponse } from "axios";
import { SchoolUpdatePasswordInterface } from "../types";
import {
  SelectPageNo,
  SelectPageSize,
  selectSchoolPasswordForm,
  SelectSearch,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import { UpdateSchoolPasswordRequest } from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* SchoolPasswordUpdateAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  const form: SchoolUpdatePasswordInterface = yield select(
    selectSchoolPasswordForm
  );

  if (form.currentPassword.length == 0) {
    CustomToast("Please Enter Current Password", "ERROR");
    return;
  }

  if (form.newPassword.length == 0) {
    CustomToast("Please Enter New Password", "ERROR");
    return;
  }
  if (form.newPassword.length > 0) {
    if (!regex.test(form.newPassword)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  if (form.confirmNewPassword.length == 0) {
    CustomToast("Please Enter Confirm New Password", "ERROR");
    return;
  }
  if (form.confirmNewPassword.length > 0) {
    if (!regex.test(form.confirmNewPassword)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  const data = {
    currentPassword: btoa(form.currentPassword),
    newPassword: btoa(form.newPassword),
  };
  try {
    const response: AxiosResponse = yield call(
      UpdateSchoolPasswordRequest,
      data
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* schoolUpdatePasswordRepoSaga() {
  yield takeLatest(
    actions.UpdateSchoolPassword,
    SchoolPasswordUpdateAddRequest
  );
}
