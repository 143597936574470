import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminReviewsState,
  ApprovedReviewsInterface,
  OverallImpressionFilter,
  PendingReviewsInterface,
  ReviewsInterface,
  ReviewStatusEnum,
  StatusFilterEnum,
} from "../types";

export const initialState: AdminReviewsState = {
  loading: false,
  pageSize: 15,
  totalRow: 0,
  search: "",
  pageNo: 1,
  approvedReviewsList: [],
  pendingReviewsList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  schoolFilter: [],
  ListingsListFilter: [],
  StatusFilter: StatusFilterEnum.All,
  totalPendingReview: 0,
  // approvedReviewFormData: { id: "" },
  pendingReviewsFormData: {
    id: "",
    userId: "",
    bookingId: "",
    firstName: "",
    lastName: "",
    email: "",
    school: "",
    retreatId: "",
    retreatTitle: "",
    overallImpression: 0,
    createdAt: "",
    updatedAt: "",
    status: 0,
  },
  reviewFormData: {
    id: "",
    valueForMoney: 0,
    accommodationAndFacilities: 0,
    foodRating: 0,
    locationRating: 0,
    qualityOfActivity: 0,
    likes: "",
    dislikes: "",
    overallImpression: 0,
    retreatId: "",
    updatedAt: "",
    createdAt: "",
    bookingId: "",
    status: ReviewStatusEnum.PENDING,
    reply: "",
    reviewStatus: false,
  },
  totalApprovedReview: 0,
  openDelete: false,
  openEdit: false,
  openPendingDelete: false,
  openPendingEdit: false,
  createdOnFilter: 0,
  date: null,
  overallImpressionFilter: OverallImpressionFilter.All,
  pendingReviewPageNo: 1,
  pendingReviewPageSize: 15,
  sortColumn: "",
  sortDir: "",
};

export const useClinicSlice = createSlice({
  name: "adminReviews",
  initialState,
  reducers: {
    updateAdminApprovedReviewFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `reviewFormData.${action.payload.key}`, action.payload.value);
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    doGetApproveReviewsList: (state) => {},
    doGetPendingReviewsList: (state) => {},
    doGetReviewsById: (
      state,
      action: PayloadAction<{ Id: string; callback: () => void }>
    ) => {},
    updateAdminReview: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setApproveRetreatsList: (
      state,
      action: PayloadAction<Array<ApprovedReviewsInterface>>
    ) => {
      state.approvedReviewsList = action.payload;
    },
    setSreatedOnFilter: (state, action: PayloadAction<any>) => {
      state.createdOnFilter = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    setPendingReviewsList: (
      state,
      action: PayloadAction<Array<PendingReviewsInterface>>
    ) => {
      state.pendingReviewsList = action.payload;
    },
    setTotalApprovedReviewList: (state, action: PayloadAction<number>) => {
      state.totalApprovedReview = action.payload;
    },
    setTotalPendingReviewList: (state, action: PayloadAction<number>) => {
      state.totalPendingReview = action.payload;
    },
    deleteAdminReview: (state, action: PayloadAction<any>) => {},

    setReviewDataInForm: (state, action: PayloadAction<ReviewsInterface>) => {
      state.reviewFormData = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggleOpenDelete: (state) => {
      state.openDelete = !state.openDelete;
    },
    toggleOpenEdit: (state) => {
      state.openEdit = !state.openEdit;
    },
    toggleOpenPendingDelete: (state) => {
      state.openPendingDelete = !state.openPendingDelete;
    },
    toggleOpenPendingEdit: (state) => {
      state.openPendingEdit = !state.openPendingEdit;
    },
    setPendingReviewPageNo: (state, action: PayloadAction<number>) => {
      state.pendingReviewPageNo = action.payload;
    },
    setPendingReviewPageSize: (state, action: PayloadAction<number>) => {
      state.pendingReviewPageSize = action.payload;
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.schoolFilter = action.payload;
    },
    ListingsListFilter: (state, action: PayloadAction<any>) => {
      state.ListingsListFilter = action.payload;
    },
    overallImpressionFilter: (state, action: PayloadAction<any>) => {
      state.overallImpressionFilter = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: AdminReviewsSliceKey } = useClinicSlice;
