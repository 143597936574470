import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import queryString from "query-string";
import {
  SelectAcceptOrDeclineBookingForm,
  SelectFilterForm,
  SelectFollowUpPageNo,
  SelectMassageForm,
  SelectMassagePackageForm,
  SelectNewMessagePageNo,
  SelectPaymentNotEnablePageNo,
  SelectSortColumn,
  SelectSortDir,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  DoSendmessageAPI,
  DoSendmessageWithAttachmentAPI,
  DoUpdateMessageBookingsDetail,
  DoUpdateMessagePackageDetail,
  DoUpdateMessagePackageTripDetail,
  DodeclineAvialabilityAPI,
  GeFollowUPRequest,
  GeMessagesListRequest,
  GeNewMessageRequest,
  GePackageDetailRequest,
  GePaymentNotEnabledyetRequest,
  GetPackageListRequest,
  PackageBookingFormListRequest,
  addAttachmentRequest,
} from "../../../../utils/request";
import {
  AcceptOrDeclineBookingrequest,
  BookingAvailabilityEnum,
  FilterInterface,
  MassageFormInterface,
  messageRequest,
} from "../types";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import { selectUserRole } from "../../../Login/redux/selector";
import { END, eventChannel } from "redux-saga";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";
export function* getPaymentNotEnabledYetListRequest(action: {
  payload: { schoolId: string };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPaymentNotEnablePageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GePaymentNotEnabledyetRequest,
      `?pageSize=${10}&pageNo=${pageNo}&schoolId=${action.payload.schoolId}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(
      actions.setPaymentNotEnableYetTotalRow(
        response.data[0].meta ? response.data[0].meta.total : 0
      )
    );
    yield put(actions.setPaymentNotEnableYetList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getFollowUPListRequest(action: {
  payload: { schoolId: string };
}) {
  yield delay(500);
  const usertype: number = yield select(selectUserRole);
  try {
    const pageNo: number = yield select(SelectFollowUpPageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GeFollowUPRequest,
      `?pageSize=${10}&pageNo=${pageNo}&schoolId=${action.payload.schoolId}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setFollowUpTotalRow(
        response.data[0].meta ? response.data[0].meta.total : 0
      )
    );
    yield put(actions.setFollowUpList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getNewMessageListRequest(action: {
  payload: { schoolId: string };
}) {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectNewMessagePageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GeNewMessageRequest,
      `?pageSize=${10}&pageNo=${pageNo}&schoolId=${action.payload.schoolId}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setNewMessageTotalRow(
        response.data[0].meta ? response.data[0].meta.total : 0
      )
    );
    yield put(actions.setNewMessageList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getPackageDetailRequestSaga(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GePackageDetailRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setpackageDetail(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getMassageListRequest(action: {
  payload: { schoolId: string };
}) {
  yield delay(500);
  try {
    const form: FilterInterface = yield select(SelectFilterForm);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const filterstatus = queryString.stringify({ status: form.status });
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GeMessagesListRequest,
      `?title=${form.title}&schoolId=${action.payload.schoolId}&email=${
        form.email
      }&${filterstatus || ""}&name=${form.name}&pageSize=${
        form.pageSize
      }&pageNo=${form.pageNumber}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    log(response.data, "dfddgdfgfgsgdfgdgd");
    yield put(actions.setAllMassageLength(response.data[0].meta.total));
    yield put(actions.setAllMessageList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getPackageList(action: {
  payload: { retreatId: string; inquiryId: string; callback: () => void };
}) {
  const data = {
    retreatId: action.payload.retreatId,
    inquiryId: action.payload.inquiryId,
  };
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetPackageListRequest, data);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setPackageList(response.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getPackageBookingFormList(action: {
  payload: { retreatId: string; inquiryId: string; callback: () => void };
}) {
  const data = {
    retreatId: action.payload.retreatId,
    inquiryId: action.payload.inquiryId,
  };
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      PackageBookingFormListRequest,
      data
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setPackageList(response.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UpdateTripDetail(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DoUpdateMessagePackageTripDetail,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UpdatePackageDetail(action: {
  payload: { data: any; callback: () => void };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DoUpdateMessagePackageDetail,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UpdateBookingDetail(action: {
  payload: { data: any; callback: () => void };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DoUpdateMessageBookingsDetail,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* dodeclineAvialability(action: {
  payload: { data: number; callback: any };
}) {
  yield delay(500);

  try {
    const massageForm: MassageFormInterface = yield select(
      SelectMassagePackageForm
    );
    const form: AcceptOrDeclineBookingrequest = yield select(
      SelectAcceptOrDeclineBookingForm
    );
    if (action.payload.data == BookingAvailabilityEnum.DECLINE) {
      if (form.deactiveInquiryReason.length == 0) {
        CustomToast("Please add the decline reason", "ERROR");
        return;
      }
    }

    const data = {
      inquiryId: massageForm.id,
      holdingTime: form.holdingTime,
      deactiveInquiryReason: form.deactiveInquiryReason,
      acceptOrDeclineAvailablity:
        action.payload.data || form.acceptOrDeclineAvailablity,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(DodeclineAvialabilityAPI, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DosendMessageBySchool(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: messageRequest = yield select(SelectMassageForm);
  if (!form.message) {
    CustomToast("Write A Message FIrst", "ERROR");
    return;
  }
  try {
    const usertype: number = yield select(selectUserRole);
    const massageForm: MassageFormInterface = yield select(
      SelectMassagePackageForm
    );
    const form: messageRequest = yield select(SelectMassageForm);
    const data = {
      message: form.message,
      inquiryId: massageForm.id,
      senderId: usertype == 3 ? massageForm.userId : massageForm.schoolId,
      receiverId: usertype == 3 ? massageForm.schoolId : massageForm.userId,
      messageType: form.messageType,
    };
    yield put(actions.setMessageLoading(true));
    const response: AxiosResponse = yield call(DoSendmessageAPI, data);
    yield put(actions.setMessageLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setMessageLoading(false));
    CatchBlockFunction(error);
  }
}
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = addAttachmentRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) {
      log("END");

      emit(END);
    } else emit(percentCompleted);
  });
  return [uploadPromise, chan];
}
export function* DosendMessageWithAttachmentBySchool(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setMessageLoading(true));
    const response: AxiosResponse = yield call(
      DoSendmessageWithAttachmentAPI,
      action.payload.data
    );
    yield put(actions.setMessageLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setMessageLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRetreatsCardRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setAttechmentData(response.data.data.name));
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* SchoolMassageRepoSaga() {
  yield takeLatest(
    actions.doGetPaymentNotEnabledYetList,
    getPaymentNotEnabledYetListRequest
  );
  yield takeLatest(actions.doGetNewMessageList, getNewMessageListRequest);
  yield takeLatest(actions.doGetFollowUPList, getFollowUPListRequest);
  yield takeLatest(actions.doGetAllMessageList, getMassageListRequest);

  yield takeLatest(actions.dogetPackageDetail, getPackageDetailRequestSaga);
  yield takeLatest(actions.doUpdateTripDetails, UpdateTripDetail);
  yield takeLatest(actions.doUpdatePackageDetails, UpdatePackageDetail);
  yield takeLatest(actions.doUpdateBookingDetails, UpdateBookingDetail);
  yield takeLatest(actions.dogetPackageList, getPackageList);
  yield takeLatest(
    actions.dogetPackageBookingFormList,
    getPackageBookingFormList
  );
  yield takeLatest(actions.dodeclineAvaibility, dodeclineAvialability);
  yield takeLatest(actions.dosendMessage, DosendMessageBySchool);
  yield takeLatest(actions.addDocument, FileUploadRetreatsCardRequest);
  yield takeLatest(
    actions.HandleSendMessageWithAttachment,
    DosendMessageWithAttachmentBySchool
  );
}
