import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolMyMoney) {
      return state.schoolMyMoney;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const SelectInvoiceList = createSelector(
  [selectDomain],
  (state) => state.InvoiceList
);
export const SelectPaymentList = createSelector(
  [selectDomain],
  (state) => state.PaymentList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const Selectinvoice = createSelector(
  [selectDomain],
  (state) => state.invoice
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectInvoicePageNo = createSelector(
  [selectDomain],
  (state) => state.invoicepageNo
);
export const SelectInvoicePageSize = createSelector(
  [selectDomain],
  (state) => state.invoicepageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectTotalPayments = createSelector(
  [selectDomain],
  (state) => state.totalPayments
);
export const SelectTotalInvoice = createSelector(
  [selectDomain],
  (state) => state.totalInvoice
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumnInvoice = createSelector(
  [selectDomain],
  (state) => state.sortColumnInvoice
);
export const SelectSortDirInvoice = createSelector(
  [selectDomain],
  (state) => state.sortDirInvoice
);
