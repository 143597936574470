import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import { getSchoolReviewList } from "../../../../utils/request";
import { SelectPageNo, SelectPageSize } from "./selector";
import { actions } from "./slice";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* getAllReviewListDataRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    const PageSize: number = yield select(SelectPageSize);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getSchoolReviewList,
      `?schoolId=${action.payload && action.payload.length > 0 ? action.payload : ""}&pageNo=${PageNo}&pageSize=${PageSize}`
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalReview(response.data.data[0].meta.total));
    yield put(actions.setSchoolReviewData(response.data.data[0].results));
    yield put(actions.setLoading(false));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* SchoolReviewRepoSaga() {
  yield takeLatest(actions.doGetSchoolReview, getAllReviewListDataRequest);
}
