import { call, delay, put, fork, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { actions as actionResponce } from "../../redux/slice";
import {
  SelectImageArray,
  selectSchoolListingBookingPolicyForm,
  selectSchoolListingPriceForm,
  selectSchoolListingPriceImagesForm,
  selectSchoolListingPriceList,
  selectSchoolListingRankingForm,
} from "./selector";
import {
  AddSchoolListingPricing,
  AddSchoolListingRanking,
  AddSchoolListingsBookingsPolicy,
  CheckPackageDeleteRequest,
  DeletePackageByID,
  getAllPackageListRequest,
  GetBookingPolicyDataByIdRequest,
  GetListingPackageByIdRequest,
  GetRankingByIdRequest,
  ListingsMediaUplodeRequest,
  SetListingPackagePrice,
  uplodeImageRequest,
} from "../../../../../utils/request";
import { actions } from "./slice";
import { actions as actionsModal } from "../../redux/slice";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  CancellationPolicyTypeEnum,
  ListingBookingPolicyInterface,
  ListingPriceInterface,
  ListingRankingInterface,
  listingPhotoModel,
} from "../types";
import { END, eventChannel } from "redux-saga";
import { actions as actionUploader } from "../../../../Frontend/uploader/redux/slice";
import { watchOnProgress } from "../../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = ListingsMediaUplodeRequest(
    payload,
    (progressEvent: any) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted == 100) emit(END);
      else emit(percentCompleted);
    }
  );

  return [uploadPromise, chan];
}

export function* bookingPolicyAddRequest(action: {
  payload: { isOnlineOffline: any; callback: any };
}) {
  yield delay(500);

  const form: ListingBookingPolicyInterface = yield select(
    selectSchoolListingBookingPolicyForm
  );
  yield put(actions.setbookingLoader(true));
  try {
    const response: AxiosResponse = yield call(
      AddSchoolListingsBookingsPolicy,
      form
    );
    yield put(actions.setbookingLoader(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setbookingLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getBookingPolicyByIdRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setbookingLoader(true));

    const response: AxiosResponse = yield call(
      GetBookingPolicyDataByIdRequest,
      action.payload
    );
    yield put(actions.setbookingLoader(false));

    yield put(actions.SetBookingModalType(response.data.model));
    if (response && !response.data.requestBookingPolicy[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.SetBookingPolicyFormDataById(
        response.data.requestBookingPolicy[0]
      )
    );
    yield put(
      actions.SetBookingReqPolicyFormDataById(
        response.data.requestBookingPolicy
      )
    );
    yield put(
      actionResponce.updateApiResponceValue({
        key: "bookingPolicy_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setbookingLoader(false));

    CatchBlockFunction(error);
  }
}
export function* rankingAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ListingRankingInterface = yield select(
    selectSchoolListingRankingForm
  );
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(AddSchoolListingRanking, form);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getGetRankingByIdRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      GetRankingByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));

    yield put(actions.SetRankingModalType(response.data.model));
    if (response && !response.data.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetRankingFormDataById(response.data.data[0]));
    yield put(actions.SetRankingData(response.data.data));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "ranking_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));

    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  const FoodPhotoForm: listingPhotoModel[] = yield select(
    selectSchoolListingPriceImagesForm
  );
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      FoodPhotoForm.filter(
        (val: listingPhotoModel) => response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setPhotoData({
          photo: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    yield put(actionsModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* PriceAddRequest(action: {
  payload: { retreat_id: any; callback: any };
}) {
  yield delay(500);

  const packageD: ListingPriceInterface = yield select(
    selectSchoolListingPriceList
  );
  const packageData = {
    packageData: packageD,
    retreatId: action.payload.retreat_id,
  };
  try {
    yield put(actions.setmainPagePriceLoader(true));
    const response: AxiosResponse = yield call(
      AddSchoolListingPricing,
      packageData
    );
    yield put(actions.setmainPagePriceLoader(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setmainPagePriceLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getAllPackage(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setmainPagePriceLoader(true));

    const response: AxiosResponse = yield call(
      getAllPackageListRequest,
      action.payload
    );
    yield put(actions.setmainPagePriceLoader(false));
    yield put(actions.setPackageList(response.data.data));

    if (response && !response.data) {
      return;
    }
    yield put(actions.SetPriceModalType(response.data.model));

    yield put(
      actionResponce.updateApiResponceValue({
        key: "price_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setmainPagePriceLoader(false));
    CatchBlockFunction(error);
  }
}

export function* deletePackageRequest(action: {
  payload: { packageId: string; listingsID: string };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      DeletePackageByID,
      `?packageId=${action.payload.packageId}&retreatId=${action.payload.listingsID}`
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (action.payload.packageId != "") {
      yield put(actions.getPackage(action.payload.listingsID));
    }
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* GetListingPackageByidRequest(action: {
  payload: { id: any; retreatid: number };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetListingPackageByIdRequest,
      `?packageId=${action.payload.id}&retreatId=${action.payload.retreatid}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setPackageForEdit(response.data[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* GetForCallback(action: {
  payload: { data: ListingPriceInterface; index: number; callback: any };
}) {
  yield delay(500);
  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UpdateInstantSaga(action: {
  payload: { value: any; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.updateInstantBookbableSaga(action.payload.value));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

/// package price ///
export function* doSetPackagePriceRequest(action: {
  payload: { code: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setPriceLoading(true));
    const currencyCode = { currencyCode: action.payload.code };
    const response: AxiosResponse = yield call(
      SetListingPackagePrice,
      currencyCode
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setPriceLoading(false));
    yield put(actions.setListingPackagePrice(response.data.data));
  } catch (error: any) {
    yield put(actions.setPriceLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doCheckPackageDeleteRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      CheckPackageDeleteRequest,
      action.payload.id
    );
    yield put(actions.setLoading(false));

    if (response.data.data == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* schoolListingsPackagesRepoSaga() {
  yield takeLatest(actions.AddRanking, rankingAddRequest);
  yield takeLatest(actions.AddBookingPolicyDetails, bookingPolicyAddRequest);
  yield takeLatest(actions.doAddImage, UploadRequest);
  yield takeLatest(actions.AddPackage, PriceAddRequest);
  yield takeLatest(actions.getPackage, getAllPackage);
  yield takeLatest(actions.doGetPackageforEdit, GetListingPackageByidRequest);
  yield takeLatest(
    actions.doGetBookingPolicyDataByID,
    getBookingPolicyByIdRequest
  );
  yield takeLatest(actions.doGetGetRankingDataByID, getGetRankingByIdRequest);
  yield takeLatest(actions.HandleInstantsetPackageForEdit, GetForCallback);
  yield takeLatest(actions.updateInstantBookbable, UpdateInstantSaga);
  yield takeLatest(actions.doSetPackagePeice, doSetPackagePriceRequest);
  yield takeLatest(actions.doCheckPackageDelete, doCheckPackageDeleteRequest);
}
