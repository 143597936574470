import { RetreatOverviewTypeEnum } from "../../School/Listings/types";
export interface ApproveRetreatsInterface {
  id: string;
  isActivte: boolean;
  retreatId: string;
  retreatTitle: string;
  location: string;
  hostName: string;
  activity: string;
  rankingScore: string;
  retreatType: string;
  createdOn: string;
  updatedOn: string;
  featured: boolean;
}
export interface PendingRetreatsInterface {
  id: string;
  retreatTitle: string;
  hostName: string;
  location: string;
  activity: string;
  isActivte: boolean;
  rankingScore: string;
  createdOn: string;
  updatedOn: string;
}
export interface RejectedRetreatsInterface {
  id: string;
  retreatId: string;
  retreatTitle: string;
  location: string;
  hostName: string;
  activity: string;
  rankingScore: string;
  retreatType: string;
  createdOn: string;
  updatedOn: string;
}
export enum RequestTypeEnum {
  PENDING = 1,
  APPROVED = 2, //approve
  REJECTED = 3, // rejected
  REQUEST_TO_PUBLISH = 4, //pending
  ALL = 5,
}
export interface approvePendingRequest {
  retreatId: string;
  status: RequestTypeEnum;
}
export interface rejectedPendingRequest {
  retreatId: string;
  status: RequestTypeEnum;
}
export interface requestToPublishRequestInterFace {
  retreatId: string;
  status: RequestTypeEnum;
  requestStatusFlag: boolean;
}
export interface requestToAdminPendingRequestInterFace {
  retreatId: string;
  status: RequestTypeEnum;
}
export interface requestToCountInterFace {
  retreatId: string;
  status: number;
  approvedRequestCount: number;
  countRequest: number;
  seoTitle: string;
  seoActivityTitle: string;
}
export interface requestToDeactiveInterFace {
  retreatId: string;
  isActive: boolean;
  deActivationReason: string;
}
export interface requestTostatusFilterInterface {
  retreatId: string;
  status: RequestTypeEnum;
}
export interface requestToFeaturedFilterInterface {
  id: string;
  featured: boolean;
}
export interface AdminRetreatsState {
  ApproveRetreatsList: ApproveRetreatsInterface[];
  PendingRetreatsList: PendingRetreatsInterface[];
  RejectedRetreatsList: RejectedRetreatsInterface[];
  ApprovePendingRequest: approvePendingRequest;
  RejectedPendingRequest: rejectedPendingRequest;
  RequestToPublishForm: requestToPublishRequestInterFace;
  RequestToAdminPendingForm: requestToAdminPendingRequestInterFace;
  RequestToCountForm: requestToCountInterFace;
  requestToDeactiveForm: requestToDeactiveInterFace;
  statusFilter: requestTostatusFilterInterface;
  featuredChangeForm: requestToFeaturedFilterInterface;
  featuredChangeList: requestToFeaturedFilterInterface[];
  editIndex: number;
  requestToPublishLoader: boolean;
  handleRequestToEdit: boolean;
  requestToActive: boolean;
  requestToDeactive: boolean;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  totalList: number;
  dissableCheck: boolean;
  schoolFilter: any;
  countryFilter: any;
  CategoryFilter: string[];
  commission: number;
  activityFilter: number;
  rankingFilter: number;
  sortColumn: string;
  sortDir: string;
  sortColumnPending: string;
  sortDirPending: string;
  sortColumnRejected: string;
  sortDirRejected: string;
}
export type InitialMileStoneStata = AdminRetreatsState;
