import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.FakeReviewState) {
      return state.FakeReviewState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectCustomerReviewForm = createSelector(
  [selectDomain],
  (state) => state.customerReviewFormData
);
export const selectCustomerReviewListData = createSelector(
  [selectDomain],
  (state) => state.customerReviewList
);
export const selectReviewModal = createSelector(
  [selectDomain],
  (state) => state.reviewModal
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);


export const SelectSchoolList = createSelector(
  [selectDomain],
  (state) => state.SchoolList
);
export const Selectretreatlist = createSelector(
  [selectDomain],
  (state) => state.retreatlist
);
export const selectuserList = createSelector(
  [selectDomain],
  (state) => state.userList
);
