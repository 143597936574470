import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  HomeSearchFormState,
  ListingFilterState,
  ListingFormState,
  RetreatSortTypeEnum,
  RetreatsTypeEnum,
} from "../types";

export const initialState: ListingFilterState = {
  ListingForm: {
    // price: {
    //   maxprice: 0,
    //   minprice: 0,
    // },
    activityFilter: [],
    cancellationPolicyFilter: [],
    expertiseLevelFilter: [],
    foodFilter: [],
    maxduration: 0,
    maxprice: 0,
    minduration: 0,
    minprice: 0,
    pageNo: 1,
    pageSize: 7,
    retreatFeatured: 0,
    retreatStyleFilter: [],
    retreatTypeFilter: RetreatsTypeEnum.All,
    search: "",
    sortData: RetreatSortTypeEnum.Recommended,
    userId: "",
    Loading: false,
    date: "",
    durationFilter: [],
    country: "",
    // state: "",
    city: "",
  },
  RetreatList: "",
  ActivityData: [],
  Fooddata: [],
  Styledata: [],
  totalListing: 0,
  SearchLog: {
    activity: [],
    defaultSearch: [],
    listings: [],
    locations: [],
    search: [],
  },
  skillLevel: [],
  location: [],
  handelChange: 0,
  search: "",
  feature: [],
  cancellationPolicy: [],
  ProgramDuration: [],
  countryList: [],
  stateList: [],
  cityList: [],
};

export const useClinicSlice = createSlice({
  name: "ListingFilterState",
  initialState,
  reducers: {
    updateListingsFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `ListingForm.${action.payload.key}`, action.payload.value);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.ListingForm.Loading = action.payload;
    },
    setFilterFormValues: (state, action: PayloadAction<any>) => {
      state.ListingForm.maxprice = action.payload.maxprice;
      state.ListingForm.maxduration = action.payload.maxduration;
      state.ListingForm.minduration = action.payload.minduration;
      state.ListingForm.minprice = action.payload.minprice;
    },
    setSearchLogList: (state, action: PayloadAction<HomeSearchFormState>) => {
      state.SearchLog = action.payload;
    },
    DoGetSearchLogList: (state) => {},

    clearSearchData: (state) => {
      state.ListingForm.search = "";
      state.ListingForm.date = "";
    },
    clearSearch: (state) => {
      state.ListingForm.search = "";
    },
    clearActivitySearch: (state) => {
      state.ListingForm.activityFilter = [];
    },
    clearFilterForm: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.ListingForm.Loading = false;
      state.ListingForm.activityFilter = [];
      state.ListingForm.cancellationPolicyFilter = [];
      state.ListingForm.date = "";
      state.ListingForm.expertiseLevelFilter = [];
      state.ListingForm.foodFilter = [];
      state.ListingForm.maxduration = 0;
      state.ListingForm.maxprice = 0;
      state.ListingForm.durationFilter = [];
      state.ListingForm.minduration = 0;
      state.ListingForm.maxduration = 0;
      state.ListingForm.minprice = 0;
      state.ListingForm.pageNo = 1;
      state.ListingForm.pageSize = 7;
      state.ListingForm.retreatFeatured = 0;
      state.ListingForm.retreatStyleFilter = [];
      state.ListingForm.retreatTypeFilter = RetreatsTypeEnum.All;
      state.ListingForm.search = "";
      state.ListingForm.sortData = RetreatSortTypeEnum.Recommended;
      state.ListingForm.userId = "";
      state.ListingForm.country = "";
      state.ListingForm.city = "";
    },
    clearLocationFilterForm: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.ListingForm.Loading = false;
      state.ListingForm.country = "";
      state.ListingForm.city = "";
    },
    setRetreatList: (state, action: PayloadAction<any>) => {
      state.RetreatList = action.payload;
    },
    setActivityList: (state, action: PayloadAction<any>) => {
      state.ListingForm.activityFilter = action.payload;
    },
    setsearchlogsearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setHandelChange: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.handelChange = 0;
      } else {
        state.handelChange = state.handelChange + 1;
      }
    },
    setListingForm: (state, action: PayloadAction<ListingFormState>) => {
      state.ListingForm.Loading = action.payload.Loading || false;
      state.ListingForm.date = action.payload.date;
      state.ListingForm.activityFilter = action.payload.activityFilter || [];
      state.ListingForm.cancellationPolicyFilter =
        action.payload.cancellationPolicyFilter || [];
      state.ListingForm.expertiseLevelFilter =
        action.payload.expertiseLevelFilter || [];
      state.ListingForm.foodFilter = action.payload.foodFilter || [];
      state.ListingForm.maxduration = action.payload.maxduration || 0;
      state.ListingForm.maxprice = action.payload.maxprice || 0;
      state.ListingForm.minduration = action.payload.minduration || 0;
      state.ListingForm.minprice = action.payload.minprice || 0;
      state.ListingForm.pageNo = action.payload.pageNo || 1;
      state.ListingForm.pageSize = Number(action.payload.pageSize) || 7;
      state.ListingForm.retreatFeatured =
        Number(action.payload.retreatFeatured) || 0;
      state.ListingForm.retreatStyleFilter =
        action.payload.retreatStyleFilter || [];
      state.ListingForm.retreatTypeFilter =
        action.payload.retreatTypeFilter || "";
      state.ListingForm.search = action.payload.search || "";
      state.ListingForm.sortData =
        action.payload.sortData || RetreatSortTypeEnum.Recommended;
      state.ListingForm.userId = action.payload.userId || "";
      state.ListingForm.country = action.payload.country || "";
      state.ListingForm.city = action.payload.city || "";
    },
    setFoodActivityAndStyleData: (
      state,
      action: PayloadAction<{
        food: any;
        style: any;
        activity: any;
        skillLevel: any;
        feature: any;
        cancellationPolicy: any;
        ProgramDuration: any;
        location: any;
      }>
    ) => {
      state.feature = action.payload.feature;
      state.cancellationPolicy = action.payload.cancellationPolicy;
      state.ProgramDuration = action.payload.ProgramDuration;
      state.ActivityData = action.payload.activity;
      state.Fooddata = action.payload.food;
      state.Styledata = action.payload.style;
      state.skillLevel = action.payload.skillLevel;
      state.location = action.payload.location;
    },
    doFilter: (state, action: PayloadAction<ListingFormState>) => {},
    dogetfoodActivityStyleData: (
      state,
      action: PayloadAction<{ qa: any }>
    ) => {},
    setTotalListinglist: (state, action: PayloadAction<number>) => {
      state.totalListing = action.payload;
    },

    doGetListingCountryData: (state) => {},
    doGetListingStateData: (state, action: PayloadAction<string>) => {},
    doGetListingCityData: (state, action: PayloadAction<string>) => {},
    setListingCountryList: (state, action: PayloadAction<any>) => {
      state.countryList = action.payload;
    },
    setListingStateList: (state, action: PayloadAction<any>) => {
      state.stateList = action.payload;
    },
    setListingCityList: (state, action: PayloadAction<any>) => {
      state.cityList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
