import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { CustomerReviewState } from "../types";

export const initialState: CustomerReviewState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
  customerReviewList: [],
  customerReviewFormData: {
    id: "",
    userId: "",
    orderId: "",
    retreatId: "",
    rating: 1,
    review: "",
    qualityOfActivity: 1,
    locationRating: 1,
    foodRating: 1,
    accommodationAndFacilities: 1,
    valueForMoney: 1,
    likes: "",
    dislikes: "",
    reply: "",
    status: "",
    bookingId: "",
    overallImpression: 0,
  },
  reviewModal: false,
};

export const useClinicSlice = createSlice({
  name: "customerReview",
  initialState,
  reducers: {
    updateCustomerReviewFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `customerReviewFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    sendReview: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearFrom: (state) => {
      state.customerReviewFormData.id = "";
      state.customerReviewFormData.userId = "";
      state.customerReviewFormData.orderId = "";
      state.customerReviewFormData.retreatId = "";
      state.customerReviewFormData.rating = 1;
      state.customerReviewFormData.review = "";
      state.customerReviewFormData.qualityOfActivity = 1;
      state.customerReviewFormData.locationRating = 1;
      state.customerReviewFormData.foodRating = 1;
      state.customerReviewFormData.valueForMoney = 1;
      state.customerReviewFormData.accommodationAndFacilities = 1;
      state.customerReviewFormData.likes = "";
      state.customerReviewFormData.dislikes = "";
      state.customerReviewFormData.reply = "";
      state.customerReviewFormData.status = "";
      state.customerReviewFormData.bookingId = "";
      state.customerReviewFormData.overallImpression = 0;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggleReviewModal: (state) => {
      state.reviewModal = !state.reviewModal;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
