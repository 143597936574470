import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  selectBeniftsForms,
  selectGiftsForms,
  selectGrabForms,
} from "./selector";
import { actions as actionResponce } from "../../redux/slice";
import { GrabTheAttentionInterface, GrabTheAttentionModel } from "../types";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  ADDGrabRequest,
  CreateGrabBenifitsTempSchool,
  CreateGrabGiftTempSchool,
  DeleteBenifitsRequest,
  DeleteGiftsRequest,
  getBenefitsTempSchoolStyle,
  getGrabBeniftsFixedAdminStyle,
  getGrabGiftsFixedAdminStyle,
  getGrabTempSchoolStyle,
  GetGrabTheAttentiondataByIdRequest,
} from "../../../../../utils/request";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
export function* AddNewGrabBenifitsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: GrabTheAttentionModel = yield select(selectBeniftsForms);
  if (form.name.trim() == "") {
    CustomToast("Please enter benefits", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      CreateGrabBenifitsTempSchool,
      form
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getgetGrabBenifitsAdminRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getGrabBeniftsFixedAdminStyle);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setGrabBenifitsAdminList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGrabBenifitsSchoolListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getBenefitsTempSchoolStyle,
      action.payload
    );

    if (response && !response.data.benefits) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setGrabBenifitsdSchoolList(response.data.benefits));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewGrabSpecialGiftsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: GrabTheAttentionModel = yield select(selectGiftsForms);
  if (form.name.trim() == "") {
    CustomToast("Please enter gifts", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(CreateGrabGiftTempSchool, form);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGrabSpecialGiftsAdminListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getGrabGiftsFixedAdminStyle);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setGrabSpecialGiftsAdminList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGrabSpecialGiftsSchoolListSaga(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getGrabTempSchoolStyle,
      action.payload
    );

    if (response && !response.data.gifts) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setGrabSpecialGiftsSchoolList(response.data.gifts));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddGrabFormRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: GrabTheAttentionInterface = yield select(selectGrabForms);
  try {
    yield put(actions.setloading(true));
    const response: AxiosResponse = yield call(ADDGrabRequest, form);
    yield put(actions.setloading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setloading(false));
    CatchBlockFunction(error);
  }
}
export function* getGrabTheAttentionByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setloading(true));

    const response: AxiosResponse = yield call(
      GetGrabTheAttentiondataByIdRequest,
      action.payload
    );
    yield put(actions.setloading(false));
    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetGrabTheAttModalType(response.data?.model));
    yield put(actions.SetGrabTheAttentionFormDataById(response.data?.data[0]));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "grabTheAtten_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setloading(false));
    CatchBlockFunction(error);
  }
}
export function* DeletePillsBenifitsRequest(action: {
  payload: { id: string; callback: any; retreatId: string };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteBenifitsRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.getGrabBenifitsSchoolList(action.payload.retreatId));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeletePillsGiftsRequest(action: {
  payload: { id: string; callback: any; retreatId: string };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteGiftsRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.getGrabSpecialGiftsSchoolList(action.payload.retreatId));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* RepoSagaSchoolListingsGrabAttention() {
  yield takeLatest(actions.AddGrabForm, AddGrabFormRequest);
  yield takeLatest(actions.DeleteBenifitsPills, DeletePillsBenifitsRequest);
  yield takeLatest(actions.DeleteGiftsPills, DeletePillsGiftsRequest);
  yield takeLatest(actions.AddNewGrabBenifits, AddNewGrabBenifitsRequest);
  yield takeLatest(
    actions.getGrabBenifitsAdminList,
    getgetGrabBenifitsAdminRequest
  );
  yield takeLatest(
    actions.getGrabBenifitsSchoolList,
    getGrabBenifitsSchoolListRequest
  );
  yield takeLatest(
    actions.AddNewGrabSpecialGifts,
    AddNewGrabSpecialGiftsRequest
  );
  yield takeLatest(
    actions.getGrabSpecialGiftsAdminList,
    getGrabSpecialGiftsAdminListRequest
  );
  yield takeLatest(
    actions.getGrabSpecialGiftsSchoolList,
    getGrabSpecialGiftsSchoolListSaga
  );
  yield takeLatest(
    actions.doGetGrabTheAttentionDataByID,
    getGrabTheAttentionByIdRequest
  );
}
