import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.retreatsFeaturedCards) {
      return state.retreatsFeaturedCards;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectFeaturedRetreatsCardForm = createSelector(
  [selectDomain],
  (state) => state.featuredRetreatsForm
);
export const selectCouponForm = createSelector(
  [selectDomain],
  (state) => state.couponForm
);
export const selectcouponResponceForm = createSelector(
  [selectDomain],
  (state) => state.couponResponceForm
);
export const selectFeaturedRetreatsCardListData = createSelector(
  [selectDomain],
  (state) => state.featuredRetreatsCardList
);
export const selectTeacherTrainingListListData = createSelector(
  [selectDomain],
  (state) => state.teacherTrainingList
);
export const selectReviewDetailData = createSelector(
  [selectDomain],
  (state) => state.reviewDetailInfo
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectFeatureRetreatDetailData = createSelector(
  [selectDomain],
  (state) => state.featureRetreatDetailInfo
);
export const SelectFrequentlyRetreatDetailData = createSelector(
  [selectDomain],
  (state) => state.frequentlyRetreatDetailInfo
);
export const SelectSimilarlyRetreatsDetailData = createSelector(
  [selectDomain],
  (state) => state.similarlyRetreatsDetailInfo
);
export const SelectRecentlyRetreatsDetailInfo = createSelector(
  [selectDomain],
  (state) => state.recentlyRetreatsDetailInfo
);
export const SelectRecentViewedDataRequest = createSelector(
  [selectDomain],
  (state) => state.RecentViewedDataRequest
);
export const SelectReviewStatus = createSelector(
  [selectDomain],
  (state) => state.reviewStatus
);
export const SelecttotalReview = createSelector(
  [selectDomain],
  (state) => state.totalReview
);
