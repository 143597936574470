import axios from "axios";
import config from "../config.json";
export const path = () => {
  return config.STRU_URL;
};

export default axios.create({
  withCredentials: true,
  baseURL: config.API_URL,
});
