import { put, take } from "redux-saga/effects";
import { actions } from "./slice";

export function* watchOnProgress(chan: any) {
  while (true) {
    let data: number = yield take(chan);
    yield put(
      actions.updateImageProgress({ key: "updateImageProgress", value: data })
    );
  }
}

export function* UploaderSaga() {
  // yield takeLatest(actions.doLogin, loginRequest);
}
