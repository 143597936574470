import axios from "axios";
import Api from "./Api";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { log } from "./logger";

const AdminLoginRequest = async (data: any) => {
  return Api.post(`/auth/admin/login`, data);
};
const LoginRequest = async (data: any) => {
  return Api.post(`/auth/login`, data);
};
const googleLoginRequest = async (data: any) => {
  return Api.post(`/auth/google/login`, data);
};
const googleConnectRequest = async (data: any) => {
  return Api.post(`/auth/social/connect`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
// WOrking
const SchoolSignUpRequest = async (data: any) => {
  return Api.post(`/school/signup`, data);
};
const AddSchoolAdminRequest = async (data: any) => {
  return Api.post(`school/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserNewSignUpRequest = async (data: any) => {
  return Api.post(`/user/signup`, data);
};
// WOrking
const MeProfileRequest = async (data?: any) => {
  return Api.get(`/auth/me`, {
    headers: {
      Authorization: "Bearer " + (data ? data : localStorage.getItem("token")),
    },
  });
};
// WOrking
const GetPartnerProfileRequest = async () => {
  return Api.get(`/school/getProfile`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getCountryRequest = async () => {
  return Api.get(`/country/getCountries`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getStateRequest = async (countryId: string) => {
  return Api.get(`/country/getStates/${countryId}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getCityRequest = async (stateId: string) => {
  return Api.get(`/country/get/cities/${stateId}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getMainActivity = async (search: any) => {
  return Api.get(`/data/getRetreatActivity${search}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

//hdjsfhsdjf
const getCurrencyRequest = async () => {
  return Api.get(`/data/getcurrency`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateCurrencyRequest = async (data: any) => {
  return Api.put(`/data/updateCurrencyStatus/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllCurrencyRequest = async () => {
  return Api.get(`/data/getallcurrency`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getCurrencyByIDRequest = async (filter: any) => {
  return Api.get(`/data/getCurrencyById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPartnerRequest = async (filter: any) => {
  return Api.get(`/school/allPartner${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPartnerRequestByAdmin = async (filter: any) => {
  return Api.get(`/school/getProfileById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
// instructor API
const DeletePartnerProfileRequest = async (id: string) => {
  return Api.delete(`/school/deleteSchoolProfile/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetInstructorRequest = async () => {
  return Api.get(`/data/getassociation`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAssociationRequest = async () => {
  return Api.get(`/data/getassociation`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCertificateLavelRequest = async () => {
  return Api.get(`/data/getcertificatelevels`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getCertificateRequest = async (id: any) => {
  return Api.get(`/school/getCertificatesById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getInstructorRequestCertificateRequest = async (filter: any) => {
  return Api.get(`/instructor/getCertificatesById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getCRegistrationRequest = async (id: any) => {
  return Api.get(`/school/getRegistrationById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getInstructorSkillsRequest = async (filter: any) => {
  return Api.get(`/instructor/getSkillsById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getALLInstrutorBYSchoolID = async (filter: any) => {
  return Api.get(`/instructor/getAllInstructor/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteCertificateRequest = async (id: string) => {
  return Api.delete(`/school/deleteProfileCertificate/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteRegistrationRequest = async (id: string) => {
  return Api.delete(`/school/deleteProfileRegistration/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteSkillsRequest = async (id: string) => {
  return Api.delete(`/user/deleteProfileRegistration/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteSInstructorByIDRequest = async (id: string) => {
  return Api.delete(`/instructor/deleteInstructorProfile/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateInstructorDetailRequest = async (data: any) => {
  return Api.put(`instructor/updateInstructorProfile/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetSkillsRequest = async () => {
  return Api.get(`/data/getskills`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateSchoolDetailRequest = async (data: any) => {
  return Api.put(`school/updateSchoolProfile/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const uplodeImageRequest = async (data: any, onProgress: any) => {
  return Api.put(`school/upload`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onProgress,
  });
};
const AddSchoolPayments = async (data: any) => {
  return Api.post(`payment/addPaymentDetails`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSchoolPaymentRequest = async (id: any) => {
  return Api.get(`payment/getPaymentDetails/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateCurrencyByCookiesRequest = async (data: any) => {
  return Api.get(`user/update-user-currency`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateSchoolPasswordRequest = async (data: any) => {
  return Api.post(`/auth/updatepassword`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AdminUpdatePasswordRequest = async (data: any) => {
  return Api.post(`/auth/admin/update-password`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeletePaymentInformationRequest = async () => {
  return Api.delete(`payment/deletePaymentDetails`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSiteSetting = async (data: any) => {
  return Api.post(`siteSetting/addSiteSetting`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const uplodeSiteSettingImageRequest = async (data: any) => {
  return Api.put(`siteSetting/upload`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  });
};
const GetSiteSettingRequest = async () => {
  return Api.get(`siteSetting/getSiteSettings`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddWelcomeBar = async (data: any) => {
  return Api.post(`siteSetting/addWellcomeBarSetting`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetWelcomeBarRequest = async () => {
  return Api.get(`siteSetting/getWellcomeBarSettings`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCommissionRequest = async () => {
  return Api.get(`siteSetting/getCommisonSettings`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddCommission = async (data: any) => {
  return Api.post(`siteSetting/addCommisionSetting`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getTimeZoneRequest = async () => {
  return Api.get(`/data/gettimezone`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddUserByAdmin = async (data: any) => {
  return Api.post(`/user/addUser`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getUserById = async (id: any) => {
  return Api.get(`/user/getUserById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getLanguageByIdRequest = async (filter: any) => {
  return Api.get(`/data/getLanguageById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateLanguageRequest = async (data: any) => {
  return Api.put(`/data/updateLanguageDetails/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
//bfhjgfdhg fh
const getAllLanguageRequest = async () => {
  return Api.get(`/data/getAlllanguage`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllActiveLanguageListRequest = async () => {
  return Api.get(`/data/getActivelanguage`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllUserProfileList = async (filter: any) => {
  return Api.get(`/user/allUser${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllFakeReviewList = async (filter: any) => {
  return Api.post(
    `/review/get-all-dummy${filter}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const getAllUserList = async () => {
  return Api.get(`/review/view-users-list`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllRetreatList = async () => {
  return Api.get(`/review/get-school-approved-list`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllSchoolList = async () => {
  return Api.get(`/review/view-partner-list`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolCertificate = async (data: any) => {
  return Api.put(`/school/updateProfileCertificate`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolBasicDetail = async (data: any) => {
  return Api.put(`/school/update-basic-details`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolBusinessRegistrationAddressDetail = async (data: any) => {
  return Api.put(`/school/update-business-registration-address`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolContactPersonDetail = async (data: any) => {
  return Api.put(`/school/update-contact-person-details`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolNotificationDetail = async (data: any) => {
  return Api.put(`/school/update-notification-details`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const EmailVerifiedIndexDetail = async (data: any) => {
  return Api.get(`/school/verify-email${data}`);
};
const AddSchoolBussinessOwnerDetail = async (data: any) => {
  return Api.put(`/school/update-owner-details`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolRegistration = async (data: any) => {
  return Api.put(`/school/addSchoolRegistration`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddInstructorSkills = async (data: any) => {
  return Api.put(`/Instructor/addInstructorSkills`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddInstructorBasicDetails = async (data: any) => {
  return Api.post(`/Instructor/registerInstructor`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSchoolListForHostRequest = async (filter: any) => {
  return Api.get(`/school/allActiveSchoolList${filter}`, {
    // headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetListingListForFilter = async (filter: any) => {
  return Api.get(`/school/get-all-approved-list${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const uplodeInstructorsImageRequest = async (data: any, onProgress: any) => {
  return Api.put(`/instructor/upload`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onProgress,
  });
};
const getInstructoryIdRequest = async (filter: any) => {
  return Api.get(`/instructor/getInstructorById/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateListing = async (data: any) => {
  return Api.post(`/retreat/createRetreat`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateListingOverview = async (data: any) => {
  return Api.post(`/retreat/addRetreatOverview`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllRetreat = async (listType: any) => {
  return Api.get(`/retreat/getAllRetreats/${listType}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const SchoolCustomerSupportRequest = async (data: any) => {
  return Api.post(`/customer/registerCustomerQuery`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const AddInstructorCertificates = async (data: any) => {
  return Api.put(`/instructor/add-instructor-skills-certificate`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateOverviewRetreatStyle = async (data: any) => {
  return Api.post(`/retreat/addRetreatStyle`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getFixedOverviewRetreatStyle = async () => {
  return Api.get(`/data/getFixedRetreatStyles`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getTempSchoolOverviewRetreatStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatStyles/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAccommodationCategoryRequest = async () => {
  return Api.get(`/data/getAccommodationCategory`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAccommodationRoomTypeRequest = async () => {
  return Api.get(`/data/getRooms`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ListingItineraryRequest = async (data: any) => {
  return Api.post(`/retreat/addItinerary`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolAccomodation = async (data: any) => {
  return Api.post(`school/addAccommodation`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAllAccommodationListRequest = async (filter: any) => {
  return Api.get(`school/getAllAccommodation/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const DeleteAccommodationRequest = async (id: string) => {
  return Api.delete(`/school/deleteAccommodation/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateAccommodationDetailRequest = async (data: any) => {
  return Api.put(`/school/updateAccommodation/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAccommodationByIdRequest = async (id: any) => {
  return Api.get(`/school/getAccommodationById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolListingFaq = async (data: any) => {
  return Api.post(`/retreat/addRetreatFAQ`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllListingFaqListRequest = async (id: any) => {
  return Api.get(`/retreat/getAllFaq/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const DeleteListingFaq = async (filter: any) => {
  return Api.put(
    `/retreat/deleteFaq${filter}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const GetListingFaqByIdRequest = async (filter: string) => {
  return Api.get(`/retreat/getFaqById${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ListingsMediaUplodeRequest = async (data: any, onProgress: any) => {
  return Api.put(`retreat/upload`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onProgress,
  });
};
const AddSchoolMedia = async (data: any) => {
  return Api.post(`retreat/addPhotos`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolVideoMedia = async (data: any) => {
  return Api.post(`retreat/addVideos`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolListingAutoReply = async (data: any) => {
  return Api.post(`/retreat/addAutoReply`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolListingRanking = async (data: any) => {
  return Api.post(`/retreat/addRankingScore`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolListingPricing = async (data: any) => {
  return Api.post(`/retreat/addPackage`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolListingsBookingsPolicy = async (data: any) => {
  return Api.post(`/retreat/addBookingPolicy`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getFixedSchoolFoodAndDrinks = async () => {
  return Api.get(`/data/getDrinks`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllCeanAndSafetyFeaturesRequest = async () => {
  return Api.get(`/data/getCleanAndSafetyFeatures`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const addCleaningAndSafetyMeasures = async (data: any) => {
  return Api.post(`/retreat/addCleaningAndSafetyMeasures`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const getFixedSchoolMeals = async () => {
  return Api.get(`/data/getMeals`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getTempSchoolMealsStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatMeals/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getTempSchoolDrinksStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatDrinks/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateDrinksRetreatStyle = async (data: any) => {
  return Api.post(`/retreat/addDrinks`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateMealsRetreatStyle = async (data: any) => {
  return Api.post(`/retreat/addMeals`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const CreateListingFoodAndDrinks = async (data: any) => {
  return Api.post(`/retreat/addFoodAndDrinks`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

/// ......... Arrival Information .................
const getFixedSchoolSpokenLanguage = async () => {
  return Api.get(`/data/getSpokenLanguage`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
//fjdf hdgfhgfd
const getTempSchoolSpokenLanguage = async (id: any) => {
  return Api.get(`retreat/getSchoolSpokenLanguage/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateSpokenLanguageRetreatStyle = async (data: any) => {
  return Api.post(`/retreat/addRetreatSpokenLanguage`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddListingArrivalInformation = async (data: any) => {
  return Api.post(`/retreat/addArrivalInformation`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getSchoolHost = async (id: string) => {
  return Api.get(`/retreat/getAllHost/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
//............ location ..................///
const getAirportRequest = async (filter: string) => {
  return Api.get(`/retreat/getNearestAirport${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getFixedSchoolSurroundingEnvironment = async () => {
  return Api.get(`/data/getSurroundingEnvironment`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getTempSchoolSurroundingEnvironment = async (id: any) => {
  return Api.get(`retreat/getSchoolSurroundingEnvironment/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateSurroundingEnvironmentRetreatStyle = async (data: any) => {
  return Api.post(`/retreat/addSurroundingEnvironment`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddListingLocation = async (data: any) => {
  return Api.post(`/retreat/addRetreatLocation`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

// ...........................Grab The Attention ...........................

const CreateGrabBenifitsTempSchool = async (data: any) => {
  return Api.post(`/retreat/addRetreatBenefits`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateGrabGiftTempSchool = async (data: any) => {
  return Api.post(`/retreat/addRetreatGifts`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getGrabBeniftsFixedAdminStyle = async () => {
  return Api.get(`/data/getRetreatBenefits`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getGrabGiftsFixedAdminStyle = async () => {
  return Api.get(`/data/getRetreatGifts`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getBenefitsTempSchoolStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatBenefits/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getGrabTempSchoolStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatGifts/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ADDGrabRequest = async (data: any) => {
  return Api.post(`/retreat/addGrabTheAttention`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

// ...........................Inclusion && Exclusion...........................

const CreateinclusionTempSchool = async (data: any) => {
  return Api.post(`/retreat/addInclusions`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CreateExclusionTempSchool = async (data: any) => {
  return Api.post(`/retreat/addExclusions`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getinclusionFixedAdminStyle = async () => {
  return Api.get(`/data/getInclusions`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getinclusionTempSchoolStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatInclusions/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getExclusionFixedAdminStyle = async () => {
  return Api.get(`/data/getExclusions`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getExclusionTempSchoolStyle = async (id: any) => {
  return Api.get(`/retreat/getSchoolRetreatExclusions/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ADDInclusionAndExclusionRequest = async (data: any) => {
  return Api.post(`/retreat/addInclusionsExclusions`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllPackageListRequest = async (retreatId: any) => {
  return Api.get(`/retreat/getAllpackage/${retreatId}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeletePackageByID = async (filter: any) => {
  return Api.delete(`/retreat/deletePackage${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetListingPackageByIdRequest = async (id: string) => {
  return Api.get(`/retreat/getPackageById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const getInstructorRole = async () => {
  return Api.get(`/data/getroles`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddListingInstructorList = async (data: any) => {
  return Api.post(`/retreat/addRetreatinstructors`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetPendingRetreatslistRequest = async (filter: string) => {
  return Api.get(`/admin/getAllPendingRetreat${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetApprovedRetreatslistRequest = async (filter: string) => {
  return Api.get(`/admin/getAllPendingRetreat${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const deleteRetreatListRequest = async (id: string) => {
  return Api.delete(`/retreat/deleteRetreat/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAOverviewFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getOverview/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetMediaFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getRetreatPhotos/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetMediaVideoFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getRetreatVideos/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetLocationFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getLocation/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetFoodAndDrinksFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getFoodAndDrinks/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetItineraryFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getItinerary/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetArrivalInformationFormdataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getArrivalInformation/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetGrabTheAttentiondataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getGrabTheAttention/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetInclusionsexclusionsDataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getInclusionsExclusions/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetBookingPolicyDataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getBookingPolicy/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAutoReplyDataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getAutoReply/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCleanAndSafeDataByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getCleaningAndSafetyMeasures/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ApprovePendingRetreatRequestAPI = async (data: any) => {
  return Api.put(`/admin/changeRequestStatus`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const RequestToPublishRetreatRequestAPI = async (Id: any) => {
  return Api.put(
    `/retreat/requestToPublish/${Id}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
// check retreat stutes
const GetRetreatStatusPublishByIdRequest = async (id: any) => {
  return Api.get(`/retreat/checkRequestStatus/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
//first time creted new retreats counts is 1
const GetStatusCountForRetreats = async (id: any) => {
  return Api.get(`/retreat/getCountOfRequest/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
// request to publish for edit (requestToPublish) -> (pending)
const RequestToPublishEditRequestAPI = async (Id: any) => {
  return Api.put(
    `/retreat/requestToEdit/${Id}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const GetRankingByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getRankingScore/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

// delete school retreats list
const DeletechoolListingRequest = async (id: string) => {
  return Api.delete(`/school/deleteAccommodation/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetRetreatByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getRankingScore/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAllApprovedRetreat = async (filter: string) => {
  return Api.get(`/admin/getAllApprovedRetreat${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAllRejectedRetreat = async (filter: string) => {
  return Api.get(`/admin/getAllRejectedRetreat${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAllRetreatslistRequest = async (querryParam: string, data: any) => {
  return Api.post(`/admin/get-all-list${querryParam}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetApprovedRetreatslistAdminRequest = async (
  querryParam: string,
  data: any
) => {
  return Api.post(`/admin/get-approved-retreats-list${querryParam}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetRejectedRetreatslistAdminRequest = async (
  querryParam: string,
  data: any
) => {
  return Api.post(`/admin/get-rejected-retreats-list${querryParam}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteUserProfile = async (id: string) => {
  return Api.delete(`/user/deleteUser/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteFakeReview = async (id: any) => {
  return Api.delete(`/review/delete/dummy/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateUserProfile = async (data: any) => {
  return Api.put(`/user/updateUser/${data.id}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateApproveFeaturedList = async (data: any) => {
  return Api.put(`/retreat/setRetreatFetured${data}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateSchoolStatus = async (data: any) => {
  return Api.put(`/school/activate-deactivate-school${data}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateActiveStatus = async (data: any) => {
  return Api.put(`/affiliates/affiliate-status-update`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getFeaturedRetreatsCard = async () => {
  return Api.get(`/yogaretreats/getFeaturedRetreats`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const MediaManagerAdd = async (data: any) => {
  return Api.post(`/school/addMedia`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const getMediaManagerList = async (filter: any) => {
  return Api.get(`/school/getMedia/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const DeleteMediaManager = async (id: string) => {
  return Api.delete(`/school/deleteMedia/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetMediaManagerById = async (id: any) => {
  return Api.get(`/school/getMediaById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetNotificationRequest = async (filter: any) => {
  return Api.get(`/admin/get-all-notification${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getMediaManagerListForAdmin = async (list: any, data: any) => {
  return Api.post(`/school/getAllMedia/${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetCalendarDataRequest = async (packageId: string, retreatId: string) => {
  return Api.get(
    `retreat/getreatreatcalendar?packageId=${packageId}&retreatId=${retreatId}`,
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const AddCalendarData = async (data: any) => {
  return Api.post(`/retreat/addcalendar`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddListingFaqDragList = async (data: any) => {
  return Api.post(`/retreat/addretreatfaqlist`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetListingInstructorByIdRequest = async (id: any) => {
  return Api.get(`/retreat/getRetreatInstructors${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetRetreatsModalById = async (id: any) => {
  return Api.get(`/retreat/getRetreatById/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteRetreatStyleRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreatstyle/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteSurranidngRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-surroundingenvironment/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteMealsRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreatmeal/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteDrinksRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreatdrink/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteInclushionRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreat-inclusion/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteExcushionRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreat-exclusion/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteBenifitsRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreat-benefit/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteGiftsRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-retreat-gift/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteSpokenlanguageRequest = async (id: string) => {
  return Api.delete(`/retreat/delete-spoken-language/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddWishlist = async (id: any) => {
  return Api.post(`/yogaretreats/addwishlist/${id}`, id, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllWishList = async (filter: any) => {
  return Api.get(`/yogaretreats/viewwishlist/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteWishList = async (Id: any) => {
  return Api.delete(`/yogaretreats/deletewishlist/${Id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddressList = async (data: string) => {
  return axios.get(
    `https://nominatim.openstreetmap.org/search?q=${data}&format=json`
  );
};

const schoolprofileYogaRetreatsdata = async (id: any) => {
  return Api.get(`/yogaretreats/view-school-yoga-retreats/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const schoolprofileTeacherTrainingdata = async (id: any) => {
  return Api.get(`/yogaretreats/view-school-yoga-teacher-training/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const schoolprofileOnlineRetreatsdata = async (id: any) => {
  return Api.get(`/yogaretreats/view-school-online-retreats/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const schoolprofileInstructordata = async (id: string) => {
  return Api.get(`/yogaretreats/view-school-instructors/${id}`);
};

const IndexFilterRequest = async (data: any, token: any) => {
  return Api.post(`/yogaretreats/viewretreats`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};
const IndexFilterPriceAndDurationRequest = async (data: any, token: any) => {
  return Api.post(`/yogaretreats/get-listing-minmax-price-duration`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};
const GetFoodStyleActivityData = async (data: any) => {
  return Api.post(`/yogaretreats/get-food-activity-styles`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const ListingCountryDataRequest = async () => {
  return Api.get(`/yogaretreats/get-country-based-listing`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ListingStateDataRequest = async (country: string) => {
  return Api.get(`/yogaretreats/get-state/${country}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ListingCityDataRequest = async (state: string) => {
  return Api.get(`/yogaretreats/get-city/${state}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSearchLogListData = async (search: string) => {
  return Api.get(`/yogaretreats/viewsearchlog/${search}`, {});
};
const RequestToDeactiveRetreatAPI = async (Filter: any, data: any) => {
  return Api.put(`/admin/active-deactive-retreat${Filter}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const CustomerSupportRequest = async (data: any) => {
  return Api.post(`/customer/add-customer-support`, data);
};
const getHelpContextRequest = async () => {
  return Api.get(`/data/get-customer-support-context`);
};

const MediaManagerUpdate = async (data: any) => {
  return Api.put(`/school/updatemedia`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoActiveInactiveRequest = async (data: any) => {
  return Api.put(`/school/verify-school-registration`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoActiveInactiveRegistrationRequest = async (data: any) => {
  return Api.put(`/school/verify-school-certificate`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getBookingInquiryContextRequest = async () => {
  return Api.get(`/data/get-booking-inquiry-context`);
};

const getBookingPackageDetails = async (data: any) => {
  return Api.post(`/booking/view-booking-package-details`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getviewpackageavailabilityondates = async (data: any) => {
  return Api.post(`/yogaretreats/view-date-based-package-list`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const BookingInquiryRequest = async (data: any) => {
  return Api.post(`/booking/send-inquiry`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetFrequentlyRetreatDetailData = async (id: any) => {
  return Api.get(`/yogaretreats/get-frequently-brought-retreats/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSimilarRetreatDetail = async (id: any) => {
  return Api.get(`/yogaretreats/get-similar-yoga-retreats/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetRecentlyViewedRetreatDetail = async (data: any) => {
  return Api.post(`/yogaretreats/get-recently-viewed-retreats-data`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserMessageCardList = async (filter: string) => {
  return Api.get(`/booking/get-inquiry-cards${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GePackageDetailRequest = async (filter: any) => {
  return Api.get(`/booking/get-inquiry-details/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GeMessagesListRequest = async (filter: any) => {
  return Api.get(`/booking/get-messages-list/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPackageListRequest = async (Data: any) => {
  return Api.put(`/booking/get-package-list`, Data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const PackageBookingFormListRequest = async (Data: any) => {
  return Api.put(`/booking/get-school-package-list`, Data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const DoUpdateMessagePackageTripDetail = async (body: any) => {
  return Api.put(`/booking/update-inquiry-trip-details`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoUpdateMessagePackageDetail = async (body: any) => {
  return Api.put(`/booking/update-inquiry-package-details`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoUpdateMessageBookingsDetail = async (body: any) => {
  return Api.put(`/booking/update-inquiry-booking-policy-details`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DodeclineAvialabilityAPI = async (body: any) => {
  return Api.put(`/booking/accept-decline-booking-availability`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoSendmessageAPI = async (body: any) => {
  return Api.post(`/booking/send-message`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoSendmessageWithAttachmentAPI = async (body: any) => {
  return Api.put(`/booking/send-message-with-file`, body, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  });
};

const GePaymentNotEnabledyetRequest = async (filter: any) => {
  return Api.get(`/booking/get-payment-not-enabled-messages-list/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GeNewMessageRequest = async (filter: any) => {
  return Api.get(`/booking/get-new-messages-list/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GeFollowUPRequest = async (filter: any) => {
  return Api.get(`/booking/get-follow-up-messages-list/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPackageItinararyData = async (filter: string) => {
  return Api.get(`/booking/get-itinerary-details/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPackageContetctData = async (filter: string) => {
  return Api.get(`/booking/get-customer-details/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoGetPriceData = async (body: any) => {
  return Api.post(`/booking/get-total-price`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DoGetCouponData = async (body: any) => {
  return Api.post(`/booking/coupon/check-package-price`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddContactAndItinararyData = async (body: any) => {
  return Api.post(`/booking/check-booking-availability`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddBookingContinue = async (body: any) => {
  return Api.post(`/booking/continue-booking`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddBookingPayment = async (body: any) => {
  return Api.post(`/booking/continue-booking`, body, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateBookingPayment = async (id: string) => {
  return Api.get(`/booking/update/payment?payment_intent=${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const MarkAsAllRead = async () => {
  return Api.put(
    `/admin/mark-all-notification-read`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const MarkAsRead = async (id: any) => {
  return Api.put(
    `/admin/marknotificationread/${id}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};

const GetUpComingBookingUserData = async (filter: string) => {
  return Api.get(`/booking/user-upcoming-bookings/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPastBookingUserData = async (filter: string) => {
  return Api.get(`/booking/user-past-bookings/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const StillMakingRetreatsPlanRequest = async (data: any) => {
  return Api.post(`/newsletter/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAdminDashboardData = async (filter: any) => {
  return Api.get(`admin/get-admin-dashboard-data${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAdminDashboardGraphData = async (filter: any) => {
  return Api.get(`/dashboard/admin-data${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const ForgetPasswordRequest = async (data: any) => {
  return Api.post(
    `auth/forgot/password${data}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const ResetPasswordRequest = async (data: any) => {
  return Api.post(`auth/set-password`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserMessageList = async (filter: any) => {
  return Api.get(`/booking/get-messages/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserInquiryDetail = async (filter: any) => {
  return Api.get(`/booking/get-inquiry-message-detail/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCurrentlyconnectedSchoolData = async (filter: string) => {
  return Api.get(`/booking/school-current-bookings/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPreviousBookingsDataData = async (filter: string) => {
  return Api.get(`/booking/school-past-bookings/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetUpcomingBookingsDataData = async (filter: string) => {
  return Api.get(`/booking/school-upcoming-bookings/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCurrentBookingsAdminData = async (list: any, data: any) => {
  return Api.post(`/booking/view-all-current-bookings${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetPastBookingsAdminData = async (list: any, data: any) => {
  return Api.post(`/booking/view-all-past-bookings${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCancelledBookingsAdminData = async (list: any, data: any) => {
  return Api.post(`/booking/view-all-cancelled-bookings${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const getAllPaymentsListRequest = async (list: any, data: any) => {
  return Api.post(`/admin/get-all-payment/${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPaymentsListRequest = async (filter: any) => {
  return Api.get(`/payment/get-school-payment/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPaymentsInvoiceListRequest = async (filter: any) => {
  return Api.get(`/payment/get-school-invoice-list/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetTransactionDetailData = async (id: string) => {
  return Api.get(`/booking/view-transaction-detail/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AccountDeactivate = async (id: string) => {
  return Api.get(`/school/deactivate-account/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddVoucherCode = async (data: any) => {
  return Api.post(`/voucher/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddDiscountCode = async (data: any) => {
  return Api.post(`/user/addUser`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllAffliateListRequest = async (list: any) => {
  return Api.post(`/school/getAllMedia/${list}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllEarningReportListRequest = async (list: any, data: any) => {
  return Api.post(`/admin/get-earning-report/${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddSchoolCoupon = async (data: any) => {
  return Api.post(`/booking/add-coupon`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAllSchoolCouponListRequest = async (filter: any) => {
  return Api.get(`/booking/view-school-coupon/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const DeleteCouponRequest = async (id: string) => {
  return Api.delete(`/booking/delete-coupon/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCouponByIdRequest = async (id: any) => {
  return Api.get(`/booking/coupon-by-id/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSchoolDashboarddataRequest = async (id: any) => {
  return Api.get(`/dashboard/school-data/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetSchoolDashboardOverallClicksListingsRequest = async (id: any) => {
  return Api.get(`/dashboardget-overall-clicks/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const addAttachmentRequest = async (data: any, onProgress: any) => {
  return Api.put(`school/upload`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onProgress,
  });
};
const getAllDiscountCodeListRequest = async (list: any, data: any) => {
  return Api.post(`/booking/view-all-coupon/${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllVoucherCodeListRequest = async (list: any) => {
  return Api.get(
    `/voucher/get-all/${list}`,

    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const deleteVoucher = async (id: string) => {
  return Api.delete(`/voucher/delete/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetVoucherDataByIdRequest = async (id: any) => {
  return Api.get(`/voucher/get/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetVoucherForuserRequest = async () => {
  return Api.get(`/voucher/get-user-voucher`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAlluserListRequest = async () => {
  return Api.get(`/voucher/get-all-user-list`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllAdminReviewListRequest = async (list: any, data: any) => {
  return Api.post(`/review/get-all/${list}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAdminReviewById = async (id: any) => {
  return Api.get(`/review/get-by-id/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const updateAdminReview = async (data: any) => {
  return Api.post(`/school/addMedia`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const getSchoolReviewList = async (filter: any) => {
  return Api.get(`/review/get-school-reviews/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const schoolHostReviewdata = async (filter: any) => {
  return Api.get(`/review/get-school-approved-reviews/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetReviewDetailRequest = async (id: any) => {
  return Api.get(`/yogaretreats/view-retreat-reviews/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CustomerReviewAddRequest = async (data: any) => {
  return Api.post(`/review/add-dummy-review`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doAddReviewRequest = async (data: any) => {
  return Api.post(`/review/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddRecentlyViewedData = async (data: any) => {
  return Api.post(`/yogaretreats/add-recently-viewed-data`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const SendInstructorMobOtp = async (data: any) => {
  return Api.post(`/instructor/send-otp-with-email`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const SendEmailVerification = async (data: any) => {
  return Api.post(`/school/email-verifcation`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const VerifyMobBooking = async (data: any) => {
  return Api.post(`/booking/send-otp`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const VerifyInstructorMobOtp = async (data: any) => {
  return Api.post(`/instructor/verify-otp`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAllEmailNewsLetterList = async (filter: any, bodyData: any) => {
  return Api.post(`/newsletter/get-all/${filter}`, bodyData, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const doDeleteEmailNewsLetter = async (id: string) => {
  return Api.delete(`/newsletter/delete/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const newAffliateSignUpRequest = async (data: any) => {
  return Api.post(`/affiliates/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const newAffliateAdminRequest = async (data: any) => {
  return Api.post(`/affiliates/add-affiliate`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAllffliatelistRequest = async (filter: any) => {
  return Api.get(`/affiliates/get-all/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAffliateBuIdRequest = async (id: string) => {
  return Api.get(`/affiliates/get/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAffliateDetailsByIdRequest = async (id: string) => {
  return Api.get(`/affiliates/get-payment-detail/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const deleteAffliateRequest = async (id: string) => {
  return Api.delete(`/affiliates/delete/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAllPartnerListRequest = async (search: string) => {
  return Api.get(`/admin/view-partner-list${search}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPromotionList = async (filter: any) => {
  return Api.get(`/promotion/get-listing/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const getFeaturedRetreatsAndTeacherTraingingCard = async (
  token: any,
  cookies: any
) => {
  return Api.get(`/yogaretreats/get-featured-retreat-and-teacher-training`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};
const viewschoolprofiledata = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.get(`/yogaretreats/viewschoolprofiledata/${data?.id}`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};
const getOnlineRetreatsExperienceData = async (token: string) => {
  return Api.get(`/experience/get-online-retreats`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const getOnlineTeacherTrainingExperienceData = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.get(`/experience/get-online-teacher-training`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};
const GetFeatureRetreatDetailData = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.get(`/yogaretreats/viewFearturedRetreatById/${data.id}`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};
const GetSiteSettingServerSideRequest = async (token: string) => {
  return Api.get(`siteSetting/getSiteSettings`, {
    headers: { Authorization: "Bearer " + token },
  });
};
const GetStoryExportReportRequest = async (filter: string, bodyData: any) => {
  return Api.post(`/newsletter/export-to-excel${filter}`, bodyData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetInvoicePDFRequest = async (id: string) => {
  return Api.get(`/payment/export-payment-by-id/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetPaymentPDFRequest = async (id: string) => {
  return Api.get(`/payment/export-invoice-by-id/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetEarningByIdRequest = async (id: any) => {
  return Api.get(`/admin/export-earning-report-by-id/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetEarningReportRequest = async (data: any) => {
  return Api.post(`/admin/export-all-earning-report`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
//affiliate

const AddAffiliateLinkRequest = async (data: any) => {
  return Api.post(`/affiliates/add-link`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetAffiliateLinkRequest = async (filter: string) => {
  return Api.get(`/affiliates/get-all-link${filter}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetAffiliatePaymentRequest = async (filter: string) => {
  return Api.get(`/affiliates/get-all-payment${filter}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const dodeleteAffiliateLinkRequest = async (id: string) => {
  return Api.delete(`/affiliates/delete-link/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const dodeleteAffiliatePaymentRequest = async (id: string) => {
  return Api.delete(`/affiliates/delete-payment/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doCancelBookingRequest = async (data: any) => {
  return Api.post(`/booking/view-all-cancelled-bookings`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddAffiliateBank = async (data: any) => {
  return Api.post(`/affiliates/add-payment`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetCancelVoucherDataByIdRequest = async (id: any) => {
  return Api.get(`/booking/get-booking-details/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const AddCancelBookingVoucherCode = async (data: any) => {
  return Api.post(`/voucher/add-booking-cancel-voucher`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const landingPageDataRequest = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.put(
    `/static/get-popular-retreat-data?search=${data.name}&activity=${data.activity}`,
    {},
    {
      headers: { Authorization: "Bearer " + token, cookie: cookies },
    }
  );
};
const getFeaturedRetreatsCardServerSide = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.get(`/yogaretreats/getFeaturedRetreats?searchBy=${data.name}`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};

const getFeaturedTeacherTrainingCard = async (
  data: any,
  token: string,
  cookies: any
) => {
  return Api.get(
    `/yogaretreats/get-featured-teacher-training?searchBy=${data.name}`,
    {
      headers: { Authorization: "Bearer " + token, cookie: cookies },
    }
  );
};
const getOnlineFeaturedTeacherTrainingCard = async (
  token: string,
  cookies: any
) => {
  return Api.get(`/yogaretreats/get-online-featured-teacher-training`, {
    headers: { Authorization: "Bearer " + token, cookie: cookies },
  });
};

const affliateCalculateRequest = async (data: any) => {
  return Api.post(`/affiliates/check-calculation`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doEditAffiliatePayment = async (id: string) => {
  return Api.get(`/affiliates/get-payment/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAdminAffiliateDataRequest = async (Filter: any, data: any) => {
  return Api.post(`/affiliates/get-payment-list-by-id${Filter}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAdminAffiliateDashboardData = async (Filter: any, data: any) => {
  return Api.post(`/affiliates/get-payments-list${Filter}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const GetAffiliatepatrnerDashboardData = async (Filter: any) => {
  return Api.put(
    `/affiliates/get-partner-payment-list${Filter}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};

const getAdminAffiliateUpdateData = async (data: any) => {
  return Api.post(`/affiliates/update-affiliate-commsion-status`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAffiliateRecordPayment = async () => {
  return Api.get(`/affiliates/record-payment`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getAdminAffiliatePaymentData = async (filter: any, data: any) => {
  return Api.post(`/affiliates/get-all-booking-earnings${filter}`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getUpdateRecordPayments = async (data: any) => {
  return Api.post(`/admin/update-payment-status`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
///Set package Price//
const SetListingPackagePrice = async (data: any) => {
  return Api.post(`/retreat/package-price-range`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetLoginCountData = async () => {
  return Api.get(`/auth/login-details`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetCountryRequest = async () => {
  return Api.get(`/static/get-all-country`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetCityRequest = async (filter: string) => {
  return Api.get(`/static/get-all-city${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doUpdateLandingRequest = async (data: any) => {
  return Api.put(`/static/update-data`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetLandingRequest = async (filter: any) => {
  return Api.get(`/static/get-all-data${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetLandingDataById = async (filter: any) => {
  return Api.get(`/static/get-data${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const CheckPackageDeleteRequest = async (id: any) => {
  return Api.get(`/retreat/package-deletable/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
export {
  GetLoginCountData,
  doCancelBookingRequest,
  GetAffiliatePaymentRequest,
  dodeleteAffiliateLinkRequest,
  GetAffiliateLinkRequest,
  AddAffiliateLinkRequest,
  GetEarningReportRequest,
  GetEarningByIdRequest,
  GetInvoicePDFRequest,
  GetPaymentPDFRequest,
  GetStoryExportReportRequest,
  GetSiteSettingServerSideRequest,
  getAllEmailNewsLetterList,
  SendEmailVerification,
  AddSchoolBussinessOwnerDetail,
  AddSchoolContactPersonDetail,
  AddSchoolBusinessRegistrationAddressDetail,
  AddSchoolBasicDetail,
  AddRecentlyViewedData,
  DoSendmessageWithAttachmentAPI,
  UserInquiryDetail,
  addAttachmentRequest,
  GetCouponByIdRequest,
  DeleteCouponRequest,
  GetAllSchoolCouponListRequest,
  AddSchoolCoupon,
  GetTransactionDetailData,
  GetCurrentBookingsAdminData,
  GetPastBookingsAdminData,
  GetCancelledBookingsAdminData,
  GetCurrentlyconnectedSchoolData,
  GetUpcomingBookingsDataData,
  GetPreviousBookingsDataData,
  UserMessageList,
  GetUpComingBookingUserData,
  GetPastBookingUserData,
  DeleteFakeReview,
  MarkAsAllRead,
  AddBookingContinue,
  DoGetPriceData,
  AddContactAndItinararyData,
  GetPackageContetctData,
  GetPackageItinararyData,
  DoSendmessageAPI,
  GeNewMessageRequest,
  GeFollowUPRequest,
  GePaymentNotEnabledyetRequest,
  DoUpdateMessageBookingsDetail,
  DodeclineAvialabilityAPI,
  GetPackageListRequest,
  GeMessagesListRequest,
  DoUpdateMessagePackageDetail,
  DoUpdateMessagePackageTripDetail,
  GePackageDetailRequest,
  UserMessageCardList,
  GetSearchLogListData,
  DoActiveInactiveRegistrationRequest,
  AddressList,
  getAllFakeReviewList,
  DoActiveInactiveRequest,
  GetFoodStyleActivityData,
  IndexFilterRequest,
  DeleteDrinksRequest,
  DeleteMealsRequest,
  DeleteInclushionRequest,
  DeleteExcushionRequest,
  DeleteBenifitsRequest,
  DeleteGiftsRequest,
  DeleteSpokenlanguageRequest,
  DeleteSurranidngRequest,
  DeleteRetreatStyleRequest,
  AddCalendarData,
  GetCalendarDataRequest,
  GetFeatureRetreatDetailData,
  GetNotificationRequest,
  GetMediaVideoFormdataByIdRequest,
  GetFoodAndDrinksFormdataByIdRequest,
  GetItineraryFormdataByIdRequest,
  GetLocationFormdataByIdRequest,
  GetAOverviewFormdataByIdRequest,
  GetApprovedRetreatslistRequest,
  ApprovePendingRetreatRequestAPI,
  deleteRetreatListRequest,
  GetPendingRetreatslistRequest,
  getAllUserList,
  getAllRetreatList,
  getAllSchoolList,
  DeletePackageByID,
  getAllPackageListRequest,
  CreateinclusionTempSchool,
  getinclusionFixedAdminStyle,
  getinclusionTempSchoolStyle,
  CreateExclusionTempSchool,
  getExclusionTempSchoolStyle,
  getExclusionFixedAdminStyle,
  ADDInclusionAndExclusionRequest,
  CreateGrabBenifitsTempSchool,
  getBenefitsTempSchoolStyle,
  getGrabGiftsFixedAdminStyle,
  getGrabTempSchoolStyle,
  getGrabBeniftsFixedAdminStyle,
  CreateGrabGiftTempSchool,
  ADDGrabRequest,
  GetListingListForFilter,
  addCleaningAndSafetyMeasures,
  GetMediaFormdataByIdRequest,
  AddSchoolListingPricing,
  AddSchoolListingsBookingsPolicy,
  AddSchoolVideoMedia,
  AddSchoolMedia,
  UpdateCurrencyByCookiesRequest,
  ListingsMediaUplodeRequest,
  GetAccommodationByIdRequest,
  UpdateAccommodationDetailRequest,
  DeleteAccommodationRequest,
  GetAllAccommodationListRequest,
  AddSchoolAccomodation,
  ListingItineraryRequest,
  getTempSchoolOverviewRetreatStyle,
  CreateListingOverview,
  getFixedOverviewRetreatStyle,
  CreateOverviewRetreatStyle,
  getAllRetreat,
  getMainActivity,
  CreateListing,
  DeletePartnerProfileRequest,
  getInstructoryIdRequest,
  AddInstructorBasicDetails,
  SchoolSignUpRequest,
  UserNewSignUpRequest,
  AdminLoginRequest,
  getCurrencyByIDRequest,
  getCRegistrationRequest,
  LoginRequest,
  MeProfileRequest,
  GetPartnerProfileRequest,
  getCountryRequest,
  getCurrencyRequest,
  getPartnerRequest,
  getAllCurrencyRequest,
  GetAssociationRequest,
  GetCertificateLavelRequest,
  GetSkillsRequest,
  UpdateSchoolDetailRequest,
  uplodeImageRequest,
  getPartnerRequestByAdmin,
  UpdateSchoolPasswordRequest,
  AdminUpdatePasswordRequest,
  AddSchoolPayments,
  GetSchoolPaymentRequest,
  DeletePaymentInformationRequest,
  AddSiteSetting,
  uplodeSiteSettingImageRequest,
  GetSiteSettingRequest,
  AddWelcomeBar,
  GetWelcomeBarRequest,
  AddCommission,
  GetCommissionRequest,
  getTimeZoneRequest,
  UpdateCurrencyRequest,
  getLanguageByIdRequest,
  UpdateLanguageRequest,
  getAllLanguageRequest,
  AddUserByAdmin,
  getAllUserProfileList,
  getUserById,
  AddSchoolCertificate,
  GetSchoolListForHostRequest,
  uplodeInstructorsImageRequest,
  getCertificateRequest,
  DeleteCertificateRequest,
  DeleteRegistrationRequest,
  AddSchoolRegistration,
  DeleteSkillsRequest,
  AddInstructorSkills,
  getInstructorSkillsRequest,
  GetInstructorRequest,
  getALLInstrutorBYSchoolID,
  DeleteSInstructorByIDRequest,
  UpdateInstructorDetailRequest,
  getAllActiveLanguageListRequest,
  SchoolCustomerSupportRequest,
  AddInstructorCertificates,
  getAccommodationCategoryRequest,
  getAccommodationRoomTypeRequest,
  AddSchoolListingFaq,
  DeleteListingFaq,
  getAllListingFaqListRequest,
  AddSchoolListingAutoReply,
  AddSchoolListingRanking,
  GetListingFaqByIdRequest,
  getAllCeanAndSafetyFeaturesRequest,
  getFixedSchoolFoodAndDrinks,
  getFixedSchoolMeals,
  getTempSchoolDrinksStyle,
  getTempSchoolMealsStyle,
  CreateDrinksRetreatStyle,
  CreateMealsRetreatStyle,
  CreateListingFoodAndDrinks,
  getFixedSchoolSpokenLanguage,
  getTempSchoolSpokenLanguage,
  CreateSpokenLanguageRetreatStyle,
  AddListingArrivalInformation,
  getAirportRequest,
  getFixedSchoolSurroundingEnvironment,
  getTempSchoolSurroundingEnvironment,
  CreateSurroundingEnvironmentRetreatStyle,
  AddListingLocation,
  getInstructorRole,
  getSchoolHost,
  AddListingInstructorList,
  GetListingPackageByIdRequest,
  GetArrivalInformationFormdataByIdRequest,
  GetGrabTheAttentiondataByIdRequest,
  GetInclusionsexclusionsDataByIdRequest,
  GetBookingPolicyDataByIdRequest,
  GetAutoReplyDataByIdRequest,
  RequestToPublishRetreatRequestAPI,
  GetRetreatStatusPublishByIdRequest,
  RequestToPublishEditRequestAPI,
  GetStatusCountForRetreats,
  GetRankingByIdRequest,
  DeletechoolListingRequest,
  GetRetreatByIdRequest,
  UpdateActiveStatus,
  GetCleanAndSafeDataByIdRequest,
  GetAllApprovedRetreat,
  GetAllRejectedRetreat,
  GetAllRetreatslistRequest,
  getInstructorRequestCertificateRequest,
  DeleteUserProfile,
  UpdateUserProfile,
  UpdateApproveFeaturedList,
  getFeaturedRetreatsCard,
  MediaManagerAdd,
  getMediaManagerList,
  DeleteMediaManager,
  GetMediaManagerById,
  getMediaManagerListForAdmin,
  AddListingFaqDragList,
  GetListingInstructorByIdRequest,
  GetRetreatsModalById,
  AddWishlist,
  getAllWishList,
  DeleteWishList,
  getFeaturedTeacherTrainingCard,
  getOnlineFeaturedTeacherTrainingCard,
  viewschoolprofiledata,
  schoolprofileYogaRetreatsdata,
  schoolprofileInstructordata,
  RequestToDeactiveRetreatAPI,
  CustomerSupportRequest,
  getHelpContextRequest,
  schoolprofileTeacherTrainingdata,
  schoolprofileOnlineRetreatsdata,
  MediaManagerUpdate,
  getBookingInquiryContextRequest,
  BookingInquiryRequest,
  getBookingPackageDetails,
  GetFrequentlyRetreatDetailData,
  GetSimilarRetreatDetail,
  GetRecentlyViewedRetreatDetail,
  StillMakingRetreatsPlanRequest,
  GetAdminDashboardData,
  ForgetPasswordRequest,
  ResetPasswordRequest,
  getAllPaymentsListRequest,
  getPaymentsListRequest,
  getPaymentsInvoiceListRequest,
  getAllAffliateListRequest,
  getAllEarningReportListRequest,
  AddVoucherCode,
  AddDiscountCode,
  getAllDiscountCodeListRequest,
  deleteVoucher,
  GetVoucherDataByIdRequest,
  getAllVoucherCodeListRequest,
  getAllAdminReviewListRequest,
  GetAdminReviewById,
  updateAdminReview,
  getSchoolReviewList,
  schoolHostReviewdata,
  GetReviewDetailRequest,
  CustomerReviewAddRequest,
  doAddReviewRequest,
  getOnlineTeacherTrainingExperienceData,
  getOnlineRetreatsExperienceData,
  SendInstructorMobOtp,
  VerifyInstructorMobOtp,
  getCityRequest,
  getStateRequest,
  doDeleteEmailNewsLetter,
  EmailVerifiedIndexDetail,
  AddSchoolNotificationDetail,
  newAffliateSignUpRequest,
  GetAllffliatelistRequest,
  GetAffliateBuIdRequest,
  GetAffliateDetailsByIdRequest,
  deleteAffliateRequest,
  GetApprovedRetreatslistAdminRequest,
  GetAllPartnerListRequest,
  GetSchoolDashboarddataRequest,
  googleLoginRequest,
  DoGetCouponData,
  UpdateSchoolStatus,
  GetVoucherForuserRequest,
  GetAlluserListRequest,
  getPromotionList,
  GetRejectedRetreatslistAdminRequest,
  getFeaturedRetreatsAndTeacherTraingingCard,
  AddBookingPayment,
  googleConnectRequest,
  GetSchoolDashboardOverallClicksListingsRequest,
  IndexFilterPriceAndDurationRequest,
  AddAffiliateBank,
  dodeleteAffiliatePaymentRequest,
  GetCancelVoucherDataByIdRequest,
  AddCancelBookingVoucherCode,
  GetAdminDashboardGraphData,
  MarkAsRead,
  VerifyMobBooking,
  landingPageDataRequest,
  affliateCalculateRequest,
  doEditAffiliatePayment,
  getAdminAffiliateDataRequest,
  getAdminAffiliateDashboardData,
  GetAffiliatepatrnerDashboardData,
  getAdminAffiliateUpdateData,
  getAffiliateRecordPayment,
  getAdminAffiliatePaymentData,
  getUpdateRecordPayments,
  getviewpackageavailabilityondates,
  PackageBookingFormListRequest,
  SetListingPackagePrice,
  newAffliateAdminRequest,
  AddSchoolAdminRequest,
  AccountDeactivate,
  doGetCountryRequest,
  doGetCityRequest,
  doUpdateLandingRequest,
  getFeaturedRetreatsCardServerSide,
  ListingCountryDataRequest,
  ListingStateDataRequest,
  ListingCityDataRequest,
  doGetLandingRequest,
  doGetLandingDataById,
  UpdateBookingPayment,
  CheckPackageDeleteRequest,
};
