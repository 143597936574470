import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { selectSchoolCustomerSupportForm } from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import { SchoolCustomerSupportRequest } from "../../../../utils/request";
import { CustomerSupportInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* SchoolCustomerSupportAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: CustomerSupportInterface = yield select(
    selectSchoolCustomerSupportForm
  );

  if (form.fullName.length == 0) {
    CustomToast("Please Enter Full Name", "ERROR");
    return;
  }
  if (form.email.length == 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (form.query.length == 0) {
    CustomToast("Please Enter Wuery", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      SchoolCustomerSupportRequest,
      form
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* schoolCustomerSupportRepoSaga() {
  yield takeLatest(actions.AddQuery, SchoolCustomerSupportAddRequest);
}
