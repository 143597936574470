import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.adminSchool) {
      return state.adminSchool;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectAdminSchoolForm = createSelector(
  [selectDomain],
  (state) => state.SchoolFormData
);
export const selectCertificateForm = createSelector(
  [selectDomain],
  (state) => state.certificate
);

export const selectCertificateList = createSelector(
  [selectDomain],
  (state) => state.certificateList
);
export const selectSchoolId = createSelector(
  [selectDomain],
  (state) => state.SchoolID
);
export const selectSchoolRegistration = createSelector(
  [selectDomain],
  (state) => state.RegistrationList
);
export const SelectshowOTPField = createSelector(
  [selectDomain],
  (state) => state.showOTPField
);
export const SelectOTP = createSelector([selectDomain], (state) => state.OTP);
export const selectRegistrationForm = createSelector(
  [selectDomain],
  (state) => state.Registration
);
export const selectRegistrationList = createSelector(
  [selectDomain],
  (state) => state.RegistrationList
);

export const selectSchoolCertificate = createSelector(
  [selectDomain],
  (state) => state.certificateList
);
export const selectAdminSchoolListData = createSelector(
  [selectDomain],
  (state) => state.SchoolList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSchoolEditModal = createSelector(
  [selectDomain],
  (state) => state.editModal
);
export const SelectSchoolEditTab = createSelector(
  [selectDomain],
  (state) => state.schoolEditTab
);
export const SelectfeaturedPhoto = createSelector(
  [selectDomain],
  (state) => state.featuredPhotoShow
);
export const SelectProfilePhotoCropShow = createSelector(
  [selectDomain],
  (state) => state.profilePhotoShow
);
export const SelectStatusFilter = createSelector(
  [selectDomain],
  (state) => state.statusFilter
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectProfileTypeFilter = createSelector(
  [selectDomain],
  (state) => state.profileTypeFilter
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectEmailVerification = createSelector(
  [selectDomain],
  (state) => state.emailVerification
);
export const SelectisDraftBasicDetail = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftBasicDetail
);
export const SelectisDraftBusinessOwnerDetail = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftBusinessOwnerDetail
);
export const SelectisDraftContactDetail = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftContactDetail
);
export const SelectisDraftLicencesDetail = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftLicencesDetail
);
export const SelectisDraftRegistrationAddress = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftRegistrationAddress
);
export const SelectisDraftNotification = createSelector(
  [selectDomain],
  (state) => state.isDraftModal.isDraftNotification
);
export const SelectisDraftForm = createSelector(
  [selectDomain],
  (state) => state.isDraftModal
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectisEmailLoading = createSelector(
  [selectDomain],
  (state) => state.isEmailLoading
);
