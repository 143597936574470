import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { END, eventChannel } from "redux-saga";
import { AxiosResponse } from "axios";
import { actions as actionsModal } from "../../../School/Listings/redux/slice";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import queryString from "query-string";
import {
  selectAdminUserForm,
  SelectCreatedOnFilter,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectStatusFilter,
  selectUserUpadatePasswordForm,
  SelectDateFilter,
  SelectSortColumn,
  SelectSortDir,
  selectUpdateAdminPasswordForm,
} from "./selector";
import {
  UpdateAdminPasswordInterface,
  UpdatePasswordInterface,
  UserInterface,
  UserStatusEnum,
} from "../types";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AddUserByAdmin,
  DeleteUserProfile,
  getUserById,
  getAllUserProfileList,
  UpdateUserProfile,
  UpdateSchoolPasswordRequest,
  uplodeImageRequest,
  SendInstructorMobOtp,
  VerifyInstructorMobOtp,
  UpdateCurrencyByCookiesRequest,
  AdminUpdatePasswordRequest,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";

export function* addUserAdminRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UserInterface = yield select(selectAdminUserForm);
  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddUserByAdmin, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.doGetUserList());
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getUserListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const statusFilter: string = yield select(SelectStatusFilter);
    const createdOnFilter: string = yield select(SelectCreatedOnFilter);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
    const data: any = queryString.stringify({
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
    });

    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllUserProfileList,
      `?statusFilter=${statusFilter}&createdOnFilter=${createdOnFilter}&pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? "&search=" + searchText : ""
      }&${data !== "endDate=&startDate=" ? data : ""}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0
      )
    );
    yield put(actions.setUserList(response.data.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateUserDataRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UserInterface = yield select(selectAdminUserForm);

  try {
    const response: AxiosResponse = yield call(UpdateUserProfile, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteUserProfileRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteUserProfile,
      action.payload
    );
    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.doGetUserList());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getUserByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getUserById,
      action.payload.id || action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setUserForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* UserPasswordUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  const form: UpdatePasswordInterface = yield select(
    selectUserUpadatePasswordForm
  );

  if (form.currentPassword.length == 0) {
    CustomToast("Please Enter Current Password", "ERROR");
    return;
  }

  if (form.newPassword.length == 0) {
    CustomToast("Please Enter New Password", "ERROR");
    return;
  }

  if (form.newPassword.length > 0) {
    if (!regex.test(form.newPassword)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  if (form.confirmNewPassword.length == 0) {
    CustomToast("Please Enter Confirm New Password", "ERROR");
    return;
  }
  if (form.confirmNewPassword.length > 0) {
    if (!regex.test(form.confirmNewPassword)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  const data = {
    currentPassword: btoa(form.currentPassword),
    newPassword: btoa(form.newPassword),
  };
  try {
    const response: AxiosResponse = yield call(
      UpdateSchoolPasswordRequest,
      data
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback(response.data.token));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = uplodeImageRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) emit(END);
    else emit(percentCompleted);
  });

  return [uploadPromise, chan];
}
export function* ProflieImagesUplodeRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.updateUserFormValue({
        key: "profilePic",
        value: response.data.data.name,
      })
    );
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* doupdateSchoolProfileDataCurrencyRequest(action: {
  payload: { currency: any; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      UpdateCurrencyByCookiesRequest,
      action.payload.currency
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield call(action?.payload?.callback());
    CatchBlockFunction(error);
  }
}

export function* AddPersonOtpVerification(action: {
  payload: { OTPTYPE: number; callback: any };
}) {
  yield delay(500);

  try {
    const form: UserInterface = yield select(selectAdminUserForm);
    log(form, "form");

    let firstName = form.firstName
      ? form.firstName.length > 0
        ? form.firstName
        : form.firstName
      : form.firstName;
    let lastName = form.lastName
      ? form.lastName.length > 0
        ? form.lastName
        : form.lastName
      : form.lastName;
    let data = {
      email: form.email || "",
      name: firstName + " " + lastName,
      phoneNo: form.mobileNumber || 0,
      otpType: action.payload.OTPTYPE,
    };
    const response: AxiosResponse = yield call(SendInstructorMobOtp, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationToken(response.data.token));
    yield put(actions.setShowOTPField(true));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* mobileNoOtpVerification(action: {
  payload: { OTP: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const form: UserInterface = yield select(selectAdminUserForm);
    let data = {
      code: action.payload.OTP,
      token: form.Verificationtoken,
    };
    const response: AxiosResponse = yield call(VerifyInstructorMobOtp, data);

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(
      actions.updateUserFormValue({
        key: "phoneNoVerify",
        value: response.data.status,
      })
    );
    yield put(actions.clearOtPField());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UpdateAdminPasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: UpdateAdminPasswordInterface = yield select(
    selectUpdateAdminPasswordForm
  );

  if (form.password.length == 0) {
    CustomToast("Please Enter New Password", "ERROR");
    return;
  }

  if (form.confirmPassword.length == 0) {
    CustomToast("Please Enter Confirm New Password", "ERROR");
    return;
  }
  const data = {
    confirmPassword: btoa(form.confirmPassword),
    password: btoa(form.password),
  };
  try {
    const response: AxiosResponse = yield call(
      AdminUpdatePasswordRequest,
      data
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback(response.data.token));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* adminRepoSagaAdminUser() {
  yield takeLatest(actions.AddNewAdminUser, addUserAdminRequest);
  yield takeLatest(actions.doGetUserList, getUserListRequest);
  yield takeLatest(actions.doGetUserFormById, getUserByIdRequest);
  yield takeLatest(actions.updateUserData, updateUserDataRequest);
  yield takeLatest(actions.deleteUserProfile, deleteUserProfileRequest);
  yield takeLatest(actions.doUpdateUserPassword, UserPasswordUpdateRequest);
  yield takeLatest(actions.doAddProfileImage, ProflieImagesUplodeRequest);
  yield takeLatest(
    actions.doupdateSchoolProfileDataCurrency,
    doupdateSchoolProfileDataCurrencyRequest
  );
  yield takeLatest(actions.doVerifiedMobileNo, AddPersonOtpVerification);
  yield takeLatest(actions.doVerifiedOTP, mobileNoOtpVerification);
  yield takeLatest(actions.doUpdateAdminPassword, UpdateAdminPasswordRequest);
}
