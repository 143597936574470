// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.SchoolListingMediaState) {
      return state.SchoolListingMediaState;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelectVideoModalType = createSelector(
  [selectDomain],
  (state) => state.VideoModalType
);
export const SelectImageModalType = createSelector(
  [selectDomain],
  (state) => state.ImagesModalType
);
export const selectRetreatPhotoForm = createSelector(
  [selectDomain],
  (state) => state.MediaImageForm.retreatPhotos
);
export const selectFoodPhotoForm = createSelector(
  [selectDomain],
  (state) => state.MediaImageForm.foodPhotos
);
export const selectMediaForm = createSelector(
  [selectDomain],
  (state) => state.MediaImageForm
);
export const selectRetreatVideoForm = createSelector(
  [selectDomain],
  (state) => state.MediaVideoForm.retreatVideos
);
export const selectFoodVideoForm = createSelector(
  [selectDomain],
  (state) => state.MediaVideoForm.foodVideos
);
export const selectMediaVideoForm = createSelector(
  [selectDomain],
  (state) => state.MediaVideoForm
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectVideoloader = createSelector(
  [selectDomain],
  (state) => state.Videoloader
);
export const SelectFoodPhotosCrop = createSelector(
  [selectDomain],
  (state) => state.foodCrop
);
export const SelectRetreatsPhotoCrop = createSelector(
  [selectDomain],
  (state) => state.retreatsCrop
);
export const SelectPhotoDraft = createSelector(
  [selectDomain],
  (state) => state.MediaImageForm.isDraft
);
export const SelectVideoDraft = createSelector(
  [selectDomain],
  (state) => state.MediaVideoForm.isDraft
);
export const SelectImageArray = createSelector(
  [selectDomain],
  (state) => state.imageArray
);
export const SelectRetreatimageArray = createSelector(
  [selectDomain],
  (state) => state.RetreatimageArray
);
export const SelectUploadFiles = createSelector(
  [selectDomain],
  (state) => state.uploadFiles
);
export const SelectUploadingCurrentIndex = createSelector(
  [selectDomain],
  (state) => state.uploadingCurrentIndex
);
export const SelectUploadFileType = createSelector(
  [selectDomain],
  (state) => state.uploadFileType
);
