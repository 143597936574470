import { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AllCountriesInterface,
  AllCurrencyInterface,
  SchoolPaymentsInterface,
  SchoolPaymentsState,
} from "../types";
import { log } from "../../../../utils/logger";

export const initialState: SchoolPaymentsState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  SchoolPaymentsFormData: {
    paymentType: 1,
    paypalEmail: "",
    id: "",
    country: "",
    currency: "",
    accountHolderName: "",
    bankName: "",
    beneficiaryName: "",
    bankAccountNo: "",
    bankId: "",
    schoolId: "",
  },
  allCountriesList: [],
  allCurrencyList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  allActiveCurrencyList: [],
  allCityList: [],
  allStateList: [],
};

export const useClinicSlice = createSlice({
  name: "schoolPayments",
  initialState,
  reducers: {
    updateSchoolPaymentsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `SchoolPaymentsFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.SchoolPaymentsFormData.id = "";
      state.SchoolPaymentsFormData.currency = "";
      state.SchoolPaymentsFormData.country = "";
      state.SchoolPaymentsFormData.bankAccountNo = "";
      state.SchoolPaymentsFormData.accountHolderName = "";
      state.SchoolPaymentsFormData.bankId = "";
      state.SchoolPaymentsFormData.bankName = "";
      state.SchoolPaymentsFormData.beneficiaryName = "";
      state.SchoolPaymentsFormData.paypalEmail = "";
      state.SchoolPaymentsFormData.schoolId = "";
    },

    AddSchoolPayments: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
    },

    DeletePaymentInfo: (state) => {},

    doGetPaymentList: (state, action: PayloadAction<any>) => {},
    languageEdit: (state, action: PayloadAction<any>) => {},

    setDataInForm: (state, action: PayloadAction<SchoolPaymentsInterface>) => {
      state.SchoolPaymentsFormData = action.payload;
    },
    doGetCountryList: (
      state,
      action: PayloadAction<{ serach: string; callback: () => void }>
    ) => {},
    doGetStateList: (state, action: PayloadAction<string>) => {},
    dogetCityList: (state, action: PayloadAction<string>) => {},
    setCountryList: (
      state,
      action: PayloadAction<Array<AllCountriesInterface>>
    ) => {
      state.allCountriesList = action.payload;
    },
    setStateList: (state, action: PayloadAction<[]>) => {
      state.allStateList = action.payload;
    },
    setCityList: (state, action: PayloadAction<[]>) => {
      state.allCityList = action.payload;
    },
    doGetCurrencyList: (state) => {},
    setCurrencyList: (
      state,
      action: PayloadAction<Array<AllCurrencyInterface>>
    ) => {
      state.allCurrencyList = action.payload;
    },
    setALLActiveCurrencyList: (
      state,
      action: PayloadAction<Array<AllCurrencyInterface>>
    ) => {
      state.allActiveCurrencyList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: SchoolPaymentsSliceKey,
} = useClinicSlice;
