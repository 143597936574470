export interface SchoolCouponState {
  totalRefferals: number;
  couponForm: couponFormInterface;
  couponList: couponFormInterface[];
  refferalsList: any;
  pageNo: number;
  PageSize: number;
  promotionPageNo: number;
  promotionPageSize: number;
  totalCouponCount: number;
  loading: boolean;
  sortColumn: string;
  sortDir: string;
}
export enum couponDiscountTypeEnum {
  PERCENTAGE = 1,
}
export interface couponFormInterface {
  id: string;
  couponName: string;
  couponCode: string;
  expiryDate: string;
  discountType: couponDiscountTypeEnum;
  discount: any;
  listing: string[];
  quantity: number;
  remaining: number;
}
export type InitialMileStoneStata = SchoolCouponState;
