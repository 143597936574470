import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.adminUser) {
      return state.adminUser;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectAdminUserForm = createSelector(
  [selectDomain],
  (state) => state.UserFormData
);
export const selectAdminUserListData = createSelector(
  [selectDomain],
  (state) => state.UserList
);
export const selectUserUpadatePasswordForm = createSelector(
  [selectDomain],
  (state) => state.updatePassword
);
export const selectUserUpadatePasswordList = createSelector(
  [selectDomain],
  (state) => state.updatePasswordList
);
export const selectCropShowModal = createSelector(
  [selectDomain],
  (state) => state.cropShow
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectUserEditModal = createSelector(
  [selectDomain],
  (state) => state.editModal
);
export const SelectUserDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);
export const SelectShowPassword = createSelector(
  [selectDomain],
  (state) => state.showPassword
);
export const SelectUserStatusShow = createSelector(
  [selectDomain],
  (state) => state.userStatusShow
);
export const SelectUserCreatedOnShow = createSelector(
  [selectDomain],
  (state) => state.userCreatedOnShow
);
export const SelectStatusFilter = createSelector(
  [selectDomain],
  (state) => state.statusFilter
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectDate = createSelector(
  [selectDomain],
  (state) => state.userCreatedOnShow
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectShowCurrentPassword = createSelector(
  [selectDomain],
  (state) => state.currentPassword
);
export const SelectShowNewPassword = createSelector(
  [selectDomain],
  (state) => state.newPassword
);
export const SelectShowOTPField = createSelector(
  [selectDomain],
  (state) => state.showOTPField
);
export const SelectOTP = createSelector([selectDomain], (state) => state.OTP);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const selectUpdateAdminPasswordForm = createSelector(
  [selectDomain],
  (state) => state.updateAdminPassword
);
