import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";
import { log } from "../../../../utils/logger";

const selectDomain = (state: RootState) => {


  if (state) {
    if (state.SchoolBookingState) {
      return state.SchoolBookingState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const SelectCurrentlyconnectedList = createSelector(
  [selectDomain],
  (state) => state.CurrentlyconnectedList
);
export const SelectPreviousBookingsList = createSelector(
  [selectDomain],
  (state) => state.PreviousBookingsList
);
export const SelectUpcomingBookingsList = createSelector(
  [selectDomain],
  (state) => state.UpcomingBookingsList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectTotalPreviousBooking = createSelector(
  [selectDomain],
  (state) => state.TotalPreviousBookingList
);
export const SelectTotalCurrentlyBooking = createSelector(
  [selectDomain],
  (state) => state.TotalCurrentlyBookingList
);
export const SelectTotalUpComingBooking = createSelector(
  [selectDomain],
  (state) => state.TotalUpComingBookingList
);
export const selectBookingsListPagination = createSelector(
  [selectDomain],
  (state) => state.BookingsListPagination
);
