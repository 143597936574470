import { AxiosResponse } from "axios";
import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  DeleteMediaManager,
  GetMediaManagerById,
  getMediaManagerList,
  getMediaManagerListForAdmin,
  MediaManagerAdd,
  MediaManagerUpdate,
  uplodeImageRequest,
} from "../../../../utils/request";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import { MediaManagerInterface } from "../types";
import {
  SelectPageNo,
  SelectPageSize,
  SelectSortColumn,
  SelectSortDir,
  selectSchoolMediaManagerForm,
  selectSchoolMediaManagerListData,
} from "./selector";
import { actions } from "./slice";
import { END, eventChannel } from "redux-saga";
import { actions as actionsModal } from "../../Listings/redux/slice";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import {
  SelectCreatedOn,
  SelectListingFIlter,
  SelectSchoolFilter,
  SelectSearch,
  SelectSelectDate,
  SelectStatus,
} from "../../../Admin/MediaManager/redux/selector";
import queryString from "query-string";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadPromise = uplodeImageRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) emit(END);
    else emit(percentCompleted);
  });

  return [uploadPromise, chan];
}
export function* mediaManagerFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  const PhotoForm: MediaManagerInterface[] = yield select(
    selectSchoolMediaManagerListData
  );
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      PhotoForm.filter(
        (val: MediaManagerInterface) =>
          response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.updateSchoolMediaManagerFormValue({
          key: "video",
          value: response.data.data.name,
        })
      );
      yield put(
        actions.updateSchoolMediaManagerFormValue({
          key: "fileName",
          value: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Video Already Uploaded", "ERROR");
    }
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}

export function* mediaManagerAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: MediaManagerInterface = yield select(
    selectSchoolMediaManagerForm
  );
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(MediaManagerAdd, form);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalMedia(0));
    yield put(actions.setMediaManagerData([]));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* mediaManagerUpdateRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: MediaManagerInterface = yield select(
    selectSchoolMediaManagerForm
  );

  const data = {
    mediaId: action.payload.id,
    listing: form.listing,
    schoolId: form.schoolId,
  };
  try {
    const response: AxiosResponse = yield call(MediaManagerUpdate, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getAllMediaManagerListRequest(action: { payload: string }) {
  yield delay(1000);
  try {
    const PageNo: number = yield select(SelectPageNo);
    const PageSize: number = yield select(SelectPageSize);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getMediaManagerList,
      `?schoolId=${action.payload}&pageNo=${PageNo}&pageSize=${PageSize}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTotalMedia(response.data[0].meta.total));
    yield put(actions.setMediaManagerData(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeleteMediaManagerRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      DeleteMediaManager,
      action.payload.id
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getMediaManagerByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetMediaManagerById,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setMediaDataInForm(response.data[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getAllMediaManagerListForAdminRequest(action: {
  payload: any;
}) {
  yield delay(500);

  const PageNo: number = yield select(SelectPageNo);
  const pageSize: number = yield select(SelectPageSize);
  const SortColumn: string = yield select(SelectSortColumn);
  const SortDir: string = yield select(SelectSortDir);
  const search: string = yield select(SelectSearch);
  const createdon: number = yield select(SelectCreatedOn);
  const dateForQuery: { value: Array<any> } = yield select(SelectSelectDate);
  const listing: [] = yield select(SelectListingFIlter);
  const school: [] = yield select(SelectSchoolFilter);
  const status: number = yield select(SelectStatus);
  const date: any = queryString.stringify({
    startDate: dateForQuery ? dateForQuery.value[0]?.startDate : "",
    endDate: dateForQuery ? dateForQuery.value[0]?.endDate : "",
  });
  try {
    const data = {
      schoolFilter: school,
      retreatFilter: listing,
      createdOnFilter: createdon,
      statusFilter: status,
      search: search,
      date: dateForQuery,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getMediaManagerListForAdmin,
      `?pageNo=${PageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setTotalList(response.data[0].meta.total));
    yield put(actions.setMediaManagerData(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* schoolMediaManagerRepoSaga() {
  yield takeLatest(actions.doAddVideo, mediaManagerFileUploadRequest);
  yield takeLatest(actions.AddMediaManagerVideo, mediaManagerAddRequest);
  yield takeLatest(actions.updateMediaManagerVideo, mediaManagerUpdateRequest);
  yield takeLatest(
    actions.doGetMediaManagerList,
    getAllMediaManagerListRequest
  );

  yield takeLatest(actions.deleteSchoolMediaManager, DeleteMediaManagerRequest);
  yield takeLatest(
    actions.doGetSchoolMediaManagerById,
    getMediaManagerByIdRequest
  );

  //  list for Admin ..........
  yield takeLatest(
    actions.doGetMediaManagerListForAdmin,
    getAllMediaManagerListForAdminRequest
  );
}
