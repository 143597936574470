import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { AccommodationPhotosModel, AccommodationsInterface } from "../types";
import {
  SelectPageNo,
  selectPhotosAccommodationForm,
  selectSchoolAccommodationForm,
} from "./selector";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import { END, eventChannel } from "redux-saga";
import { actions as actionModal } from "../../Listings/redux/slice";

import {
  AddSchoolAccomodation,
  DeleteAccommodationRequest,
  GetAccommodationByIdRequest,
  getAccommodationCategoryRequest,  
  getAccommodationRoomTypeRequest,
  GetAllAccommodationListRequest,
  UpdateAccommodationDetailRequest,
  uplodeImageRequest,
} from "../../../../utils/request";
import { actions } from "./slice";
import CustomToast from "../../../../components/UI/CustomToast";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* accommodationAddRequest(action: {
  payload: { schoolID: string; localStorageSchoolId: string; callback: any };
}) {
  var regex = /^[a-zA-Z0-9 ]*$/gm;
  yield delay(500);
  const form: AccommodationsInterface = yield select(
    selectSchoolAccommodationForm
  );
  if (form.accommodationSharing === null) {
    CustomToast("Please Select Accommodation Sharing", "ERROR");
    return;
  }
  if (form.accommodationCategory === "") {
    CustomToast("Please Select Accommodation Category", "ERROR");
    return;
  }
  if (form.accommodationName === "") {
    CustomToast("Please Fill Accommodation Name", "ERROR");
    return;
  }
  if (!regex.test(form.accommodationName)) {
    CustomToast("Please Correct Accommodation Name", "ERROR");
    return;
  }
  if (form.roomType === "") {
    CustomToast("Please Select Room Type", "ERROR");
    return;
  }
  if (form.description == "") {
    CustomToast("Please Fill Description", "ERROR");
    return;
  }
  if (
    form.accommodationPhotos == null ||
    form.accommodationPhotos.length == 0
  ) {
    CustomToast("Please Select Photos", "ERROR");
    return;
  }
  const data = {
    accommodationCategory: form.accommodationCategory,
    accommodationName: form.accommodationName,
    accommodationPhotos: form.accommodationPhotos,
    accommodationSharing: form.accommodationSharing,
    description: form.description,
    id: form.id,
    maxOccupancy: form.maxOccupancy,
    photos: form.photos,
    roomType: form.roomType,
    schoolId: action?.payload?.localStorageSchoolId,
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(AddSchoolAccomodation, data);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.doGetAccommodationList(action.payload.schoolID));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getAllAccomodationListListRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setaccomodationLoader(true));
    const PageNo: number = yield select(SelectPageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAllAccommodationListRequest,
      `?schoolId=${action.payload}&pageNo=${PageNo}&pageSize=3`
    );
    yield put(actions.setaccomodationLoader(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setTotalAccomodationlist(response.data.data[0].meta.total)
    );
    yield put(actions.setAccomodationlistData(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setaccomodationLoader(false));
    CatchBlockFunction(error);
  }
}

export function* DeleteAccommodation(action: {
  payload: { id: string; callback: () => void };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    CustomToast("Please Select Instructor", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteAccommodationRequest,
      action.payload.id
    );

    if (response && !response.data.status) {
      
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadPromise = uplodeImageRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) emit(END);
    else emit(percentCompleted);
  });

  return [uploadPromise, chan];
}
export function* AccommodationFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  const FoodPhotoForm: AccommodationPhotosModel[] = yield select(
    selectPhotosAccommodationForm
  );
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      FoodPhotoForm.filter(
        (val: AccommodationPhotosModel) =>
          response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setPhotosData({
          photo: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    yield put(actionModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* getAccommodationRoomTypeListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAccommodationRoomTypeRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setAccommodationRoomTypeList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAccommodationCategoryListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAccommodationCategoryRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setAccommodationCategoryList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateData(action: {
  payload: { schoolID: string; callback: any };
}) {
  yield delay(500);
  const form: AccommodationsInterface = yield select(
    selectSchoolAccommodationForm
  );
  var regex = /^[a-zA-Z0-9 ]*$/gm;
  if (form.accommodationSharing === null) {
    CustomToast("Please Select Accommodation Sharing", "ERROR");
    return;
  }
  if (form.accommodationCategory === "") {
    CustomToast("Please Select Accommodation Category", "ERROR");
    return;
  }
  if (form.accommodationName === "") {
    CustomToast("Please Fill Accommodation Name", "ERROR");
    return;
  }
  if (!regex.test(form.accommodationName)) {
    CustomToast("Please Correct Accommodation Name", "ERROR");
    return;
  }
  if (form.roomType === "") {
    CustomToast("Please Select Room Type", "ERROR");
    return;
  }
  if (form.description == "") {
    CustomToast("Please Fill Description", "ERROR");
    return;
  }
  if (
    form.accommodationPhotos == null ||
    form.accommodationPhotos.length == 0
  ) {
    CustomToast("Please Select Photos", "ERROR");
    return;
  }
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      UpdateAccommodationDetailRequest,
      form
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.doGetAccommodationList(action.payload.schoolID));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAllAccomodationByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAccommodationByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setDataInForm(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* schoolAccommodationRepoSaga() {
  yield takeLatest(actions.AddAccommodation, accommodationAddRequest);
  yield takeLatest(
    actions.doGetAccommodationList,
    getAllAccomodationListListRequest
  );
  yield takeLatest(actions.DeleteAccomodation, DeleteAccommodation);
  yield takeLatest(
    actions.doAddAccommodationImage,
    AccommodationFileUploadRequest
  );
  yield takeLatest(
    actions.doGetAccommodationCategory,
    getAccommodationCategoryListRequest
  );
  yield takeLatest(
    actions.doGetAccommodationRoomType,
    getAccommodationRoomTypeListRequest
  );
  yield takeLatest(actions.updateDataHelp, updateData);
  yield takeLatest(
    actions.doGetAccommodationById,
    getAllAccomodationByIdRequest
  );
}
