import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  FeaturedRetreatsInterface,
  GetCouponResponceInterface,
  RetreatsFeaturedCardState,
  ReviewDetailInterface,
  TeacherTrainingInterface,
} from "../types";

export const initialState: RetreatsFeaturedCardState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 3,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  featuredRetreatsCardList: [],
  featuredRetreatsForm: {
    id: "",
    file: "",
    photo: "",
    title: "",
    likeWishList: false,
    rating: 0,
    price: 0,
    retreatId: "",
    type: "",
  },
  featureRetreatDetailInfo: [],
  teacherTrainingList: [],
  frequentlyRetreatDetailInfo: [],
  similarlyRetreatsDetailInfo: [],
  recentlyRetreatsDetailInfo: [],
  reviewDetailInfo: [],
  RecentViewedDataRequest: {
    retreatId: "",
    userId: "",
  },
  couponResponceForm: {
    couponCode: "",
    expiryDate: "",
    remainingAmount: 0,
    remainingCoupon: 0,
    requiredDeposit: 0,
    saving: 0,
    totalPrice: 0,
  },
  couponForm: {
    couponCode: "",
    packageId: "",
    retreatId: "",
  },
  reviewStatus: false,
  totalReview: 0,
};

export const useClinicSlice = createSlice({
  name: "retreatsFeaturedCards",
  initialState,
  reducers: {
    updateCouponFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `couponForm.${action.payload.key}`, action.payload.value);
    },
    ClearcouponCode: (state) => {
      state.couponForm.couponCode = "";
      (state.couponResponceForm.couponCode = ""),
        (state.couponResponceForm.expiryDate = ""),
        (state.couponResponceForm.remainingAmount = 0),
        (state.couponResponceForm.remainingCoupon = 0),
        (state.couponResponceForm.requiredDeposit = 0),
        (state.couponResponceForm.saving = 0),
        (state.couponResponceForm.totalPrice = 0);
    },
    GetCouponRequest: (state) => {},
    setFeaturedRetreatsCardList: (
      state,
      action: PayloadAction<Array<FeaturedRetreatsInterface>>
    ) => {
      state.featuredRetreatsCardList = action.payload;
    },
    setCouponResponce: (
      state,
      action: PayloadAction<GetCouponResponceInterface>
    ) => {
      state.couponResponceForm = action.payload;
    },
    setFeaturedTeacherTrainingList: (
      state,
      action: PayloadAction<Array<TeacherTrainingInterface>>
    ) => {
      state.teacherTrainingList = action.payload;
    },

    setReviewListData: (
      state,
      action: PayloadAction<Array<ReviewDetailInterface>>
    ) => {
      state.reviewDetailInfo = action.payload;
    },
    doGetFeaturedRetreatsCardList: (state) => {},
    doGetFeaturedTeacherTrainingList: (state) => {},
    doGetFeaturedRetreatsDetailData: (state, action: PayloadAction<any>) => {},
    doGetFeaturedRetreatsDetailList: (state, action: PayloadAction<any>) => {},
    doGetSimilarlyRetreatsDetailList: (state, action: PayloadAction<any>) => {},
    doGetRecentlyRetreatsDetailList: (state, action: PayloadAction<any>) => {},
    doGetReviewDetailList: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalReview: (state, action: PayloadAction<number>) => {
      state.totalReview = action.payload;
    },
    doaddRecentlyViewed: (
      state,
      action: PayloadAction<{userid:string , retreatId :string, callback: () => void }>
    ) => {},
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setRetreatDetailData: (state, action: PayloadAction<any>) => {
      state.featureRetreatDetailInfo = action.payload;
    },
    setFrequentlyRetreatDetailData: (state, action: PayloadAction<any>) => {
      state.frequentlyRetreatDetailInfo = action.payload;
    },
    setSimilarlyRetreatDetailData: (state, action: PayloadAction<any>) => {
      state.similarlyRetreatsDetailInfo = action.payload;
    },
    setRecentlyRetreatDetailData: (state, action: PayloadAction<any>) => {
      state.recentlyRetreatsDetailInfo = action.payload;
    },
    toggleShowReview: (state) => {
      state.reviewStatus = !state.reviewStatus;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
