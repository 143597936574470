import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { isNull, set } from "lodash";
import {
  BookingPaymentScheduleEnum,
  CancellationPolicyTypeEnum,
  ListingBookingPolicyInterface,
  ListingPackagePriceInterface,
  listingPhotoModel,
  ListingPriceInterface,
  SchoolListingPackagesState,
} from "../types";
import { FileUploadItems } from "../../Media/types";
import { log } from "../../../../../utils/logger";

export const initialState: SchoolListingPackagesState = {
  bookingPolicyList: [],
  BookingModalType: 0,
  PriceModalType: 0,
  RankingModalType: 0,
  CropModal: true,
  bookingPolicyFormData: {
    id: "",
    cancellationPolicy: CancellationPolicyTypeEnum.FULLY_REFUNDABLE,
    newPolicy: "",
    paymentDaysBeforeArrival: 0,
    paymentSchedule: BookingPaymentScheduleEnum.ON_ARRIVAL,
    remainingAmount: 0,
    requiredDeposit: 0,
    retreatId: "",
    isDraft: false,
    count: 0,
  },
  rankingList: [],
  rankingFormData: {
    id: "",
    rankingScore: 0,
    retreatId: "",
    isDraft: false,
    count: 0,
  },
  priceFormData: {
    roomAccommodationType: "",
    photos: [],
    roomAccommodationName: "",
    id: "",
    currencyCode: "",
    instantlyBookable: false,
    listingPhoto: [],
    noOfPeople: 0,
    note: "",
    price: "",
    curCode: "",
    curName: "",
    roomAccommodation: "",
    isDraft: false,
    count: 0,
    packageId: "",
  },
  priceList: [],
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: -1,
  isadd: false,
  dissableCheck: false,
  imageArray: [],
  bookingPolicyData: [],
  rankingData: [],
  listingPackagePrice: { maximumPrice: "", minimumPrice: "" },
  priceLoading: false,
  mainPagePriceLoader: false,
  bookingLoader: false,
  uploadFiles: [],
  uploadingCurrentIndex: -1,
};

export const useClinicSlice = createSlice({
  name: "schoolListingPackages",
  initialState,
  reducers: {
    DeletePackage: (
      state,
      action: PayloadAction<{
        packageId: string;
        listingsID: any;
        index: number;
      }>
    ) => {
      //@ts-ignore
      state.priceList.splice(action.payload.index, 1);
    },
    setUploadingCurrentIndex: (state, action: PayloadAction<number>) => {
      state.uploadingCurrentIndex = action.payload;
    },

    setUploadFiles: (state, action: PayloadAction<FileUploadItems[]>) => {
      state.uploadFiles = action.payload;
    },
    updateSchoolListingRankingFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `rankingFormData.${action.payload.key}`, action.payload.value);
    },
    updateInstantBookbable: (
      state,
      action: PayloadAction<{ value: any; callback: () => void }>
    ) => {},
    updateInstantBookbableSaga: (state, action: PayloadAction<boolean>) => {
      set(state, `priceFormData.instantlyBookable`, action.payload);
      state.priceList[state.editIndex].instantlyBookable = action.payload;
      state.editIndex = -1;
    },
    updateSchoollistingBookingPolicyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `bookingPolicyFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    setImageArray: (state, action: PayloadAction<any>) => {
      // state.imageArray.push(action.payload);
      state.imageArray.push(action.payload);
    },
    AddRanking: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your Instructors Skills add");
      // state.SchoolList.push(state.SchoolFormData);
    },
    setPackageList: (
      state,
      action: PayloadAction<Array<ListingPriceInterface>>
    ) => {
      state.priceList = action.payload;
    },

    AddBookingPolicyDetails: (
      state,
      action: PayloadAction<{ isOnlineOffline: any; callback: () => void }>
    ) => {},

    SetBookingPolicyFormDataById: (
      state,
      action: PayloadAction<ListingBookingPolicyInterface>
    ) => {
      state.bookingPolicyFormData = action.payload;
    },
    SetBookingReqPolicyFormDataById: (
      state,
      action: PayloadAction<Array<ListingBookingPolicyInterface>>
    ) => {
      state.bookingPolicyData = action.payload;
    },
    SetRankingFormDataById: (state, action: PayloadAction<any>) => {
      state.rankingFormData = action.payload;

      state.rankingFormData = {
        id: "",
        rankingScore: action.payload.rankingScore
          ? action.payload.rankingScore
          : 0,
        retreatId: action.payload.retreatId ? action.payload.retreatId : "",
        isDraft: false,
        count: 0,
      };
    },
    SetRankingData: (state, action: PayloadAction<any>) => {
      state.rankingData = action.payload;
    },
    doGetBookingPolicyDataByID: (state, action: PayloadAction<any>) => {},
    doGetGetRankingDataByID: (state, action: PayloadAction<any>) => {},
    AddAutoReplyDetails: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      // state.SchoolList.push(state.SchoolFormData);
    },
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    getPackage: (state, action: PayloadAction<any>) => {},

    updateSchoolListingPriceFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `priceFormData.${action.payload.key}`, action.payload.value);
    },
    setPhotoData: (state, action: PayloadAction<listingPhotoModel>) => {
      state.priceFormData.photos.push(action.payload);
    },
    photosData: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {
      state.priceFormData.photos = action.payload.data;
      action.payload.callback();
    },
    photosDragData: (
      state,
      action: PayloadAction<{
        rowIndex: number;
        data: any;
        callback: () => void;
      }>
    ) => {
      state.priceList[action.payload.rowIndex].photos = action.payload.data;
      action.payload.callback();
    },
    AddPricePackage: (
      state,
      action: PayloadAction<{ isOnlineOffline: any; callback: () => void }>
    ) => {
      if (state.editIndex > -1) {
        state.priceList[state.editIndex] = state.priceFormData;
        state.editIndex = -1;
      } else {
        state.priceList.push(state.priceFormData);
      }
      state.editIndex = -1;
      action.payload.callback();
    },
    AddPackage: (
      state,
      action: PayloadAction<{ retreat_id: any; callback: () => void }>
    ) => {},
    clearPackageform: (state) => {
      state.priceFormData.id = "";
      state.priceFormData.roomAccommodation = "";
      state.priceFormData.noOfPeople = 0;
      state.priceFormData.curName = "";
      state.priceFormData.curCode = "";
      state.priceFormData.currencyCode = "";
      state.priceFormData.price = "";
      state.priceFormData.note = "";
      state.priceFormData.instantlyBookable = false;
      state.priceFormData.roomAccommodationName = "";
      state.priceFormData.listingPhoto = [];
      state.priceFormData.photos = [];
      state.imageArray = [];
      state.editIndex = -1;
    },

    setPackageForEdit: (
      state,
      action: PayloadAction<{ data: ListingPriceInterface; index: number }>
    ) => {
      state.editIndex = action.payload.index;
      state.priceFormData = action.payload.data;
    },

    HandleInstantsetPackageForEdit: (
      state,
      action: PayloadAction<{
        data: ListingPriceInterface;
        index: number;
        callback: () => void;
      }>
    ) => {
      state.editIndex = action.payload.index;
      state.priceFormData = action.payload.data;
    },
    doGetPackageforEdit: (
      state,
      action: PayloadAction<{ id: any; retreatid: any }>
    ) => {},
    doAddImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddAccommodationImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doHandleInstantlyBookable: (
      state,
      action: PayloadAction<{
        status: boolean;
        packageId: string;
        schoolId: string;
      }>
    ) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setbookingLoader: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.bookingLoader = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setmainPagePriceLoader: (state, action: PayloadAction<boolean>) => {
      state.mainPagePriceLoader = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    SetRankingModalType: (state, action: PayloadAction<number>) => {
      state.RankingModalType = action.payload;
    },
    SetBookingModalType: (state, action: PayloadAction<number>) => {
      state.BookingModalType = action.payload;
    },
    SetPriceModalType: (state, action: PayloadAction<number>) => {
      state.PriceModalType = action.payload;
    },
    deletePhotos: (state, action: PayloadAction<any>) => {
      state.priceFormData.photos.splice(action.payload, 1);
    },
    deletePhotosFromList: (
      state,
      action: PayloadAction<{
        rowIndex: number;
        photoIndex: number;
        imageName: string;
      }>
    ) => {
      state.priceList[action.payload.rowIndex].photos.splice(
        action.payload.photoIndex,
        1
      );
    },
    deleteCardPhotos: (state, action: PayloadAction<any>) => {
      state.priceFormData.photos.splice(action.payload, 1);
    },
    toggalCropShowModal: (state) => {
      state.CropModal = !state.CropModal;
    },
    RankingDraft: (state, action: PayloadAction<boolean>) => {
      state.rankingFormData.isDraft = action.payload;
    },

    BookingDraft: (state, action: PayloadAction<boolean>) => {
      state.bookingPolicyFormData.isDraft = action.payload;
    },
    Pricedraft: (state, action: PayloadAction<boolean>) => {
      state.priceFormData.isDraft = action.payload;
    },

    RankingCount: (state) => {
      state.rankingFormData.count = state.rankingFormData.count + 1;
    },

    BookingCount: (state) => {
      state.bookingPolicyFormData.count = state.bookingPolicyFormData.count + 1;
    },
    PriceCount: (state) => {
      state.priceFormData.count = state.priceFormData.count + 1;
    },
    clearBookingPolicyFormData: (state) => {
      state.bookingPolicyFormData.id = "";
      state.bookingPolicyFormData.requiredDeposit = 0;
      state.bookingPolicyFormData.remainingAmount = 0;
      state.bookingPolicyFormData.paymentSchedule =
        BookingPaymentScheduleEnum.DAYS_BEFORE_ARRIVAL;
      state.bookingPolicyFormData.paymentDaysBeforeArrival = 0;
      state.bookingPolicyFormData.cancellationPolicy =
        CancellationPolicyTypeEnum.PARTIALLY_REFUNDABLE;
      state.bookingPolicyFormData.newPolicy = "";
      state.bookingPolicyFormData.isDraft = false;
      state.bookingPolicyFormData.count = 0;
      state.bookingPolicyFormData.retreatId = "";
    },
    clearRankingFormData: (state) => {
      state.rankingFormData.id = "";
      state.rankingFormData.rankingScore = 0;
      state.rankingFormData.isDraft = false;
      state.rankingFormData.count = 0;
      state.rankingFormData.retreatId = "";
    },
    clearPriceListData: (state) => {
      state.priceList = [];
    },
    //********* package price set */
    doSetPackagePeice: (
      state,
      action: PayloadAction<{ code: string; callback: () => void }>
    ) => {},
    setListingPackagePrice: (
      state,
      action: PayloadAction<ListingPackagePriceInterface>
    ) => {
      state.listingPackagePrice = action.payload;
    },
    setPriceLoading: (state, action: PayloadAction<boolean>) => {
      state.priceLoading = action.payload;
    },

    doCheckPackageDelete: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
