import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolListingOther) {
      return state.schoolListingOther;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolListingFaqForm = createSelector(
  [selectDomain],
  (state) => state.listingFaqFormData
);
export const selectSchoolListingFaqList = createSelector(
  [selectDomain],
  (state) => state.listingFaqList
);
export const selectautoReplyLoading = createSelector(
  [selectDomain],
  (state) => state.autoReplyLoading
);
export const selectCleanLoader = createSelector(
  [selectDomain],
  (state) => state.cleanLoader
);
export const selectSchoolListingAutoReplyForm = createSelector(
  [selectDomain],
  (state) => state.listingAutoReplyFormData
);
export const selectSchoolListingAutoReplyList = createSelector(
  [selectDomain],
  (state) => state.listingAutoReplyList
);
export const selectSchoolListingCleanSafeMeasuresForm = createSelector(
  [selectDomain],
  (state) => state.listingCleanSafeMeasuresFormData
);
export const selectSchoolListingCleanSafeMeasuresList = createSelector(
  [selectDomain],
  (state) => state.listingCleanSafeMeasuresList
);
export const selectAllCleanAndSafeMeasuresListData = createSelector(
  [selectDomain],
  (state) => state.cleanAndSafeMeasuresList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectFaqDragList = createSelector(
  [selectDomain],
  (state) => state.listingFaqDragList
);
export const selectlistingFaqDragFormData = createSelector(
  [selectDomain],
  (state) => state.listingFaqDragFormData
);
export const SelectAutoReplyModalType = createSelector(
  [selectDomain],
  (state) => state.AutoReplyModalType
);
export const SelectFAqModalType = createSelector(
  [selectDomain],
  (state) => state.FAQModalType
);
export const SelectCleanSafeModalType = createSelector(
  [selectDomain],
  (state) => state.CleanAndSafeModalType
);
export const cleanAndSafeDraft = createSelector(
  [selectDomain],
  (state) => state.listingCleanSafeMeasuresFormData.isDraft
);
export const FaqDraft = createSelector(
  [selectDomain],
  (state) => state.listingFaqFormData.isDraft
);
export const AutoReplyDraft = createSelector(
  [selectDomain],
  (state) => state.listingAutoReplyFormData.isDraft
);
export const SelectcleanandSafeSelectedList = createSelector(
  [selectDomain],
  (state) => state.cleanandSafeSelectedList
);

export const SelectfaqLoader = createSelector(
  [selectDomain],
  (state) => state.faqLoader
);