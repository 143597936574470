import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.adminEmailNewsLetter) {
      return state.adminEmailNewsLetter;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectEmailNewsLetterListData = createSelector(
  [selectDomain],
  (state) => state.adminEmailNewsLetterList
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);
export const selectTotalList = createSelector(
  [selectDomain],
  (state) => state.totalList
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
