import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolReview) {
      return state.schoolReview;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolReviewForm = createSelector(
  [selectDomain],
  (state) => state.schoolReviewForm
);
export const selectSchoolAllReviewListData = createSelector(
  [selectDomain],
  (state) => state.schoolReviewListData
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectTotalList = createSelector(
  [selectDomain],
  (state) => state.totalList
);

export const SelectTotalReview = createSelector(
  [selectDomain],
  (state) => state.totalReview
);
export const SelectEditModal = createSelector(
  [selectDomain],
  (state) => state.editModal
);
