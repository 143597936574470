// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";

import { initialState } from "./slice";
import { log } from "../../../../utils/logger";

const selectDomain = (state: RootState) => {


  if (state) {
    return state.adminPayments;
  } else {
    return initialState;
  }
};

export const SelectPaymentsListData = createSelector(
  [selectDomain],
  (state) => state.PaymentsList
);
export const SelectAffiliatesListData = createSelector(
  [selectDomain],
  (state) => state.AffiliatesList
);
export const SelectEarningReportsListData = createSelector(
  [selectDomain],
  (state) => state.EarningReportsList
);
export const SelectUserListData = createSelector(
  [selectDomain],
  (state) => state.user
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const SelectAffiliatePageNo = createSelector(
  [selectDomain],
  (state) => state.AffiliatepageNo
);
export const SelectAffiliatePageSize = createSelector(
  [selectDomain],
  (state) => state.AffiliatepageSize
);
export const SelectDiscountPageNo = createSelector(
  [selectDomain],
  (state) => state.DiscountpageNo
);
export const SelectDiscountPageSize = createSelector(
  [selectDomain],
  (state) => state.DiscountpageSize
);
export const SelectEarningReportPageNo = createSelector(
  [selectDomain],
  (state) => state.EarningReportpageNo
);
export const SelectEarningReportPageSize = createSelector(
  [selectDomain],
  (state) => state.EarningReportpageSize
);
export const SelectPaymentsPageNo = createSelector(
  [selectDomain],
  (state) => state.PaymentspageNo
);
export const SelectPaymentsPageSize = createSelector(
  [selectDomain],
  (state) => state.PaymentspageSize
);
export const SelectVoucherPageNo = createSelector(
  [selectDomain],
  (state) => state.VoucherpageNo
);
export const SelectVoucherPageSize = createSelector(
  [selectDomain],
  (state) => state.VoucherpageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectTotalPayments = createSelector(
  [selectDomain],
  (state) => state.totalPayments
);
export const SelectEarningtopData = createSelector(
  [selectDomain],
  (state) => state.topData
);
export const SelectTotalAffiliate = createSelector(
  [selectDomain],
  (state) => state.totalAffiliate
);
export const SelectTotalEarningReport = createSelector(
  [selectDomain],
  (state) => state.totalEarningReport
);
export const SelectTotalVoucher = createSelector(
  [selectDomain],
  (state) => state.totalVoucher
);
export const Selectstartdate = createSelector(
  [selectDomain],
  (state) => state.startdate
);
export const SelectendDate = createSelector(
  [selectDomain],
  (state) => state.endDate
);
export const SelectTotalDiscount = createSelector(
  [selectDomain],
  (state) => state.totalDiscount
);
export const SelectVoucherCodesList = createSelector(
  [selectDomain],
  (state) => state.voucherCodesList
);
export const SelectVoucherCodesFormData = createSelector(
  [selectDomain],
  (state) => state.voucherCodesFormData
);
export const SelectDiscountCodesList = createSelector(
  [selectDomain],
  (state) => state.discountCodesList
);

export const SelectShowVoucher = createSelector(
  [selectDomain],
  (state) => state.showVoucher
);
export const SelectVoucherForUserData = createSelector(
  [selectDomain],
  (state) => state.VoucherForUserData
);
export const SelectSchoolListFilter = createSelector(
  [selectDomain],
  (state) => state.SchoolListFilter
);
export const SelectListingsListFilter = createSelector(
  [selectDomain],
  (state) => state.ListingsListFilter
);
export const SelectStatusFilter = createSelector(
  [selectDomain],
  (state) => state.StatusFilter
);
export const SelectPaymentStatusFilter = createSelector(
  [selectDomain],
  (state) => state.paymentStatus
);
export const SelectPaymentMethodFilter = createSelector(
  [selectDomain],
  (state) => state.paymentMethod
);
export const SelectCurrencyFilterFilter = createSelector(
  [selectDomain],
  (state) => state.currencyFilter
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumnDiscount = createSelector(
  [selectDomain],
  (state) => state.sortColumnDiscount
);
export const SelectSortDirDiscount = createSelector(
  [selectDomain],
  (state) => state.sortDirDiscount
);
export const SelectSortColumnVoucher = createSelector(
  [selectDomain],
  (state) => state.sortColumnVoucher
);
export const SelectSortDirVoucher = createSelector(
  [selectDomain],
  (state) => state.sortDirVoucher
);
export const SelectSortColumnEarning = createSelector(
  [selectDomain],
  (state) => state.sortColumnEarning
);
export const SelectSortDirEarning = createSelector(
  [selectDomain],
  (state) => state.sortDirEarning
);
