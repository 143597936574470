import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  selectRequestToReservationForm,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  BookingInquiryRequest,
  getBookingInquiryContextRequest,
  getBookingPackageDetails,
  getviewpackageavailabilityondates,
} from "../../../../utils/request";
import { RequestToReservationInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* sendEnquiryAddRequest(action: {
  payload: { retreatId: string; callback: any };
}) {
  yield delay(500);
  const form: RequestToReservationInterface = yield select(
    selectRequestToReservationForm
  );
  try {
    
    yield put(actions.setEnquiryLoading(true));
    const response: AxiosResponse = yield call(BookingInquiryRequest, form);
    yield put(actions.setEnquiryLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      window.location.reload()
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());
    yield put(actions.setEnquiryID(response.data.data.id));
    yield put(actions.setRequestToReservationList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setEnquiryLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getBookingInquiryListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getBookingInquiryContextRequest);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setBookingInquiryContextList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getBookingPackageDetailsRequest(action: {
  payload: { packageId: string; retreatId: string };
}) {
  yield delay(500);
  try {
    let data = {
      packageId: action.payload.packageId,
      retreatId: action.payload.retreatId,
    };
    const response: AxiosResponse = yield call(getBookingPackageDetails, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setBookingInquiryDetailsList(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getdoGetgetBookingPackageDetailsListRequest(action: {
  payload: { date: string; retreatId: string; callback: any };
}) {
  yield delay(500);
  let data = {
    retreatId: action.payload.retreatId,
    arrivalDate: action.payload.date,
  };
  try {
   
    const response: AxiosResponse = yield call(
      getviewpackageavailabilityondates,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setpackageDetailsListRetreat(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* RequestToReservationRepoSaga() {
  yield takeLatest(actions.sendEnquiry, sendEnquiryAddRequest);
  yield takeLatest(
    actions.doGetBookingInquiryContextList,
    getBookingInquiryListRequest
  );
  yield takeLatest(
    actions.doGetgetBookingPackageDetailsList,
    getBookingPackageDetailsRequest
  );
  yield takeLatest(
    actions.doGetviewpackageavailabilityondates,
    getdoGetgetBookingPackageDetailsListRequest
  );
}
