import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { SchoolReviewInterface, SchoolReviewState } from "../types";

export const initialState: SchoolReviewState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 5,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  schoolReviewForm: {
    id: "",
    image: "",
    reviewId: "",
    username: "",
    createdAt: "",
    title: "",
    retreatId: "",
    likes: "",
    dontlike: "",
    schoolReply: "",
    foodRating: "",
    accommodationAndFacilities: "",
    locationRating: "",
    overall_impression: "",
    qualityOfActivity: "",
    valueForMoney: "",
  },
  schoolReviewListData: [],
  totalList: 0,
  totalReview: 0,
  editModal: false,
};

export const useClinicSlice = createSlice({
  name: "schoolReview",
  initialState,
  reducers: {
    updateSchoolMediaManagerFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `mediaManagerForm.${action.payload.key}`,
        action.payload.value
      );
    },

    setSchoolReviewData: (
      state,
      action: PayloadAction<Array<SchoolReviewInterface>>
    ) => {
      state.schoolReviewListData = action.payload;
    },

    setSchoolReviewDataInForm: (
      state,
      action: PayloadAction<SchoolReviewInterface>
    ) => {
      state.schoolReviewForm = action.payload;
    },

    doGetSchoolReview: (state, action: PayloadAction<any>) => {},

    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    toggalMediaEditModal: (state) => {
      state.editModal = !state.editModal;
    },
    setTotalReview: (state, action: PayloadAction<number>) => {
      state.totalReview = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
