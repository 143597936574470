import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  SelectCreatedOnFilter,
  SelectDateFilter,
  SelectListingsListFilter,
  SelectOverallImpressionFilter,
  SelectPageNo,
  SelectPageSize,
  SelectPendingReviewPageNo,
  SelectPendingReviewPageSize,
  SelectReviewFormData,
  SelectSchoolListFilter,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir,
  SelectStatusFilter,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetAdminReviewById,
  getAllAdminReviewListRequest,
  doAddReviewRequest,
} from "../../../../utils/request";
import {
  ApprovedReviewsInterface,
  ReviewsInterface,
  ReviewStatusEnum,
} from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* getAllAdminReviewsListDataRequest(action: { payload: any }) {
  yield delay(500);

  const PageNo: number = yield select(SelectPageNo);
  const pageSize: number = yield select(SelectPageSize);
  const search: string = yield select(SelectSearch);
  const school: [] = yield select(SelectSchoolListFilter);
  const listing: [] = yield select(SelectListingsListFilter);
  const SortColumn: string = yield select(SelectSortColumn);
  const SortDir: string = yield select(SelectSortDir);
  SelectCreatedOnFilter;
  const status: number = yield select(SelectStatusFilter);
  const overallImpressionFilter: number = yield select(
    SelectOverallImpressionFilter
  );
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);

  try {
    const data = {
      statusFilter: 0,
      schoolFilter: school,
      retreatFilter: listing,
      overallImpressionFilter: overallImpressionFilter,
      createdOnFilter: createdOnFilter,
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      search: search,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllAdminReviewListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setTotalApprovedReviewList(response.data.data[0].meta.total)
    );
    yield put(actions.setApproveRetreatsList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAllAdminPendingReviewsListDataRequest(action: {
  payload: any;
}) {
  yield delay(500);
  const PageNo: number = yield select(SelectPendingReviewPageNo);
  const pageSize: number = yield select(SelectPendingReviewPageSize);
  const search: string = yield select(SelectSearch);
  const school: [] = yield select(SelectSchoolListFilter);
  const listing: [] = yield select(SelectListingsListFilter);
  const status: number = yield select(SelectStatusFilter);
  const overallImpressionFilter: number = yield select(
    SelectOverallImpressionFilter
  );
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  const SortColumn: string = yield select(SelectSortColumn);
  const SortDir: string = yield select(SelectSortDir);
  try {
    const data = {
      statusFilter: 1,
      schoolFilter: school,
      retreatFilter: listing,
      overallImpressionFilter: overallImpressionFilter,
      createdOnFilter: createdOnFilter,
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      search: search,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllAdminReviewListRequest,
      `?pageNo=${PageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setTotalPendingReviewList(response.data.data[0].meta.total)
    );
    yield put(actions.setPendingReviewsList(response.data.data[0].results));
    if (action.payload == ReviewStatusEnum.APPROVED) {
      yield put(actions.setApproveRetreatsList(response.data[0].results));
    } else {
      if (action.payload == ReviewStatusEnum.PENDING) {
        yield put(actions.setPendingReviewsList(response.data[0].results));
      } else {
        return;
      }
    }
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getReviewByIdRequest(action: {
  payload: { Id: string; callback: () => void };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAdminReviewById,
      action.payload.Id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setReviewDataInForm(response.data.data[0]));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateAdminReviewDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ReviewsInterface = yield select(SelectReviewFormData);
  const data = {
    id: form.id,
    retreatId: form.id,
    valueForMoney: form.valueForMoney,
    accommodationAndFacilities: form.accommodationAndFacilities,
    foodRating: form.foodRating,
    qualityOfActivity: form.qualityOfActivity,
    locationRating: form.locationRating,
    likes: form.likes,
    dislikes: form.dislikes,
    reply: form.reply,
    status: form.status,
    bookingId: form.bookingId,
  };
  try {
    const response: AxiosResponse = yield call(doAddReviewRequest, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setPendingReviewsList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminReviewsRepoSaga() {
  yield takeLatest(
    actions.doGetApproveReviewsList,
    getAllAdminReviewsListDataRequest
  );
  yield takeLatest(
    actions.doGetPendingReviewsList,
    getAllAdminPendingReviewsListDataRequest
  );
  yield takeLatest(actions.doGetReviewsById, getReviewByIdRequest);
  yield takeLatest(actions.updateAdminReview, updateAdminReviewDataRequest);
}
