import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.customerReview) {
      return state.customerReview;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectCustomerReviewForm = createSelector(
  [selectDomain],
  (state) => state.customerReviewFormData
);
export const selectCustomerReviewListData = createSelector(
  [selectDomain],
  (state) => state.customerReviewList
);
export const selectReviewModal = createSelector(
  [selectDomain],
  (state) => state.reviewModal
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
