import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminWelcomeBarState,
  BarPositionEnum,
  StatusEnum,
  WelcomeBarInterface,
} from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: AdminWelcomeBarState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  welcomeBarList: [],
  welcomeBarFormData: {
    id: "",
    barMessage: "",
    linkTo: "",
    backgroundColor: "",
    textColor: "",
    barHeight: 0,
    fontSize: 0,
    barPosition: BarPositionEnum.TOPFIXED,
    barStatus: StatusEnum.INACTIVE,
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
};

export const useClinicSlice = createSlice({
  name: "adminWelcomeBar",
  initialState,
  reducers: {
    updateWelcomeBarFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      log(
        "action.payload.key,action.payload.value",
        action.payload.key,
        action.payload.value
      );
      set(
        state,
        `welcomeBarFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    // clearFrom: (state) => {
    //     state.welcomeBarFormData.id = "";
    //     state.welcomeBarFormData.message = "";
    //     state.welcomeBarFormData.linkto = "";
    //     state.welcomeBarFormData.backgroundcolor = "";
    //     state.welcomeBarFormData.textcolor = "";
    //     state.welcomeBarFormData.barheight = "";
    //     state.welcomeBarFormData.fontsize = "";
    //     state.welcomeBarFormData.barposition = "";
    //     state.welcomeBarFormData.status = "";
    // },

    AddWelcomeBar: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your Welcome Bar added");
      state.welcomeBarList.push(state.welcomeBarFormData);
    },
    setDataInForm: (state, action: PayloadAction<WelcomeBarInterface>) => {
      state.welcomeBarFormData = action.payload;
    },
    doGetWellcomeBarList: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: AdminWelcomeBarSliceKey,
} = useClinicSlice;
