import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { actions as actionResponce } from "../redux/slice";
import {
  CreateListing,
  CreateOverviewRetreatStyle,
  getAllRetreat,
  getMainActivity,
  uplodeImageRequest,
  getFixedOverviewRetreatStyle,
  CreateListingOverview,
  getTempSchoolOverviewRetreatStyle,
  ListingItineraryRequest,
  GetAOverviewFormdataByIdRequest,
  GetItineraryFormdataByIdRequest,
  DeletechoolListingRequest,
  GetRetreatsModalById,
  DeleteRetreatStyleRequest,
} from "../../../../utils/request";
import {
  selectCreateListingForm,
  SelectDailyScheduleForm,
  selectItineraryForm,
  selectOverViewForm,
  SelectPageNo,
  selectretreatStylesForm,
} from "./selector";
import {
  CreateListingForm,
  ItineraryInterface,
  OverViewInterface,
  RequestTypeEnum,
  RetreatStyleModel,
  dailyScheduleModel,
} from "../types";
import { END, eventChannel } from "redux-saga";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import CustomToast from "../../../../components/UI/CustomToast";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import moment from "moment";
import { log } from "../../../../utils/logger";
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = uplodeImageRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) {
      log("END");

      emit(END);
    } else emit(percentCompleted);
  });
  log("uploadPromise", uploadPromise);
  return [uploadPromise, chan];
}
export function* getMainActivityListRequest(action: {
  payload: { serach: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getMainActivity,

      `?search=${action.payload.serach}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setMainActivityList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAllRetreatsListRequest(action: {
  payload: { Type: any; schoolId: string; callback: any };
}) {
  yield delay(500);
  const PageNo: number = yield select(SelectPageNo);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      getAllRetreat,
      `?schoolId=${action.payload.schoolId}&statusFilter=${action.payload.Type}&pageNo=${PageNo}&pageSize=10`
    );
    if (response && !response.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalretreats(response.data[0].meta.total));
    yield put(actions.setAllRetreatsList(response.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  } finally {
    yield put(actions.setLoading(false));
  }
}
export function* getRetreatListingsOverviewStyleTypeAdminRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getFixedOverviewRetreatStyle);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setRetreatListingsOverviewStyleTypeAdminList(response.data)
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatListingsOverviewStyleTypeSchoolRequest(action: {
  payload: any;
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getTempSchoolOverviewRetreatStyle,
      action.payload
    );
    if (response && !response.data) {
      return;
    }
    yield put(
      actions.setRetreatListingsOverviewStyleTypeSchoolList(
        response.data.requestStyles
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* CreateRetretsListing(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CreateListingForm = yield select(selectCreateListingForm);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(CreateListing, form);
    yield put(actions.setLoading(false));
    if (response && response.status == 200 && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setListingsID(response.data.data.retreatId));
    yield put(actions.setOnlineOrOffline(response.data.data.retreatType));
    yield call(
      action?.payload?.callback({
        id: response.data.data.retreatId,
        type: response.data.data.retreatType,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* CreateRetretsListingOverview(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  const form: OverViewInterface = yield select(selectOverViewForm);
  try {
    const response: AxiosResponse = yield call(CreateListingOverview, form);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* AddNewRetreatStyleOverviewSchool(action: {
  payload: { RetreatId: string; callback: any };
}) {
  yield delay(500);
  const form: RetreatStyleModel = yield select(selectretreatStylesForm);
  if (form.name.trim() == "") {
    CustomToast("Please enter Retreat Styles", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      CreateOverviewRetreatStyle,
      form
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.getRetreatListingsOverviewStyleTypeSchoolList(
        action.payload.RetreatId
      )
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (
      error.message !==
      "Cannot read properties of undefined (reading 'context')"
    ) {
      CatchBlockFunction(error);
    }
  }
}
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setOverviewFileData(response.data.data.name));

    yield put(actions.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());

    CatchBlockFunction(error);
  }
}
export function* FileUploadRetreatsCardRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setFileData(response.data.data.name));
    yield put(actions.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());

    CatchBlockFunction(error);
  }
}
export function* AddSchoolListingItineraryRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const form: ItineraryInterface = yield select(selectItineraryForm);
    const response: AxiosResponse = yield call(ListingItineraryRequest, form);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getOverviewSchoolByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAOverviewFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    yield put(actions.SetOverviewModalType(response.data.model));
    if (response && !response.data.data[0]) {
      return;
    }
    yield put(actions.SetOverviewDataById(response.data.data[0]));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "overview_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getItinerarySchoolByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setItinararyloading(true));
    const response: AxiosResponse = yield call(
      GetItineraryFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setItinararyloading(false));
    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.SetItineraryDataById(response.data.data[0]));
    yield put(actions.SetItineraryModalType(response.data.model));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "itinarary_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setItinararyloading(false));
    CatchBlockFunction(error);
  }
}
export function* DeleteSchoolRetreatsListing(action: { payload: any }) {
  yield delay(500);
  if (action.payload.length === 0) {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      DeletechoolListingRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");

      return;
    }

    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

//// retreat by id
export function* getRetretsModalDataByidRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      GetRetreatsModalById,
      action.payload
    );

    if (response && !response.data.retreat) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    // yield put(actions.setRetreatsModalForEdit(response.data.retreat));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeleteRetreatStyleRequestSaga(action: {
  payload: { id: string; callback: any; RetreatId: string };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteRetreatStyleRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(
      actions.getRetreatListingsOverviewStyleTypeSchoolList(
        action.payload.RetreatId
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminRepoSagaSchoolListings() {
  yield takeLatest(actions.doGetALLActivityList, getMainActivityListRequest);
  yield takeLatest(actions.AddListings, CreateRetretsListing);
  yield takeLatest(
    actions.AddSchoolListingItinerary,
    AddSchoolListingItineraryRequest
  );
  yield takeLatest(actions.AddListingsOverview, CreateRetretsListingOverview);
  yield takeLatest(actions.doGetALLRetreatsList, getAllRetreatsListRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.addImageRetreats, FileUploadRetreatsCardRequest);
  yield takeLatest(
    actions.getRetreatListingsOverviewStyleTypeAdminList,
    getRetreatListingsOverviewStyleTypeAdminRequest
  );
  yield takeLatest(
    actions.getRetreatListingsOverviewStyleTypeSchoolList,
    getRetreatListingsOverviewStyleTypeSchoolRequest
  );
  yield takeLatest(
    actions.AddNewRetreatStyleOverview,
    AddNewRetreatStyleOverviewSchool
  );
  yield takeLatest(
    actions.doGetOverviewSchoolDataByID,
    getOverviewSchoolByIdRequest
  );
  yield takeLatest(
    actions.doGetItinerarySchoolDataByID,
    getItinerarySchoolByIdRequest
  );

  yield takeLatest(actions.DeleteSchoolRetreats, DeleteSchoolRetreatsListing);
  yield takeLatest(
    actions.getRetretsModalDataByid,
    getRetretsModalDataByidRequest
  );
  yield takeLatest(actions.DeleteRetreatStyle, DeleteRetreatStyleRequestSaga);
}
