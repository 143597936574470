// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.NotificationState) {
      return state.NotificationState;
    }
  } else {
    return initialState;
  }
};
export const SelecNotificationList = createSelector(
  [selectDomain],
  (state) => state?.adminNotification
);
export const SelecNotificationPageNo = createSelector(
  [selectDomain],
  (state) => state?.pageNo
);
export const SelectotalNotifiction = createSelector(
  [selectDomain],
  (state) => state?.totalNotifiction
);
export const SelectotalNewNotifiction = createSelector(
  [selectDomain],
  (state) => state?.totalNewNotifiction
);
