import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  MassageFilter,
  MessageSort,
  UserInquiryMessageState,
  inquiryCardDetailInterface,
  AllMessageCountListInterface,
} from "../types";

export const initialState: UserInquiryMessageState = {
  loading: false,
  hasMore: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: 0,
  businessProfilepic: "",
  messagePageNo: 1,
  businessName: "",
  userFirstName: "",
  userLastName: "",
  userProfilePic: "",
  messagePageSize: 20,
  InquiryMassageCard: [],
  MessageSort: MessageSort.NO_GROUPING,
  MeassageFilter: MassageFilter.ALL_MESSAGES,
  MessageList: [],
  totaMessageCount: 0,
  totalInquiryCard: 0,
  inquiryCardDetail: {
    arrivalDate: "",
    departureDate: "",
    id: "",
    location: "",
    price: 0,
    retreatId: "",
    seoActivityTitle: "",
    seoTitle: "",
    retreatTitle: "",
    spokenLanguage: [],
    status: 0,
    currencyCode: "",
  },
  allMessageCountList: [],
  loader: false,
};

export const useClinicSlice = createSlice({
  name: "userInquiryMessageState",
  initialState,
  reducers: {
    clearFrom: (state) => {},
    doGetMessageCardList: (state) => {},
    doGetMessageList: (
      state,
      action: PayloadAction<{ id: string; schoolId: string; pageNo?: number }>
    ) => {},
    doGetInquiryDetail: (state, action: PayloadAction<string>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setTotalMessageRow: (state, action: PayloadAction<number>) => {
      state.totaMessageCount = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.userFirstName = action.payload;
    },
    setUserLastName: (state, action: PayloadAction<string>) => {
      state.userLastName = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<string>) => {
      state.userProfilePic = action.payload;
    },
    setSchoolName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setSchoolProfile: (state, action: PayloadAction<string>) => {
      state.businessProfilepic = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setinquiryCardDetail: (
      state,
      action: PayloadAction<inquiryCardDetailInterface>
    ) => {
      state.inquiryCardDetail = action.payload;
    },
    settotalInquiryCard: (state, action: PayloadAction<number>) => {
      state.totalInquiryCard = action.payload;
    },
    setInquiryMassageCard: (state, action: PayloadAction<any>) => {
      state.InquiryMassageCard = action.payload;
    },
    setMessageList: (state, action: PayloadAction<any>) => {
      state.MessageList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    messageSetPageNo: (state, action: PayloadAction<number>) => {
      state.messagePageNo = action.payload;
    },
    messageSetPageSize: (state, action: PayloadAction<number>) => {
      state.messagePageSize = action.payload;
    },
    setMassageFilter: (state, action: PayloadAction<any>) => {
      state.MeassageFilter = action.payload;
    },
    setMassageSort: (state, action: PayloadAction<any>) => {
      state.MessageSort = action.payload;
    },
    setloader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setAllMessageCount: (
      state,
      action: PayloadAction<Array<AllMessageCountListInterface>>
    ) => {
      state.allMessageCountList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
