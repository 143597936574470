// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.arrivalInformationState) {
      return state.arrivalInformationState;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelectArrivalModalType = createSelector(
  [selectDomain],
  (state) => state.ArrivalModalType
);
export const SelectArrivalDraft = createSelector(
  [selectDomain],
  (state) => state.ArrivalInformationForm.isDraft
);
export const SelectInstructorModalType = createSelector(
  [selectDomain],
  (state) => state.InstructorModalType
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectListingsID = createSelector(
  [selectDomain],
  (state) => state.ListingsId
);
export const SelectOnlineOrOffline = createSelector(
  [selectDomain],
  (state) => state.offlineOrOnline
);
export const SelectArrivalInformationForm = createSelector(
  [selectDomain],
  (state) => state.ArrivalInformationForm
);
export const SelectArrivalInformationList = createSelector(
  [selectDomain],
  (state) => state.ArrivalInformationList
);
export const SelectInstructorListDataForm = createSelector(
  [selectDomain],
  (state) => state.InstructorListForm
);
export const SelectInstructorListData = createSelector(
  [selectDomain],
  (state) => state.InstructorListData
);

export const SelectSchoolSpokenLanguageFixedList = createSelector(
  [selectDomain],
  (state) => state.spokenLanguagesFixedList
);
export const SelectSchoolSpokenLanguagesTempList = createSelector(
  [selectDomain],
  (state) => state.spokenLanguagesTempList
);
export const selectLanguagesForm = createSelector(
  [selectDomain],
  (state) => state.ArrivalInformationForm?.spokenLanguagesForm
);
export const selectAllAirportListData = createSelector(
  [selectDomain],
  (state) => state.allAirportList
);
export const selectAllHostListData = createSelector(
  [selectDomain],
  (state) => state.allHostList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectSearchOpen = createSelector(
  [selectDomain],
  (state) => state.searchOpen
);
