// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    return state.adminReviews;
  } else {
    return initialState;
  }
};
// export const SelecApproveReviewsFormData = createSelector(
//   [selectDomain],
//   (state) => state.approvedReviewFormData
// );
export const SelecPendingReviewsFormData = createSelector(
  [selectDomain],
  (state) => state.pendingReviewsFormData
);
export const SelecApproveReviewsList = createSelector(
  [selectDomain],
  (state) => state.approvedReviewsList
);
export const SelecPendingReviewsList = createSelector(
  [selectDomain],
  (state) => state.pendingReviewsList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectPendingReviewPageNo = createSelector(
  [selectDomain],
  (state) => state.pendingReviewPageNo
);
export const SelectPendingReviewPageSize = createSelector(
  [selectDomain],
  (state) => state.pendingReviewPageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSchoolListFilter = createSelector(
  [selectDomain],
  (state) => state.schoolFilter
);
export const SelectListingsListFilter = createSelector(
  [selectDomain],
  (state) => state.ListingsListFilter
);
export const SelectStatusFilter = createSelector(
  [selectDomain],
  (state) => state.StatusFilter
);
export const SelectOpenEdit = createSelector(
  [selectDomain],
  (state) => state.openEdit
);
export const SelectOpenDelete = createSelector(
  [selectDomain],
  (state) => state.openDelete
);
export const SelectPendingOpenEdit = createSelector(
  [selectDomain],
  (state) => state.openPendingEdit
);
export const SelectPendingOpenDelete = createSelector(
  [selectDomain],
  (state) => state.openPendingDelete
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectOverallImpressionFilter = createSelector(
  [selectDomain],
  (state) => state.overallImpressionFilter
);
export const SelectTotalPendingReview = createSelector(
  [selectDomain],
  (state) => state.totalPendingReview
);
export const SelectTotalApprovedReview = createSelector(
  [selectDomain],
  (state) => state.totalApprovedReview
);
export const SelectReviewFormData = createSelector(
  [selectDomain],
  (state) => state.reviewFormData
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
