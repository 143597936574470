import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BookingAvailabilityEnum,
  FilterStatusEnum,
  InquiryStatusEnum,
  MessageTypeEnum,
  PaymentNotEnabledYetInterface,
  SchoolMassageState,
} from "../types";
import set from "lodash.set";
import { log } from "../../../../utils/logger";

export const initialState: SchoolMassageState = {
  PaymentNotEnabledYetList: [],
  hasMore: false,
  MassageForm: {
    id: "",
    firstName: "",
    lastName: "",
    profilePic: "",
    currencyCode: "",
    status: InquiryStatusEnum.OPEN,
    email: "",
    schoolId: "",
    phoneNo: "",
    tripDetails: {
      arrivalDate: "",
      departureDate: "",
      acceptOrDeclineAvailablity: BookingAvailabilityEnum.ACCEPT,
    },
    packageDetails: {
      title: "",
      noOfPeople: 0,
      programDuration: 0,
      price: 0,
      roomAccommodationName: "",
      roomAccommodationType: "",
      roomAccommodation: "",
      retreatType: 2,
      packageId: "",
      actualPrice: 0,
    },
    bookingPolicy: {
      requiredDeposit: 0,
      remainingAmount: 0,
      cancellationPolicy: 0,
      newPolicy: "",
      paymentSchedule: 0,
      paymentDaysBeforeArrival: 0,
    },
    retreatId: "",
    userId: "",
    _id: "",
  },
  FollowUpList: [],
  NewMassageList: [],
  AllMassage: [],
  loading: false,

  packageDetail: {},
  PackageList: [],
  AcceptOrDeclineBookingForm: {
    acceptOrDeclineAvailablity: BookingAvailabilityEnum.ACCEPT,
    deactiveInquiryReason: "",
    holdingTime: 72,
    inquiryId: "",
  },
  conversation: {
    inquiryId: "",
    message: "",
    receiverId: "",
    senderId: "",
    messageType: MessageTypeEnum.TEXT,
    attachmentFile: "",
  },
  filter: {
    pageNumber: 1,
    pageSize: 10,
    email: "",
    name: "",
    status: [FilterStatusEnum.ALL],
    title: "",
  },
  PaymentNotEnableLength: 0,
  NewMessageLength: 0,
  FollowUpLength: 0,
  PaymentNotEnablePageNo: 1,
  NewMessagePageNo: 1,
  FollowUpPageNo: 1,
  AllMassageLength: 0,
  sortColumn: "",
  sortDir: "",
  messageLoading: false,
};

export const useClinicSlice = createSlice({
  name: "schoolMassage",
  initialState,
  reducers: {
    dosendMessage: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    HandleSendMessageWithAttachment: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {},
    setPaymentNotEnableYetTotalRow: (state, action: PayloadAction<number>) => {
      state.PaymentNotEnableLength = action.payload;
    },
    setNewMessageTotalRow: (state, action: PayloadAction<any>) => {
      state.NewMessageLength = action.payload;
    },
    setFollowUpTotalRow: (state, action: PayloadAction<any>) => {
      state.FollowUpLength = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    updatePackageFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `MassageForm.packageDetails.${action.payload.key}`,
        action.payload.value
      );
    },
    updateFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.filter.status.push(action.payload.value);
    },
    updateFilterForm: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `filter.${action.payload.key}`, action.payload.value);
    },
    updatePageNoValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `filter.${action.payload.key}`, action.payload.value);
    },
    DeletefilterStatusList: (state, action: PayloadAction<any>) => {
      state.filter.status.splice(
        state.filter.status.findIndex((obj) => obj === action.payload),
        1
      );
    },
    updateMessageFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `conversation.${action.payload.key}`, action.payload.value);
    },
    updateAcceptOrDeclineBookingForm: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `AcceptOrDeclineBookingForm.${action.payload.key}`,
        action.payload.value
      );
    },

    clearAcceptOrDeclineBookingForm: (state) => {
      state.AcceptOrDeclineBookingForm.acceptOrDeclineAvailablity =
        BookingAvailabilityEnum.ACCEPT;
      state.AcceptOrDeclineBookingForm.deactiveInquiryReason = "";
      state.AcceptOrDeclineBookingForm.holdingTime = 72;
      state.AcceptOrDeclineBookingForm.inquiryId = "";
    },
    updateBookingFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `MassageForm.bookingPolicy.${action.payload.key}`,
        action.payload.value
      );
    },
    doupdatePackagedetail: (
      state,
      action: PayloadAction<{ packageId: string }>
    ) => {},

    setPackageDataForEdit: (state) => {
      state.MassageForm = state.packageDetail;
    },
    doGetAllMessageList: (
      state,
      action: PayloadAction<{ schoolId: string }>
    ) => {},
    doClearFilterForm: (state) => {
      state.filter.email = "";
      state.filter.name = "";
      state.filter.title = "";
      state.filter.status = [FilterStatusEnum.ALL];
    },
    doClearMassageForm: (state) => {
      state.conversation.message = "";
    },
    setAttechmentData: (state, action: PayloadAction<any>) => {
      state.conversation.attachmentFile = action.payload;
    },
    addDocument: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    setPackageList: (state, action: PayloadAction<any>) => {
      state.PackageList = action.payload;
    },
    setPaymentNotEnableYetList: (state, action: PayloadAction<any>) => {
      state.PaymentNotEnabledYetList = action.payload;
    },
    setNewMessageList: (state, action: PayloadAction<any>) => {
      state.NewMassageList = action.payload;
    },
    setFollowUpList: (state, action: PayloadAction<any>) => {
      state.FollowUpList = action.payload;
    },
    dogetPackageList: (
      state,
      action: PayloadAction<{
        retreatId: string;
        inquiryId: string;
        callback: () => void;
      }>
    ) => {},
    dogetPackageBookingFormList: (
      state,
      action: PayloadAction<{
        retreatId: string;
        inquiryId: string;
        callback: () => void;
      }>
    ) => {},
    doUpdateTripDetails: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {},
    doUpdatePackageDetails: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {},
    doUpdateBookingDetails: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {},
    doGetPaymentNotEnabledYetList: (
      state,
      action: PayloadAction<{ schoolId: string }>
    ) => {},
    doGetNewMessageList: (
      state,
      action: PayloadAction<{
        schoolId: string;
      }>
    ) => {},
    doGetFollowUPList: (
      state,
      action: PayloadAction<{ schoolId: string }>
    ) => {},
    dodeclineAvaibility: (
      state,
      action: PayloadAction<{ data: any; callback: () => void }>
    ) => {},
    dogetPackageDetail: (state, action: PayloadAction<string>) => {},
    setPaymentNotEnabledYetList: (
      state,
      action: PayloadAction<Array<PaymentNotEnabledYetInterface>>
    ) => {
      state.PaymentNotEnabledYetList = action.payload;
    },
    setAllMessageList: (state, action: PayloadAction<Array<any>>) => {
      state.AllMassage = action.payload;
    },
    setpackageDetail: (state, action: PayloadAction<[]>) => {
      state.packageDetail = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },

    setPaymentNotEnablePageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.PaymentNotEnablePageNo = action.payload;
    },
    setFollowUpPageNo: (state, action: PayloadAction<number>) => {
      state.FollowUpPageNo = action.payload;
    },
    setNewMessagePageNo: (state, action: PayloadAction<number>) => {
      state.NewMessagePageNo = action.payload;
    },
    setAllMassageLength: (state, action: PayloadAction<number>) => {
      state.AllMassageLength = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setMessageLoading: (state, action: PayloadAction<boolean>) => {
      state.messageLoading = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
