// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.SchoolListingState) {
      return state.SchoolListingState;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectStatusFilter = createSelector(
  [selectDomain],
  (state) => state.statusFilter
);
export const SelectApiResponce = createSelector(
  [selectDomain],
  (state) => state.apiResponce
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectMainActivity = createSelector(
  [selectDomain],
  (state) => state.AllMainActivityList
);
export const selectCreateListingForm = createSelector(
  [selectDomain],
  (state) => state.CreateListingForm
);
export const SelectListingsList = createSelector(
  [selectDomain],
  (state) => state.ListingList
);
export const SelectListingsID = createSelector(
  [selectDomain],
  (state) => state.ListingsId
);
export const SelectOnlineOrOffline = createSelector(
  [selectDomain],
  (state) => state.offlineOrOnline
);
export const SelectTotalRetreats = createSelector(
  [selectDomain],
  (state) => state.totalRetreats
);
export const selectOverViewForm = createSelector(
  [selectDomain],
  (state) => state.OverviewForm
);
export const selectretreatStylesForm = createSelector(
  [selectDomain],
  (state) => state.OverviewForm?.retreatStylesForm
);
export const selectretreatStylesList = createSelector(
  [selectDomain],
  (state) => state.OverviewForm?.retreatStyles
);
export const SelectOverViewList = createSelector(
  [selectDomain],
  (state) => state.OverViewList
);

export const selectLocationForm = createSelector(
  [selectDomain],
  (state) => state.LocationForm
);
export const SelectLocationList = createSelector(
  [selectDomain],
  (state) => state.LocationList
);
export const SelectOverviewRetreatStyleFixedAdminnList = createSelector(
  [selectDomain],
  (state) => state.OverviewRetreatStyleFixedAdmin
);
export const SelectOverviewRetreatStyletempSchoolList = createSelector(
  [selectDomain],
  (state) => state.OverviewRetreatStyleTempSchool
);
export const selectItineraryForm = createSelector(
  [selectDomain],
  (state) => state.ItineraryFormData
);
export const SelectItineraryList = createSelector(
  [selectDomain],
  (state) => state.ItineraryList
);
export const SelectprogressBarModal = createSelector(
  [selectDomain],
  (state) => state.progressBarModal
);
export const SelectoverviewModalType = createSelector(
  [selectDomain],
  (state) => state.overviewModalType
);
export const SelectItineraryModalType = createSelector(
  [selectDomain],
  (state) => state.itineraryModalType
);
export const SelectOverViewDraft = createSelector(
  [selectDomain],
  (state) => state.OverviewForm.isDraft
);
export const SelectItinararyDraft = createSelector(
  [selectDomain],
  (state) => state.ItineraryFormData.isDraft
);
export const SelectDailyScheduleForm = createSelector(
  [selectDomain],
  (state) => state.ItineraryFormData.dailyScheduleForm
);
export const SelectVisitedLinks = createSelector(
  [selectDomain],
  (state) => state.VisitedLinks
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectItinararyloading = createSelector(
  [selectDomain],
  (state) => state.Itinararyloading
);
export const SelectIsAdmin = createSelector(
  [selectDomain],
  (state) => state.isAdmin
);