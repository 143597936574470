import { call, delay, put, select, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { SchoolPaymentsInterface } from "../types";
import { selectSchoolPaymentsForm } from "./selector";
import {
  AddSchoolPayments,
  DeletePaymentInformationRequest,
  getCityRequest,
  getCountryRequest,
  getCurrencyRequest,
  GetSchoolPaymentRequest,
  getStateRequest,
} from "../../../../utils/request";
import { actions } from "./slice";
import CustomToast from "../../../../components/UI/CustomToast";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* SchoolPaymentsAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: SchoolPaymentsInterface = yield select(selectSchoolPaymentsForm);
  if (form.paymentType == 1) {
    if (form.country === "") {
      CustomToast("Please Select Country", "ERROR");
      return;
    }
    if (form.currency === "") {
      CustomToast("Please Enter Account Currency", "ERROR");
      return;
    }
    if (form.accountHolderName === "") {
      CustomToast("Please Enter Account Holder Name", "ERROR");
      return;
    }
    if (form.bankName === "") {
      CustomToast("Please Enter Bank Name", "ERROR");
      return;
    }
    if (form.beneficiaryName === "") {
      CustomToast("Please Enter BeneficiaryName Name", "ERROR");
      return;
    }
    if (form.bankAccountNo === "") {
      CustomToast("Please Enter Account Number", "ERROR");
      return;
    }
    if (form.bankId === "") {
      CustomToast("Please Enter Bank Id", "ERROR");
      return;
    }
  } else {
    let emailregex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (form.paypalEmail.trim().length > 0) {
      if (!emailregex.test(form.paypalEmail)) {
        CustomToast("Enter Valid Email ID", "ERROR");
        return;
      }
    }
  }

  try {
    const response: AxiosResponse = yield call(AddSchoolPayments, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getPaymentListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      GetSchoolPaymentRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDataInForm(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeletePaymentInfoRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(DeletePaymentInformationRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCountryListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getCountryRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setCountryList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getStateListRequest(action: { payload: string }) {
  yield delay(500);
  try {
   
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getStateRequest, action.payload);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setStateList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCityListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getCityRequest, action.payload);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setCityList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCurrencyListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getCurrencyRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setALLActiveCurrencyList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* schoolPaymentsRepoSaga() {
  yield takeLatest(actions.AddSchoolPayments, SchoolPaymentsAddRequest);
  yield takeLatest(actions.doGetPaymentList, getPaymentListRequest);
  yield takeLatest(actions.doGetCountryList, getCountryListRequest);
  yield takeLatest(actions.doGetCurrencyList, getCurrencyListRequest);
  yield takeLatest(actions.DeletePaymentInfo, DeletePaymentInfoRequest);
  yield takeLatest(actions.doGetStateList, getStateListRequest);
  yield takeLatest(actions.dogetCityList, getCityListRequest);
}
