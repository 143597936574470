import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsModal } from "../../../School/Listings/redux/slice";
import { actions as userAction } from "../../../Login/redux/slice";
import { AxiosResponse } from "axios";
import {
  selectAdminSchoolForm,
  selectCertificateList,
  SelectCreatedOnFilter,
  SelectPageNo,
  SelectPageSize,
  SelectProfileTypeFilter,
  selectRegistrationList,
  SelectSearch,
  SelectStatusFilter,
  SelectDateFilter,
  selectRegistrationForm,
  selectCertificateForm,
  SelectSortColumn,
  SelectSortDir,
} from "./selector";
import {
  CertiifcateModal,
  EmailVerificationTypeEnum,
  RegistrationModal,
  SchoolInterface,
} from "../types";
import {
  AddSchoolCertificate,
  DeletePartnerProfileRequest,
  getPartnerRequest,
  getPartnerRequestByAdmin,
  UpdateSchoolDetailRequest,
  uplodeImageRequest,
  GetSchoolListForHostRequest,
  getCertificateRequest,
  DeleteCertificateRequest,
  DeleteRegistrationRequest,
  AddSchoolRegistration,
  getCRegistrationRequest,
  DoActiveInactiveRequest,
  DoActiveInactiveRegistrationRequest,
  GetListingInstructorByIdRequest,
  SendInstructorMobOtp,
  VerifyInstructorMobOtp,
  AddSchoolBasicDetail,
  AddSchoolBusinessRegistrationAddressDetail,
  AddSchoolContactPersonDetail,
  AddSchoolBussinessOwnerDetail,
  SendEmailVerification,
  EmailVerifiedIndexDetail,
  AddSchoolNotificationDetail,
  UpdateSchoolStatus,
} from "../../../../utils/request";
import { END, eventChannel } from "redux-saga";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import CustomToast from "../../../../components/UI/CustomToast";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import queryString from "query-string";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { selectSendVerificationOtpRequestForm } from "../../../User/Bookings/redux/selector";
import { SendVerificationOtpRequest } from "../../../User/Bookings/types";
import { log } from "../../../../utils/logger";
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = uplodeImageRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted == 100) emit(END);
    else emit(percentCompleted);
  });

  return [uploadPromise, chan];
}
export function* EditRequest(action: { payload: any }) {
  yield put(actions.setSchoolForEdit(action.payload));
  yield put(actions.setSchoolNotifcationEmail(action.payload));
}
export function* updateData(action: { payload: any }) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);
  log(form, "formupdatevalue");

  try {
    const response: AxiosResponse = yield call(UpdateSchoolDetailRequest, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.doGetSchoolPartnerList());
    yield put(actions.clearFrom());
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getPartnerListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const profileTypeFilter: string = yield select(SelectProfileTypeFilter);
    const statusFilter: string = yield select(SelectStatusFilter);
    const createdOnFilter: string = yield select(SelectCreatedOnFilter);
    const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const data: any = queryString.stringify({
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
    });
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPartnerRequest,
      `?profileTypeFilter=${profileTypeFilter}&statusFilter=${statusFilter}&createdOnFilter=${createdOnFilter}&pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? "&search=" + searchText : ""
      }${data !== "endDate=&startDate=" ? "&" + data : ""}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}
      `
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSchoolList(response.data.data.results));
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0
      )
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* SchoolProfileForAdmin(action: {
  payload: { id: string; isUpdateProfile: boolean };
}) {
  yield delay(500);
  yield put(userAction.setloadingProfile(true));
  try {
    if (action.payload.id.length == 0) {
      return;
    }
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPartnerRequestByAdmin,
      action.payload.id
    );
    yield put(userAction.setloadingProfile(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setSchoolId(action.payload.id));
    yield put(actions.setSchoolForEdit(response.data));
    yield put(
      actions.setSchoolOwnerName(
        response.data.ownerFirstName
          ? response.data.ownerFirstName.length > 0 &&
              response.data.ownerFirstName
          : response.data.firstName
      )
    );

    yield put(actions.setSchoolNotifcationEmail(response.data));
    yield put(
      actions.setSchoolOwnerLastName(
        response.data.ownerLastName
          ? response.data.ownerLastName.length > 0 &&
              response.data.ownerLastName
          : response.data.lastName
      )
    );

    if (action.payload.isUpdateProfile) {
      yield put(
        userAction.setProfileData({
          name: response.data.firstName,
          lastname: response.data.lastName,
          profilePic: response.data.profilePic,
          email: response.data.email,
          role: 2,
          businessName: response.data.businessName,
          id: response.data.id,
          googleId: response?.data?.googleId,
          isProfileFill:
            response.data.isProfileFill == undefined
              ? true
              : response.data.isProfileFill
              ? response.data.isProfileFill
              : true,
          isAffiliate: response?.data?.isAffiliate,
          affiliateCode: response?.data?.affiliateCode,
          currencyCode: response?.data?.currency?.code,
        })
      );
    }
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* SchoolProfileForSchool(action: {
  payload: { id: string; isUpdateProfile: boolean };
}) {
  yield delay(500);

  try {
    if (action.payload.id.length == 0) {
      return;
    }
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPartnerRequestByAdmin,
      action.payload.id
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSchoolId(action.payload.id));
    yield put(actions.setSchoolForEdit(response.data));
    yield put(
      actions.setSchoolOwnerName(
        response.data.ownerFirstName
          ? response.data.ownerFirstName.length > 0 &&
              response.data.ownerFirstName
          : response.data.firstName
      )
    );
    yield put(actions.setSchoolNotifcationEmail(response.data));
    yield put(
      actions.setSchoolOwnerLastName(
        response.data.ownerLastName
          ? response.data.ownerLastName.length > 0 &&
              response.data.ownerLastName
          : response.data.lastName
      )
    );
    if (action.payload.isUpdateProfile) {
      yield put(
        userAction.setProfileData({
          name: response.data.firstName,
          lastname: response.data.lastName,
          profilePic: response.data.profilePic,
          email: response.data.email,
          role: response.data.role,
          businessName: response.data.businessName,
          id: response.data.id,
          googleId: response?.data?.googleId,
          isAffiliate: response?.data?.isAffiliate,
          isProfileFill: response.data.isProfileFill,
          affiliateCode: response?.data?.affiliateCode,
          currencyCode: response?.data?.currency?.code,
        })
      );
    }
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* profileFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.updateSchoolFormValue({
        key: "profilePic",
        value: response.data.data.name,
      })
    );
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* featuredPhotoFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.updateSchoolFormValue({
        key: "featuredPhoto",
        value: response.data.data.name,
      })
    );
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}

export function* licenceFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setLicensesFileData(response.data.data.name));
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* registrationFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.setFileData(response.data.data.name));
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}

export function* deletePartnerProfile(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeletePartnerProfileRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.doGetSchoolPartnerList());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddCertificate(action: { payload: { callback: any } }) {
  yield delay(500);
  const schoolForm: SchoolInterface = yield select(selectAdminSchoolForm);
  const form: CertiifcateModal[] = yield select(selectCertificateList);

  if (form.length == 0) {
    CustomToast(" Please add licenses", "ERROR");
    return;
  }
  const data = {
    ...form[0],
    schoolId: schoolForm.id,
  };
  try {
    const response: AxiosResponse = yield call(AddSchoolCertificate, data);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCertificateIDRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getCertificateRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setSchoolCertificateList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getPartnerActiveListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetSchoolListForHostRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? "&search=" + searchText : ""
      }`
    );

    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0
      )
    );

    yield put(actions.setSchoolList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* deleteCertificateProfile(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      DeleteCertificateRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteRegistrationProfile(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteRegistrationRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddRegistration(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: RegistrationModal[] = yield select(selectRegistrationList);
  if (!form[0]) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddSchoolRegistration, form);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRegistrationIDRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getCRegistrationRequest,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSchoolRegistrationList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doActiveRegistrationRequest(action: {
  payload: { id: string; checked: boolean; callback: any };
}) {
  yield delay(500);

  const data = {
    id: action.payload.id,
    registrationVerify: action.payload.checked,
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(DoActiveInactiveRequest, data);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doActiveCertificateRequest(action: {
  payload: { id: string; checked: boolean; callback: any };
}) {
  yield delay(500);
  const data = {
    id: action.payload.id,
    certificateVerify: action.payload.checked,
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DoActiveInactiveRegistrationRequest,
      data
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* OTPMobileVerificationRequest(action: {
  payload: { OTP: string; inquiryId: string; callback: any };
}) {
  yield delay(500);
  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);
    let data = {
      code: action.payload.OTP,
      inquiryId: action.payload.inquiryId,
      token: form.Verificationtoken,
    };
    const response: AxiosResponse = yield call(VerifyInstructorMobOtp, data);

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(
      actions.updateSchoolFormValue({
        key: "ownerPhonenoVerify",
        value: response.data.status,
      })
    );
    yield put(actions.clearOtPField());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (error.response) {
      CatchBlockFunction(error);
    }
  }
}
export function* AddMobileNumberForverification(action: {
  payload: { OTPTYPE: number; callback: () => void };
}) {
  yield delay(500);
  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);

    let firstName = form.ownerFirstName;
    let lastName = form.ownerLastName;
    let data = {
      email: form.ownerEmail,
      name: firstName + " " + lastName,
      phoneNo: form.ownerPhoneno,
      otpType: action.payload.OTPTYPE,
    };
    const response: AxiosResponse = yield call(SendInstructorMobOtp, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationToken(response.data.token));
    yield put(actions.setShowOTPField(true));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddEmailForverification(action: {
  payload: { email: string; type: number; callback: any };
}) {
  yield delay(500);
  try {
    let data = {
      email: action.payload.email,
      type: action.payload.type,
    };
    const response: AxiosResponse = yield call(SendEmailVerification, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* contactPersonOtpVerification(action: {
  payload: { OTP: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);
    let data = {
      code: action.payload.OTP,
      token: form.Verificationtoken,
    };
    const response: AxiosResponse = yield call(VerifyInstructorMobOtp, data);

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(
      actions.updateSchoolFormValue({
        key: "contactPersonPhonenoVerify",
        value: response.data.status,
      })
    );
    yield put(actions.clearOtPField());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddContactPersonOtpVerification(action: {
  payload: { OTPTYPE: number; callback: any };
}) {
  yield delay(500);

  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);
    log(form, "form");

    let firstName = form.contactPersonFirstName
      ? form.contactPersonFirstName.length > 0
        ? form.contactPersonFirstName
        : form.firstName
      : form.firstName;
    let lastName = form.contactPersonLastName
      ? form.contactPersonLastName.length > 0
        ? form.contactPersonLastName
        : form.lastName
      : form.lastName;
    let data = {
      email: form.contactPersonEmail || "",
      name: firstName + " " + lastName,
      phoneNo: form.contactPersonPhoneno || 0,
      otpType: action.payload.OTPTYPE,
    };
    const response: AxiosResponse = yield call(SendInstructorMobOtp, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationToken(response.data.token));

    yield put(actions.setShowOTPField(true));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* AddBasicDetailRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);
  const RegistrationForm: RegistrationModal[] = yield select(
    selectRegistrationList
  );

  const data = {
    schoolId: form.id,
    businessName: form.businessName,
    profilePic: form.profilePic,
    featuredPhoto: form.featuredPhoto,
    profileType: form.profileType,
    description: form.description,
    webUrl: form.webUrl,
    status: form.status,
    ...RegistrationForm[0],
  };
  try {
    const response: AxiosResponse = yield call(AddSchoolBasicDetail, data);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddBusinessRegistrationAddressRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);
  if (form.country == undefined || form.country?.length == 0) {
    CustomToast("Please Select Country", "ERROR");
    return;
  }
  if (form.houseNumber == undefined || form.houseNumber?.length == 0) {
    CustomToast("Please Enter House Number", "ERROR");
    return;
  }

  if (form.postalCode == undefined || form.postalCode?.length == 0) {
    CustomToast("Please Enter ZIP Code", "ERROR");
    return;
  }
  if (!(form.postalCode.length > 5 && form.postalCode.length <= 7)) {
    CustomToast("Enter Valid ZIP Code", "ERROR");
    return;
  }

  const data = {
    schoolId: form.id,
    country: form.country,
    houseNumber: form.houseNumber,
    city: form.city,
    postalCode: form.postalCode,
    state: form.state,
  };
  try {
    const response: AxiosResponse = yield call(
      AddSchoolBusinessRegistrationAddressDetail,
      data
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddContactPersonRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);
  if (
    form.contactPersonFirstName == undefined ||
    form.contactPersonFirstName?.length == 0
  ) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (
    form.contactPersonLastName == undefined ||
    form.contactPersonLastName?.length == 0
  ) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }

  if (
    form.contactPersonEmail == undefined ||
    form.contactPersonEmail?.length == 0
  ) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      form.contactPersonEmail
    )
  ) {
    CustomToast("Please Enter Valid Email", "ERROR");
    return;
  }
  const data = {
    schoolId: form.id,
    contactPersonFirstName: form.contactPersonFirstName,
    contactPersonLastName: form.contactPersonLastName,
    contactPersonPhoneno: form.contactPersonPhoneno,
    contactPersonEmail: form.contactPersonEmail,
    contactPersonPhonenoVerify: form.contactPersonPhonenoVerify || false,
    numberToken: form.Verificationtoken || "",
  };
  try {
    const response: AxiosResponse = yield call(
      AddSchoolContactPersonDetail,
      data
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddBusinessOwnerRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);

  if (form.ownerFirstName == undefined || form.ownerFirstName?.length == 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }

  if (form.ownerDob == undefined || form.ownerDob?.length == 0) {
    CustomToast("Please Enter DOB", "ERROR");
    return;
  }
  if (form.ownerLastName == undefined || form.ownerLastName?.length == 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }

  if (form.ownerEmail == undefined || form.ownerEmail?.length == 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.ownerEmail)) {
    CustomToast("Please Enter Valid Email", "ERROR");
    return;
  }
  const data = {
    schoolId: form.id,
    ownerFirstName: form.ownerFirstName,
    ownerLastName: form.ownerLastName,
    ownerMiddleName: form.ownerMiddleName,
    ownerDob: form.ownerDob,
    ownerEmail: form.ownerEmail,
    ownerPhoneno: form.ownerPhoneno,
    ownerPhonenoVerify: form.ownerPhonenoVerify || false,
    numberToken: form.Verificationtoken || "",
    sameAsContact: form.sameAsContact || false,
  };
  try {
    const response: AxiosResponse = yield call(
      AddSchoolBussinessOwnerDetail,
      data
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNotificationOtpVerification() {
  yield delay(500);
  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);
    let data = {
      email: form.ownerEmail,
      name: form.ownerFirstName + " " + form.ownerLastName,
      phoneNo: form.notificationPhoneNo,
    };

    const response: AxiosResponse = yield call(SendInstructorMobOtp, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationToken(response.data.token));

    yield put(actions.setShowOTPField(true));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* NotificationOtpVerification(action: {
  payload: { OTP: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const form: SchoolInterface = yield select(selectAdminSchoolForm);
    let data = {
      code: action.payload.OTP,
      token: form.Verificationtoken,
    };
    const response: AxiosResponse = yield call(VerifyInstructorMobOtp, data);

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(
      actions.updateSchoolFormValue({
        key: "notificationPhoneNoVerify",
        value: response.data.status,
      })
    );
    yield put(actions.clearOtPField());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNotificationRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInterface = yield select(selectAdminSchoolForm);
  if (
    form.notificationEmail == undefined ||
    form.notificationEmail?.length == 0
  ) {
    CustomToast("Please Enter Notification Email", "ERROR");
    return;
  }
  if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      form.notificationEmail
    )
  ) {
    CustomToast("Please Enter Valid Email", "ERROR");
    return;
  }
  const data = {
    schoolId: form.id,
    notificationEmail: form.notificationEmail,
    notificationMethod: form.notificationMethod,
    notificationPhoneNo: form.notificationPhoneNo,
    notificationPhoneNoVerify: form.notificationPhoneNoVerify,
    phoneNotificationType: form.phoneNotificationType,
    emailNotificationType: true,
    numberToken: form.Verificationtoken,
  };
  try {
    const response: AxiosResponse = yield call(
      AddSchoolNotificationDetail,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddMailVerificatonIndexRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);

  try {
    yield put(actions.setEmailVarifiedLoading(true));
    const response: AxiosResponse = yield call(
      EmailVerifiedIndexDetail,
      `?token=${action.payload.token}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setEmailVarifiedLoading(false));
    yield put(actions.setEmailConfirmation(response.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setEmailVarifiedLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UpdatestatusChnageRequest(action: {
  payload: { id: string; status: boolean; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      UpdateSchoolStatus,
      `?schoolId=${action.payload.id}&status=${action.payload.status}`
    );

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddMobileBookingNumberForverification(action: {
  payload: { OTPTYPE: number; callback: any };
}) {
  yield delay(500);
  try {
    const form: SendVerificationOtpRequest = yield select(
      selectSendVerificationOtpRequestForm
    );
    const response: AxiosResponse = yield call(SendInstructorMobOtp, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationToken(response.data.token));
    yield put(actions.setShowOTPField(true));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminRepoSagaAdminSchool() {
  yield takeLatest(actions.EditAdminSchoolForm, EditRequest);
  yield takeLatest(actions.updateDataHelp, updateData);
  yield takeLatest(actions.doProfileAddImages, profileFileUploadRequest);
  yield takeLatest(actions.doFeaturedAddImages, featuredPhotoFileUploadRequest);
  yield takeLatest(actions.doLicenceAddFile, licenceFileUploadRequest);
  yield takeLatest(
    actions.doRegistrationFileAdd,
    registrationFileUploadRequest
  );
  yield takeLatest(actions.getSchoolProfileForAdmin, SchoolProfileForAdmin);
  yield takeLatest(actions.doGetSchoolPartnerList, getPartnerListRequest);
  yield takeLatest(
    actions.doGetActiveSchoolPartnerList,
    getPartnerActiveListRequest
  );
  yield takeLatest(actions.DeleteAdminSchool, deletePartnerProfile);
  // yield takeLatest(actions.DeleteCertificateSchool, deleteCertificateProfile);
  yield takeLatest(actions.AddNewCertificateSchool, AddCertificate);
  yield takeLatest(actions.doGetCertificateList, getCertificateIDRequest);
  // yield takeLatest(actions.DeleteRegistrationSchool, deleteRegistrationProfile);
  yield takeLatest(actions.AddNewRegistrationSchool, AddRegistration);
  yield takeLatest(actions.doGetRegistrationList, getRegistrationIDRequest);
  yield takeLatest(actions.doActiveRegistration, doActiveRegistrationRequest);
  yield takeLatest(actions.doActiveCertificate, doActiveCertificateRequest);
  yield takeLatest(actions.doVerifiedMobileNo, AddMobileNumberForverification);
  yield takeLatest(actions.doVerifiedEmailAddress, AddEmailForverification);
  yield takeLatest(actions.doVerifiedOTP, OTPMobileVerificationRequest);
  // Contact Person Otp Verification
  yield takeLatest(
    actions.doVerifiedContactPersonMobileNo,
    AddContactPersonOtpVerification
  );
  yield takeLatest(
    actions.doVerifiedContactPersonOTP,
    contactPersonOtpVerification
  );
  yield takeLatest(actions.AddBasicDetail, AddBasicDetailRequest);
  yield takeLatest(actions.AddBusinessOwner, AddBusinessOwnerRequest);
  yield takeLatest(
    actions.AddBusinessRegistrationAddress,
    AddBusinessRegistrationAddressRequest
  );
  yield takeLatest(actions.AddContactPerson, AddContactPersonRequest);
  yield takeLatest(
    actions.doVerifiedNotificationMobileNo,
    AddNotificationOtpVerification
  );
  yield takeLatest(
    actions.doVerifiedNotificationOTP,
    NotificationOtpVerification
  );
  yield takeLatest(
    actions.doVerifiedEmailIndex,
    AddMailVerificatonIndexRequest
  );
  yield takeLatest(actions.addSchoolNotification, AddNotificationRequest);
  yield takeLatest(actions.getSchoolProfileForSchool, SchoolProfileForSchool);
  yield takeLatest(actions.statusChangeFormValue, UpdatestatusChnageRequest);
  yield takeLatest(
    actions.doVerifiedBookingMobileNo,
    AddMobileBookingNumberForverification
  );
}
