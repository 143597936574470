import { createSlice } from "../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";

import {
  AffiliateAuthState,
  PaymentFormInterface,
  affiliateDashboardInterface,
  affiliateDashboardTopDataInterface,
  listAffiliateLinkInterface,
  paymentTypeEnum,
} from "../types";
import { LoginMessage, SignupMessage } from "../../../Messages";
import { set } from "lodash";

export const initialState: AffiliateAuthState = {
  affliateList: [],
  affiateFormData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    instagramLink: "",
    websiteLink: "",
    termsAndConditions: false,
    youtubeLink: "",
    Errors: [],
    Success: "",
    password: "",
  },
  editIndex: 0,
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 0,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  addAffiliateLink: {
    id: "",
    link: "",
    // retreatId: "",
    seoTitle: "",
  },
  ListAffiliateLink: [],
  affiliateLinkPageNo: 1,
  affiliateLinkTotal: 0,
  PaymentForm: {
    id: "",
    bankName: "",
    beneficiaryName: "",
    accountHolderName: "",
    paymentType: paymentTypeEnum.PAYPAL,
    bankAccountNo: "",
    bankId: "",
    country: "",
    currency: "",
    payPalEmail: "",
    schoolId: "",
  },
  affiliatePaymentPageNo: 1,
  affiliatePaymentTotal: 0,
  PaymentList: [],
  affiliateDashboardTopData: {
    ApprovedAmount: 0,
    Bookings: 0,
    Inquiries: 0,
    PendingAmount: 0,
    TotalPaidAmount: 0,
  },
  affiliateCommissions: [],
  addAccountModal: false,
  addPaypalModal: false,
  addWiseModal: false,
  calculateForm: {
    averageBookingValue: "",
    id: "",
    numberOfBookings: "",
  },
  calculateFormData: [],
  affiliateDashboardPageNo: 1,
  affiliateDashboardTotalRow: 0,
  affiliateDashboardPageSize: 1,
  affiliateDashboardDataList: [],
};

export const useClinicSlice = createSlice({
  name: "affiliateAuthState",
  initialState,
  reducers: {
    clearFrom: (state) => {
      state.affiateFormData.id = "";
      state.affiateFormData.firstName = "";
      state.affiateFormData.lastName = "";
      state.affiateFormData.email = "";
      state.affiateFormData.instagramLink = "";
      state.affiateFormData.websiteLink = "";
      state.affiateFormData.youtubeLink = "";
      state.affiateFormData.termsAndConditions = false;
      state.affiateFormData.password = "";
    },

    updateAffiliatePaymentFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `PaymentForm.${action.payload.key}`, action.payload.value);
    },
    updateAffiliateSignFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `affiateFormData.${action.payload.key}`, action.payload.value);
    },

    doAffiliateSignUp: (
      state,
      action: PayloadAction<{ role: number; callback: () => void }>
    ) => {},
    doAffiliateLinkList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doAffiliatePaymentList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doAddAffiliateLink: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    deleteAffiliateLink: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {},
    deleteAffiliatePayment: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    GetAffiliatePaymentDetailById: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    updateAffiliateLinkFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `addAffiliateLink.${action.payload.key}`,
        action.payload.value
      );
    },
    setAffiliateList: (
      state,
      action: PayloadAction<Array<listAffiliateLinkInterface>>
    ) => {
      state.ListAffiliateLink = action.payload;
    },

    doGetAffiliateDashboardTopData: (
      state,
      action: PayloadAction<{
        // id: string;
        callback: () => void;
      }>
    ) => {},
    setaffiliateDashboardTopData: (
      state,
      action: PayloadAction<affiliateDashboardTopDataInterface>
    ) => {
      state.affiliateDashboardTopData = action.payload;
    },
    clearAffiliateLinkForm: (state) => {
      state.addAffiliateLink.link = "";
    },
    setAffiliatePaymentList: (
      state,
      action: PayloadAction<Array<PaymentFormInterface>>
    ) => {
      state.PaymentList = action.payload;
    },
    setaffiliateLinkPageNo: (state, action: PayloadAction<number>) => {
      state.affiliateLinkPageNo = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setaffiliateLinkTotal: (state, action: PayloadAction<number>) => {
      state.affiliateLinkTotal = action.payload;
    },
    setaffiliatePaymentDataInForm: (
      state,
      action: PayloadAction<PaymentFormInterface>
    ) => {
      state.PaymentForm = action.payload;
    },
    setaffiliatePaymentPageNo: (state, action: PayloadAction<number>) => {
      state.affiliatePaymentPageNo = action.payload;
    },
    setaffiliatePaymentTotal: (state, action: PayloadAction<number>) => {
      state.affiliatePaymentTotal = action.payload;
    },
    setsearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    doAddAffiliatePayment: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    toggleAddAccountModal: (state) => {
      state.addAccountModal = !state.addAccountModal;
    },
    togglePaypalAccount: (state) => {
      state.addPaypalModal = !state.addPaypalModal;
    },
    toggleWiseAccount: (state) => {
      state.addWiseModal = !state.addWiseModal;
    },
    clearAffiliatePaymentForm: (state) => {
      state.PaymentForm.accountHolderName = "";
      state.PaymentForm.bankAccountNo = "";
      state.PaymentForm.bankId = "";
      state.PaymentForm.bankName = "";
      state.PaymentForm.beneficiaryName = "";
      state.PaymentForm.country = "";
      state.PaymentForm.currency = "";
      state.PaymentForm.id = "";
      state.PaymentForm.payPalEmail = "";
      state.PaymentForm.paymentType = 1;
      state.PaymentForm.schoolId = "";
    },

    updateAffiliateCalculateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `calculateForm.${action.payload.key}`, action.payload.value);
    },

    doAffiliateCalculateForm: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    setCalculateForm: (state, action: PayloadAction<any>) => {
      state.calculateFormData = action.payload;
    },

    clearCalculateForm: (state) => {
      state.calculateForm.id = "";
      state.calculateForm.averageBookingValue = "";
      state.calculateForm.numberOfBookings = "";
    },
    setAffiliateDashboardTotalRow: (state, action: PayloadAction<number>) => {
      state.affiliateDashboardTotalRow = action.payload;
    },
    setAffiliateDasboardList: (
      state,
      action: PayloadAction<Array<affiliateDashboardInterface>>
    ) => {
      state.affiliateDashboardDataList = action.payload;
    },
    setAffiliateDashboardPageNo: (state, action: PayloadAction<number>) => {
      state.affiliateDashboardPageNo = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
