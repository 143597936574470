// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state && state.AdminBookingState) {
    return state.AdminBookingState;
  } else {
    return initialState;
  }
};
export const SelectCurrentBookingsList = createSelector(
  [selectDomain],
  (state) => state.CurrentBookingsList
);
export const SelectPastBookingsData = createSelector(
  [selectDomain],
  (state) => state.PastBookingsList
);
export const SelectCancelledBookingslist = createSelector(
  [selectDomain],
  (state) => state.CancelledBookingsList
);
export const SelectTotalCurrentBookingsList = createSelector(
  [selectDomain],
  (state) => state.TotalCurrentBookingsList
);
export const SelectTotalPastBookingsData = createSelector(
  [selectDomain],
  (state) => state.TotalPastBookingsList
);
export const SelectTotalCancelledBookingslist = createSelector(
  [selectDomain],
  (state) => state.TotalCancelledBookingsList
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectListingFIlter = createSelector(
  [selectDomain],
  (state) => state.ListingsListFilter
);
export const SelectSchoolFilter = createSelector(
  [selectDomain],
  (state) => state.schoolFilter
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectCurrentBookingsPagination = createSelector(
  [selectDomain],
  (state) => state.CurrentBookingsPagination
);
export const SelectPastBookingsPagination = createSelector(
  [selectDomain],
  (state) => state.PastBookingsPagination
);
export const SelectCancelledBookingsPagination = createSelector(
  [selectDomain],
  (state) => state.CancelledBookingsPagination
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectCancelBookingReason = createSelector(
  [selectDomain],
  (state) => state.cancelBookingReason
);
export const SelectShowVoucher = createSelector(
  [selectDomain],
  (state) => state.showVoucher
);
export const SelectVoucherCodesList = createSelector(
  [selectDomain],
  (state) => state.voucherCodesList
);
export const SelectVoucherCodesFormData = createSelector(
  [selectDomain],
  (state) => state.voucherCodesFormData
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortColumnPast = createSelector(
  [selectDomain],
  (state) => state.sortColumnPast
);
export const SelectSortDirPast = createSelector(
  [selectDomain],
  (state) => state.sortDirPast
);
export const SelectSortColumnCancel = createSelector(
  [selectDomain],
  (state) => state.sortColumnCancel
);
export const SelectSortDirCancel = createSelector(
  [selectDomain],
  (state) => state.sortDirCancel
);
