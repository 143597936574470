export interface messageReplyChartInterface {
  x: string;
  y: number;
}
export enum OverallClickTypeEnum {
  Published = 1,
  Unpublished = 2,
  Draft = 3,
  De_Active = 4,
  All = 5,
}
export interface bookingGraphInterface {
  enquires: number[];
  booked: number[];
  notBooked: number[];
}
export interface DateInterface {
  end: string;
  start: string;
}
export interface SchoolDashboardState {
  revenueGraph: number[];
  overAllClicksListingsFilter: number;
  clicksGraph: number[];
  labelGraph: [];
  bookingGraph: bookingGraphInterface;
  messageReplyChart: messageReplyChartInterface[];
  createdOnFilter: number | 0;
  date: any | "";
  averageReplyScore : number;
  averageReplyTime: number;
}

export type InitialSchoolDashboardState = SchoolDashboardState;
