import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminPaymentsState,
  AffiliateInterface,
  DiscountCodesInterface,
  EarningReportsInterface,
  PaymentMethodFilterEnum,
  PaymentsInterface,
  PaymentStatusFilterEnum,
  StatusFilterEnum,
  StatusTypeEnum,
  TopDataInterface,
  UserListInterface,
  VoucherCodesInterface,
} from "../types";
import { log } from "../../../../utils/logger";

export const initialState: AdminPaymentsState = {
  loading: false,
  AffiliatepageNo: 1,
  AffiliatepageSize: 15,
  DiscountpageNo: 1,
  DiscountpageSize: 15,
  EarningReportpageNo: 1,
  EarningReportpageSize: 15,
  PaymentspageNo: 1,
  PaymentspageSize: 15,
  totalRow: 10,
  VoucherpageNo: 1,
  VoucherpageSize: 15,
  search: "",
  PaymentsList: [],
  AffiliatesList: [],
  EarningReportsList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  discountCodesList: [],
  totalPayments: 0,
  totalAffiliate: 0,
  totalEarningReport: 0,
  totalVoucher: 0,
  totalDiscount: 0,
  voucherCodesFormData: {
    id: "",
    voucherName: "",
    amount: "",
    validTill: "",
    userId: "",
    email: "",
    note: "",
    status: StatusTypeEnum.All,
    firstName: "",
    lastName: "",
  },
  voucherCodesList: [],
  showVoucher: false,
  SchoolListFilter: [],
  ListingsListFilter: [],
  StatusFilter: StatusFilterEnum.All,
  paymentStatus: [PaymentStatusFilterEnum.ALL],
  paymentMethod: PaymentMethodFilterEnum.CREDIT_CARD,
  currencyFilter: "",
  user: [],
  startdate: "",
  endDate: "",
  VoucherForUserData: [],
  topData: {
    failedAmount: 0,
    numberOfTransaction: 0,
    paidAmount: 0,
    pendingAmount: 0,
    total_price: 0,
  },
  sortColumn: "",
  sortDir: "",
  sortColumnDiscount: "",
  sortDirDiscount: "",
  sortColumnVoucher: "",
  sortDirVoucher: "",
  sortColumnEarning: "",
  sortDirEarning: "",
};

export const useClinicSlice = createSlice({
  name: "adminPayments",
  initialState,
  reducers: {
    updateStatusFilterFormValue: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
        callback: (value: any) => void;
      }>
    ) => {
      set(state, `statusFilter`, action.payload.value);
    },
    updatePaymentStatusFilterFormValue: (
      state,
      action: PayloadAction<{
        value: any;
      }>
    ) => {
      set(state, `paymentStatus`, action.payload.value);
    },
    updateVoucherCodeFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `voucherCodesFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.SchoolListFilter = action.payload;
    },
    StartDateFilter: (state, action: PayloadAction<any>) => {
      state.startdate = action.payload;
    },
    EndDateFilter: (state, action: PayloadAction<any>) => {
      state.endDate = action.payload;
    },
    setVoucherForUserData: (state, action: PayloadAction<any>) => {
      state.VoucherForUserData = action.payload;
    },
    ListingFilter: (state, action: PayloadAction<any>) => {
      state.ListingsListFilter = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    AddVoucherCode: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    deleteVoucher: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetVoucherDataById: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setVoucherCodesFormData: (
      state,
      action: PayloadAction<VoucherCodesInterface>
    ) => {
      state.voucherCodesFormData = action.payload;
    },
    setAllVoucherCodesListList: (
      state,
      action: PayloadAction<Array<VoucherCodesInterface>>
    ) => {
      state.voucherCodesList = action.payload;
    },
    doGetPaymentsList: (state) => {},
    doGetVoucherUserList: (state) => {},
    doGetVoucherList: (state, action: PayloadAction<number>) => {},
    doClearVoucherForm: (state) => {
      state.voucherCodesFormData.amount = "";
      state.voucherCodesFormData.email = "";
      state.voucherCodesFormData.id = "";
      state.voucherCodesFormData.note = "";
      state.voucherCodesFormData.status = StatusTypeEnum.All;
      state.voucherCodesFormData.userId = "";
      state.voucherCodesFormData.validTill = "";
      state.voucherCodesFormData.voucherName = "";
    },
    doClearVoucherDateForm: (state) => {
      state.voucherCodesFormData.validTill = "";
    },
    doGetUserList: (state) => {},
    doGetDiscountCodeList: (state) => {},
    doGetAffiliatesList: (state) => {},
    doGetEarningReportsList: (state) => {},

    setAllPaymentsList: (
      state,
      action: PayloadAction<Array<PaymentsInterface>>
    ) => {
      state.PaymentsList = action.payload;
    },
    setAllDiscountList: (
      state,
      action: PayloadAction<Array<DiscountCodesInterface>>
    ) => {
      state.discountCodesList = action.payload;
    },
    setAlluserList: (
      state,
      action: PayloadAction<Array<UserListInterface>>
    ) => {
      state.user = action.payload;
    },
    setAffiliatesList: (
      state,
      action: PayloadAction<Array<AffiliateInterface>>
    ) => {
      state.AffiliatesList = action.payload;
    },
    setEarningReportsList: (
      state,
      action: PayloadAction<Array<EarningReportsInterface>>
    ) => {
      state.EarningReportsList = action.payload;
    },

    setTotalPaymentList: (state, action: PayloadAction<number>) => {
      state.totalPayments = action.payload;
    },
    setTotalAffiliateList: (state, action: PayloadAction<number>) => {
      state.totalAffiliate = action.payload;
    },
    setTotalDiscountList: (state, action: PayloadAction<number>) => {
      state.totalDiscount = action.payload;
    },
    setTotalEarningReportList: (state, action: PayloadAction<number>) => {
      state.totalEarningReport = action.payload;
    },
    settopData: (state, action: PayloadAction<TopDataInterface>) => {
      state.topData = action.payload;
    },

    setTotalVoucherList: (state, action: PayloadAction<number>) => {
      state.totalVoucher = action.payload;
    },
    DeleteAdminPayments: (state, action: PayloadAction<any>) => {
      state.PaymentsList.splice(action.payload, 1);
    },
    DeleteAdminAffiliates: (state, action: PayloadAction<any>) => {
      state.AffiliatesList.splice(action.payload, 1);
    },
    DeleteAdminEarningReports: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    // VoucherpageNo: 1,
    // VoucherpageSize: 10,
    setAffiliatePageNo: (state, action: PayloadAction<number>) => {
      state.AffiliatepageNo = action.payload;
    },
    setAffiliatePageSize: (state, action: PayloadAction<number>) => {
      state.AffiliatepageSize = action.payload;
    },
    setDiscountPageNo: (state, action: PayloadAction<number>) => {
      state.DiscountpageNo = action.payload;
    },
    setDiscountPageSize: (state, action: PayloadAction<number>) => {
      state.DiscountpageSize = action.payload;
    },
    setEarningReportPageNo: (state, action: PayloadAction<number>) => {
      state.EarningReportpageNo = action.payload;
    },
    setEarningReportPageSize: (state, action: PayloadAction<number>) => {
      state.EarningReportpageSize = action.payload;
    },
    setPaymentsPageNo: (state, action: PayloadAction<number>) => {
      state.PaymentspageNo = action.payload;
    },
    setPaymentsPageSize: (state, action: PayloadAction<number>) => {
      state.PaymentspageSize = action.payload;
    },
    setVoucherPageNo: (state, action: PayloadAction<number>) => {
      state.VoucherpageNo = action.payload;
    },
    setVoucherPageSize: (state, action: PayloadAction<number>) => {
      state.VoucherpageSize = action.payload;
    },
    doExportBYID: (state, action: PayloadAction<string>) => {},
    doExportReport: (state) => {},
    toggleShowVoucher: (state) => {
      state.showVoucher = !state.showVoucher;
    },

    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setDiscountSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnDiscount = action.payload;
    },
    setDiscountSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirDiscount = action.payload;
    },
    setVoucherSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnVoucher = action.payload;
    },
    setVoucherSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirVoucher = action.payload;
    },
    setEarningSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnEarning = action.payload;
    },
    setEarningSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirEarning = action.payload;
    },
    doUpdateRecordPaymentId: (
      state,
      action: PayloadAction<{ id: string; status: any; callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: AdminPaymenstSliceKey } = useClinicSlice;
