import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AddSchoolCoupon,
  DeleteCouponRequest,
  GetAllSchoolCouponListRequest,
  GetCouponByIdRequest,
  getPromotionList,
} from "../../../../utils/request";
import {
  SelectCouponForm,
  SelectPageNo,
  SelectPromotionPageNo,
  SelectSortColumn,
  SelectSortDir,
} from "./selector";
import { couponFormInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* AddCouponRequest(action: {
  payload: { schoolId: string; callback: () => void };
}) {
  yield delay(500);

  const form: couponFormInterface = yield select(SelectCouponForm);

  if (form.couponCode === "") {
    CustomToast("Please Fill Coupon Code", "ERROR");
    return;
  }
  if (form.couponName === "") {
    CustomToast("Please Fill Coupon Name", "ERROR");
    return;
  }
  if (form.discount === "") {
    CustomToast("Please Fill Coupon discount", "ERROR");
    return;
  }
  // if (form.expiryDate === "") {
  //   CustomToast("Please Select Expiry Date", "ERROR");
  //   return;
  // }
  if (form.listing.length == 0) {
    CustomToast("Please Select Listing", "ERROR");
    return;
  }
  if (form.quantity == 0) {
    CustomToast("Please Fill Coupon Quantity", "ERROR");
    return;
  }

  const data = {
    couponCode: form.couponCode,
    couponName: form.couponName,
    discount: form.discount,
    expiryDate: form.expiryDate,
    listing: form.listing,
    quantity: form.quantity,
    schoolID: action.payload.schoolId,
    id: form.id ? form.id : "",
    discountType: form.discountType,
    remaining: form.remaining,
  };
  try {
    const response: AxiosResponse = yield call(AddSchoolCoupon, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setPageNo(1));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAllCouponListRequest(action: {
  payload: { schoolId: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      GetAllSchoolCouponListRequest,
      `?schoolId=${action.payload.schoolId}&pageNo=${PageNo}&pageSize=10${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.settotalCount(response.data.data[0].meta.total));
    yield put(actions.setCouponlistData(response.data.data[0].results));
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeleteCouponByID(action: {
  payload: { CouponId: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      DeleteCouponRequest,
      action.payload.CouponId
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCouponByIdRequest(action: {
  payload: { CouponId: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      GetCouponByIdRequest,
      action.payload.CouponId
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDataInForm(response.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getAllRefferalsListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPromotionPageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPromotionList,
      `?schoolId=${action.payload}&pageNo=${PageNo}&pageSize=${10}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTotalRefferals(response.data.data[0].meta.total));
    yield put(actions.setRefferalsListData(response.data.data[0]));

    yield put(actions.setLoading(false));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* SchoolCouponRepoSaga() {
  yield takeLatest(actions.addCoupon, AddCouponRequest);
  yield takeLatest(actions.getCouponList, getAllCouponListRequest);
  yield takeLatest(actions.DeleteCouponDataById, DeleteCouponByID);
  yield takeLatest(actions.getCouponDataById, getCouponByIdRequest);
  yield takeLatest(actions.getRefferalsList, getAllRefferalsListRequest);
}
