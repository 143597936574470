import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  OverallClickTypeEnum,
  SchoolDashboardState,
  bookingGraphInterface,
} from "../types";

export const initialState: SchoolDashboardState = {
  revenueGraph: [],
  clicksGraph: [],
  bookingGraph: {
    enquires: [],
    booked: [],
    notBooked: [],
  },
  messageReplyChart: [
    {
      x: "Repiled",
      y: 40,
    },
    {
      x: "Not Replied",
      y: 60,
    },
  ],
  createdOnFilter: 0,
  date: null,
  overAllClicksListingsFilter: OverallClickTypeEnum.All,
  labelGraph: [],
  averageReplyScore: 0,
  averageReplyTime: 0
};

export const useClinicSlice = createSlice({
  name: "schoolDashboard",
  initialState,
  reducers: {
    setSreatedOnFilter: (state, action: PayloadAction<any>) => {
      state.createdOnFilter = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    messageReplyChart: (state ,action: PayloadAction<any>) => {
      state.messageReplyChart[0].y = action.payload
      state.messageReplyChart[1].y = 10 - action.payload
    },
    setoverAllClicksListingsFilter: (state, action: PayloadAction<any>) => {
      state.overAllClicksListingsFilter = action.payload;
    },
    doGetSchoolDashboardDetails: (
      state,
      action: PayloadAction<{ schoolId: string; callback: () => void }>
    ) => {},

    doGetTotalListingsClicksList: (
      state,
      action: PayloadAction<{ schoolId: string; callback: () => void }>
    ) => {},
    setclicksGraph: (state, action: PayloadAction<any>) => {
      state.clicksGraph = action.payload;
    },
    setbookingGraph: (state, action: PayloadAction<bookingGraphInterface>) => {
      state.bookingGraph = action.payload;
    },
    setrevenueGraph: (state, action: PayloadAction<number[]>) => {
      state.revenueGraph = action.payload;
    },
    setlabelGraph: (state, action: PayloadAction<[]>) => {
      state.labelGraph = action.payload;
    },
    setAverageReplyScore: (state, action: PayloadAction<number>) => {
      state.averageReplyScore = action.payload;
    },
    setAverageReplyTime: (state, action: PayloadAction<number>) => {
      state.averageReplyTime = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
