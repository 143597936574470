import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { CustomerReviewInterface, FakeReviewState } from "../types";


export const initialState: FakeReviewState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
  customerReviewList: [],
  customerReviewFormData: {
    id: "",
    review: "",
    qualityOfActivity: 1,
    locationRating: 1,
    foodRating: 1,
    accommodationAndFacilities: 1,
    valueForMoney: 1,
    likes: "",
    dislikes: "",
    overallImpression: 0,
    overview: "",
    schoolId: "",
    userId: "",
    retreatId: "",
    email: "",
    firstName: "",
    lastName: "",
    retreatTitle: "",
    school: "",
    date: "",
    createdAt: ""
  },
  reviewModal: false,
  userList: [],
  retreatlist: [],
  SchoolList: []
};

export const useClinicSlice :any = createSlice({
  name: "FakeReviewState",
  initialState,
  reducers: {
    updateCustomerReviewFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `customerReviewFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    sendReview: (state, action: PayloadAction<{ callback: () => void }>) => { },
    deleteReview: (state, action: PayloadAction<{ id: string, callback: () => void }>) => { },
    doGetReviewList: (state) => { },
    doGetSchoolList: (state) => { },
    doGetUserList: (state) => { },
    doGetRetreatList: (state) => { },
    clearFrom: (state) => {
      state.customerReviewFormData.id = "";
      state.customerReviewFormData.review = "";
      state.customerReviewFormData.date = "";
      state.customerReviewFormData.qualityOfActivity = 1;
      state.customerReviewFormData.locationRating = 1;
      state.customerReviewFormData.foodRating = 1;
      state.customerReviewFormData.valueForMoney = 1;
      state.customerReviewFormData.accommodationAndFacilities = 1;
      state.customerReviewFormData.likes = "";
      state.customerReviewFormData.dislikes = "";
      state.customerReviewFormData.overallImpression = 0;
      state.customerReviewFormData.schoolId = "";
      state.customerReviewFormData.retreatId = "";
      state.customerReviewFormData.userId = "";
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserList: (state, action: PayloadAction<any>) => {
      state.userList = action.payload;
    },
    setRetreatList: (state, action: PayloadAction<any>) => {
      state.retreatlist = action.payload;
    },
    setSchoolList: (state, action: PayloadAction<any>) => {
      state.SchoolList = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setCustomerReviewList: (state, action: PayloadAction<Array<CustomerReviewInterface>>) => {
      state.customerReviewList = action.payload;
    },
    toggleReviewModal: (state) => {
      state.reviewModal = !state.reviewModal;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
