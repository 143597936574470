export enum BarPositionEnum {
  TOPFIXED = 1,
  TOPFixed_WITH_DISMISS_ICON = 2,
}
export enum StatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}

export interface WelcomeBarInterface {
  id: string;
  barMessage: string;
  linkTo: string;
  backgroundColor: string;
  textColor: string;
  barHeight: number;
  fontSize: number;
  barPosition: BarPositionEnum;
  barStatus: StatusEnum;
}
export interface AdminWelcomeBarState {
  welcomeBarList: WelcomeBarInterface[];
  welcomeBarFormData: WelcomeBarInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
}

export type InitialAdminWelcomeBarState = AdminWelcomeBarState;
