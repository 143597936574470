import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { SelectPageNo, SelectPageSize, SelectSearch } from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetAllPartnerListRequest,
  GetListingListForFilter,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* getListingListRequest(action: {
  payload: { serach: string; id: any; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetListingListForFilter,
      `?schoolId=${
        action.payload.id && action.payload.id.length > 0 ? action.payload.id : ""
      }&search${action.payload.serach.length > 0 ? action.payload.serach : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.ListingList(response.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAllPartnerListDataRequest(action: {
  payload: { serach: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAllPartnerListRequest,

      `?search=${action.payload.serach}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setAllPartnerList(response.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* adminMediaManagerRepoSaga() {
  yield takeLatest(actions.getListingList, getListingListRequest);
  yield takeLatest(actions.doGetAllPartnerList, getAllPartnerListDataRequest);
}
