import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { selectCustomerReviewForm } from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import { doAddReviewRequest } from "../../../../utils/request";
import { CustomerReviewInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* CustomerReviewAddDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: CustomerReviewInterface = yield select(selectCustomerReviewForm);

  try {
    const response: AxiosResponse = yield call(doAddReviewRequest, form);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* CustomerReviewRepoSaga() {
  yield takeLatest(actions.sendReview, CustomerReviewAddDataRequest);
}
