import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  AddListingLocation,
  AddressList,
  CreateSurroundingEnvironmentRetreatStyle,
  DeleteSurranidngRequest,
  getFixedSchoolSurroundingEnvironment,
  GetLocationFormdataByIdRequest,
  getTempSchoolSurroundingEnvironment,
} from "../../../../../utils/request";
import { LocationInterface, SurroundingEnvironmentModel } from "../types";
import {
  SelectListingLocationForm,
  selectSurroundingEnvironmentForm,
} from "./selector";
import { actions } from "./slice";
import { actions as actionResponce } from "../../redux/slice";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

export function* getSurroundingEnvironmentFixedListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getFixedSchoolSurroundingEnvironment
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSurroundingEnvironmentFixedList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getTempSurroundingEnvironmentRequest(action: {
  payload: any;
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getTempSchoolSurroundingEnvironment,
      action.payload
    );

    if (response && !response.data.surroundingEnviroment) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(
      actions.setSurroundingEnvironmentTempList(
        response.data.surroundingEnviroment
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewSurroundingEnvironmentStyleRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: SurroundingEnvironmentModel = yield select(
    selectSurroundingEnvironmentForm
  );
  if (form.name.trim() == "") {
    CustomToast("Please enter Surrounding Environment", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      CreateSurroundingEnvironmentRetreatStyle,
      form
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewListingLocationRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  const form: LocationInterface = yield select(SelectListingLocationForm);
  try {
    const response: AxiosResponse = yield call(AddListingLocation, form);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getLocationSchoolByIdRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      GetLocationFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    yield put(actions.SetLocationModalType(response.data.model));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetLocationDataById(response.data.data[0]));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "location_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeletePillsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteSurranidngRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getaddressListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    if (action.payload.length < 3) {
      yield put(actions.setAddressList([]));
      return;
    }
    const response: AxiosResponse = yield call(AddressList, action.payload);
    yield put(actions.setAddressList(response.data));
    // yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* repoSagaListingLocation() {
  yield takeLatest(
    actions.getFixedSurroundingEnvironmentTypeList,
    getSurroundingEnvironmentFixedListRequest
  );
  yield takeLatest(
    actions.getTempSurroundingEnvironmentList,
    getTempSurroundingEnvironmentRequest
  );
  yield takeLatest(
    actions.AddNewSurroundingEnvironmentStyle,
    AddNewSurroundingEnvironmentStyleRequest
  );
  yield takeLatest(actions.AddListingLocation, AddNewListingLocationRequest);
  yield takeLatest(
    actions.doGetLocationSchoolDataByID,
    getLocationSchoolByIdRequest
  );
  yield takeLatest(actions.DeletePills, DeletePillsRequest);
  yield takeLatest(actions.doGetAddressList, getaddressListRequest);
}
