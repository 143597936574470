export interface AdminBookingState {
  CurrentBookingsList: [];
  cancelBookingReason: string;
  PastBookingsList: [];
  CancelledBookingsList: [];
  TotalCurrentBookingsList: number;
  TotalPastBookingsList: number;
  TotalCancelledBookingsList: number;
  schoolFilter: any;
  search: string;
  ListingsListFilter: [];
  date: "";
  createdOnFilter: number | 0;
  loading: boolean;
  CurrentBookingsPagination: BookingPaginationInterface;
  PastBookingsPagination: BookingPaginationInterface;
  CancelledBookingsPagination: BookingPaginationInterface;
  showVoucher: boolean;
  voucherCodesList: VoucherCodesInterface[];
  voucherCodesFormData: VoucherCodesInterface;
  sortColumn: string;
  sortDir: string;
  sortColumnPast: string;
  sortDirPast: string;
  sortColumnCancel: string;
  sortDirCancel: string;
}
export interface BookingPaginationInterface {
  pageNo: number;
  pageSize: number;
}
export interface VoucherCodesInterface {
  id: string;
  voucherName: string;
  amount: any;
  firstName: string;
  lastName: string;
  validTill: string;
  userId: string;
  email: string;
  note: string;
  status: StatusTypeEnum;
}
export enum StatusTypeEnum {
  InActive = 0,
  Active = 1,
  All = 2,
}
export type InitialMileStoneStata = AdminBookingState;
