// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";
const selectDomain = (state?: RootState) =>
  state?.locationState || initialState;
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectgeoLocation = createSelector(
  [selectDomain],
  (state) => state.locationForm.geoLocation
);

export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectListingsID = createSelector(
  [selectDomain],
  (state) => state.ListingsId
);
export const SelectOnlineOrOffline = createSelector(
  [selectDomain],
  (state) => state.offlineOrOnline
);
export const SelectListingLocationForm = createSelector(
  [selectDomain],
  (state) => state.locationForm
);
export const SelectListingLocationList = createSelector(
  [selectDomain],
  (state) => state.locationList
);

export const SelectSchoolSurroundingEnvironmentFixedList = createSelector(
  [selectDomain],
  (state) => state.surroundingEnvironmentFixedList
);
export const SelectSchoolSurroundingEnvironmentTempList = createSelector(
  [selectDomain],
  (state) => state.surroundingEnvironmentTempList
);
export const selectSurroundingEnvironmentForm = createSelector(
  [selectDomain],
  (state) => state.locationForm?.surroundingEnvironmentForm
);

export const selectSurroundingEnvironmentList = createSelector(
  [selectDomain],
  (state) => state.locationForm.surroundingEnvironment
);
export const SelectLocationModalType = createSelector(
  [selectDomain],
  (state) => state.locationModalType
);
export const SelectLocationDraft = createSelector(
  [selectDomain],
  (state) => state.locationForm.isDraft
);
export const SelectAddressList = createSelector(
  [selectDomain],
  (state) => state.AddressList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
