import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionResponce } from "../../redux/slice";
import { AxiosResponse } from "axios";
import {
  ADDInclusionAndExclusionRequest,
  CreateDrinksRetreatStyle,
  CreateExclusionTempSchool,
  CreateinclusionTempSchool,
  CreateListingFoodAndDrinks,
  CreateMealsRetreatStyle,
  DeleteDrinksRequest,
  DeleteExcushionRequest,
  DeleteInclushionRequest,
  DeleteMealsRequest,
  getExclusionFixedAdminStyle,
  getExclusionTempSchoolStyle,
  getFixedSchoolFoodAndDrinks,
  getFixedSchoolMeals,
  GetFoodAndDrinksFormdataByIdRequest,
  getinclusionFixedAdminStyle,
  GetInclusionsexclusionsDataByIdRequest,
  getinclusionTempSchoolStyle,
  getTempSchoolDrinksStyle,
  getTempSchoolMealsStyle,
} from "../../../../../utils/request";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  selectDrinksStyleForm,
  selectExclusionForms,
  selectfoodAndDrinksForm,
  selectInclusionsAndExclusionForms,
  selectInclusionssForms,
  selectMealsStyleForm,
} from "./selector";
import {
  DrinksStyleModel,
  FoodAndDrinksInterface,
  InclusionsExclushionModel,
  InclusionsExclusionsInterface,
  InclusionsFormModel,
  MealsStyleModel,
} from "../types";
import { fromPairs } from "lodash";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

export function* getRetreatDrinksTypeFixedListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getFixedSchoolFoodAndDrinks);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDrinksTypeFixedList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatMealsTypeFixedListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getFixedSchoolMeals);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setMealsTypeFixedList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatDrinksTypeTempRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getTempSchoolDrinksStyle,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDrinksTypeTempList(response.data.drinks));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatMealsTypeTempRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getTempSchoolMealsStyle,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setMealsTypeTempList(response.data.retreatMeals));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewDrinksRetreatStyleRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: DrinksStyleModel = yield select(selectDrinksStyleForm);
  if (form.name.trim() == "") {
    CustomToast("Please enter drinks", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(CreateDrinksRetreatStyle, form);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewMealsRetreatStyleRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: MealsStyleModel = yield select(selectMealsStyleForm);
  if (form.name.trim() == "") {
    CustomToast("Please enter meals", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(CreateMealsRetreatStyle, form);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewlistingFoodAndDrinksRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: FoodAndDrinksInterface = yield select(selectfoodAndDrinksForm);

  try {
    yield put(actions.setloading(true));
    const response: AxiosResponse = yield call(
      CreateListingFoodAndDrinks,
      form
    );
    yield put(actions.setloading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setloading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setloading(false));
    CatchBlockFunction(error);
  }
}
export function* AddInclusionsSchool(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: InclusionsExclushionModel = yield select(selectInclusionssForms);
  if (form.name.trim() == "") {
    CustomToast("Please enter inclusions", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(CreateinclusionTempSchool, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatListingsInclusionsAdminRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getinclusionFixedAdminStyle);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setRetreatListingsInclusionsAdminList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getListingsInclusionschoolListRequest(action: {
  payload: any;
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getinclusionTempSchoolStyle,
      action.payload
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setRetreatListingsInclusionsdSchoolList(response.data.inclusions)
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddExclusionSchool(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: InclusionsExclushionModel = yield select(selectExclusionForms);
  if (form.name.trim() == "") {
    CustomToast("Please enter exclusions", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(CreateExclusionTempSchool, form);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatListingsExclusionAdminRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getExclusionFixedAdminStyle);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setRetreatListingsExclusionAdminList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getListingsExclusionchoolListRequest(action: {
  payload: any;
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getExclusionTempSchoolStyle,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setRetreatListingsExclusionSchoolList(response.data.exclusions)
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddInclusionAndExclusionRequestSaga(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: InclusionsFormModel = yield select(
    selectInclusionsAndExclusionForms
  );

  try {
    yield put(actions.setInclusionLoading(true));
    const response: AxiosResponse = yield call(
      ADDInclusionAndExclusionRequest,
      form
    );
    yield put(actions.setInclusionLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setInclusionLoading(false));
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setInclusionLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getFoodAndDrinksByIdRequest(action: { payload: any }) {
  yield delay(500);

  try {
    yield put(actions.setloading(true));
    const response: AxiosResponse = yield call(
      GetFoodAndDrinksFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setloading(false));
    yield put(actions.SetFoodDrinksModalType(response.data.model));
    if (response && !response.data.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetFoodAndDrinkDataById(response.data?.data[0]));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "foodDrink_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setloading(false));
    CatchBlockFunction(error);
  }
}

export function* getInclusionandExclusionSchoolByIdRequest(action: {
  payload: any;
}) {
  yield delay(500);

  try {
    yield put(actions.setInclusionLoading(true));
    const response: AxiosResponse = yield call(
      GetInclusionsexclusionsDataByIdRequest,
      action.payload
    );
    yield put(actions.SetdoGetInclusionandExclusionById(response.data));
    yield put(actions.setInclusionLoading(false));
    if (response && !response.data.inclushionList.length) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetInclushionExclushionModalType(response.data.model));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "inclushionExclushioin_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setInclusionLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeletePillsDrinksRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteDrinksRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeletePillsMealsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteMealsRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeleteInclushionPillsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteInclushionRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeleteExclushionPillsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteExcushionRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* repoSagaSchoolListingsSectionOverview() {
  yield takeLatest(actions.DeleteDrinksPills, DeletePillsDrinksRequest);
  yield takeLatest(actions.DeleteMealsPills, DeletePillsMealsRequest);
  yield takeLatest(actions.DeleteExclushion, DeleteExclushionPillsRequest);
  yield takeLatest(actions.DeleteInclushion, DeleteInclushionPillsRequest);
  yield takeLatest(
    actions.getFixedDrinksTypeList,
    getRetreatDrinksTypeFixedListRequest
  );
  yield takeLatest(
    actions.AddListingsFoodAndDrinks,
    AddNewlistingFoodAndDrinksRequest
  );
  yield takeLatest(
    actions.AddSchoolInclusionandExclusion,
    AddInclusionAndExclusionRequestSaga
  );
  yield takeLatest(
    actions.getFixedMealsTypeList,
    getRetreatMealsTypeFixedListRequest
  );
  yield takeLatest(
    actions.getTempDrinksTypeSchoolList,
    getRetreatDrinksTypeTempRequest
  );
  yield takeLatest(
    actions.getTempMealsTypeSchoolList,
    getRetreatMealsTypeTempRequest
  );
  yield takeLatest(
    actions.AddNewDrinksRetreatStyle,
    AddNewDrinksRetreatStyleRequest
  );
  yield takeLatest(
    actions.AddNewMealsRetreatStyle,
    AddNewMealsRetreatStyleRequest
  );
  yield takeLatest(actions.AddNewListingsInclusions, AddInclusionsSchool);
  yield takeLatest(
    actions.getRetreatListingsInclusionsAdminList,
    getRetreatListingsInclusionsAdminRequest
  );
  yield takeLatest(
    actions.getRetreatListingsInclusionsSchoolList,
    getListingsInclusionschoolListRequest
  );
  yield takeLatest(actions.AddNewListingsExclusion, AddExclusionSchool);
  yield takeLatest(
    actions.getRetreatListingsExclusionAdminList,
    getRetreatListingsExclusionAdminRequest
  );
  yield takeLatest(
    actions.getRetreatListingsExclusionSchoolList,
    getListingsExclusionchoolListRequest
  );
  yield takeLatest(
    actions.doGetFoodAndDrinksDataByID,
    getFoodAndDrinksByIdRequest
  );

  yield takeLatest(
    actions.doGetInclusionandExclusionSchoolDataByID,
    getInclusionandExclusionSchoolByIdRequest
  );
}
