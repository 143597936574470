// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolListingOverview) {
      return state.schoolListingOverview;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectMainActivity = createSelector(
  [selectDomain],
  (state) => state.AllMainActivityList
);

export const SelectListingsID = createSelector(
  [selectDomain],
  (state) => state.ListingsId
);
export const SelectOnlineOrOffline = createSelector(
  [selectDomain],
  (state) => state.offlineOrOnline
);
export const selectfoodAndDrinksForm = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm
);
export const selectDrinksStyleForm = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm.drinksStyleForm
);
export const selectDrinksStyleList = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm.drinksList
);
export const selectMealsStyleForm = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm.mealsStyleForm
);
export const selectMealsStyleList = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm.mealsList
);
export const SelectfoodAndDrinksList = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksList
);

export const SelectSchoolDrinksFixedList = createSelector(
  [selectDomain],
  (state) => state.DrinksFixedList
);
export const SelectSchoolDrinksTempList = createSelector(
  [selectDomain],
  (state) => state.DrinksTempList
);
export const SelectSchoolMealsFixedList = createSelector(
  [selectDomain],
  (state) => state.MealsFixedList
);
export const SelectSchoolMealsTempList = createSelector(
  [selectDomain],
  (state) => state.MealsTempList
);
export const SelectFoodDrinksModalType = createSelector(
  [selectDomain],
  (state) => state.FoodDrinksModalType
);
// ----------- Inclusin and Exclusion
export const selectInclusionssForms = createSelector(
  [selectDomain],
  (state) =>
    state.InclusionsExclusionsForm?.InclushionExclushionForm?.Inclushion
);
export const selectInclusionsExclusionsForm = createSelector(
  [selectDomain],
  (state) => state.InclusionsExclusionsForm?.InclushionExclushionForm
);
export const SelectInclusionsFixedAdminnList = createSelector(
  [selectDomain],
  (state) => state.InclusionsFixedAdmin
);
export const SelectInclusionstempSchoolList = createSelector(
  [selectDomain],
  (state) => state.InclusionsTempSchool
);
export const SelectExclusionFixedAdminnList = createSelector(
  [selectDomain],
  (state) => state.ExclusionFixedAdmin
);
export const SelectExclusionstempSchoolList = createSelector(
  [selectDomain],
  (state) => state.ExclusionTempSchool
);
export const selectExclusionForms = createSelector(
  [selectDomain],
  (state) =>
    state.InclusionsExclusionsForm?.InclushionExclushionForm?.Exclushion
);
export const selectInclusionsAndExclusionForms = createSelector(
  [selectDomain],
  (state) => state.InclusionsExclusionsForm.InclushionExclushionForm
);
export const SelectInclushionExclushionModalType = createSelector(
  [selectDomain],
  (state) => state.InclushionExlushionModalType
);
export const SelectFoodAndDrinksDraft = createSelector(
  [selectDomain],
  (state) => state.foodAndDrinksForm.isDraft
);

export const SelectInclushionAndExclushionDraft = createSelector(
  [selectDomain],
  (state) => state.InclusionsExclusionsForm.isDraft
);
export const Selectloading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectInclusionLoading = createSelector(
  [selectDomain],
  (state) => state.InclusionLoading
);