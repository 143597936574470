import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CurrentlyconnectedInterface,
  PreviousBookingsInterface,
  SchoolBookingState,
  UpcomingBookingsInterface,
} from "../types";
import set from "lodash.set";

export const initialState: SchoolBookingState = {
  loading: false,
  CurrentlyconnectedList: [],
  PreviousBookingsList: [],
  UpcomingBookingsList: [],
  BookingsListPagination: {
    CurrentlyBookingsPageNo: 1,
    PreviousBookingsPageNo: 1,
    UpComingBookingsPageNo: 1,
  },
  TotalCurrentlyBookingList: 0,
  TotalPreviousBookingList: 0,
  TotalUpComingBookingList: 0,
};

export const useClinicSlice = createSlice({
  name: "SchoolBookingState",
  initialState,
  reducers: {
    doGetCurrentlyconnectedList: (
      state,
      action: PayloadAction<{
        schoolId: string;
        callback: () => void;
      }>
    ) => {},
    doGetPreviousBookingsList: (
      state,
      action: PayloadAction<{
        schoolId: string;
        callback: () => void;
      }>
    ) => {},
    doGetUpcomingBookingsList: (
      state,
      action: PayloadAction<{
        schoolId: string;
        callback: () => void;
      }>
    ) => {},
    setCurrentlyconnectedList: (
      state,
      action: PayloadAction<Array<CurrentlyconnectedInterface>>
    ) => {
      state.CurrentlyconnectedList = action.payload;
    },
    setPreviousBookingsList: (
      state,
      action: PayloadAction<Array<PreviousBookingsInterface>>
    ) => {
      state.PreviousBookingsList = action.payload;
    },
    setUpcomingBookingsList: (
      state,
      action: PayloadAction<Array<UpcomingBookingsInterface>>
    ) => {
      state.UpcomingBookingsList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalPreviousBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalPreviousBookingList = action.payload;
    },
    setTotalCurrentlyBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalCurrentlyBookingList = action.payload;
    },
    setTotaUpComingBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalUpComingBookingList = action.payload;
    },
    updatePageNoValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `BookingsListPagination.${action.payload.key}`,
        action.payload.value
      );
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.BookingsListPagination.UpComingBookingsPageNo = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
