import config from "./config.json";
let BASE_URL = config.MAIN_URL;
export { BASE_URL };

let BASE_URLAPI = config.API_URL;
export { BASE_URLAPI };
let BASEURL = config.API_URL;
export { BASEURL };
let COOKIE_DOMAIN = config.COOKIE_DOMAIN;
export { COOKIE_DOMAIN };
let STRIPE_PUBLIC_KEY = config.STRIPE_PUBLIC_KEY;
export { STRIPE_PUBLIC_KEY };