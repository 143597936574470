import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  ContextInterface,
  PackageDetailsInterface,
  RequestToReservationInterface,
  RequestToReservationState,
} from "../types";
import { log } from "../../../../utils/logger";

export const initialState: RequestToReservationState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  requestToReservationList: [],
  requestToReservationFormData: {
    noDates: false,
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    message: "",
    context: "",
    isRecieveEmail: false,
    retreatId: "",
    packageId: "",
    arrivalDate: null,
    availability: 0,
    affiliateCode: "",
    promotionCode: "",
  },
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
  contextList: [],
  packageId: "",
  retreatId: "",
  packageDetailsList: [],
  EnquiryID: "",
  openModal: false,
  packageDetailsListRetreat: [],
  enquiryLoading: false
};

export const useClinicSlice = createSlice({
  name: "requestToReservation",
  initialState,
  reducers: {
    updateEnquiryFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `requestToReservationFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.requestToReservationFormData.id = "";
      state.requestToReservationFormData.firstName = "";
      state.requestToReservationFormData.lastName = "";
      state.requestToReservationFormData.email = "";
      state.requestToReservationFormData.phoneNo = "";
      state.requestToReservationFormData.arrivalDate = null;
      state.requestToReservationFormData.context = "";
      state.requestToReservationFormData.message = "";
      state.requestToReservationFormData.noDates = true;
      state.requestToReservationFormData.availability = 0;
    },
    setEnquiryID: (state, action: PayloadAction<string>) => {
      state.EnquiryID = action.payload;
    },
    setEnquiryLoading: (state, action: PayloadAction<boolean>) => {
      state.enquiryLoading = action.payload;
    },
    sendEnquiry: (
      state,
      action: PayloadAction<{ retreatId: string; callback: () => void }>
    ) => { },
    setBookingInquiryContextList: (
      state,
      action: PayloadAction<Array<ContextInterface>>
    ) => {
      state.contextList = action.payload;
    },
    setBookingInquiryDetailsList: (
      state,
      action: PayloadAction<Array<PackageDetailsInterface>>
    ) => {
      state.packageDetailsList = action.payload;
    },
    setpackageDetailsListRetreat: (state, action: PayloadAction<any>) => {
      state.packageDetailsListRetreat = action.payload;
    },

    doGetBookingInquiryContextList: (state) => { },
    doGetgetBookingPackageDetailsList: (
      state,
      action: PayloadAction<{
        retreatId: string;
        packageId: string;
        callback: () => void;
      }>
    ) => { },
    doGetviewpackageavailabilityondates: (
      state,
      action: PayloadAction<{
        date: string;
        retreatId: string;
        callback: () => void;
      }>
    ) => { },
    setLoading: (state, action: PayloadAction<boolean>) => {

      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {

      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {

      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggleOpen: (state) => {
      state.openModal = !state.openModal;
    },
    setRequestToReservationList: (
      state,
      action: PayloadAction<Array<RequestToReservationInterface>>
    ) => {
      state.requestToReservationList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
