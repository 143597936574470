export interface AffliateSignUpInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  instagramLink: string;
  websiteLink: string;
  youtubeLink: string;
  password: string;
  termsAndConditions: boolean;
  Errors: Array<string>;
  Success: string;
}
export interface addAffiliateLinkInterface {
  id: string;
  link: string;
  // retreatId: string;
  seoTitle: string;

}
export enum paymentTypeEnum {
  WISE = 1,
  PAYPAL = 2,
}

export interface PaymentFormInterface {
  id: string;
  country: string;
  currency: string;
  accountHolderName: string;
  bankName: string;
  beneficiaryName: string;
  bankAccountNo: string;
  bankId: string;
  payPalEmail: string;
  paymentType: paymentTypeEnum;
  schoolId: string;
}
export interface listAffiliateLinkInterface {
  id: string;
  link: string;
  retreatId: string;
  title: string;
  createdOn: string;
}
export interface affiliateDashboardTopDataInterface {
  ApprovedAmount: number;
  Bookings: number;
  Inquiries: number;
  PendingAmount: number;
  TotalPaidAmount: number;
}
export interface affiliateCommissionsInterface {
  id: string;
  date: string;
  transactionId: string;
  title: string;
  commissions: string;
  status: string;
}
export interface affiliateCalculateInterface {
  id: string;
  numberOfBookings: string;
  averageBookingValue: string;
}
export interface affiliateDashboardInterface {
  id: string;
  commision: number;
  price: number;
  retreatTitle: string;
  retreatBookingId: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}
export interface AffiliateAuthState {
  affliateList: AffliateSignUpInterface[];
  affiateFormData: AffliateSignUpInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  addAffiliateLink: addAffiliateLinkInterface;
  ListAffiliateLink: listAffiliateLinkInterface[];
  affiliateLinkPageNo: number;
  affiliateLinkTotal: number;
  affiliatePaymentPageNo: number;
  affiliatePaymentTotal: number;
  PaymentForm: PaymentFormInterface;
  PaymentList: PaymentFormInterface[];
  affiliateDashboardTopData?: affiliateDashboardTopDataInterface;
  affiliateCommissions: affiliateCommissionsInterface[];
  addAccountModal: boolean;
  addPaypalModal: boolean;
  addWiseModal: boolean;
  calculateForm: affiliateCalculateInterface;
  calculateFormData: affiliateCalculateInterface[];
  affiliateDashboardPageNo: number;
  affiliateDashboardTotalRow: number;
  affiliateDashboardPageSize: number;
  affiliateDashboardDataList: affiliateDashboardInterface[];
}
export type InitialAffiliateAuthState = AffiliateAuthState;
