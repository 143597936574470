import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { actions as actionResponce } from "../../redux/slice";
import {
  selectFaqDragList,
  selectlistingFaqDragFormData,
  selectSchoolListingAutoReplyForm,
  selectSchoolListingCleanSafeMeasuresForm,
  selectSchoolListingFaqForm,
  selectSchoolListingFaqList,
} from "./selector";
import {
  addCleaningAndSafetyMeasures,
  AddListingFaqDragList,
  AddSchoolListingAutoReply,
  AddSchoolListingFaq,
  DeleteListingFaq,
  getAllCeanAndSafetyFeaturesRequest,
  getAllListingFaqListRequest,
  GetAutoReplyDataByIdRequest,
  GetCleanAndSafeDataByIdRequest,
  GetListingFaqByIdRequest,
} from "../../../../../utils/request";
import { actions } from "./slice";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  FaqDragListInterface,
  ListingAutoReplyInterface,
  ListingCleanSafeMeasuresInterface,
  ListingFaqInterface,
} from "../types";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
import { log } from "../../../../../utils/logger";

export function* faqAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ListingFaqInterface = yield select(selectSchoolListingFaqForm);

  if (form.question === "") {
    CustomToast("Please Fill Question", "ERROR");
    return;
  }
  if (form.answer === "") {
    CustomToast("Please Fill Answer", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddSchoolListingFaq, form);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* listingFaqListAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: FaqDragListInterface = yield select(selectlistingFaqDragFormData);

  const FaqLlist: ListingFaqInterface = yield select(
    selectSchoolListingFaqList
  );
  yield put(actions.setfaqLoader(true));
  const data = {
    faqList: FaqLlist,
    id: form.id,
    retreatId: form.retreatId,
  };
  try {
    const response: AxiosResponse = yield call(
      AddListingFaqDragList,
      form.faqList.length >= 1 ? form : data
    );
    yield put(actions.setfaqLoader(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    log(response.data.data, "Add Instructor Certificates ");
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setfaqLoader(false));
    CatchBlockFunction(error);
  }
}
export function* DeleteFaqRequest(action: {
  payload: { id: string; retreatid: string };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      DeleteListingFaq,
      `?faqId=${action.payload.id}&retreatId=${action.payload.retreatid}`
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.doGetListingFaqList(action.payload.retreatid));
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAllSchoolListingFaqListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setfaqLoader(true));
    const response: AxiosResponse = yield call(
      getAllListingFaqListRequest,
      action.payload
    );
    yield put(actions.setfaqLoader(false));
    yield put(actions.SetFaqModalType(response.data.model));
    if (response && !response?.data?.requestFaq) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setlistingFaqListData(response?.data?.requestFaq));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "faq_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setfaqLoader(false));
    CatchBlockFunction(error);
  }
}
export function* GetListingFaqByIdRequestRequest(action: {
  payload: { id: string; retreatid: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetListingFaqByIdRequest,
      `?faqId=${action.payload.id}&retreatId=${action.payload.retreatid}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setFaqForEdit(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* AddlistingAutoReplyAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ListingAutoReplyInterface = yield select(
    selectSchoolListingAutoReplyForm
  );
  if (form.autoReply == true) {
    if (form.autoReplyMessage?.trim() == "") {
      CustomToast("Please Fill Message Auto Reply", "ERROR");
      return;
    }
  }

  if (form.followUp == true) {
    if (form.followUpMessage?.trim() == "") {
      CustomToast("Please Fill Message follow Up", "ERROR");
      return;
    }
    if (form.sendAfter == 0 || form.sendAfter == null) {
      CustomToast("Please Select Send After", "ERROR");
      return;
    }
  }
  yield put(actions.setAutoReplyLoading(true));
  try {
    const response: AxiosResponse = yield call(AddSchoolListingAutoReply, form);
    yield put(actions.setAutoReplyLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setAutoReplyLoading(false));
    CatchBlockFunction(error);
  }
}
export function* AddlistingCleanAndSafeMeasuresRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ListingCleanSafeMeasuresInterface = yield select(
    selectSchoolListingCleanSafeMeasuresForm
  );
  yield put(actions.setcleanLoader(true));
  try {
    const response: AxiosResponse = yield call(
      addCleaningAndSafetyMeasures,
      form
    );
    yield put(actions.setcleanLoader(false));

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setcleanLoader(false));

    CatchBlockFunction(error);
  }
}
export function* getAllCleanAndSafeMeMeasuresListRequest(action: {
  payload: any;
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllCeanAndSafetyFeaturesRequest
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setLocalGuidelinesList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getListingAutoReplyBySchoolIdRequest(action: {
  payload: any;
}) {
  yield delay(500);
  try {
    yield put(actions.setAutoReplyLoading(true));

    const response: AxiosResponse = yield call(
      GetAutoReplyDataByIdRequest,
      action.payload
    );
    yield put(actions.setAutoReplyLoading(false));

    yield put(actions.SetAutoReplyModalType(response.data.model));
    if (response && !response.data.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.SetListingAutoReplyFormDataById(response.data.data[0]));

    yield put(
      actionResponce.updateApiResponceValue({
        key: "autoReply_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setAutoReplyLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getListingAutoReplyByIdRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetAutoReplyDataByIdRequest, "");

    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetListingAutoReplyFormDataById(response.data?.data[0]));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "autoReply_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getListingCleanAndSefetyBySchoolIdRequest(action: {
  payload: any;
}) {
  yield delay(500);
  try {
    yield put(actions.setcleanLoader(true));
    const response: AxiosResponse = yield call(
      GetCleanAndSafeDataByIdRequest,
      action.payload
    );
    yield put(actions.setcleanLoader(false));
    //@ts-ignore
    yield put(actions.SetCleanandSafeModalType(response.data.model));
    if (
      (response && response.data.message == "Data not found") ||
      response.data?.data == ""
    ) {
      return;
    } else {
      yield put(
        actions.SetListingCleanAndSafeMeasuresFormDataById(response.data?.data)
      );
    }
  } catch (error: any) {
    yield put(actions.setcleanLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getListingCleanAndSefetyByIdRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      GetCleanAndSafeDataByIdRequest,
      ""
    );

    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.SetListingCleanAndSafeMeasuresFormDataById(response.data?.data[0])
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* schoolListingsOtherRepoSaga() {
  yield takeLatest(actions.AddFaq, faqAddRequest);
  yield takeLatest(
    actions.doGetListingFaqList,
    getAllSchoolListingFaqListRequest
  );
  yield takeLatest(actions.doGetFaqforEdit, GetListingFaqByIdRequestRequest);
  yield takeLatest(actions.DeleteFaq, DeleteFaqRequest);
  yield takeLatest(actions.AddFaqList, listingFaqListAddRequest);
  yield takeLatest(actions.AddAutoReplyDetails, AddlistingAutoReplyAddRequest);
  yield takeLatest(
    actions.AddCleanAndSafeMeasures,
    AddlistingCleanAndSafeMeasuresRequest
  );
  yield takeLatest(
    actions.doGetAllCleanAndSafeMeasuresList,
    getAllCleanAndSafeMeMeasuresListRequest
  );
  yield takeLatest(
    actions.doGetListingAutoReplyDataByID,
    getListingAutoReplyByIdRequest
  );
  yield takeLatest(
    actions.doGetListingCleanAndSafeMeasuresDataByID,
    getListingCleanAndSefetyByIdRequest
  );
  // get data for school
  yield takeLatest(
    actions.doGetListingAutoReplySchoolDataByID,
    getListingAutoReplyBySchoolIdRequest
  );
  yield takeLatest(
    actions.doGetListingCleanAndSafeMeasuresSchoolDataByID,
    getListingCleanAndSefetyBySchoolIdRequest
  );
}
