import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.ListingFilterState) {
      return state.ListingFilterState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const SelectListingForm = createSelector(
  [selectDomain],
  (state) => state.ListingForm
);
export const SelectSearchLog = createSelector(
  [selectDomain],
  (state) => state.SearchLog
);
export const SelectFoodData = createSelector(
  [selectDomain],
  (state) => state.Fooddata
);
export const SelectActivityData = createSelector(
  [selectDomain],
  (state) => state.ActivityData
);
export const SelectStyleData = createSelector(
  [selectDomain],
  (state) => state.Styledata
);
export const SelectSearchLogSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectskillLevelData = createSelector(
  [selectDomain],
  (state) => state.skillLevel
);

export const Selectfeature = createSelector(
  [selectDomain],
  (state) => state.feature
);
export const selectcancellationPolicy = createSelector(
  [selectDomain],
  (state) => state.cancellationPolicy
);
export const selectProgramDuration = createSelector(
  [selectDomain],
  (state) => state.ProgramDuration
);

export const SelectRetreatStyle = createSelector(
  [selectDomain],
  (state) => state.RetreatList
);
export const selectTotalListing = createSelector(
  [selectDomain],
  (state) => state.totalListing
);
export const selectHandeleChange = createSelector(
  [selectDomain],
  (state) => state.handelChange
);
export const selectListingCountry = createSelector(
  [selectDomain],
  (state) => state.countryList
);
export const selectListingLocation = createSelector(
  [selectDomain],
  (state) => state.location
);
export const selectListingState = createSelector(
  [selectDomain],
  (state) => state.stateList
);
export const selectListingCity = createSelector(
  [selectDomain],
  (state) => state.cityList
);

