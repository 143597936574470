import CustomToast from "../components/UI/CustomToast";

export default function CatchBlockFunction(error: any) {
  if (error.message !== "e is undefined") {
    if (
      error.message !== "undefined is not an object (evaluating 'e.context')"
    ) {
      if (
        error.message !==
        "Cannot read properties of undefined (reading 'context')"
      ) {
        if (
          error.message !== "Cannot read properties of undefined (reading '0')"
        )
          if (
            error.message !==
            "call: argument of type {context, fn} has undefined or null `fn`"
          ) {
            if( error.message !=="Cannot read properties of undefined (reading 'apply')"){
               if (error.message !== "call: argument fn is undefined or null") {
                if (
                  error.message !==
                  "Cannot read properties of undefined (reading 'results')"
                ) {

                  if (
                    error.message !==
                    "undefined is not an object (evaluating 'r.data[0].results')"
                  ) {
                    {
                      if (error.response) {
                        if (error.response.status !== 401) {
                          if (error.response.data.errors) {
                            let dataerrer = error.response.data.errors.map(
                              (item: any) => {
                                return {
                                  name: item.property,
                                  message: item.constraints.matches,
                                };
                              }
                            );
                            for (
                              let index = 0;
                              index < dataerrer.length;
                              index++
                            ) {
                              const element = dataerrer[index];
                              CustomToast(element.message, "ERROR");
                            }
                            let dataerrer1 = error.response.data.errors.map(
                              (item: any) => {
                                return {
                                  name: item.property,
                                  message: item.constraints.isEmail,
                                };
                              }
                            );
                            for (
                              let index = 0;
                              index < dataerrer1.length;
                              index++
                            ) {
                              const element = dataerrer1[index];
                              CustomToast(element.message, "ERROR");
                            }
                            let dataerrer2 = error.response.data.errors.map(
                              (item: any) => {
                                return {
                                  name: item.property,
                                  message: item.constraints.isString,
                                };
                              }
                            );
                            for (
                              let index = 0;
                              index < dataerrer2.length;
                              index++
                            ) {
                              const element = dataerrer2[index];
                              CustomToast(element.message, "ERROR");
                            }
                            let dataerrer3 = error.response.data.errors.map(
                              (item: any) => {
                                return {
                                  name: item.property,
                                  message: item.constraints.isNotEmpty,
                                };
                              }
                            );
                            for (
                              let index = 0;
                              index < dataerrer2.length;
                              index++
                            ) {
                              const element = dataerrer3[index];
                              CustomToast(element.message, "ERROR");
                            }
                          } else {
                            CustomToast(error.response.data.message, "ERROR");
                          }
                        } else {
                          localStorage.removeItem("token");
                        }
                      } else {
                        CustomToast(error.message, "ERROR");
                      }
                    }
                  }
                }
              }
            }
            
             
            
          }
      }
    }
  }
}
