import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  FileUploadItems,
  FileUplodType,
  MediaFormInterface,
  MediaVideoFormInterface,
  SchoolListingMediaState,
} from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: SchoolListingMediaState = {
  MediaImageForm: {
    foodPhotos: [],
    retreatId: "",
    retreatPhotos: [],
    id: "",
    isDraft: false,
    count: 0,
  },
  ImagesModalType: 0,
  VideoModalType: 0,
  MediaVideoForm: {
    foodVideos: [],
    retreatId: "",
    retreatVideos: [],
    id: "",
    isDraft: false,
    count: 0,
  },
  loading: false,
  foodCrop: false,
  retreatsCrop: false,
  imageArray: [],
  RetreatimageArray: [],
  uploadFiles: [],
  uploadingCurrentIndex: -1,
  uploadFileType: FileUplodType.RETREAT,
  Videoloader: false,
};

export const useClinicSlice = createSlice({
  name: "SchoolListingMediaState",
  initialState,
  reducers: {
    setImageArray: (state, action: PayloadAction<any>) => {
      state.imageArray.push(action.payload);
    },
    setUploadingCurrentIndex: (state, action: PayloadAction<number>) => {
      state.uploadingCurrentIndex = action.payload;
    },
    setUploadFileType: (state, action: PayloadAction<FileUplodType>) => {
      state.uploadFileType = action.payload;
    },

    setUploadFiles: (state, action: PayloadAction<FileUploadItems[]>) => {
      state.uploadFiles = action.payload;
    },
    setRetreatImageArray: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.retreatPhotos.push(action.payload);
    },
    SetVideoModalType: (state, action: PayloadAction<number>) => {
      state.VideoModalType = action.payload;
    },
    Setloader: (state, action: PayloadAction<boolean>) => {
      state.Videoloader = action.payload;
    },
    SetImageModalType: (state, action: PayloadAction<number>) => {
      state.ImagesModalType = action.payload;
    },
    doGetMediaDataByID: (state, action: PayloadAction<any>) => {},
    doGetMediaVideoDataByID: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setRetreatPhotoData: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.retreatPhotos.push(action.payload);
    },
    photosData: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.retreatPhotos = action.payload;
    },
    photosFoodData: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.foodPhotos = action.payload;
    },
    VideoFoodData: (state, action: PayloadAction<any>) => {
      state.MediaVideoForm.foodVideos = action.payload;
    },
    VideoRetreatData: (state, action: PayloadAction<any>) => {
      state.MediaVideoForm.retreatVideos = action.payload;
    },
    VideoDraft: (state, action: PayloadAction<boolean>) => {
      state.MediaVideoForm.isDraft = action.payload;
    },
    VideoCount: (state) => {
      state.MediaVideoForm.count = state.MediaVideoForm.count + 1;
    },
    ImageCount: (state) => {
      state.MediaImageForm.count = state.MediaImageForm.count + 1;
    },
    ImageDraft: (state, action: PayloadAction<boolean>) => {
      state.MediaImageForm.isDraft = action.payload;
    },

    setRetreatPhotosFormData: (
      state,
      action: PayloadAction<MediaFormInterface>
    ) => {
      state.MediaImageForm = action.payload;
    },
    setRetreatVideoFormData: (
      state,
      action: PayloadAction<MediaVideoFormInterface>
    ) => {
      state.MediaVideoForm = action.payload;
    },
    setFoodPhotoData: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.foodPhotos.push(action.payload);
    },
    doAddRetreatImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddFoodImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddFoodVideo: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddRetreatVideo: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    AddMedia: (
      state,
      action: PayloadAction<{ isOnlineOffline: any; callback: () => void }>
    ) => {
      log("Your language add");
    },

    AddMediaVideo: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your language add");
    },

    setRetreatVideoData: (state, action: PayloadAction<any>) => {
      state.MediaVideoForm.retreatVideos.push(action.payload);
    },
    setFoodVideoData: (state, action: PayloadAction<any>) => {
      state.MediaVideoForm.foodVideos.push(action.payload);
    },
    deleteFilerinFileArrayData: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.retreatPhotos.splice(action.payload, 1);
    },
    updateListingMediaFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `MediaImageForm.${action.payload.key}`, action.payload.value);
    },
    updateListingMediaVideoFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `MediaVideoForm.${action.payload.key}`, action.payload.value);
    },
    deleteFoodPhotos: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.foodPhotos.splice(action.payload, 1);
    },
    deleteRetreatsPhotos: (state, action: PayloadAction<any>) => {
      state.MediaImageForm.retreatPhotos.splice(action.payload, 1);
    },
    deleteFoodVideosDelete: (state, action: PayloadAction<any>) => {
      state.MediaVideoForm.foodVideos.splice(action.payload, 1);
    },
    deleteRetreatsVideosDelete: (state, action: PayloadAction<number>) => {
      state.MediaVideoForm.retreatVideos.splice(action.payload, 1);
    },
    toggalFoodCropShowModal: (state) => {
      state.foodCrop = !state.foodCrop;
    },
    toggalRetreatPhotosCropShowModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.retreatsCrop = action.payload;
    },
    clearMediaFormData: (state) => {
      state.MediaImageForm.id = "";
      state.MediaImageForm.foodPhotos = [];
      state.MediaImageForm.retreatPhotos = [];
      state.MediaImageForm.isDraft = false;
      state.MediaImageForm.count = null || 0;
      state.imageArray = [];
    },
    clearMediaVideoFormData: (state) => {
      state.MediaVideoForm.id = "";
      state.MediaVideoForm.foodVideos = [];
      state.MediaVideoForm.retreatVideos = [];
      state.MediaVideoForm.isDraft = false;
      state.MediaVideoForm.count = null || 0;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
