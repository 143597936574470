import { call, delay, fork, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsModal } from "../../Listings/redux/slice";
import { actions as modelInstructor } from "../../Listings/ArrivalInformation&Instructor/redux/slice";
import { AxiosResponse } from "axios";
import { actions as actionUploader } from "../../../Frontend/uploader/redux/slice";
import { actions as actionResponce } from "../../Listings/redux/slice";
import {
  certificateMainInterface,
  InstructorListingsInterfaceForm,
  SchoolInstructorsInterface,
  SkillsMainInterface,
} from "../types";
import { END, eventChannel } from "redux-saga";
import {
  selectCertificate,
  selectInstructorList,
  SelectInstructorListForListingList,
  selectInstructorListListing,
  SelectPageNo,
  selectSchoolInstructorsForm,
  SelectSearch,
  selectSkills,
} from "./selector";
import {
  AddInstructorBasicDetails,
  AddInstructorCertificates,
  AddInstructorSkills,
  AddListingInstructorList,
  DeleteSInstructorByIDRequest,
  DeleteSkillsRequest,
  getALLInstrutorBYSchoolID,
  GetAssociationRequest,
  GetCertificateLavelRequest,
  getInstructorRequestCertificateRequest,
  getInstructorRole,
  getInstructorSkillsRequest,
  getInstructoryIdRequest,
  GetListingInstructorByIdRequest,
  GetSkillsRequest,
  SendInstructorMobOtp,
  UpdateInstructorDetailRequest,
  uplodeInstructorsImageRequest,
  VerifyInstructorMobOtp,
} from "../../../../utils/request";
import CustomToast from "../../../../components/UI/CustomToast";
import { watchOnProgress } from "../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";

export function* getRolesListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    //
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getInstructorRole);

    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setRolesList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCertificateIDRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getInstructorRequestCertificateRequest,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setInstructorCertificateList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* InstructorsBasicDetailsAddRequest(action: {
  payload: { schoolId: string; callback: any };
}) {
  yield delay(500);
  const form: SchoolInstructorsInterface = yield select(
    selectSchoolInstructorsForm
  );
  if (form.profilePic == "") {
    CustomToast("Please Select profile Pic", "ERROR");
    return;
  }
  if (form.jobrole.length == 0) {
    CustomToast("Please Select Job Role", "ERROR");
    return;
  }
  if (form.biography.length == 0) {
    CustomToast("Please fill Biography", "ERROR");
    return;
  }
  if (form.fullName == "") {
    CustomToast("Please fill Full Name", "ERROR");
    return;
  }
  let emailregex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (form.email.trim().length > 0) {
    if (!emailregex.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }
  }
  let regex = /^[a-z][a-z0-9\.,\-_]{5,31}$/;
  if (form.skypeId.trim().length > 0) {
    if (!regex.test(form.skypeId)) {
      CustomToast("Enter Valid Skype ID", "ERROR");
      return;
    }
  }
  const data = {
    biography: form.biography,
    email: form.email,
    fullName: form.fullName,
    gender: form.gender,
    id: form.id,
    jobrole: form.jobrole,
    phoneno: form.phoneno,
    profilePic: form.profilePic,
    skypeId: form.skypeId,
    schoolId: action.payload.schoolId,
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(AddInstructorBasicDetails, data);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(actions.SetInstructorID(response.data.data));
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* AddSkills(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* InstructorCertificatesAddRequest(action: {
  payload: { schoolId: string; callback: any };
}) {
  yield delay(500);
  const skills: SkillsMainInterface = yield select(selectSkills);
  const form: certificateMainInterface = yield select(selectCertificate);
  const data = {
    certificate: form.CertificatesModalFormList,
    skills: skills.SkillsModalList,
    id: form.id,
    schoolId: action.payload.schoolId,
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(AddInstructorCertificates, data);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    log(response.data.data, "Add Instructor Certificates ");
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAssociationListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetAssociationRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setAssociationList(response.data));
  } catch (error: any) {
    if (error.response) {
      if (error.response.status !== 401) {
        if (error.response.data.valData) {
          let dataerrer = error.response.data.valData.errors.map(
            (item: any) => {
              return { name: item.param, message: item.msg };
            }
          );
          for (let index = 0; index < dataerrer.length; index++) {
            // const element = dataerrer[index];
            // toast.error(element.message);
          }
        } else {
          // toast.error(error.response.data.message);
        }
      } else {
        yield put(actions.setLoading(false));
        localStorage.removeItem("token");
      }
    } else {
      // toast.error(error.message);
    }
  }
}

export function* getInstructorRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetAssociationRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setAssociationList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCertificateLavelListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetCertificateLavelRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setCertificateLavelList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getSkillsListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetSkillsRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSkillsList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateHelpRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: SchoolInstructorsInterface = yield select(
    selectSchoolInstructorsForm
  );
}
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadPromise = uplodeInstructorsImageRequest(
    payload,
    (progressEvent: any) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted == 100) emit(END);
      else emit(percentCompleted);
    }
  );

  return [uploadPromise, chan];
}
export function* InstructorsFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.updateSchoolInstructorsFormValue({
        key: "profilePic",
        value: response.data.data.name,
      })
    );
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* InstructorsCertificateFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  log(action.payload.data, "SUCCESS");

  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield put(
      actions.updateCertificatesModalFormValue({
        key: "certificateFile",
        value: response.data.data.name,
      })
    );
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* deleteSkills(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    CustomToast("Please Select Skill", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteSkillsRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.doGetSkillsList(action.payload));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeleteInstructorOfTheSchool(action: {
  payload: { id: string; callback: () => void };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    CustomToast("Please Select Instructor", "ERROR");
    return;
  }
  try {
    
    const response: AxiosResponse = yield call(
      DeleteSInstructorByIDRequest,
      action.payload.id
    );

    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getSkillsIDRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getInstructorSkillsRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setInstructorSkillsList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getInstructorBySchoolIDRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    yield put(actions.setLoader(true));
    yield put(actions.setListingLoader(true));
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getALLInstrutorBYSchoolID,
      `?schoolId=${action.payload}&pageNo=${PageNo}&pageSize=${10}`
    );
    yield put(actions.setListingLoader(false));
    yield put(actions.setLoader(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalInstructor(response.data[0].meta.total));
    yield put(
      actions.setInstructorListOfThePerticulerSchool(response.data[0].results)
    );
  } catch (error: any) {
    yield put(actions.setListingLoader(false));
    yield put(actions.setLoading(false));
    yield put(actions.setLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getInstructorByidRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getInstructoryIdRequest,
      action.payload
    );

    if (response && !response.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setInstructorForEdit(response.data[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateData(action: { payload: any }) {
  yield delay(500);
  const form: SchoolInstructorsInterface = yield select(
    selectSchoolInstructorsForm
  );
  if (form.profilePic == "") {
    CustomToast("Please Select profile Pic", "ERROR");
    return;
  }
  if (form.fullName == "") {
    CustomToast("Please fill Full Name", "ERROR");
    return;
  }
  if (form.phoneno == null) {
    CustomToast("Please fill Instructor Phone Number", "ERROR");
    return;
  }

  if (form.skypeId.length < 0) {
    CustomToast("Please fill Instructor Skyup ID", "ERROR");
    return;
  }
  let regex = /^[a-z][a-z0-9\.,\-_]{5,31}$/;

  if (form.skypeId.trim().length > 0) {
    if (!regex.test(form.skypeId)) {
      CustomToast("Enter Valid Skype ID", "ERROR");
      return;
    }
  }
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      UpdateInstructorDetailRequest,
      form
    ); yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* AddInstructorFormListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: InstructorListingsInterfaceForm = yield select(
    selectInstructorList
  );
  yield put(actions.setListingLoader(true));
  try {
    const response: AxiosResponse = yield call(AddListingInstructorList, form);
    yield put(actions.setListingLoader(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setListingLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getinstructorlistdragRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    yield put(actions.setListingLoader(true));

    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetListingInstructorByIdRequest,
      `?retreatId=${action.payload}&pageNo=${PageNo}&pageSize=10`
    );
    yield put(actions.setListingLoader(false));
    if (response && !response.data) {
      return;
    }
    yield put(modelInstructor.SetInstructorModalType(response.data.model));
    yield put(actions.InstructorListingSelected(response.data.data));
    yield put(actions.InstructorReqListingSelected(response.data.data));

    yield put(
      actionResponce.updateApiResponceValue({
        key: "instructor_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setListingLoader(false));
    CatchBlockFunction(error);
  }
}
export function* OTPMobileVerificationRequest(action: {
  payload: { OTP: string; callback: () => void };
}) {
  yield delay(500);
  try {
    const form: SchoolInstructorsInterface = yield select(
      selectSchoolInstructorsForm
    );
    let data = {
      code: action.payload.OTP,
      token: form.token,
    };
    const response: AxiosResponse = yield call(VerifyInstructorMobOtp, data);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setInstructorOtpStatus(response.data.status));
    yield put(actions.setShowOTPField(false));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddMobileNumberForverification(action: {
  payload: { OTPTYPE: number; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setShowOTPField(true));
    const form: SchoolInstructorsInterface = yield select(
      selectSchoolInstructorsForm
    );
    let data = {
      email: form.email,
      name: form.fullName,
      phoneNo: form.phoneno,
      otpType: action.payload.OTPTYPE,
    };
    const response: AxiosResponse = yield call(SendInstructorMobOtp, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setInstructorOtpToken(response.data.token));
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setShowOTPField(true));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* schoolInstructorsRepoSaga() {
  yield takeLatest(actions.doGetRolesList, getRolesListRequest);
  yield takeLatest(
    actions.AddInstructorsBasicDetails,
    InstructorsBasicDetailsAddRequest
  );
  yield takeLatest(
    actions.AddInstructorsCertificates,
    InstructorCertificatesAddRequest
  );
  yield takeLatest(actions.updateLanguageData, updateHelpRequest);
  yield takeLatest(actions.doGetAssociationList, getAssociationListRequest);
  yield takeLatest(
    actions.doGetCertificateLevelList,
    getCertificateLavelListRequest
  );
  yield takeLatest(actions.doGetAllSkilsList, getSkillsListRequest);
  yield takeLatest(actions.doAddInstructorsImage, InstructorsFileUploadRequest);
  yield takeLatest(
    actions.doAddInstructorsCertificateImage,
    InstructorsCertificateFileUploadRequest
  );
  yield takeLatest(actions.DeleteSkiilsSchool, deleteSkills);
  yield takeLatest(actions.AddInstructorsSkills, AddSkills);
  yield takeLatest(actions.doGetSkillsList, getSkillsIDRequest);
  yield takeLatest(actions.doGetInstructorList, getInstructorRequest);
  yield takeLatest(
    actions.doGetInstructorListBySchoolIDList,
    getInstructorBySchoolIDRequest
  );
  yield takeLatest(
    actions.DeleteInstructorOfTheSchool,
    DeleteInstructorOfTheSchool
  );
  yield takeLatest(
    actions.doGetInstructorListByIDList,
    getInstructorByidRequest
  );
  yield takeLatest(actions.updateDataHelp, updateData);
  yield takeLatest(actions.doGetCertificateList, getCertificateIDRequest);

  // .............Listing instructor ...................
  yield takeLatest(actions.AddInstructorFormList, AddInstructorFormListRequest);
  yield takeLatest(
    actions.dogetinstructorlistdrag,
    getinstructorlistdragRequest
  );
  yield takeLatest(actions.doVerifiedMobileNo, AddMobileNumberForverification);
  yield takeLatest(actions.doVerifiedOTP, OTPMobileVerificationRequest);
}
