import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  AddCalendarData,
  GetCalendarDataRequest,
} from "../../../../../utils/request";
import { actions as actionResponce } from "../../redux/slice";
import { ISavedDateRange } from "../types";
import { SelectSavedRange } from "./selector";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
export function* getCalendarFormDataRequest(action: {
  payload: { packageId: string; retreatId: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setloading(true));
    const response: AxiosResponse = yield call(
      GetCalendarDataRequest,
      action.payload.packageId,
      action.payload.retreatId
    );
    yield put(actions.setloading(false));
    if (response && !response.data.status) {
      yield put(actions.SetListingCalendarFormDataById([]));
      // CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.SetListingCalendarFormDataById(response.data.calendar));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "calender_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewCalendarRequest() {
  yield delay(500);
  const form: ISavedDateRange = yield select(SelectSavedRange);
  const data = { data: form };
  try {
    const response: AxiosResponse = yield call(AddCalendarData, data);
    yield put(actions.toggelLoading());
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    
  } catch (error: any) {
    yield put(actions.toggelLoading());
    CatchBlockFunction(error);
  }
}
export function* RepoSagaSchoolListingsCalender() {
  yield takeLatest(
    actions.doGetCalendarFormDataByID,
    getCalendarFormDataRequest
  );
  yield takeLatest(actions.AddNewCalender, AddNewCalendarRequest);
}
