import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { SelectPageNo, selectCustomerReviewForm ,SelectPageSize } from "./selector";
import { CustomerReviewInterface } from "../types";
import CustomToast from "../../../components/UI/CustomToast";
import { CustomerReviewAddRequest, DeleteFakeReview  ,getAllFakeReviewList, getAllRetreatList, getAllSchoolList, getAllUserList} from "../../../utils/request";
import CatchBlockFunction from "../../../hooks/CatchBlockFunction";


export function* CustomerReviewAddDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: CustomerReviewInterface = yield select(selectCustomerReviewForm);
  if (form.userId.trim() == "") {
    CustomToast("Please Select User", "ERROR");
    return;
  }
  if (form.schoolId.trim() == "") {
    CustomToast("Please Select School", "ERROR");
    return;
  }
  if (form.retreatId.trim() == "") {
    CustomToast("Please Select Listing", "ERROR");
    return;
  }
   if (form.likes.trim() == "") {
    CustomToast("Please Enter Likes", "ERROR");
    return;
  }
  if (form.dislikes.trim() == "") {
    CustomToast("Please Enter Dislike", "ERROR");
    return;
  }

  if (form.date.trim() == "") {
    CustomToast("Please Enter Review date", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(CustomerReviewAddRequest, form);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteReviewRequest(action: { payload: {id:string, callback: any} }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      DeleteFakeReview,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.doGetReviewList());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getReviewListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);

    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllFakeReviewList,
      `?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0
      )
    );
    yield put(actions.setCustomerReviewList(response.data.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getSchoolListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getAllSchoolList,
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setSchoolList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getUserListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getAllUserList,
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUserList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getAllRetreatList,
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setRetreatList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FakeCustomerReviewRepoSaga() {
  yield takeLatest(actions.sendReview, CustomerReviewAddDataRequest);
  yield takeLatest(actions.deleteReview, deleteReviewRequest);
  yield takeLatest(actions.doGetReviewList, getReviewListRequest);
  yield takeLatest(actions.doGetRetreatList, getRetreatListRequest);
  yield takeLatest(actions.doGetSchoolList, getSchoolListRequest);
  yield takeLatest(actions.doGetUserList, getUserListRequest);
}
