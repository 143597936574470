import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminRetreatsState,
  ApproveRetreatsInterface,
  PendingRetreatsInterface,
  RejectedRetreatsInterface,
  requestToPublishRequestInterFace,
  RequestTypeEnum,
} from "../types";
import { log } from "../../../../utils/logger";

export const initialState: AdminRetreatsState = {
  loading: false,
  CategoryFilter: [],
  pageSize: 15,
  totalRow: 0,
  search: "",
  pageNo: 1,
  totalList: 0,
  commission: 0,
  ApprovePendingRequest: {
    retreatId: "",
    status: 2,
  },
  ApproveRetreatsList: [],
  PendingRetreatsList: [],
  RejectedRetreatsList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  RequestToPublishForm: {
    retreatId: "",
    status: 4,
    requestStatusFlag: false,
  },
  RequestToAdminPendingForm: {
    retreatId: "",
    status: 1,
  },
  RequestToCountForm: {
    retreatId: "",
    status: 0,
    approvedRequestCount: 0,
    countRequest: 0,
    seoTitle: "",
    seoActivityTitle: ""
  },
  statusFilter: { retreatId: "", status: RequestTypeEnum.ALL },
  featuredChangeForm: {
    id: "",
    featured: false,
  },
  featuredChangeList: [],
  RejectedPendingRequest: {
    retreatId: "",
    status: 3,
  },
  schoolFilter: [],
  activityFilter: 2,
  rankingFilter: 0,
  requestToDeactiveForm: {
    retreatId: "",
    isActive: false,
    deActivationReason: "",
  },
  countryFilter: undefined,
  sortColumn: "",
  sortDir: "",
  sortColumnPending: "",
  sortDirPending: "",
  sortColumnRejected: "",
  sortDirRejected: "",
  requestToPublishLoader: false,
  handleRequestToEdit: false,
  requestToActive: false,
  requestToDeactive: false
};

export const useClinicSlice = createSlice({
  name: "adminRetreats",
  initialState,
  reducers: {
    doGetApproveRetreatsList: (state) => { },
    doGetPendingRetreatsList: (state) => { },
    doGetRejectedRetreatsList: (state) => { },
    setApproveRetreatsList: (
      state,
      action: PayloadAction<Array<ApproveRetreatsInterface>>
    ) => {
      state.ApproveRetreatsList = action.payload;
    },
    clearFilter: (state) => {
      state.rankingFilter = 0;
    },
    setPendingRetreatsList: (
      state,
      action: PayloadAction<Array<PendingRetreatsInterface>>
    ) => {
      state.PendingRetreatsList = action.payload;
    },
    setRejectedRetreatsList: (
      state,
      action: PayloadAction<Array<RejectedRetreatsInterface>>
    ) => {
      state.RejectedRetreatsList = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    doGetIsActive: (
      state,
      action: PayloadAction<{
        id: string;
        isActive: boolean;
        callback: () => void;
      }>
    ) => { },
    doGetIsDeactive: (
      state,
      action: PayloadAction<{
        id: string;
        isActive: boolean;
        callback: () => void;
      }>
    ) => { },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRequestToPublishLoader: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.requestToPublishLoader = action.payload;
    },
    setRequestToEdit: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.handleRequestToEdit = action.payload;
    },
    setRequestToActive: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.requestToActive = action.payload;
    },
    setRequestToDeactive: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.requestToDeactive = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    DeletePending: (state, action: PayloadAction<any>) => { },
    DeleteApprove: (state, action: PayloadAction<any>) => { },

    ApprovePendingRetreat: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => { },
    RejectedPendingRetreat: (state, action: PayloadAction<any>) => { },
    FeaturedChangeRetreatsFormValue: (
      state,
      action: PayloadAction<{
        id: string;
        featured: boolean;
        callback: () => void;
      }>
    ) => { },
    doGetAllRetreatsList: (state, action: PayloadAction<any>) => { },
    doGetApprovedRetreatsList: (state, action: PayloadAction<any>) => { },
    doGetNewRejectedRetreatsList: (state, action: PayloadAction<any>) => { },
    RequestToPublishRetreat: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => { },
    RequestToDeactiveRetreat: (state, action: PayloadAction<any>) => { },
    updatePendingRequestApproveValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `ApprovePendingRequest.${action.payload.key}`,
        action.payload.value
      );
    },
    updateRequestRejectedValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `RejectedPendingRequest.${action.payload.key}`,
        action.payload.value
      );
    },
    updateAdminPendingValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `RejectedPendingRequest.${action.payload.key}`,
        action.payload.value
      );
    },
    updateApproveRequestFeaturedValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `featuredChangeForm.${action.payload.key}`,
        action.payload.value
      );
    },

    updateRequestToPublishValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `RequestToPublishForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateRequestToAdminPendingValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `RequestToAdminPendingForm.${action.payload.key}`,
        action.payload.value
      );
    },
    SetRetreatsStatusFormData: (
      state,
      action: PayloadAction<requestToPublishRequestInterFace>
    ) => {
      state.RequestToPublishForm = action.payload;
    },

    SetRetreatsStatusCountFormData: (state, action: PayloadAction<any>) => {
      state.RequestToCountForm = action.payload;
    },
    doGetRetreatsStatus: (state, action: PayloadAction<any>) => { },
    doGetRetreatsStatusCount: (state, action: PayloadAction<any>) => { },

    doRetreatRequestToPublishEdit: (
      state,
      action: PayloadAction<{ retreat_id: any; callback: () => void }>
    ) => { },

    updatestatusFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `statusFilter.${action.payload.key}`, action.payload.value);
    },
    updatestatusToDeactiveFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `requestToDeactiveForm.${action.payload.key}`,
        action.payload.value
      );
    },

    updateFeaturedChangeRetreatsFormValue: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => { },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalList = action.payload;
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.schoolFilter = action.payload;
    },
    countryFilter: (state, action: PayloadAction<any>) => {
      state.countryFilter = action.payload;
    },
    CategoryFilter: (state, action: PayloadAction<any>) => {
      state.CategoryFilter = action.payload;
    },
    CommisshionFilter: (state, action: PayloadAction<number>) => {
      state.rankingFilter = action.payload;
    },
    deleteAdminRetreats: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => { },
    updateActivityFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `activityFilter`, action.payload.value);
    },
    updateRankingFiltersFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `rankingFilter.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setPendingSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnPending = action.payload;
    },
    setPendingSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirPending = action.payload;
    },
    setRejectedSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnRejected = action.payload;
    },
    setRejectedSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirRejected = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
