import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminEmailNewsLetterInterface,
  AdminEmailNewsLetterState,
} from "../types";

export const initialState: AdminEmailNewsLetterState = {
  adminEmailNewsLetterList: [],
  editIndex: 0,
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 15,
  totalRow: 0,
  search: "",
  date: null,
  dissableCheck: false,
  deleteModal: false,
  totalList: 0,
  createdOnFilter: 0,
  exportFile: "",
  sortColumn: "",
  sortDir: "",
};

export const useClinicSlice = createSlice({
  name: "adminEmailNewsLetter",
  initialState,
  reducers: {
    setSreatedOnFilter: (state, action: PayloadAction<any>) => {
      state.createdOnFilter = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    setEmailNewsLetterList: (
      state,
      action: PayloadAction<Array<AdminEmailNewsLetterInterface>>
    ) => {
      state.adminEmailNewsLetterList = action.payload;
    },
    doGetEmailNewsLetterList: (state) => {},
    deleteEmailNewsLetter: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setexportFile: (state, action: PayloadAction<any>) => {
      state.exportFile = action.payload;
    },
    doExportList: (state) => {},
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalList = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
