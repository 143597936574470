import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolInstructors) {
      return state.schoolInstructors;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const selectInstructorListListing = createSelector(
  [selectDomain],
  (state) => state.InstructorListForListing.instructorList
);
export const selectInstructorList = createSelector(
  [selectDomain],
  (state) => state.InstructorListForListing
);
export const SelectInstructorDraft = createSelector(
  [selectDomain],
  (state) => state.isDraft
);
export const selectSchoolInstructorsForm = createSelector(
  [selectDomain],
  (state) => state.SchoolFormData
);
export const selectSchoolInstructorsListData = createSelector(
  [selectDomain],
  (state) => state.SchoolList
);
export const selectInstructorOfTheSchoolListData = createSelector(
  [selectDomain],
  (state) => state.InstructorListForSchool
);
export const selectInstructorSkills = createSelector(
  [selectDomain],
  (state) => state.InstructorSkills
);
export const selectSkillsForm = createSelector(
  [selectDomain],
  (state) => state.skills.SkillsModalForm
);
export const selectSkillsList = createSelector(
  [selectDomain],
  (state) => state.skills.SkillsModalList
);
export const selectSkills = createSelector(
  [selectDomain],
  (state) => state.skills
);
export const selectCertificatesModalForm = createSelector(
  [selectDomain],
  (state) => state.certificate.CertificatesModalForm
);
export const selectCertificateModalList = createSelector(
  [selectDomain],
  (state) => state.certificate.CertificatesModalFormList
);
export const selectCertificate = createSelector(
  [selectDomain],
  (state) => state.certificate
);

export const selectALlSkillsList = createSelector(
  [selectDomain],
  (state) => state.ALLSkillsList
);
export const SelectCertificateList = createSelector(
  [selectDomain],
  (state) => state.certificateList
);
export const SelectCertificateForm = createSelector(
  [selectDomain],
  (state) => state.certificateFormData
);
export const SelectInstructorID = createSelector(
  [selectDomain],
  (state) => state.instructorId
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectAssociationList = createSelector(
  [selectDomain],
  (state) => state.Association
);
export const selectCertificateLavelList = createSelector(
  [selectDomain],
  (state) => state.CertificateLevel
);
export const selectCertificateModalShowValue = createSelector(
  [selectDomain],
  (state) => state.CertificateModal
);
export const selectAllRolesListData = createSelector(
  [selectDomain],
  (state) => state.allRolesList
);
export const selectCertificateEditIndex = createSelector(
  [selectDomain],
  (state) => state.certificateEditIndex
);
export const selectSkillEditIndex = createSelector(
  [selectDomain],
  (state) => state.skillEditIndex
);
export const SelectTotalInstructor = createSelector(
  [selectDomain],
  (state) => state.totalInstructor
);
export const SelectInstructorListForListingList = createSelector(
  [selectDomain],
  (state) => state.InstructorListForListingList
);
export const SelectInstructorListingSelectedList = createSelector(
  [selectDomain],
  (state) => state.InstructorListingSelectedList
);

export const SelectSelctedInstructroListId = createSelector(
  [selectDomain],
  (state) => state.SelctedInstructroListId
);
export const Selectprogress = createSelector(
  [selectDomain],
  (state) => state.progress
);
export const SelectshowOTPField = createSelector(
  [selectDomain],
  (state) => state.showOTPField
);
export const SelectOTP = createSelector([selectDomain], (state) => state.OTP);
export const SelectOtpStatus = createSelector(
  [selectDomain],
  (state) => state.otpStatus
);
export const selectvisitTab = createSelector(
  [selectDomain],
  (state) => state.visitTab
);
export const selectInstructorReqListForListingList = createSelector(
  [selectDomain],
  (state) => state.InstructorReqListForListingList
);

export const selectListnigInstructorLoader = createSelector(
  [selectDomain],
  (state) => state.ListingLoader
);
export const selectloader = createSelector(
  [selectDomain],
  (state) => state.loader
);
