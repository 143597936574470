import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import { boolean } from "yup";
import { string } from "yup/lib/locale";
import {
  AdminSchoolState,
  CertiifcateModal,
  EmailVerificationInterface,
  notificationMethodEnum,
  notificationTypeEnum,
  profileTypeEnum,
  RegistrationModal,
  SchoolCertiifcateList,
  SchoolInterface,
  SchoolRegistrationList,
} from "../types";
import { type } from "os";
import { CountryCode } from "postcode-validator";
import { log } from "../../../../utils/logger";
export const initialState: AdminSchoolState = {
  loading: false,
  pageSize: 15,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editModal: false,
  schoolEditTab: "basicdetails",
  SchoolList: [],
  RegistrationList: [],
  SchoolRegistration: [],
  Registration: {
    registrationNo: "",
    registrationFile: [],
    registrationExpiryDate: "",
    registrationVerify: false,
    ref_id: "",
    registrationId: "",
  },
  SchoolCertificate: [],
  SchoolFormData: {
    notificationEmailCheck: "",
    licenseVerify: false,
    id: "",
    registrationExpiryDate: "",
    businessName: "",
    profilePic: "",
    featuredPhoto: "",
    profileType: 1,
    description: "",
    webUrl: "",
    houseNumber: "",
    state: "",
    city: "",
    postalCode: "",
    country: "",
    sameAsContact: false,
    firstName: "",
    lastName: "",
    middleName: "",
    ownerEmail: "",
    ownerDob: "",
    ownerPhoneno: "",
    language: "English",
    notificationMethod: notificationMethodEnum.OFF,
    phoneNotificationType: [],
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonEmail: "",
    contactPersonPhoneno: "",
    createdAt: "",
    updatedAt: "",
    status: "0",
    notificationEmail: "",
    emailNotificationType: false,
    notificationPhoneNo: "",
    emailVerified: false,
    phonenoVerified: false,
    registrationNo: "",
    Verificationtoken: "",
    contactPersonPhonenoVerify: false,
    ownerPhonenoVerify: false,
    notificationEmailVerify: false,
    notificationPhoneNoVerify: false,
    contactPersonEmailVerify: false,
    ownerEmailVerify: false,
    countryCode: CountryCode.IN,
    countryName: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerMiddleName: "",
    email: "",
    contactEmailToken: "",
    ownerEmailToken: "",
    notificationEmailToken: "",
    contactEmailCheck: "",
    ownerEmailCheck: "",
    isContactPersonePhoneNoValid: false,
    isOwnerPhoneNoValid: false,
    isnotificationPhoneNoValid: false,
    contactPhoneNoCheck: "",
    ownerPhoneNoCheck: "",
    NotificationPhoneNoCheck: "",
    verified: "",
  },
  certificate: {
    certificateVerify: false,
    certificateAssociate: "",
    certificateFile: [],
    certificateNo: "",
    expireDate: "",
    ref_id: "",
    type: 1,
    id: "",
  },
  certificateList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  token: "",
  SchoolID: "",
  featuredPhotoShow: false,
  profilePhotoShow: false,
  statusFilter: 2,
  profileTypeFilter: 3,
  createdOnFilter: 0,
  date: null,
  OTP: "",
  showOTPField: false,
  emailVerification: {
    data: "",
    message: "",
    status: false,
  },
  StatusChangeForm: {
    id: "",
    status: false,
  },
  sortColumn: "",
  sortDir: "",
  isDraftModal: {
    isDraftBasicDetail: false,
    isDraftRegistrationAddress: false,
    isDraftContactDetail: false,
    isDraftBusinessOwnerDetail: false,
    isDraftLicencesDetail: false,
    isDraftNotification: false,
  },
  isEmailLoading: false,
};

export const useClinicSlice = createSlice({
  name: "adminSchool",
  initialState,
  reducers: {
    doVerifiedNotificationMobileNo: (state) => {},
    doVerifiedNotificationOTP: (
      state,
      action: PayloadAction<{ OTP: string; callback: () => void }>
    ) => {},
    doVerifiedMobileNo: (
      state,
      action: PayloadAction<{ OTPTYPE: number; callback: () => void }>
    ) => {},
    doVerifiedBookingMobileNo: (
      state,
      action: PayloadAction<{ OTPTYPE: number; callback: () => void }>
    ) => {},
    doVerifiedEmailAddress: (
      state,
      action: PayloadAction<{
        email: string;
        type: number;
        callback: () => void;
      }>
    ) => {},
    statusChangeFormValue: (
      state,
      action: PayloadAction<{
        id: string;
        status: boolean;
        callback: () => void;
      }>
    ) => {},
    doVerifiedOTP: (
      state,
      action: PayloadAction<{
        OTP: string;
        inquiryId: string;
        callback: () => void;
      }>
    ) => {},
    doVerifiedEmailIndex: (
      state,
      action: PayloadAction<{ token: string; callback: () => void }>
    ) => {},
    doVerifiedContactPersonMobileNo: (
      state,
      action: PayloadAction<{ OTPTYPE: number; callback: () => void }>
    ) => {},
    doVerifiedContactPersonOTP: (
      state,
      action: PayloadAction<{ OTP: string; callback: () => void }>
    ) => {},
    setShowOTPField: (state, action: PayloadAction<boolean>) => {
      state.showOTPField = action.payload;
    },
    updateData: (state, action: PayloadAction<{ key: string; value: any }>) => {
      // state.SchoolList[action.payload.id] = action.payload.value;
    },
    setOTP: (state, action: PayloadAction<any>) => {
      state.OTP = action.payload;
    },
    updateSchoolFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `SchoolFormData.${action.payload.key}`, action.payload.value);
    },
    updateCertificateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `certificate.${action.payload.key}`, action.payload.value);
    },
    updateRegistrationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `Registration.${action.payload.key}`, action.payload.value);
    },
    updateregistrationVerifyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.RegistrationList.map((data: RegistrationModal) => {
        data.registrationVerify = action.payload.value;
      });
    },
    updateCertificateVerifyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.certificateList.map((data: CertiifcateModal) => {
        data.certificateVerify = action.payload.value;
      });
    },
    toggalSchoolEditModal: (state) => {
      state.editModal = !state.editModal;
    },
    setSchoolEditTab: (state, action: PayloadAction<string>) => {
      state.schoolEditTab = action.payload;
    },
    AddNewAdminSchool: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.SchoolList.push(state.SchoolFormData);
    },
    clearOtPField: (state) => {
      state.OTP = "";
    },
    clearFrom: (state) => {
      state.SchoolFormData.id = "";
      state.SchoolFormData.businessName = "";
      state.SchoolFormData.profilePic = "";
      state.SchoolFormData.featuredPhoto = "";
      state.SchoolFormData.profileType = profileTypeEnum.All;
      state.SchoolFormData.description = "";
      state.SchoolFormData.webUrl = "";
      state.SchoolFormData.licenseVerify = false;
      state.SchoolFormData.houseNumber = "";
      state.SchoolFormData.state = "";
      state.SchoolFormData.city = "";
      state.SchoolFormData.postalCode = "";
      state.SchoolFormData.country = "";
      state.SchoolFormData.firstName = "";
      state.SchoolFormData.lastName = "";
      state.SchoolFormData.middleName = "";
      state.SchoolFormData.ownerEmail = "";
      state.SchoolFormData.ownerDob = "";
      state.SchoolFormData.ownerPhoneno = "";
      state.SchoolFormData.language = "";
      state.SchoolFormData.notificationMethod = notificationMethodEnum.OFF;
      state.SchoolFormData.phoneNotificationType = [];
      state.SchoolFormData.contactPersonFirstName = "";
      state.SchoolFormData.contactPersonLastName = "";
      state.SchoolFormData.contactPersonEmail = "";
      state.SchoolFormData.contactPersonPhoneno = "";
      state.SchoolFormData.createdAt = "";
      state.SchoolFormData.updatedAt = "";
      state.SchoolFormData.status = "";
      state.SchoolFormData.notificationEmail = "";
      state.SchoolFormData.notificationPhoneNo = "";
      state.SchoolFormData.emailVerified = false;
      state.SchoolFormData.phonenoVerified = false;
      state.SchoolFormData.registrationNo = "";
    },

    ClearRegistrationForm: (state) => {
      state.Registration.registrationNo = "";
      state.Registration.registrationFile = [];
      state.Registration.registrationExpiryDate = "";
      state.Registration.registrationId = "";
    },
    ClearCertificateForm: (state) => {
      state.certificate.certificateAssociate = "";
      state.certificate.certificateNo = "";
      state.certificate.certificateFile = [];
      state.certificate.expireDate = "";
    },

    DeleteAdminSchool: (state, action: PayloadAction<any>) => {
      // state.SchoolList.splice(action.payload, 1)
    },
    doAddListOfSelectedCheckBox: (state, action: PayloadAction<any>) => {
      state.SchoolFormData.phoneNotificationType.push(action.payload);
    },
    DeleteCleanandSafetyMeasureList: (state, action: PayloadAction<any>) => {
      state.SchoolFormData.phoneNotificationType.splice(
        state.SchoolFormData.phoneNotificationType.findIndex(
          (obj) => obj === action.payload
        ),
        1
      );
    },
    updatestatusValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `StatusChangeForm.${action.payload.key}`,
        action.payload.value
      );
    },
    EditAdminSchoolForm: (state, action: PayloadAction<SchoolInterface>) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setEmailVarifiedLoading: (state, action: PayloadAction<boolean>) => {
      state.isEmailLoading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSchoolId: (state, action: PayloadAction<string>) => {
      state.SchoolID = action.payload;
    },
    getSchoolProfileForAdmin: (
      state,
      action: PayloadAction<{ id: any; isUpdateProfile: boolean }>
    ) => {},
    getSchoolProfileForSchool: (
      state,
      action: PayloadAction<{ id: any; isUpdateProfile: boolean }>
    ) => {},
    doGetSchoolPartnerList: (state) => {},
    doGetCertificateList: (state, action: PayloadAction<any>) => {},
    doGetRegistrationList: (state, action: PayloadAction<any>) => {},
    doGetActiveSchoolPartnerList: (state) => {},
    setSchoolList: (state, action: PayloadAction<Array<SchoolInterface>>) => {
      state.SchoolList = action.payload;
    },
    setSchoolCertificateList: (
      state,
      action: PayloadAction<Array<CertiifcateModal>>
    ) => {
      state.certificateList = action.payload;
    },
    setSchoolRegistrationList: (
      state,
      action: PayloadAction<Array<SchoolRegistrationList>>
    ) => {
      state.RegistrationList = action.payload;
    },
    setFileData: (state, action: PayloadAction<string>) => {
      state.Registration.registrationFile.push(action.payload);
    },
    setLicensesFileData: (state, action: PayloadAction<string>) => {
      state.certificate.certificateFile.push(action.payload);
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSchoolForEdit: (state, action: PayloadAction<SchoolInterface>) => {
      state.SchoolFormData = action.payload;
    },
    setSchoolOwnerName: (state, action: PayloadAction<string>) => {
      state.SchoolFormData.ownerFirstName = action.payload;
    },
    setSchoolOwnerLastName: (state, action: PayloadAction<string>) => {
      state.SchoolFormData.ownerLastName = action.payload;
    },
    setSchoolNotifcationEmail: (state, action: PayloadAction<any>) => {
      state.SchoolFormData.notificationEmailCheck =
        action.payload.notificationEmail;
      state.SchoolFormData.ownerEmailCheck = action.payload.ownerEmail;
      state.SchoolFormData.contactEmailCheck =
        action.payload.contactPersonEmail;
      state.SchoolFormData.ownerPhoneNoCheck = action.payload.ownerPhoneno;
      state.SchoolFormData.contactPhoneNoCheck =
        action.payload.contactPersonPhoneno;
      state.SchoolFormData.NotificationPhoneNoCheck =
        action.payload.notificationPhoneNo;
    },
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddNewCertificateSchool: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddNewRegistrationSchool: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    NewRegistration: (state) => {
      state.RegistrationList.push(state.Registration);
    },
    NewCertificate: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.certificateList.push(state.certificate);
      action.payload.callback();
    },
    doActiveRegistration: (
      state,
      action: PayloadAction<{
        id: string;
        checked: boolean;
        callback: () => void;
      }>
    ) => {},
    doActiveCertificate: (
      state,
      action: PayloadAction<{
        id: string;
        checked: boolean;
        callback: () => void;
      }>
    ) => {},
    doProfileAddImages: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    DeleteCertificateSchool: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.certificateList = [];
    },
    DeleteRegistrationSchool: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.RegistrationList = [];
    },
    doFeaturedAddImages: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doLicenceAddFile: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {
      log("do Licence Add File");
      // state.phone = action.payload;
    },
    doRegistrationFileAdd: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {
      log("Registration File");
      // state.phone = action.payload;
    },
    setProfileData: (state, action: PayloadAction<{ id: string }>) => {
      state.SchoolID = action.payload.id;
    },
    toggelFeaturedPhotoCropSow: (state) => {
      state.featuredPhotoShow = !state.featuredPhotoShow;
    },
    toggelProfilePhotoCropShow: (state) => {
      state.profilePhotoShow = !state.profilePhotoShow;
    },
    setStatusFilter: (state, action: PayloadAction<number>) => {
      state.statusFilter = action.payload;
    },
    setSreatedOnFilter: (state, action: PayloadAction<any>) => {
      state.createdOnFilter = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    setProfileType: (state, action: PayloadAction<any>) => {
      state.profileTypeFilter = action.payload;
    },
    updateStatusFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `statusFilter`, action.payload.value);
    },
    updateProfileTypeFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `profileTypeFilter`, action.payload.value);
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    deleteRegistrationFile: (state, action: PayloadAction<number>) => {
      state.Registration.registrationFile.splice(action.payload, 1);
    },
    deleteLicensesFile: (state, action: PayloadAction<number>) => {
      state.certificate.certificateFile.splice(action.payload, 1);
    },
    setVerificationToken: (state, action: PayloadAction<string>) => {
      state.SchoolFormData.Verificationtoken = action.payload;
    },
    // New Work

    AddBasicDetail: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    AddBusinessRegistrationAddress: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    AddContactPerson: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    addSchoolNotification: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    AddBusinessOwner: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setEmailConfirmation: (
      state,
      action: PayloadAction<EmailVerificationInterface>
    ) => {
      state.emailVerification = action.payload;
      log(state.emailVerification, "ffdsfsdfsd");
    },
    setisDraftBasicDetail: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftBasicDetail = action.payload;
    },
    setisDraftBusinessOwnerDetail: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftBusinessOwnerDetail = action.payload;
    },
    setisDraftContactDetail: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftContactDetail = action.payload;
    },
    setisDraftLicencesDetail: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftLicencesDetail = action.payload;
    },
    setisDraftNotification: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftNotification = action.payload;
    },
    setisDraftRegistrationAddress: (state, action: PayloadAction<boolean>) => {
      state.isDraftModal.isDraftRegistrationAddress = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: AdminSchoolSliceKey } = useClinicSlice;
