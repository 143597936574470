import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import { PaginationInterface } from "../types";
import { selectBookingsListPagination } from "./selector";
import {
  GetCurrentlyconnectedSchoolData,
  GetPastBookingUserData,
  GetPreviousBookingsDataData,
  GetUpcomingBookingsDataData,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* getCurrentlyconnectedListRequest(action: {
  payload: { schoolId: string; callback: any };
}) {
  yield delay(500);
  const form: PaginationInterface = yield select(selectBookingsListPagination);

  try {
    const response: AxiosResponse = yield call(
      GetCurrentlyconnectedSchoolData,
      `?pageNo=${form.CurrentlyBookingsPageNo}&pageSize=${10}&schoolId=${
        action.payload.schoolId
      }`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setTotalCurrentlyBookingsList(response.data[0].meta.total)
    );
    yield put(actions.setCurrentlyconnectedList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getPreviousBookingsListRequest(action: {
  payload: { schoolId: string; callback: any };
}) {
  yield delay(500);
  const form: PaginationInterface = yield select(selectBookingsListPagination);
  try {
    const response: AxiosResponse = yield call(
      GetPreviousBookingsDataData,
      `?pageNo=${form.PreviousBookingsPageNo}&pageSize=${10}&schoolId=${
        action.payload.schoolId
      }`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setTotalPreviousBookingsList(response.data[0].meta.total)
    );
    yield put(actions.setPreviousBookingsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getUpcomingBookingsListRequest(action: {
  payload: { schoolId: string; callback: any };
}) {
  yield delay(500);
  const form: PaginationInterface = yield select(selectBookingsListPagination);
  try {
    const response: AxiosResponse = yield call(
      GetUpcomingBookingsDataData,
      `?pageNo=${form.UpComingBookingsPageNo}&pageSize=${10}&schoolId=${
        action.payload.schoolId
      }`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setTotaUpComingBookingsList(response.data[0].meta.total));
    yield put(actions.setUpcomingBookingsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* BookingsSchholRepoSagaRetreats() {
  yield takeLatest(
    actions.doGetCurrentlyconnectedList,
    getCurrentlyconnectedListRequest
  );
  yield takeLatest(
    actions.doGetPreviousBookingsList,
    getPreviousBookingsListRequest
  );
  yield takeLatest(
    actions.doGetUpcomingBookingsList,
    getUpcomingBookingsListRequest
  );
}
