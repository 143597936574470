export interface locationNameInterface {
  title: string;
  img: string;
}
export interface popularRetreatsInterface {
  title: string;
}
export interface faqInterface {
  title: string;
  description: string;
}
export interface getDescriptionFaqRequest {
  search: string;
  activity: PopularRetreatActivityEnum;
}

export enum PopularRetreatActivityEnum {
  YOGA_RETREAT = 1,
  YOGA_TEACHER_TRAINING = 2,
}
export interface LandingState {
  // countryName: string;
  // featuredRetreats: Array<any>;
  // locationName: locationNameInterface[];
  // isYogaRetreats: boolean;
  // countryVideo: string;
  // countryVideoImage: string;
  // countryFeaturePhoto: string;
  // popularRetreats: popularRetreatsInterface[];
  // faq: faqInterface[];
  landingForm: getDescriptionFaqRequest;
  landingFormData: getDescriptionFaqRequest[];
  landingCityData: any;
  landingCountyData: any;
  landingDiscriptionData: any;
  landingPopularRetreatsData: any;
  landingPopularDestinationsData: any;
}
export type InitialLandingState = LandingState;
