import { paymentTypeEnum } from "../../Affiliate/types";

export interface AdminAffliateInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatusEnum;
  instagramLink: string;
  youtubeLink: string;
  websiteLink: string;
  termsAndConditions: boolean;
  Errors: Array<string>;
  Success: string;
  affiliateStatus: UserStatusEnum;
  bankName: string;
  bankAccountNo: string;
  bankId: string;
  country: string;
  currency: string;
  paymentType: paymentTypeEnum.WISE;
  accountHolderName: string;
  beneficiaryName: string;
}
export interface getAffiliateDataInterface {
  pageSize: number;
  pageNo: number;
  createdOnFilter: number | 0;
  startDate: any;
  endDate: any;
  id: string;
  status: AffiliatePaymentStatusEnum;
  createdAt: string;
  retreatTitle: string;
  price: number;
  retreatBookingId: string;
}
export interface getAffiliateNameDataInterface {
  id: string;
  firstName: string;
  lastName: string;
}
export interface getAffiliateDashboardDataInterface {
  id: string;
  firstName: string;
  lastName: string;
  commision: number;
  price: number;
  createdAt: string;
  updatedAt: string;
  PendingAmount: number;
  ApprovedAmount: number;
  TotalPaidAmount: number;
  commisionCount: number;
  status: AffiliatePaymentStatusEnum;
}
export enum UserStatusEnum {
  InActive = 0,
  Active = 1,
}
export enum AffiliatePaymentStatusEnum {
  PENDING = 1,
  DENIED = 2,
  APPROVED = 3,
  PAID = 4,
  ALL = 5,
}
export interface AffiliatePaymentInterface {
  id: string;
  currency: string;
  businessName: string;
  createdAt: string;
  retreatTitle: string;
  subtotal: number;
  tax: number;
  total: number;
  updatedAt: string;
}
export interface StatusChangeFormInterface {
  id: string;
  status: boolean;
}
export interface AdminAffiliateState {
  affliateList: AdminAffliateInterface[];
  affiateFormData: AdminAffliateInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  deleteModal: boolean;
  editModalShow: boolean;
  AffliateDataForm: getAffiliateDataInterface;
  AffliateDataList: getAffiliateDataInterface[];
  AffliateNameDataForm: getAffiliateNameDataInterface;
  AffliateNameDataList: getAffiliateNameDataInterface[];
  AffliateTotalAmountList: any;
  affiliateDashboardPageNo: number;
  affiliateDashboardPageSize: number;
  affiliateDashboardTotalRow: number;
  createdOnFilter: number | 0;
  date: any | "";
  affiliateDashboardData: getAffiliateDashboardDataInterface[];
  AffliateDashboardTotalAmountList: any;
  affiliatePartnerPageNo: number;
  affiliatePartnerPageSize: number;
  affiliatePartnerTotalRow: number;
  affiliatePaymentPageNo: number;
  affiliatePaymentPageSize: number;
  affiliatePaymentTotalRow: number;
  affiliatePaymentSearch: string;
  affiliatesPaymentList: AffiliatePaymentInterface[];
  SchoolListFilter: string[];
  sortColumn: string;
  sortDir: string;
  sortColumnAffiliateDashboard: string;
  sortDirAffiliateDashboard: string;
  sortColumnPayment: string;
  sortDirPayment: string;
  sortColumnPartner: string;
  sortDirPartner: string;
  StatusChangeForm: StatusChangeFormInterface;
  payDetailsModalShow: boolean;
}
export type InitialAdminAffiliateState = AdminAffiliateState;
