import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { MediaManagerInterface, SchoolMediaManagerState } from "../types";
import { log } from "../../../../utils/logger";

export const initialState: SchoolMediaManagerState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  mediaManagerForm: {
    id: "",
    video: "",
    listing: [],
    status: 1,
    businessName: "",
    schoolId: "",
    mediaId: "",
    fileName: "",
  },
  mediaManagerListData: [],
  cardShow: false,
  totalList: 0,
  deleteModal: false,
  editModal: false,
  totalMedia: 0,
  sortColumn: "",
  sortDir: "",
};

export const useClinicSlice = createSlice({
  name: "schoolMediaManager",
  initialState,
  reducers: {
    updateSchoolMediaManagerFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `mediaManagerForm.${action.payload.key}`,
        action.payload.value
      );
    },
    toggalCardShowModal: (state) => {
      state.cardShow = !state.cardShow;
    },

    doAddVideo: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},

    AddMediaManagerVideo: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
    },

    updateMediaManagerVideo: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},

    setMediaManagerData: (
      state,
      action: PayloadAction<Array<MediaManagerInterface>>
    ) => {
      state.mediaManagerListData = action.payload;
    },

    setMediaDataInForm: (
      state,
      action: PayloadAction<MediaManagerInterface>
    ) => {
      state.mediaManagerForm = action.payload;
    },
    clearForm: (state) => {
      state.mediaManagerForm.id = "";
      state.mediaManagerForm.video = "";
      state.mediaManagerForm.status = 1;
      state.mediaManagerForm.listing = [];
      state.mediaManagerForm.mediaId = "";
      state.mediaManagerForm.businessName = "";
    },
    clearAdminForm: (state) => {
      state.mediaManagerForm.id = "";
      state.mediaManagerForm.video = "";
      state.mediaManagerForm.status = 1;
      state.mediaManagerForm.listing = [];
      state.mediaManagerForm.mediaId = "";
      state.mediaManagerForm.schoolId = "";
      state.mediaManagerForm.businessName = "";
      state.mediaManagerForm.mediaId = "";
    },
    doGetMediaManagerList: (state, action: PayloadAction<any>) => {},
    doGetMediaManagerListForAdmin: (state) => {},
    deleteSchoolMediaManager: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetSchoolMediaManagerById: (state, action: PayloadAction<any>) => {},

    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },
    toggalDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggalMediaEditModal: (state) => {
      state.editModal = !state.editModal;
    },
    setTotalMedia: (state, action: PayloadAction<number>) => {
      state.totalMedia = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
