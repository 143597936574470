import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../types";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.affiliateAuthState) {
      return state.affiliateAuthState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectAffiateFormData = createSelector(
  [selectDomain],
  (state) => state.affiateFormData
);
export const selectAffiateList = createSelector(
  [selectDomain],
  (state) => state.affliateList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectaddAffiliateLinkForm = createSelector(
  [selectDomain],
  (state) => state.addAffiliateLink
);
export const selectListAffiliateLink = createSelector(
  [selectDomain],
  (state) => state.ListAffiliateLink
);
export const selectaffiliateLinkPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliateLinkPageNo
);
export const selectaffiliateLinkTotal = createSelector(
  [selectDomain],
  (state) => state.affiliateLinkTotal
);
export const selectAffiliatePaymentForm = createSelector(
  [selectDomain],
  (state) => state.PaymentForm
);
export const selectaffiliatePaymentPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentPageNo
);
export const selectaffiliatePaymentTotal = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentTotal
);
export const selectAffiliatePaymentList = createSelector(
  [selectDomain],
  (state) => state.PaymentList
);
export const selectAffiliateDashboardTopData = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardTopData
);
export const selectAddAccountModal = createSelector(
  [selectDomain],
  (state) => state.addAccountModal
);
export const selectAddPaypalModal = createSelector(
  [selectDomain],
  (state) => state.addPaypalModal
);
export const selectAddWiseModal = createSelector(
  [selectDomain],
  (state) => state.addWiseModal
);
export const selectCalculateForm = createSelector(
  [selectDomain],
  (state) => state.calculateForm
);
export const selectCalculateFormData = createSelector(
  [selectDomain],
  (state) => state.calculateFormData
);
export const selectAffiliateDashboardPageSize = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardPageSize
);
export const selectAffiliateDashboardTotalRow = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardTotalRow
);
export const selectAffiliateDashboardPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardPageNo
);
export const selectAffiliateDashboardDataList = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardDataList
);
