import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  SelectSearch,
  selectAffiateFormData,
  selectAffiliatePaymentForm,
  selectaddAffiliateLinkForm,
  selectaffiliateLinkPageNo,
  selectCalculateForm,
  selectAffiliateDashboardPageNo,
} from "./selector";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  AffliateSignUpInterface,
  PaymentFormInterface,
  addAffiliateLinkInterface,
  paymentTypeEnum,
  affiliateCalculateInterface,
} from "../types";
import CustomToast from "../../../components/UI/CustomToast";
import {
  AddAffiliateBank,
  AddAffiliateLinkRequest,
  affliateCalculateRequest,
  dodeleteAffiliateLinkRequest,
  dodeleteAffiliatePaymentRequest,
  doEditAffiliatePayment,
  GetAffiliateLinkRequest,
  GetAffiliatepatrnerDashboardData,
  GetAffiliatePaymentRequest,
  newAffliateSignUpRequest,
} from "../../../utils/request";
import CatchBlockFunction from "../../../hooks/CatchBlockFunction";

export function* AffiliateSignUpRequest(action: {
  payload: { role: number; callback: any };
}) {
  yield delay(500);

  const form: AffliateSignUpInterface = yield select(selectAffiateFormData);
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  const instagramUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/;
  const youtubeUrlRegex =
    /^(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;
  const urlRegex =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%.\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%\+.~#?&//=]*$)/;
  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    CustomToast("Enter Valid Email ID", "ERROR");
    return;
  }

  const isValidInstagramUrl = instagramUrlRegex.test(form.instagramLink);
  const isValidYouTubeUrl = youtubeUrlRegex.test(form.youtubeLink);
  const isValidWebUrl = urlRegex.test(form.websiteLink);

  if (form.instagramLink.length > 0) {
    if (!isValidInstagramUrl) {
      CustomToast("Invalid Instagram URL", "ERROR");
      return;
    }
  }
  if (form.websiteLink.length > 0) {
    if (!isValidWebUrl) {
      CustomToast("Invalid Website URL", "ERROR");
      return;
    }
  }
  if (form.youtubeLink.length > 0) {
    if (!isValidYouTubeUrl) {
      CustomToast("Invalid YouTube URL", "ERROR");
      return;
    }
  }
  if (
    form.instagramLink.length == 0 &&
    form.youtubeLink.length == 0 &&
    form.websiteLink.length == 0
  ) {
    CustomToast(
      "please enter Instagram URL,Website URL & YouTube URL",
      "ERROR"
    );
    return;
  }
  if (!action.payload.role) {
    if (form.password.length === 0) {
      CustomToast("Please Enter Password", "ERROR");
      return;
    }
    if (form.password.length > 0) {
      if (!regex.test(form.password)) {
        CustomToast("Enter Valid Password", "ERROR");
        return;
      }
    }
  }

  if (form.termsAndConditions === false) {
    CustomToast("Please Select Terms of Service", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(newAffliateSignUpRequest, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    // yield put(actions.signupSuccess());

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddAffiliateLinkRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: addAffiliateLinkInterface = yield select(
    selectaddAffiliateLinkForm
  );
  if (form.link.length === 0) {
    CustomToast("Please Enter link", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddAffiliateLinkRequest, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteAffiliateLinkRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      dodeleteAffiliateLinkRequest,
      action.payload.id
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteAffiliatePaymentRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      dodeleteAffiliatePaymentRequest,
      action.payload.id
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* dogetAffiliateLinkListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const pageNo: string = yield select(selectaffiliateLinkPageNo);
  const search: string = yield select(SelectSearch);
  try {
    const response: AxiosResponse = yield call(
      GetAffiliateLinkRequest,
      `?search=${search}&pageNo=${pageNo}&pageSize=${10}`
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setaffiliateLinkTotal(response.data.data[0].meta.total));
    yield put(actions.setAffiliateList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddAffiliatePaymentRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: PaymentFormInterface = yield select(selectAffiliatePaymentForm);

  if (form.paymentType == paymentTypeEnum.WISE) {
    if (form.accountHolderName.length === 0) {
      CustomToast("Please Enter Account Holder Name", "ERROR");
      return;
    }
    if (form.bankName.length === 0) {
      CustomToast("Please Enter Bank Name", "ERROR");
      return;
    }
    if (form.beneficiaryName.length === 0) {
      CustomToast("Please Enter Beneficiary Name", "ERROR");
      return;
    }
  }
  if (form.paymentType == paymentTypeEnum.PAYPAL) {
    if (form.payPalEmail.length === 0) {
      CustomToast("Please enter email", "ERROR");
      return;
    }
  }
  try {
    const response: AxiosResponse = yield call(AddAffiliateBank, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* dogetAffiliateaymentListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const pageNo: string = yield select(selectaffiliateLinkPageNo);
  try {
    const response: AxiosResponse = yield call(
      GetAffiliatePaymentRequest,
      `?userId=${action.payload.id}&pageNo=${pageNo}&pageSize=${10}`
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(
      actions.setaffiliatePaymentTotal(response.data.data[0].meta.total)
    );
    yield put(actions.setAffiliatePaymentList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* GetAffiliatePaymentDetailByIdRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doEditAffiliatePayment,
      action.payload.id
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setaffiliatePaymentDataInForm(response.data.data[0]));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetAffiliateDashboardTopDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const pageNo: number = yield select(selectAffiliateDashboardPageNo);

  try {
    const response: AxiosResponse = yield call(
      GetAffiliatepatrnerDashboardData,
      `?pageNo=${pageNo}&pageSize=${10}`
    );

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setaffiliateDashboardTopData(response.data.totalAmounts[0])
    );
    yield put(actions.setAffiliateDasboardList(response.data.data.results));
    yield put(
      actions.setAffiliateDashboardTotalRow(response.data.data.meta.total)
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doAffiliateCalculateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: affiliateCalculateInterface = yield select(selectCalculateForm);
  if (form.averageBookingValue.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.numberOfBookings.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(affliateCalculateRequest, form);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setCalculateForm(response.data.data));
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AffliateUserRepoSaga() {
  yield takeLatest(actions.doAffiliateSignUp, AffiliateSignUpRequest);
  yield takeLatest(actions.doAddAffiliateLink, doAddAffiliateLinkRequest);
  yield takeLatest(actions.doAffiliateLinkList, dogetAffiliateLinkListRequest);
  yield takeLatest(actions.deleteAffiliateLink, deleteAffiliateLinkRequest);
  yield takeLatest(
    actions.deleteAffiliatePayment,
    deleteAffiliatePaymentRequest
  );
  yield takeLatest(actions.doAddAffiliatePayment, doAddAffiliatePaymentRequest);
  yield takeLatest(
    actions.doAffiliatePaymentList,
    dogetAffiliateaymentListRequest
  );
  yield takeLatest(
    actions.GetAffiliatePaymentDetailById,
    GetAffiliatePaymentDetailByIdRequest
  );
  yield takeLatest(
    actions.doGetAffiliateDashboardTopData,
    doGetAffiliateDashboardTopDataRequest
  );
  yield takeLatest(
    actions.doAffiliateCalculateForm,
    doAffiliateCalculateRequest
  );
}
