import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  selectCustomerSupportForm,
  selectStillMakingRetreatsPlanForm,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  CustomerSupportRequest,
  getHelpContextRequest,
  StillMakingRetreatsPlanRequest,
} from "../../../../utils/request";
import {
  CustomerSupportInterface,
  StillMakingRetreatsPlanInterface,
} from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* CustomerSupportAddRequest(action: {
  payload: {token:string, callback: any };
}) {
  yield delay(500);
  const form: CustomerSupportInterface = yield select(
    selectCustomerSupportForm
  );

  if (form.firstname.length == 0) {
    CustomToast("Please enter first name", "ERROR");
    return;
  }
  if (form.lastname.length == 0) {
    CustomToast("Please enter last name", "ERROR");
    return;
  }
  if (form.email.length == 0) {
    CustomToast("Please enter email", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    CustomToast("Enter Valid Email ID", "ERROR");
    return;
  }

  if (form.context.length == 0) {
    CustomToast("Please enter context query", "ERROR");
    return;
  }
  if (form.query.length == 0) {
    CustomToast("Please enter message", "ERROR");
    return;
  }
  if (form.userType == null) {
    return;
  }
  const data={...form,...{token :action.payload.token}}
  try {
    const response: AxiosResponse = yield call(CustomerSupportRequest, data);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getHelpContextListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getHelpContextRequest);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setHelpContextList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* StillMakingRetreatsPlan(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: StillMakingRetreatsPlanInterface = yield select(
    selectStillMakingRetreatsPlanForm
  );

  try {
    const response: AxiosResponse = yield call(
      StillMakingRetreatsPlanRequest,
      form
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.clearFrom());

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* CustomerHelpRepoSaga() {
  yield takeLatest(actions.sendMessage, CustomerSupportAddRequest);
  yield takeLatest(actions.doGetContextList, getHelpContextListRequest);

  //Still Making Retreats Plan .............*****
  yield takeLatest(actions.stillMakingRetreatsPlan, StillMakingRetreatsPlan);
}
