import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  GrabTheAttentionInterface,
  GrabTheAttentionModel,
  SchoolListingGrabState,
} from "../types";
import { ListingTypeEnum } from "../../types";

export const initialState: SchoolListingGrabState = {
  GrabTheAttentionBenifitsFixedAdmin: [],
  GrabTheAttentionBenifitsTempSchool: [],
  GrabTheAttModalType: 0,
  GrabTheAttentionForm: {
    isDraft: false,
    description: "",
    id: "",
    retreatBenefits: [],
    retreatBenefitsFrom: {
      listingType:  ListingTypeEnum.BOTH,
      id: "",
      name: "",
      retreatId: "",
      type: 2,
      schoolId: "",
    },
    retreatId: "",
    specialGift: [],
    specialGiftFrom: {
      id: "",
      listingType:  ListingTypeEnum.BOTH,
      name: "",
      retreatId: "",
      type: 2,
      schoolId: "",
    },
    count: 0,
  },
  GrabTheAttentionList: [],
  GrabTheAttentionGiftsFixedAdmin: [],
  GrabTheAttentionGiftsTempSchool: [],
  loading: false
};

export const useClinicSlice = createSlice({
  name: "SchoolListingGrabState",
  initialState,
  reducers: {
    setGrabIsDraft: (state, action: PayloadAction<boolean>) => {
      state.GrabTheAttentionForm.isDraft = action.payload;
    },
    grabCount: (state) => {
      state.GrabTheAttentionForm.count = state.GrabTheAttentionForm.count + 1;
    },
    getGrabBenifitsAdminList: (state) => {},
    getGrabBenifitsSchoolList: (state, action: PayloadAction<any>) => {},
    setGrabBenifitsAdminList: (
      state,
      action: PayloadAction<Array<GrabTheAttentionModel>>
    ) => {
      state.GrabTheAttentionBenifitsFixedAdmin = action.payload;
    },
    setGrabBenifitsdSchoolList: (
      state,
      action: PayloadAction<Array<GrabTheAttentionModel>>
    ) => {
      state.GrabTheAttentionBenifitsTempSchool = action.payload;
    },
    updateGrabFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `GrabTheAttentionForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateGrabBenifitsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `GrabTheAttentionForm.retreatBenefitsFrom.${action.payload.key}`,
        action.payload.value
      );
    },
    SelectGrabBenifits: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.GrabTheAttentionForm.retreatBenefits.push(action.payload.value);
    },
    AddNewGrabBenifits: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddNewGrabSpecialGifts: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    SelectGrabSpecialGifts: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.GrabTheAttentionForm.specialGift.push(action.payload.value);
    },
    updateRetreatGrabSpecialGiftsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `GrabTheAttentionForm.specialGiftFrom.${action.payload.key}`,
        action.payload.value
      );
    },
    SetGrabTheAttModalType: (state, action: PayloadAction<number>) => {
      state.GrabTheAttModalType = action.payload;
    },
    setGrabSpecialGiftsAdminList: (
      state,
      action: PayloadAction<Array<GrabTheAttentionModel>>
    ) => {
      state.GrabTheAttentionGiftsFixedAdmin = action.payload;
    },
    setGrabSpecialGiftsSchoolList: (
      state,
      action: PayloadAction<Array<GrabTheAttentionModel>>
    ) => {
      state.GrabTheAttentionGiftsTempSchool = action.payload;
    },
    getGrabSpecialGiftsAdminList: (state) => {},
    getGrabSpecialGiftsSchoolList: (state, action: PayloadAction<any>) => {},
    SelectBenifitsList: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.GrabTheAttentionForm.retreatBenefits.indexOf(
          action.payload.value
        ) !== -1
      ) {
        state.GrabTheAttentionForm.retreatBenefits.splice(
          state.GrabTheAttentionForm.retreatBenefits.indexOf(
            action.payload.value
          ),
          1
        );
      } else {
        state.GrabTheAttentionForm.retreatBenefits.push(action.payload.value);
      }
    },
    SelectGiftsList: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.GrabTheAttentionForm.specialGift.indexOf(action.payload.value) !==
        -1
      ) {
        state.GrabTheAttentionForm.specialGift.splice(
          state.GrabTheAttentionForm.specialGift.indexOf(action.payload.value),
          1
        );
      } else {
        state.GrabTheAttentionForm.specialGift.push(action.payload.value);
      }
    },

    AddGrabForm: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearBenefitsform: (state) => {
      state.GrabTheAttentionForm.retreatBenefitsFrom.name = "";
      state.GrabTheAttentionForm.retreatBenefitsFrom.id = "";
      state.GrabTheAttentionForm.retreatBenefitsFrom.retreatId = "";
    },
    clearGiftform: (state) => {
      state.GrabTheAttentionForm.specialGiftFrom.name = "";
      state.GrabTheAttentionForm.specialGiftFrom.id = "";
      state.GrabTheAttentionForm.specialGiftFrom.retreatId = "";
    },
    SetGrabTheAttentionFormDataById: (
      state,
      action: PayloadAction<GrabTheAttentionInterface>
    ) => {
      state.GrabTheAttentionForm = action.payload;
      state.GrabTheAttentionForm.retreatBenefitsFrom = {
        listingType:  ListingTypeEnum.BOTH,
        id: "",
        name: "",
        retreatId: "",
        type: 2,

        schoolId: "",
      };
      state.GrabTheAttentionForm.specialGiftFrom = {
        id: "",
        listingType:  ListingTypeEnum.BOTH,
        name: "",
        retreatId: "",
        type: 2,
        schoolId: "",
      };
    },
    giftsEditRequest: (state, action: PayloadAction<GrabTheAttentionModel>) => {
      state.GrabTheAttentionForm.specialGiftFrom = action.payload;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    doGetGrabTheAttentionDataByID: (state, action: PayloadAction<any>) => {},
    BenifitsEditRequest: (
      state,
      action: PayloadAction<GrabTheAttentionModel>
    ) => {
      state.GrabTheAttentionForm.retreatBenefitsFrom = action.payload;
    },
    DeleteBenifitsPills: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
        retreatId: string;
      }>
    ) => {},
    DeleteGiftsPills: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
        retreatId: string;
      }>
    ) => {},
    clearGrabTheAttentionForm: (state) => {
      state.GrabTheAttentionForm.id = "";
      state.GrabTheAttentionForm.description = "";
      state.GrabTheAttentionForm.retreatBenefits = [];
      state.GrabTheAttentionForm.specialGift = [];
      state.GrabTheAttentionForm.isDraft = false;
      state.GrabTheAttentionForm.count = null || 0;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
