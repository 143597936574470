import { PayloadAction } from "@reduxjs/toolkit";
import {
  AdminMediaManagerState,
  StatusFilterEnum,
  listingListInterface,
  mediaInterface,
} from "../types";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
export const initialState: AdminMediaManagerState = {
  loading: false,
  pageSize: 15,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  createdOnFilter: 0,
  date: undefined,
  SchoolListFilter: [],
  ListingsListFilter: [],
  StatusFilter: StatusFilterEnum.All,
  listingList: [],
  allPartnerList: [],
};

export const useClinicSlice = createSlice({
  name: "mediaManager",
  initialState,
  reducers: {
    getListingList: (
      state,
      action: PayloadAction<{ serach: string; id: any; callback: () => void }>
    ) => {},
    doGetMediaList: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    updateStatusFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `StatusFilter`, action.payload.value);
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.SchoolListFilter = action.payload;
    },
    ListingFilter: (state, action: PayloadAction<any>) => {
      state.ListingsListFilter = action.payload;
    },
    ListingList: (
      state,
      action: PayloadAction<Array<listingListInterface>>
    ) => {
      state.listingList = action.payload;
    },
    setAllPartnerList: (state, action: PayloadAction<Array<any>>) => {
      state.allPartnerList = action.payload;
    },
    doGetAllPartnerList: (
      state,
      action: PayloadAction<{ serach: string; callback: () => void }>
    ) => {},
  },
});

export const {
  reducer,
  actions,
  name: AdminMediaManagerSliceKey,
} = useClinicSlice;
