import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  OtpTypeEnum,
  PaymentDepositTypeEnum,
  PaymentMethodEnum,
  UserBookingState,
} from "../types";

export const initialState: UserBookingState = {
  ItinararyData: {},
  visitTab: [],
  ItinararyForm: {
    id: "",
    userId: "",
    schoolId: "",
    status: 0,
    retreatId: "",
    arrivalDate: "",
    couponCode: "",
    acceptOrDeclineAvailablity: 0,
    packageId: "",
    noOfPeople: 0,
    price: 0,
    roomAccommodationName: "",
    roomAccommodationType: "",
    roomAccommodation: "",
    requiredDeposit: 0,
    remainingAmount: 0,
    cancellationPolicy: 0,
    newPolicy: "",
    paymentSchedule: 0,
    paymentDaysBeforeArrival: 0,
    packageIdFirst: "",
  },
  progressBar: 0,
  contactDetail: {},
  contactDetailForm: {
    email: "",
    id: "",
    phoneNo: "",
    whatsAppNumber: "",
    phoneNoVerify: false,
    VerificationPhoneNumber: "",
    emailVerify: false,
    emailCheck: "",
  },
  priceDetailForm: {
    inquiryId: "",
    packageId: "",
    couponCode: "",
  },
  totalPriceData: {},
  AddCard: {
    bookingId: "",
    paymentMethod: PaymentMethodEnum.PAYPAL,
    accountHolderName: "",
    billingAddress: "",
    postalCode: "",
    country: "",
    cardNumber: "",
    cardExpiryDate: "",
    cardCVV: "",
  },
  depositType: PaymentDepositTypeEnum.PARTIALLY_DEPOSIT,
  BookingId: "",
  PrivateKey: "",
  showAddCard: false,
  upcomingBookingList: [],
  pastBookingList: [],
  BookingsListPagination: {
    PastBookingsPageNo: 1,
    UpComingBookingsPageNo: 1,
  },
  TotalpastBookingLength: 0,
  TotalUpComingBookingLength: 0,
  TransactionDetail: {
    bookingId: "",
    price: "",
    transactionId: "",
    currencyCode: "",
  },
  isCouponAppliedOrNot: false,
  SendVerificationOtpRequest: {
    name: "",
    email: "",
    phoneNo: "",
    otpType: OtpTypeEnum.WHATSAPP,
  },
  Loader: false,
  paySuccessLoader: false,
  contactLoader: false,
};

export const useClinicSlice = createSlice({
  name: "UserBookingState",
  initialState,
  reducers: {
    dogetItinararyDetail: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    GetPriceRequest: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    GetTransactionDetailRequest: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    AccountDeactivateRequest: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    GetUpComingBookingRequest: (state) => {},
    GetPastBookingsRequest: (state) => {},
    setPageSizeUpcoming: (state, action: PayloadAction<number>) => {
      state.TotalUpComingBookingLength = action.payload;
    },
    setPageSizePast: (state, action: PayloadAction<any>) => {
      state.TotalpastBookingLength = action.payload;
    },

    setisCouponAppliedOrNot: (state, action: PayloadAction<boolean>) => {
      state.isCouponAppliedOrNot = action.payload;
    },
    setTransactionDetail: (state, action: PayloadAction<any>) => {
      state.TransactionDetail = action.payload;
    },
    doContinueDetail: (
      state,
      action: PayloadAction<{
        bookingId: string;
        callback: (PrivateKey: string) => void;
      }>
    ) => {},
    doPayment: (
      state,
      action: PayloadAction<{
        bookingId: string;
        address: any;
        callback: (Data: any) => void;
      }>
    ) => {},

    doUpdatePayment: (
      state,
      action: PayloadAction<{
        paymentId: string;
       
      }>
    ) => {},
    AddItinararyAndContatctDetail: (
      state,
      action: PayloadAction<{ callback: (bookingId: string) => void }>
    ) => {},
    AddCardRequest: (state) => {},
    setshowAddCard: (state, action: PayloadAction<boolean>) => {
      state.showAddCard = action.payload;
    },
    setUpcomingBookingsList: (state, action: PayloadAction<any>) => {
      state.upcomingBookingList = action.payload;
    },
    setPastBookingsList: (state, action: PayloadAction<any>) => {
      state.pastBookingList = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.Loader = action.payload;
    },
    setContactLoader: (state, action: PayloadAction<boolean>) => {
      state.contactLoader = action.payload;
    },
    setPaySuccessLoader: (state, action: PayloadAction<boolean>) => {
      state.paySuccessLoader = action.payload;
    },
    setProgressBar: (state, action: PayloadAction<number>) => {
      state.progressBar = action.payload;
    },
    setVisitTab: (state, action: PayloadAction<any>) => {
      state.visitTab.push(action.payload);
    },
    clearVisitTab: (state) => {
      state.visitTab = [];
    },
    dogetContactDetail: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setNItinararyDetail: (state, action: PayloadAction<any>) => {
      state.ItinararyData = action.payload;
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.BookingId = action.payload;
    },
    setPrivateKey: (state, action: PayloadAction<string>) => {
      state.PrivateKey = action.payload;
    },
    setPriceDetail: (state, action: PayloadAction<any>) => {
      state.totalPriceData = action.payload;
    },
    setDespositType: (state, action: PayloadAction<any>) => {
      state.depositType = action.payload;
    },
    setContactDetail: (state, action: PayloadAction<any>) => {
      state.contactDetail = action.payload;
    },
    setContactDetailInForm: (state) => {
      state.contactDetailForm.email = state.contactDetail.email;
      state.contactDetailForm.id = state.contactDetail.id;
      state.contactDetailForm.phoneNo = state.contactDetail.phoneNo;
      state.contactDetailForm.phoneNoVerify = state.contactDetail.phoneNoVerify;
      state.contactDetailForm.VerificationPhoneNumber =
        state.contactDetail.phoneNo;
      state.contactDetailForm.emailVerify = state.contactDetail.emailVerify;
      state.contactDetailForm.emailCheck = state.contactDetail.email;
    },
    setItinararyDetailInForm: (state) => {
      state.ItinararyForm = state.ItinararyData;
      state.ItinararyData.packageIdFirst = state.ItinararyData.packageId;
    },
    couponCode: (state) => {
      state.ItinararyForm.couponCode = "";
    },
    ClearcouponCode: (state) => {
      state.priceDetailForm.couponCode = "";
    },
    updateContactDetailFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `contactDetailForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updatePriceFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `priceDetailForm.${action.payload.key}`, action.payload.value);
    },
    SendVerificationOtpRequestFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `SendVerificationOtpRequest.${action.payload.key}`,
        action.payload.value
      );
    },
    updateItinararyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `ItinararyForm.${action.payload.key}`, action.payload.value);
    },
    updateAddCardFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `AddCard.${action.payload.key}`, action.payload.value);
    },
    updatePageNoValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `BookingsListPagination.${action.payload.key}`,
        action.payload.value
      );
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
