import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  InvoiceListInterface,
  PaymentListInterface,
  SchoolMyMoneyState,
} from "../types";

export const initialState: SchoolMyMoneyState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  InvoiceList: [],
  PaymentList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  totalPayments: 0,
  totalInvoice: 0,
  invoicepageNo: 1,
  invoicepageSize: 10,
  invoice: undefined,
  sortColumn: "",
  sortDir: "",
  sortColumnInvoice: "",
  sortDirInvoice: "",
};

export const useClinicSlice = createSlice({
  name: "schoolMyMoney",
  initialState,
  reducers: {
    doGetPaymentList: (
      state,
      action: PayloadAction<{ school: string; callback: () => void }>
    ) => {},
    doGetInvoicesList: (
      state,
      action: PayloadAction<{ school: string; callback: () => void }>
    ) => {},

    setPaymentList: (
      state,
      action: PayloadAction<Array<PaymentListInterface>>
    ) => {
      state.PaymentList = action.payload;
    },
    setInvoicesList: (
      state,
      action: PayloadAction<Array<InvoiceListInterface>>
    ) => {
      state.InvoiceList = action.payload;
    },
    setTotalPayments: (state, action: PayloadAction<number>) => {
      state.totalPayments = action.payload;
    },
    setTotalInvoice: (state, action: PayloadAction<number>) => {
      state.totalInvoice = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    doExportInvoice: (state, action: PayloadAction<string>) => {},
    doExportPayment: (state, action: PayloadAction<string>) => {},
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setinvoice: (state, action: PayloadAction<any>) => {
      state.invoice = action.payload;
    },
    setInvoicePageNo: (state, action: PayloadAction<number>) => {
      state.invoicepageNo = action.payload;
    },
    setInvoicePageSize: (state, action: PayloadAction<number>) => {
      state.invoicepageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setSortColumnInvoice: (state, action: PayloadAction<string>) => {
      state.sortColumnInvoice = action.payload;
    },
    setSortDirInvoice: (state, action: PayloadAction<string>) => {
      state.sortDirInvoice = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
