import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  SelectPageSize,
  SelectPageNo,
  SelectSearch,
  selectAffiateFormData,
  selectAllAffiliateDashboardPageNo,
  selectAllAffiliateDashboardPageSize,
  SelectCreatedOnFilter,
  SelectDateFilter,
  SelectAffiliatePartnerPageSize,
  SelectAffiliatePartnerPageNo,
  SelectAffiliatePaymentPageNo,
  SelectAffiliatePaymentPageSize,
  SelectAffiliatePaymentSearch,
  SelectSchoolFilter,
  SelectSortDirPartner,
  SelectSortColumnPartner,
  SelectSortDirPayment,
  SelectSortColumnPayment,
  SelectSortDirAffiliateDashboard,
  SelectSortColumnAffiliateDashboard,
  SelectSortColumn,
  SelectSortDir,
  selectStatusChangeForm,
} from "./selector";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  deleteAffliateRequest,
  GetAffliateDetailsByIdRequest,
  getAdminAffiliateDataRequest,
  GetAllffliatelistRequest,
  newAffliateSignUpRequest,
  getAdminAffiliateDashboardData,
  getAdminAffiliateUpdateData,
  getAffiliateRecordPayment,
  getAdminAffiliatePaymentData,
  newAffliateAdminRequest,
  UpdateActiveStatus,
  GetAffliateBuIdRequest,
} from "../../../../utils/request";
import { AdminAffliateInterface, StatusChangeFormInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* getAllAffiliateListDataRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAllffliatelistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? "&search=" + searchText : ""
      }${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""}${
        SortDir.length > 0 ? "&sortDir=" + SortDir : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data[0].meta.total : 0
      )
    );
    yield put(actions.setAllAffiliateList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DoDeleteAdminAffiliate(action: { payload: any }) {
  yield delay(500);
  if (action.payload.length === 0) {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      deleteAffliateRequest,
      action.payload
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAffiliateDataByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAffliateBuIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setAffiliateData(response.data.data[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAffiliatePaymentDetailsByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAffliateDetailsByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setAffiliateData(response.data.data[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAffiliateUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: AdminAffliateInterface = yield select(selectAffiateFormData);
 
  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    CustomToast("Enter Valid Email ID", "ERROR");
    return;
  }

  try {
    const data = {
      id : form.id,
      firstName: form.firstName,
      lastName : form.lastName,
      email:form.email,
      instagramLink : form.instagramLink,
      websiteLink : form.websiteLink,
      youtubeLink : form.youtubeLink
    }
    const response: AxiosResponse = yield call(newAffliateAdminRequest, data);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.toggleEditModal());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//******admin side all affilate partner  dashboard ***************/
export function* getAdminAffiliateDataAccepectRequest(action: {
  payload: { id: string; status: string; callback: any };
}) {
  yield delay(500);
  const PageNo: number = yield select(SelectAffiliatePartnerPageNo);
  const pageSize: number = yield select(SelectAffiliatePartnerPageSize);
  const createdOnFilter: Number = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  const SortColumn: string = yield select(SelectSortColumnPartner);
  const SortDir: string = yield select(SelectSortDirPartner);
  try {
    const data = {
      id: action.payload.id,
      status: action.payload.status,
      createdOnFilter: createdOnFilter,
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      pageSize: pageSize,
      pageNo: PageNo,
    };
    ////
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAdminAffiliateDataRequest,
      `?${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""}${
        SortDir.length > 0 ? "&sortDir=" + SortDir : ""
      }`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setAffiliatePartnerTotalRow(response.data.data.meta.total)
    );
    yield put(actions.setAffiliateListData(response.data.data.results));
    yield put(
      actions.setAffliateTotalAmountList(response.data.totalAmounts[0])
    );
    yield put(actions.setAffliateNameList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAdminAffiliateDashboardDataRequest(action: {
  payload: { status: number; callback: any };
}) {
  yield delay(500);
  const PageNo: number = yield select(selectAllAffiliateDashboardPageNo);
  const pageSize: number = yield select(selectAllAffiliateDashboardPageSize);
  const SortColumn: string = yield select(SelectSortColumnAffiliateDashboard);
  const SortDir: string = yield select(SelectSortDirAffiliateDashboard);
  try {
    const data = {
      pageSize: pageSize,
      pageNo: PageNo,
      status: action.payload.status,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAdminAffiliateDashboardData,
      `?${SortColumn.length > 0 ? "sortColumn=" + SortColumn : ""}${
        SortDir.length > 0 ? "&sortDir=" + SortDir : ""
      }`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalAffiliateTotalRow(response.data.data.meta.total));
    yield put(actions.setAffiliateDashboardData(response.data.data.results));
    yield put(
      actions.setTotalAffiliateDashboardTotalAmountList(
        response.data.totalAmounts[0]
      )
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAdminAffiliateUpdateDataRequest(action: {
  payload: { id: string; status: string; callback: any };
}) {
  yield delay(500);
  try {
    const data = {
      id: action.payload.id,
      status: action.payload.status,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAdminAffiliateUpdateData,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAdminAffiliateRecordPaymentRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAffiliateRecordPayment);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getAdminAffiliatePaymentDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const PageNo: number = yield select(SelectAffiliatePaymentPageNo);
  const pageSize: number = yield select(SelectAffiliatePaymentPageSize);
  const search: string = yield select(SelectAffiliatePaymentSearch);
  const school: [] = yield select(SelectSchoolFilter);
  const SortColumn: string = yield select(SelectSortColumnPayment);
  const SortDir: string = yield select(SelectSortDirPayment);
  try {
    const data = {
      pageSize: pageSize,
      pageNo: PageNo,
      search: search,
      schoolFilter: school,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAdminAffiliatePaymentData,
      `?${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""}${
        SortDir.length > 0 ? "&sortDir=" + SortDir : ""
      }`,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalList(response.data.data.meta.total));
    yield put(actions.setAffiliatesPaymentList(response.data.data.results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UpdatestatusChnageRequest(action: {
  payload: { id: string; status: boolean; callback: any };
}) {
  yield delay(500);
  const statusForm: StatusChangeFormInterface = yield select(selectStatusChangeForm);

  try {
    
    const response: AxiosResponse = yield call(
      UpdateActiveStatus,
      statusForm
    );

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AdminAffiliateRepoSaga() {
  yield takeLatest(
    actions.doGetAllAffiliateList,
    getAllAffiliateListDataRequest
  );
  yield takeLatest(actions.deleteAdminAffiliate, DoDeleteAdminAffiliate);
  yield takeLatest(actions.doEditAdminAffiliate, doGetAffiliateDataByIdRequest);
  yield takeLatest(actions.doShowAdminAffiliatePaymentDetails, doGetAffiliatePaymentDetailsByIdRequest);
  yield takeLatest(actions.doUpdateAffiliate, doAffiliateUpdateRequest);
  yield takeLatest(
    actions.doGetAdminAffiliateData,
    getAdminAffiliateDataAccepectRequest
  );
  yield takeLatest(
    actions.doGetAdminAffiliateDashboardData,
    getAdminAffiliateDashboardDataRequest
  );
  yield takeLatest(
    actions.doUpdateAdminAffiliateData,
    getAdminAffiliateUpdateDataRequest
  );
  yield takeLatest(
    actions.doGetAdminAffiliateRecordPayment,
    getAdminAffiliateRecordPaymentRequest
  );
  yield takeLatest(
    actions.doGetAdminAffiliatePaymentList,
    getAdminAffiliatePaymentDataRequest
  );
  yield takeLatest(actions.statusChangeFormValue, UpdatestatusChnageRequest);

}
