import { CountryCode } from "postcode-validator";

export enum profileTypeEnum {
  INDIVIDUAL = 1,
  BUSINESS = 2,
  All = 3,
}
export enum PartnerStatusEnum {
  InActive = 0,
  Active = 1,
  All = 2,
}
export enum OtpTypeEnum {
  WHATSAPP = 1,

  TEXT_MSG = 2,
}
export enum notificationMethodEnum {
  OFF = 0,
  WHATSAPP = 1,
  TEXT_MSG = 2,
}
export enum notificationTypeEnum {
  INQUIRY_RESERVATION_NOTIFICATION = 0,
  INQUIRY_RESERVATION_REMINDERS = 1,
}

export interface CertiifcateModal {
  certificateAssociate: string;
  certificateNo: string;
  certificateFile: any[];
  expireDate: string;
  type: number;
  certificateVerify: boolean;
  ref_id: string;
  id: string;
}
export interface SchoolCertiifcateList {
  associatationId: string;
  createdAt: string;
  id: string;
  ref_id: string;
  registrationExpiryDate: any;
  registrationFile: string;
  registrationNo: string;
  type: number;
}

export interface SchoolInterface {
  id: string;
  verified: string;
  isContactPersonePhoneNoValid: boolean;
  isOwnerPhoneNoValid: boolean;
  isnotificationPhoneNoValid: boolean;
  notificationEmailCheck: string;
  contactEmailCheck: string;
  ownerEmailCheck: string;
  contactPhoneNoCheck: string;
  NotificationPhoneNoCheck: string;
  ownerPhoneNoCheck: string;
  businessName: string;
  profilePic: string;
  featuredPhoto: string;
  profileType: profileTypeEnum;
  description: string;
  webUrl: string;
  licenseVerify: boolean;
  houseNumber: string;
  state: string;
  city: string;
  postalCode: string;
  country: string;
  firstName: string;
  sameAsContact: boolean;
  lastName: string;
  middleName: string;
  ownerEmail: string;
  ownerDob: string;
  ownerPhoneno: string;
  language: string;
  notificationMethod: notificationMethodEnum;
  phoneNotificationType: string[];
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmail: string;
  contactPersonPhoneno: string;
  createdAt: string;
  updatedAt: string;
  emailNotificationType: false;
  status: string;
  countryCode: CountryCode;
  countryName: string;
  notificationEmail: string;
  notificationPhoneNo: string;
  emailVerified: boolean | false;
  phonenoVerified: boolean | false;
  registrationNo: string;
  registrationExpiryDate: string;
  Verificationtoken: string;
  contactPersonPhonenoVerify: boolean;
  ownerPhonenoVerify: boolean;
  notificationEmailVerify: boolean;
  notificationPhoneNoVerify: boolean;
  contactPersonEmailVerify: boolean;
  ownerEmailVerify: boolean;
  ownerFirstName: string;
  ownerMiddleName: string;
  ownerLastName: string;
  email: string;
  contactEmailToken: string;
  ownerEmailToken: string;
  notificationEmailToken: string;
}

export enum EmailVerificationTypeEnum {
  USER_SIGN_UP_email = 1,
  PARTNER_SIGN_UP_EMAIL = 2,
  CONTACT_PERSON_EMAIL = 3,
  BUSINESS_OWNER_EMAIL = 4,
  NOTIFICATION_EMAIL = 5,
}
export interface RegistrationModal {
  registrationNo: string;
  registrationFile: any[];
  registrationExpiryDate: any;
  registrationVerify: boolean;
  ref_id: string;
  registrationId: string;
}
export interface isDraftModalInterface {
  isDraftBasicDetail: boolean;
  isDraftRegistrationAddress: boolean;
  isDraftContactDetail: boolean;
  isDraftBusinessOwnerDetail: boolean;
  isDraftLicencesDetail: boolean;
  isDraftNotification: boolean;
}
export interface SchoolRegistrationList {
  registrationId: string;
  registrationNo: string;
  registrationFile: any[];
  registrationExpiryDate: any;
  registrationVerify: boolean;
  ref_id: string;
}

export interface EmailVerificationInterface {
  status: boolean;
  message: string;
  data: string;
}
export interface StatusChangeFormInterface {
  id: string;
  status: boolean;
}
export interface AdminSchoolState {
  SchoolList: SchoolInterface[];
  SchoolFormData: SchoolInterface;
  isDraftModal: isDraftModalInterface;
  OTP: string;
  showOTPField: boolean;
  certificate: CertiifcateModal;
  certificateList: CertiifcateModal[];
  SchoolCertificate: SchoolCertiifcateList[];
  Registration: RegistrationModal;
  RegistrationList: RegistrationModal[];
  SchoolRegistration: SchoolRegistrationList[];
  token: string;
  editIndex: number;
  SchoolID: string;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  editModal: boolean;
  schoolEditTab: string;
  featuredPhotoShow: boolean;
  profilePhotoShow: boolean;
  statusFilter: PartnerStatusEnum;
  profileTypeFilter: profileTypeEnum;
  createdOnFilter: number | 0;
  date: any | "";
  emailVerification: EmailVerificationInterface;
  StatusChangeForm: StatusChangeFormInterface;
  sortColumn: string;
  sortDir: string;
  isEmailLoading: boolean;
}

export type InitialAdminUserState = AdminSchoolState;
