import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { ContextInterface, CustomerHelpSupportState } from "../types";
import { log } from "../../../../utils/logger";

export const initialState: CustomerHelpSupportState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  customerSupportList: [],
  customerSupportFormData: {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    userType: 1,
    context: "",
    query: "",
  },
  isadd: false,
  dissableCheck: false,
  editIndex: 0,
  contextList: [],
  stillMakingRetreatsPlanList: [],
  stillMakingRetreatsPlanFormData: { id: "", email: "" },
};

export const useClinicSlice = createSlice({
  name: "customerHelpSupport",
  initialState,
  reducers: {
    updateCustomerSupportFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `customerSupportFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateStillMakingRetreatsPlanFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `stillMakingRetreatsPlanFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.customerSupportFormData.id = "";
      state.customerSupportFormData.firstname = "";
      state.customerSupportFormData.lastname = "";
      state.customerSupportFormData.email = "";
      state.customerSupportFormData.userType = 1;
      state.customerSupportFormData.context = "";
      state.customerSupportFormData.query = "";
    },
    clearStillMakingPlanForm: (state) => {
      state.stillMakingRetreatsPlanFormData.id = "";
      state.stillMakingRetreatsPlanFormData.email = "";
    },

    sendMessage: (state, action: PayloadAction<{token:string, callback: () => void }>) => {
      log("Your language add");
    },
    stillMakingRetreatsPlan: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setHelpContextList: (
      state,
      action: PayloadAction<Array<ContextInterface>>
    ) => {
      state.contextList = action.payload;
    },
    doGetContextList: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
