import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolDashboard) {
      return state.schoolDashboard;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectRevenueData = createSelector(
  [selectDomain],
  (state) => state.revenueGraph
);
export const selectClickLists = createSelector(
  [selectDomain],
  (state) => state.clicksGraph
);
export const selectBookingLists = createSelector(
  [selectDomain],
  (state) => state.bookingGraph
);
export const selectMessageReplyLists = createSelector(
  [selectDomain],
  (state) => state.messageReplyChart
);
export const selectoverAllClicksListingsFilter = createSelector(
  [selectDomain],
  (state) => state.overAllClicksListingsFilter
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectClabelGraph = createSelector(
  [selectDomain],
  (state) => state.labelGraph
);
export const SelectAverageReplyScore = createSelector(
  [selectDomain],
  (state) => state.averageReplyScore
);
export const SelectAverageReplyTime = createSelector(
  [selectDomain],
  (state) => state.averageReplyTime
);
