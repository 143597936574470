import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";

import CustomToast from "../../../../components/UI/CustomToast";
import {
  doDeleteEmailNewsLetter,
  getAllEmailNewsLetterList,
  GetStoryExportReportRequest,
} from "../../../../utils/request";
import {
  SelectCreatedOnFilter,
  SelectDateFilter,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortDir,
  SelectSortColumn,
} from "./selector";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { BASEURL } from "../../../../environment";

export function* getAllEmailNewsLetterRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const pageNo: number = yield select(SelectPageNo);
    const createdOnFilter: string = yield select(SelectCreatedOnFilter);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
    const dataBody = {
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      createdOnFilter: createdOnFilter,
    };
    const response: AxiosResponse = yield call(
      getAllEmailNewsLetterList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? "&search=" + searchText : ""}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      dataBody
    );
    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTotalList(response.data.data[0].meta.total));
    yield put(actions.setEmailNewsLetterList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* DeleteNewsLetterRequest(action: { payload: any }) {
  yield delay(500);
  if (action.payload.length === 0) {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      doDeleteEmailNewsLetter,
      action.payload
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getGetUserExportList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const createdOnFilter: string = yield select(SelectCreatedOnFilter);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
    const dataBody = {
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      createdOnFilter: createdOnFilter,
    };
    const response: AxiosResponse = yield call(
      GetStoryExportReportRequest,
      `?&pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? "&search=" + searchText : ""
      }`,
      dataBody
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setexportFile(response.data.filename));
    window.open(BASEURL + "/csv/" + response.data.filename, "_blank");
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
      // toast.error(error.message);
    }
  }
}
export function* EmailNewsLetterRepoSaga() {
  yield takeLatest(
    actions.doGetEmailNewsLetterList,
    getAllEmailNewsLetterRequest
  );
  yield takeLatest(actions.deleteEmailNewsLetter, DeleteNewsLetterRequest);
  yield takeLatest(actions.doExportList, getGetUserExportList);
}
