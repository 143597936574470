export enum SurroundingEnvironmentTypeEnum {
  FIXED = 1,
  TEMPORARY = 2,
}
export interface SurroundingEnvironmentModel {
  name: string;
  id: string;
  type: SurroundingEnvironmentTypeEnum;
  schoolId: "";
  listingType: ListingTypeEnum;
  retreatId: string;
}
export enum ListingTypeEnum {
  ONLINE = 1,
  OFFLINE = 2,
  BOTH = 3,
}
export interface GeoLocationInterFace {
  lat: number;
  long: number;
}

export interface LocationInterface {
  id: string;
  lat: number;
  long: number;
  retreatId: string;
  retreatLocation: string;
  surroundingEnvironmentForm: SurroundingEnvironmentModel;
  surroundingEnvironment: SurroundingEnvironmentModel[];
  locationOverview: string;
  weatherOverview: string;
  geoLocationForm: GeoLocationInterFace;
  geoLocation: number[];
  isDraft: boolean;
  count: number;
  country: string;
  state: string;
  city: string;
}
export interface SchoolListingLocationState {
  locationForm: LocationInterface;
  locationList: LocationInterface[];
  surroundingEnvironmentFixedList: SurroundingEnvironmentModel[];
  surroundingEnvironmentTempList: SurroundingEnvironmentModel[];
  pageNo: number;
  loading: boolean;
  AddressList: [];
  ListingsId: any;
  pageSize: number;
  locationModalType: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  offlineOrOnline: any;
}

export type InitialSchoolListingLocationState = SchoolListingLocationState;
