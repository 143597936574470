import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AccommodationCategoryInterface,
  AccommodationPhotosModel,
  AccommodationRoomTypeInterface,
  AccommodationsInterface,
  AccommodationState,
} from "../types";
import { FileUploadItems } from "../../Listings/Media/types";
import { log } from "../../../../utils/logger";

export const initialState: AccommodationState = {
  accommodationList: [],
  accommodationFormData: {
    accommodationPhotos: [],
    id: "",
    accommodationCategory: "",
    accommodationSharing: 0,
    accommodationName: "",
    roomType: "",
    maxOccupancy: 0,
    description: "",
    photos: "",
    isEdit: false,
  },
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  AccommodationCategoryList: [],
  AccommodationRoomTypeList: [],
  totalAccommodation: 0,
  imageArray: [],
  uploadFiles: [],

  uploadingCurrentIndex: -1,
  Crop: false,
  accomodationLoader: false,
};

export const useClinicSlice = createSlice({
  name: "accommodationState",
  initialState,
  reducers: {
    updateSchoolAccommodationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `accommodationFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    setUploadingCurrentIndex: (state, action: PayloadAction<number>) => {
      state.uploadingCurrentIndex = action.payload;
    },
    setUploadFiles: (state, action: PayloadAction<FileUploadItems[]>) => {
      state.uploadFiles = action.payload;
    },
    setImageArray: (state, action: PayloadAction<any>) => {
      state.imageArray.push(action.payload);
    },
    toggalRetreatPhotosCropShowModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.Crop = action.payload;
    },
    clearFrom: (state) => {
      state.accommodationFormData.id = "";
      state.accommodationFormData.accommodationCategory = "";
      state.accommodationFormData.accommodationSharing = 0;
      state.accommodationFormData.accommodationName = "";
      state.accommodationFormData.roomType = "";
      state.accommodationFormData.maxOccupancy = 0;
      state.accommodationFormData.description = "";
      state.accommodationFormData.photos = "";
      state.imageArray = [];
    },

    AddAccommodation: (
      state,
      action: PayloadAction<{
        schoolID: string;
        localStorageSchoolId: string;
        callback: () => void;
      }>
    ) => {},
    updateDataHelp: (
      state,
      action: PayloadAction<{ schoolID: string; callback: () => void }>
    ) => {},

    DeleteAccomodation: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetAccommodationList: (state, action: PayloadAction<string>) => {},
    languageEdit: (state, action: PayloadAction<any>) => {},

    setDataInForm: (state, action: PayloadAction<AccommodationsInterface>) => {
      state.accommodationFormData = action.payload;
    },
    setaccomodationLoader: (state, action: PayloadAction<boolean>) => {
      state.accomodationLoader = action.payload;
    },
    setAccomodationlistData: (
      state,
      action: PayloadAction<Array<AccommodationsInterface>>
    ) => {
      state.accommodationList = action.payload;
    },
    photosData: (state, action: PayloadAction<any>) => {
      state.accommodationFormData.accommodationPhotos = action.payload;
    },
    setPhotosData: (state, action: PayloadAction<AccommodationPhotosModel>) => {
      state.accommodationFormData.accommodationPhotos.push(action.payload);
    },
    clearAccomodationModalForm: (state) => {
      state.accommodationFormData.accommodationCategory = "";
      state.accommodationFormData.accommodationName = "";
      state.accommodationFormData.accommodationPhotos = [];
      state.accommodationFormData.accommodationSharing = 0;
      state.accommodationFormData.description = "";
      state.accommodationFormData.id = "";
      state.accommodationFormData.maxOccupancy = 0;
      state.accommodationFormData.roomType = "";
    },

    doAddAccommodationImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},

    setAccommodationCategoryList: (
      state,
      action: PayloadAction<Array<AccommodationCategoryInterface>>
    ) => {
      state.AccommodationCategoryList = action.payload;
    },
    setAccommodationRoomTypeList: (
      state,
      action: PayloadAction<Array<AccommodationRoomTypeInterface>>
    ) => {
      state.AccommodationRoomTypeList = action.payload;
    },
    doGetAccommodationById: (state, action: PayloadAction<any>) => {},
    doGetAccommodationCategory: (state) => {},
    doGetAccommodationRoomType: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    deletePhotos: (state, action: PayloadAction<any>) => {
      log(action.payload, "deleteFoodPhotos");
      state.accommodationFormData.accommodationPhotos.splice(action.payload, 1);
    },
    setTotalAccomodationlist: (state, action: PayloadAction<number>) => {
      state.totalAccommodation = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
