import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsModal } from "../../redux/slice";
import { AxiosResponse } from "axios";
import { actions as actionUploader } from "../../../../Frontend/uploader/redux/slice";
import CustomToast from "../../../../../components/UI/CustomToast";
import { actions as actionResponce } from "../../redux/slice";
import {
  AddSchoolMedia,
  AddSchoolVideoMedia,
  GetMediaFormdataByIdRequest,
  GetMediaVideoFormdataByIdRequest,
  ListingsMediaUplodeRequest,
} from "../../../../../utils/request";
import { END, eventChannel } from "redux-saga";
import {
  SelectImageArray,
  SelectRetreatimageArray,
  selectFoodPhotoForm,
  selectFoodVideoForm,
  selectMediaForm,
  selectMediaVideoForm,
  selectRetreatPhotoForm,
  selectRetreatVideoForm,
} from "./selector";
import {
  MediaFormInterface,
  MediaVideoFormInterface,
  foodPhotos,
  foodVideos,
  retreatPhotos,
  retreatVideos,
} from "../types";
import { watchOnProgress } from "../../../../Frontend/uploader/redux/saga";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadPromise = ListingsMediaUplodeRequest(
    payload,
    (progressEvent: any) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted == 100) emit(END);
      else emit(percentCompleted);
    }
  );

  return [uploadPromise, chan];
}

export function* MediaRetreatsUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  const PhotoForm: retreatPhotos[] = yield select(selectRetreatPhotoForm);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      yield put(actionUploader.setClearimageUploadeProgress());
      return;
    }
    var arrayImageStatus =
      PhotoForm.filter(
        (val: retreatPhotos) => response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setRetreatPhotoData({
          photo: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    // yield put(actionsModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* MediaFoodsUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  const FoodPhotoForm: foodPhotos[] = yield select(selectFoodPhotoForm);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      FoodPhotoForm.filter(
        (val: foodPhotos) => response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setFoodPhotoData({
          photo: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    yield put(actionsModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* MediaAddRequest(action: {
  payload: { isOnlineOffline: any; callback: any };
}) {
  yield delay(500);
  const form: MediaFormInterface = yield select(selectMediaForm);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(AddSchoolMedia, form);
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* MediaRetreatsVideoUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  const form: retreatVideos[] = yield select(selectRetreatVideoForm);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      form.filter(
        (val: retreatVideos) => response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setRetreatVideoData({
          video: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    yield put(actionsModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* MediaFoodsVideoUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  const form: foodVideos[] = yield select(selectFoodVideoForm);
  try {
    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    var arrayImageStatus =
      form.filter(
        (val: foodVideos) => response.data.data.fileName == val.fileName
      ).length > 0;
    if (arrayImageStatus == false) {
      yield put(
        actions.setFoodVideoData({
          video: response.data.data.name,
          fileName: response.data.data.fileName,
        })
      );
      CustomToast(response.data.message, "SUCCESS");
    } else {
      CustomToast("Image Already Uploaded", "ERROR");
    }
    yield put(actionUploader.setClearimageUploadeProgress());
    yield put(actionsModal.toggelCropClose());
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actionsModal.toggelCropClose());
    yield put(actionUploader.setClearimageUploadeProgress());
    CatchBlockFunction(error);
  }
}
export function* MediaAddVideoRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: MediaVideoFormInterface = yield select(selectMediaVideoForm);
  try {
    yield put(actions.Setloader(true));
    const response: AxiosResponse = yield call(AddSchoolVideoMedia, form);
    yield put(actions.Setloader(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.Setloader(false));

    CatchBlockFunction(error);
  }
}
export function* getMediaByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      GetMediaFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));

    yield put(actions.SetImageModalType(response.data.model));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setRetreatPhotosFormData(response.data));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "photo_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));

    CatchBlockFunction(error);
  }
}
export function* getMediaVideoByIdRequest(action: { payload: any }) {
  yield delay(500);

  try {
    yield put(actions.Setloader(true));
    const response: AxiosResponse = yield call(
      GetMediaVideoFormdataByIdRequest,
      action.payload
    );
    yield put(actions.Setloader(false));
    yield put(actions.SetVideoModalType(response.data.model));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.Setloader(false));
    yield put(actions.setRetreatVideoFormData(response.data));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "video_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.Setloader(false));
    CatchBlockFunction(error);
  }
}
export function* adminRepoSagaSchoolListingsMedia() {
  yield takeLatest(actions.doAddRetreatImage, MediaRetreatsUploadRequest);
  yield takeLatest(actions.doAddFoodImage, MediaFoodsUploadRequest);
  yield takeLatest(actions.AddMedia, MediaAddRequest);
  yield takeLatest(actions.doAddRetreatVideo, MediaRetreatsVideoUploadRequest);
  yield takeLatest(actions.doAddFoodVideo, MediaFoodsVideoUploadRequest);
  yield takeLatest(actions.AddMediaVideo, MediaAddVideoRequest);
  yield takeLatest(actions.doGetMediaDataByID, getMediaByIdRequest);
  yield takeLatest(actions.doGetMediaVideoDataByID, getMediaVideoByIdRequest);
}
