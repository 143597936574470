import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import set from "lodash.set";
import {
  couponDiscountTypeEnum,
  couponFormInterface,
  SchoolCouponState,
} from "../types";

export const initialState: SchoolCouponState = {
  couponForm: {
    id: "",
    couponName: "",
    couponCode: "",
    expiryDate: "",
    discountType: couponDiscountTypeEnum.PERCENTAGE,
    discount: "",
    listing: [],
    quantity: 0,
    remaining: 0,
  },
  pageNo: 1,
  PageSize: 10,
  totalCouponCount: 0,
  couponList: [],
  refferalsList: [],
  totalRefferals: 0,
  promotionPageNo: 1,
  promotionPageSize: 10,
  loading: false,
  sortColumn: "",
  sortDir: "",
};

export const useClinicSlice = createSlice({
  name: "SchoolCouponState",
  initialState,
  reducers: {
    updateCouponFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `couponForm.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.couponForm.id = "";
      state.couponForm.couponCode = "";
      state.couponForm.couponName = "";
      state.couponForm.discount = "";
      state.couponForm.discountType = couponDiscountTypeEnum.PERCENTAGE;
      state.couponForm.expiryDate = "";
      state.couponForm.listing = [];
      state.couponForm.remaining = 0;
      state.couponForm.quantity = 0;
    },
    clearDate: (state) => {
      state.couponForm.id = "";
      state.couponForm.expiryDate = "";
    },
    addCoupon: (
      state,
      action: PayloadAction<{ schoolId: string; callback: () => void }>
    ) => {},
    getCouponDataById: (
      state,
      action: PayloadAction<{ CouponId: string; callback: () => void }>
    ) => {},
    DeleteCouponDataById: (
      state,
      action: PayloadAction<{ CouponId: string; callback: () => void }>
    ) => {},
    getCouponList: (
      state,
      action: PayloadAction<{ schoolId: string; callback: () => void }>
    ) => {},

    getRefferalsList: (state, action: PayloadAction<any>) => {},
    setDataInForm: (state, action: PayloadAction<couponFormInterface>) => {
      state.couponForm = action.payload;
    },
    setCouponlistData: (
      state,
      action: PayloadAction<Array<couponFormInterface>>
    ) => {
      state.couponList = action.payload;
    },
    setRefferalsListData: (state, action: PayloadAction<Array<any>>) => {
      state.refferalsList = action.payload;
    },
    setTotalRefferals: (state, action: PayloadAction<number>) => {
      state.totalRefferals = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    settotalCount: (state, action: PayloadAction<number>) => {
      state.totalCouponCount = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
