import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AllCleanAndSafeMeasureInterface,
  CleanAndSafeMeasuresDataTypeEnum,
  cleanAndSafeMeasuresListInterface,
  FaqDragListInterface,
  ListingAutoReplyInterface,
  ListingCleanSafeMeasuresInterface,
  ListingFaqInterface,
  SchoolListingOtherState,
} from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: SchoolListingOtherState = {
  listingFaqList: [],
  cleanandSafeSelectedList: [],
  AutoReplyModalType: 0,
  CleanAndSafeModalType: 0,
  FAQModalType: 0,
  listingFaqFormData: {
    id: "",
    question: "",
    answer: "",
    requestId: "",
    retreatId: "",
    isDraft: false,
    count: 0,
  },
  listingAutoReplyList: [],
  listingAutoReplyFormData: {
    id: "",
    autoReply: false,
    autoReplyMessage: "",
    followUp: false,
    followUpMessage: "",
    sendAfter: 0,
    retreatId: "",
    isDraft: false,
    count: 0,
  },
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  listingCleanSafeMeasuresList: [],
  listingCleanSafeMeasuresFormData: {
    id: "",
    retreatId: "",
    CleanAndSafetyMeasure: [],
    healthAndHygiene: "",
    isDraft: false,
    count: 0,
  },
  cleanAndSafeMeasuresList: [],
  listingFaqDragList: [],
  listingFaqDragFormData: {
    id: "",
    faqList: [],
    retreatId: "",
  },
  autoReplyLoading: false,
  cleanLoader: false,
  faqLoader: false,
};

export const useClinicSlice = createSlice({
  name: "schoolListingOther",
  initialState,
  reducers: {
    updateSchoolListingFaqFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `listingFaqFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    updateSchoolAutoReplyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      //
      set(
        state,
        `listingAutoReplyFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateSchoolCleanSafeMeasuresFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `listingCleanSafeMeasuresFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    clearCleanAndMeasureFrom: (state) => {
      state.listingCleanSafeMeasuresFormData.CleanAndSafetyMeasure = [];
      state.listingCleanSafeMeasuresFormData.healthAndHygiene = "";
      state.listingCleanSafeMeasuresFormData.id = "";
    },
    clearFaqFrom: (state) => {
      state.listingFaqFormData.id = "";
      state.listingFaqFormData.question = "";
      state.listingFaqFormData.answer = "";
    },
    clearFaqDragFrom: (state) => {
      state.listingFaqDragFormData.id = "";
      state.listingFaqDragFormData.faqList = [];
    },
    clearAutoReplyFormData: (state) => {
      state.listingAutoReplyFormData.id = "";
      state.listingAutoReplyFormData.autoReply = false;
      state.listingAutoReplyFormData.autoReplyMessage = "";
      state.listingAutoReplyFormData.followUp = false;
      state.listingAutoReplyFormData.followUpMessage = "";
      state.listingAutoReplyFormData.isDraft = false;
      state.listingAutoReplyFormData.count = null || 0;
      state.listingAutoReplyFormData.sendAfter = 0 || null;
    },

    AddFaq: (state, action: PayloadAction<{ callback: () => void }>) => {},
    DeleteFaq: (
      state,
      action: PayloadAction<{ id: string; retreatid: any }>
    ) => {},

    doGetListingFaqList: (state, action: PayloadAction<any>) => {},

    setlistingFaqListData: (
      state,
      action: PayloadAction<Array<ListingFaqInterface>>
    ) => {
      state.listingFaqList = action.payload;
    },
    AddAutoReplyDetails: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      // state.SchoolList.push(state.SchoolFormData);
    },

    AddCleanAndSafeMeasures: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    doGetFaqforEdit: (
      state,
      action: PayloadAction<{ id: string; retreatid: any }>
    ) => {},
    doGetListingAutoReplySchoolDataByID: (
      state,
      action: PayloadAction<any>
    ) => {},
    doGetListingCleanAndSafeMeasuresSchoolDataByID: (
      state,
      action: PayloadAction<any>
    ) => {},
    AddFaqList: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your Instructors Skills add");
      // state.SchoolList.push(state.SchoolFormData);
    },

    setFaqForEdit: (state, action: PayloadAction<ListingFaqInterface>) => {
      state.listingFaqFormData = action.payload;
    },
    doGetAllCleanAndSafeMeasuresList: (state) => {
      log("Local Guidelines List");
    },
    doGetCleaningList: (state) => {
      log("tCleaning List");
    },
    doGetPhysicalDistancingList: (state) => {},
    doGetSafetyFeaturesListList: (state) => {},
    setLocalGuidelinesList: (
      state,
      action: PayloadAction<Array<cleanAndSafeMeasuresListInterface>>
    ) => {
      state.cleanAndSafeMeasuresList = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    doAddListOfSelectedCheckBox: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {
      state.listingCleanSafeMeasuresFormData.CleanAndSafetyMeasure.push({
        id: action.payload.data,
        type: action.payload.type,
      });
    },
    DeleteCleanandSafetyMeasureList: (state, action: PayloadAction<any>) => {
      log(action.payload, "DeleteFaqModalList");
      state.listingCleanSafeMeasuresFormData.CleanAndSafetyMeasure.splice(
        state.listingCleanSafeMeasuresFormData.CleanAndSafetyMeasure.findIndex(
          (obj) => obj.id === action.payload.data
        ),
        1
      );
    },

    SetListingAutoReplyFormDataById: (
      state,
      action: PayloadAction<ListingAutoReplyInterface>
    ) => {
      state.listingAutoReplyFormData = action.payload;
    },
    SetListingCleanAndSafeMeasuresFormDataById: (
      state,
      action: PayloadAction<ListingCleanSafeMeasuresInterface>
    ) => {
      state.listingCleanSafeMeasuresFormData = action.payload;
    },

    doGetListingAutoReplyDataByID: (state) => {},
    doGetListingCleanAndSafeMeasuresDataByID: (state) => {},
    setFaqListOfRetreats: (
      state,
      action: PayloadAction<Array<ListingFaqInterface>>
    ) => {
      state.listingFaqList = action.payload;
      state.listingFaqDragFormData.faqList = [];
      set(state, `listingFaqDragFormData.faqList`, action.payload);
    },

    setfaqdragbyid: (state) => {},
    updateFaqDragListFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `listingFaqDragFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    SelectInstructorForListings: (
      state,
      action: PayloadAction<FaqDragListInterface>
    ) => {
      state.listingFaqDragFormData.retreatId = action.payload?.retreatId;
      // state.listingFaqDragFormData.faqList.push(action.payload.listingFaqList);
    },
    SetAutoReplyModalType: (state, action: PayloadAction<number>) => {
      state.AutoReplyModalType = action.payload;
    },
    SetCleanandSafeModalType: (state, action: PayloadAction<number>) => {
      state.CleanAndSafeModalType = action.payload;
    },
    SetCleanandSafeMeasureList: (state, action: PayloadAction<any>) => {
      state.cleanandSafeSelectedList = action.payload;
    },
    SetFaqModalType: (state, action: PayloadAction<number>) => {
      state.FAQModalType = action.payload;
    },
    cleanAndSafeDraft: (state, action: PayloadAction<boolean>) => {
      state.listingCleanSafeMeasuresFormData.isDraft = action.payload;
    },
    CleanAndSafeCount: (state) => {
      state.listingCleanSafeMeasuresFormData.count =
        state.listingCleanSafeMeasuresFormData.count + 1;
    },
    AutoReplyCount: (state) => {
      state.listingAutoReplyFormData.count =
        state.listingAutoReplyFormData.count + 1;
    },
    FaqCount: (state) => {
      state.listingFaqFormData.count = state.listingFaqFormData.count + 1;
    },
    FaqDraft: (state, action: PayloadAction<boolean>) => {
      state.listingFaqFormData.isDraft = action.payload;
    },
    AutoReplyDraft: (state, action: PayloadAction<boolean>) => {
      state.listingAutoReplyFormData.isDraft = action.payload;
    },
    setAutoReplyLoading: (state, action: PayloadAction<boolean>) => {
      state.autoReplyLoading = action.payload;
    },
    setcleanLoader: (state, action: PayloadAction<boolean>) => {
      state.cleanLoader = action.payload;
    },
    setfaqLoader: (state, action: PayloadAction<boolean>) => {
      state.faqLoader = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
