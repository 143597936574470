import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  ListingTypeEnum,
  LocationInterface,
  SchoolListingLocationState,
  SurroundingEnvironmentModel,
} from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: SchoolListingLocationState = {
  pageNo: 1,
  ListingsId: "",
  pageSize: 0,
  totalRow: 0,
  search: "",
  locationModalType: 0,
  dissableCheck: false,
  offlineOrOnline: "",
  surroundingEnvironmentFixedList: [],
  surroundingEnvironmentTempList: [],
  loading: false,
  locationForm: {
    isDraft: false,
    lat: 0,
    long: 0,
    id: "",
    retreatId: "",
    retreatLocation: "",
    surroundingEnvironmentForm: {
      listingType: ListingTypeEnum.BOTH,
      schoolId: "",
      name: "",
      id: "",
      type: 2,
      retreatId: "",
    },
    surroundingEnvironment: [],
    locationOverview: "",
    weatherOverview: "",
    geoLocation: [],
    geoLocationForm: {
      lat: 0,
      long: 0,
    },
    count: 0,
    country: "",
    state: "",
    city: "",
  },
  locationList: [],
  AddressList: [],
};

export const useClinicSlice = createSlice({
  name: "locationState",
  initialState,
  reducers: {
    doGetLocationDataByID: (state) => {},
    doGetLocationSchoolDataByID: (state, action: PayloadAction<any>) => {},
    SetLocationDataById: (state, action: PayloadAction<LocationInterface>) => {
      if (action.payload) state.locationForm = action.payload;

      if (action.payload && action.payload.geoLocation) {
        state.locationForm.lat = action.payload.geoLocation[1];
        state.locationForm.long = action.payload.geoLocation[0];
      } else {
        state.locationForm.lat = 0;
        state.locationForm.long = 0;
      }

      state.locationForm.isDraft = false;
      state.locationForm.surroundingEnvironmentForm = {
        listingType: ListingTypeEnum.BOTH,
        name: "",
        retreatId: "",
        type: 2,
        id: "",
        schoolId: "",
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLocationIsDraft: (state, action: PayloadAction<boolean>) => {
      state.locationForm.isDraft = action.payload;
    },
    setAddressList: (state, action: PayloadAction<any>) => {
      state.AddressList = action.payload;
    },
    doGetAddressList: (state, action: PayloadAction<string>) => {},
    LocationCount: (state) => {
      state.locationForm.count = state.locationForm.count + 1;
    },
    updateSurroundingEnvironmentStyleFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `locationForm.surroundingEnvironmentForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateGeoLocationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `locationForm.geoLocationForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateSchoolListingLocationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `locationForm.${action.payload.key}`, action.payload.value);
    },

    clearform: (state) => {
      state.locationForm.surroundingEnvironmentForm.name = "";
      state.locationForm.surroundingEnvironmentForm.id = "";
      state.locationForm.surroundingEnvironmentForm.retreatId = "";
    },
    surroundingEnvironmentEditRequest: (
      state,
      action: PayloadAction<SurroundingEnvironmentModel>
    ) => {
      state.locationForm.surroundingEnvironmentForm = action.payload;
    },
    clearGeoform: (state) => {
      state.locationForm.geoLocationForm.long = 0;
      state.locationForm.geoLocationForm.lat = 0;
    },
    SelectSurroundingEnvironmentStyleType: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.locationForm.surroundingEnvironment.indexOf(
          action.payload.value
        ) !== -1
      ) {
        state.locationForm.surroundingEnvironment.splice(
          state.locationForm.surroundingEnvironment.indexOf(
            action.payload.value
          ),
          1
        );
      } else {
        state.locationForm.surroundingEnvironment.push(action.payload.value);
      }
    },
    AddNewSurroundingEnvironmentStyle: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    AddListingLocation: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      // state.SchoolList.push(state.SchoolFormData);
    },

    setListingsID: (state, action: PayloadAction) => {
      state.ListingsId = action.payload;
    },

    getFixedSurroundingEnvironmentTypeList: (state) => {},
    getTempSurroundingEnvironmentList: (
      state,
      action: PayloadAction<any>
    ) => {},
    SetLocationModalType: (state, action: PayloadAction<number>) => {
      state.locationModalType = action.payload;
    },
    setSurroundingEnvironmentFixedList: (
      state,
      action: PayloadAction<Array<SurroundingEnvironmentModel>>
    ) => {
      state.surroundingEnvironmentFixedList = action.payload;
    },
    setSurroundingEnvironmentTempList: (
      state,
      action: PayloadAction<Array<SurroundingEnvironmentModel>>
    ) => {
      state.surroundingEnvironmentTempList = action.payload;
    },
    DeletePills: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    clearLocationFrom: (state) => {
      state.locationForm.id = "";
      state.locationForm.lat = null || 0;
      state.locationForm.long = null || 0;
      state.locationForm.retreatLocation = "";
      state.locationForm.surroundingEnvironment = [];
      state.locationForm.locationOverview = "";
      state.locationForm.weatherOverview = "";
      state.locationForm.isDraft = false;
      state.locationForm.count = null || 0;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
