import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AllActivityInterface,
  CreateListingForm,
  ExpertiseLevelEnum,
  ItineraryInterface,
  ListingTypeEnum,
  OverViewInterface,
  RequestTypeEnum,
  RetreatStyleModel,
  SchoolListingState,
} from "../types";
import { log } from "../../../../utils/logger";

export const initialState: SchoolListingState = {
  CreateListingForm: {
    activity: "",
    country: "",
    file: [],
    id: "",
    language: "",
    retreatType: 3,
    websiteUrl: "",
  },
  itineraryModalType: 0,
  overviewModalType: 0,
  LocationForm: {
    id: "",
  },
  LocationList: [],
  OverviewForm: {
    retreatStylesForm: {
      listingType: ListingTypeEnum.BOTH,
      schoolId: "",
      id: "",
      name: "",
      type: 2,
      retreatId: "",
    },
    id: "",
    expertiseLevel: ExpertiseLevelEnum.BIGINNER,
    file: [],
    overview: "",
    retreatId: "",
    retreatStyles: [],
    retreatTitle: "",
    retreatType: 3,
    websiteUrl: "",
    activity: "",
    isDraft: false,
    count: 0,
  },
  OverViewList: [],
  ListingsId: "",
  AllMainActivityList: [],
  ListingList: [],
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  dissableCheck: false,
  offlineOrOnline: "",
  OverviewRetreatStyleFixedAdmin: [],
  OverviewRetreatStyleTempSchool: [],
  ItineraryFormData: {
    isDraft: false,
    dailySchedule: [],
    dailyScheduleForm: {
      schedule: "",
      time: "",
    },
    days_instruction: 0,
    id: "",
    itinerary: "",
    maxGroupSize: 0,
    minGroupSize: 0,
    programDuration: 0,
    retreatId: "",
    count: 0,
  },
  totalRetreats: 0,
  ItineraryList: [],
  progressBarModal: false,
  statusFilter: RequestTypeEnum.ALL,
  loading: false,
  VisitedLinks: {
    overview: false,
    location: false,
    itinarary: false,
    foodAndDrinks: false,
    inclushionAndExclushion: false,
    grabTheAttenshion: false,
    instructor: false,
    arrivalInformation: false,
    price: false,
    retreatCalender: false,
    ranking: false,
    bookingPolicy: false,
    photos: false,
    video: false,
    autoReply: false,
    cleanAndSafe: false,
    faq: false,
  },
  apiResponce: {
    overview_API_Responce: false,
    location_API_Responce: false,
    itinarary_API_Responce: false,
    foodDrink_API_Responce: false,
    inclushionExclushioin_API_Responce: false,
    grabTheAtten_API_Responce: false,
    instructor_API_Responce: false,
    arrivalInfo_API_Responce: false,
    price_API_Responce: false,
    calender_API_Responce: false,
    ranking_API_Responce: false,
    bookingPolicy_API_Responce: false,
    photo_API_Responce: false,
    video_API_Responce: false,
    autoReply_API_Responce: false,
    // cleanMeasure_API_Responce: false,
    faq_API_Responce: false,
  },
  Itinararyloading: false,
  isAdmin: "",
};

export const useClinicSlice = createSlice({
  name: "SchoolListingState",
  initialState,
  reducers: {
    updateVisitedLinksValue: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      set(state, `VisitedLinks.${action.payload.key}`, action.payload.value);
    },
    updateApiResponceValue: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      set(state, `apiResponce.${action.payload.key}`, action.payload.value);
    },
    setMainActivityList: (
      state,
      action: PayloadAction<Array<AllActivityInterface>>
    ) => {
      state.AllMainActivityList = action.payload;
    },
    setAllRetreatsList: (
      state,
      action: PayloadAction<Array<CreateListingForm>>
    ) => {
      state.ListingList = action.payload;
    },
    setTotalretreats: (state, action: PayloadAction<number>) => {
      state.totalRetreats = action.payload;
    },
    setListingsID: (state, action: PayloadAction<any>) => {
      state.ListingsId = action.payload;
    },
    setOnlineOrOffline: (state, action: PayloadAction) => {
      state.offlineOrOnline = action.payload;
    },
    setOnPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    doGetALLRetreatsList: (
      state,
      action: PayloadAction<{
        Type: number;
        schoolId: string;
        callback: () => void;
      }>
    ) => {},
    doGetOverviewDataByID: (state) => {},
    doGetOverviewSchoolDataByID: (state, action: PayloadAction<any>) => {},
    doGetALLActivityList: (
      state,
      action: PayloadAction<{ serach: string; callback: () => void }>
    ) => {},
    doGetItinerarySchoolDataByID: (state, action: PayloadAction<any>) => {},
    getRetreatListingsOverviewStyleTypeAdminList: (state) => {},
    getRetreatListingsOverviewStyleTypeSchoolList: (
      state,
      action: PayloadAction<any>
    ) => {},
    setRetreatListingsOverviewStyleTypeAdminList: (
      state,
      action: PayloadAction<Array<RetreatStyleModel>>
    ) => {
      state.OverviewRetreatStyleFixedAdmin = action.payload;
    },
    setRetreatListingsOverviewStyleTypeSchoolList: (
      state,
      action: PayloadAction<Array<RetreatStyleModel>>
    ) => {
      state.OverviewRetreatStyleTempSchool = action.payload;
    },
    AddListings: (
      state,
      action: PayloadAction<{
        callback: (data: { id: string; type: string }) => void;
      }>
    ) => {},
    AddListingsOverview: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateListingsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CreateListingForm.${action.payload.key}`,
        action.payload.value
      );
    },

    clearRetreatsForm: (state) => {
      state.CreateListingForm.activity = "";
      state.CreateListingForm.country = "";
      state.CreateListingForm.language = "";
      state.CreateListingForm.websiteUrl = "";
      state.CreateListingForm.file = [];
      state.CreateListingForm.id = "";
    },
    addImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    addImageRetreats: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    setFileData: (state, action: PayloadAction<string>) => {
      state.CreateListingForm.file.push(action.payload);
    },
    setOverviewFileData: (state, action: PayloadAction<any>) => {
      state.OverviewForm.file.push(action.payload);
    },
    setOverviewIsDraft: (state, action: PayloadAction<boolean>) => {
      state.OverviewForm.isDraft = action.payload;
    },
    setItinararayIsDraft: (state, action: PayloadAction<boolean>) => {
      state.ItineraryFormData.isDraft = action.payload;
    },
    CurrentCount: (state, action: PayloadAction<number>) => {
      state.OverviewForm.count = action.payload;
    },
    setOverviewIsCountTotal: (state, action: PayloadAction<number>) => {
      state.OverviewForm.count = action.payload;
    },
    setItinararayIsCount: (state) => {
      state.ItineraryFormData.count = state.ItineraryFormData.count + 1;
    },
    updateListingsOverviewFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `OverviewForm.${action.payload.key}`, action.payload.value);
    },
    updateListingsRetreastsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CreateListingForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateListingsOverviewRetreatStyleFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `OverviewForm.retreatStylesForm.${action.payload.key}`,
        action.payload.value
      );
    },
    clearRetreatStyleModelform: (state) => {
      state.OverviewForm.retreatStylesForm.name = "";
      state.OverviewForm.retreatStylesForm.id = "";
      state.OverviewForm.retreatStylesForm.retreatId = "";
    },
    RetreatStylesEditRequest: (
      state,
      action: PayloadAction<RetreatStyleModel>
    ) => {
      state.OverviewForm.retreatStylesForm = action.payload;
    },
    SelectStyleTypeOverview: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.OverviewForm.retreatStyles.indexOf(action.payload.value) !== -1
      ) {
        state.OverviewForm.retreatStyles.splice(
          state.OverviewForm.retreatStyles.indexOf(action.payload.value),
          1
        );
      } else {
        state.OverviewForm.retreatStyles.push(action.payload.value);
      }
    },
    AddNewRetreatStyleOverview: (
      state,
      action: PayloadAction<{ RetreatId: any; callback: () => void }>
    ) => {},
    updateListingItineraryFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `ItineraryFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    AddSchoolListingItinerary: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updatestatusFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `statusFilter`, action.payload.value);
    },
    SetOverviewDataById: (state, action: PayloadAction<OverViewInterface>) => {
      state.OverviewForm = action.payload;
      state.OverviewForm.retreatStylesForm = {
        listingType: ListingTypeEnum.BOTH,
        name: "",
        retreatId: "",
        type: 2,
        id: "",
        schoolId: "",
      };
      state.OverviewForm.websiteUrl = action.payload.websiteUrl || "";
      state.OverviewForm.file = action.payload.file || [];
      state.OverviewForm.retreatTitle = action.payload.retreatTitle || "";
      state.OverviewForm.retreatType = action.payload.retreatType || null;
      state.OverviewForm.expertiseLevel = action.payload.expertiseLevel || null;
      state.OverviewForm.overview = action.payload.overview || "";
      state.OverviewForm.activity = action.payload.activity || "";
    },

    SetOverviewModalType: (state, action: PayloadAction<number>) => {
      state.overviewModalType = action.payload;
    },
    SetItineraryModalType: (state, action: PayloadAction<number>) => {
      state.itineraryModalType = action.payload;
    },
    SetItineraryDataById: (
      state,
      action: PayloadAction<ItineraryInterface>
    ) => {
      state.ItineraryFormData = action.payload;
      state.ItineraryFormData.isDraft = false;
    },
    DeleteSchoolRetreats: (state, action: PayloadAction<any>) => {},
    toggelCropSow: (state) => {
      state.progressBarModal = true;
    },
    toggelCropClose: (state) => {
      state.progressBarModal = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItinararyloading: (state, action: PayloadAction<boolean>) => {
      state.Itinararyloading = action.payload;
    },
    getRetretsModalDataByid: (state, action: PayloadAction<any>) => {},
    setRetreatsModalForEdit: (
      state,
      action: PayloadAction<CreateListingForm>
    ) => {
      state.CreateListingForm = action.payload;
    },
    deletePhotos: (state, action: PayloadAction<number>) => {
      state.OverviewForm.file.splice(action.payload, 1);
    },
    DeleteRetreatStyle: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
        RetreatId: string;
      }>
    ) => {},

    clearOverviewFrom: (state) => {
      state.OverviewForm.id = "";
      state.OverviewForm.file = [];
      state.OverviewForm.retreatTitle = "";
      state.OverviewForm.retreatStyles = [];
      state.OverviewForm.expertiseLevel = 1;
      state.OverviewForm.overview = "";
      state.OverviewForm.isDraft = false;
      state.OverviewForm.count = 0;
      state.OverviewForm.activity = "";
    },
    clearIteneryFrom: (state) => {
      state.ItineraryFormData.id = "";
      state.ItineraryFormData.programDuration = 0;
      state.ItineraryFormData.days_instruction = 0;
      state.ItineraryFormData.maxGroupSize = 0;
      state.ItineraryFormData.minGroupSize = 0;
      state.ItineraryFormData.itinerary = "";
      state.ItineraryFormData.dailySchedule = [];
      state.ItineraryFormData.isDraft = false;
    },
    deleteDailySchedule: (state, action: PayloadAction<any>) => {
      log(action.payload, "deleteDailySchedule");
      state.ItineraryFormData.dailySchedule.splice(action.payload, 1);
    },
    setIsAdmin: (state, action: PayloadAction<string|null>) => {
      state.isAdmin = action.payload
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
