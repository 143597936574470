import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import React from "react";
import { CommissionInterface } from "../types";
import { actions } from "./slice";
import { selectCommissionForm } from "./selector";
import CustomToast from "../../../../../components/UI/CustomToast";
import { AxiosResponse } from "axios";
import {
  AddCommission,
  GetCommissionRequest,
} from "../../../../../utils/request";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

export function* CommissionAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CommissionInterface = yield select(selectCommissionForm);
  if (form.offlineMinimumCommision == null) {
    CustomToast("please enter offline minimum commision", "ERROR");
    return;
  }
  if (form.offlineMaximumCommision == null) {
    CustomToast("please enter offline maximum commision", "ERROR");
    return;
  }
  if (form.onlineMinimumCommision == null) {
    CustomToast("please enter online minimum commision", "ERROR");
    return;
  }
  if (form.onlineMaximumCommision == null) {
    CustomToast("please enter online maximum commision", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddCommission, form);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCommissionRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetCommissionRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDataInForm(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* adminCommissionRepoSaga() {
  yield takeLatest(actions.AddCommission, CommissionAddRequest);
  yield takeLatest(actions.doGetCommission, getCommissionRequest);
}
