// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    return state.adminRetreats;
  } else {
    return initialState;
  }
};
export const SelecApproveRetreatsList = createSelector(
  [selectDomain],
  (state) => state.ApproveRetreatsList
);
export const SelecFeaturedChangeRetreatsformData = createSelector(
  [selectDomain],
  (state) => state.featuredChangeForm
);
export const SelecFeaturedChangeRetreatslist = createSelector(
  [selectDomain],
  (state) => state.featuredChangeList
);
export const SelecPendingRetreatsList = createSelector(
  [selectDomain],
  (state) => state.PendingRetreatsList
);
export const SelecRejectedRetreatsList = createSelector(
  [selectDomain],
  (state) => state.RejectedRetreatsList
);
export const SelecApprovePendingForm = createSelector(
  [selectDomain],
  (state) => state.ApprovePendingRequest
);
export const SelecRequestToPublishForm = createSelector(
  [selectDomain],
  (state) => state.RequestToPublishForm
);
export const SelecRequestStatusFilter = createSelector(
  [selectDomain],
  (state) => state.statusFilter
);
export const SelecRequestToCountForm = createSelector(
  [selectDomain],
  (state) => state.RequestToCountForm
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);

export const SelecRejectedPendingForm = createSelector(
  [selectDomain],
  (state) => state.RejectedPendingRequest
);
export const SelectTotalList = createSelector(
  [selectDomain],
  (state) => state.totalList
);
export const SelectSchoolFilter = createSelector(
  [selectDomain],
  (state) => state.schoolFilter
);
export const SelectcountryFilter = createSelector(
  [selectDomain],
  (state) => state.countryFilter
);
export const SelectActivityFilter = createSelector(
  [selectDomain],
  (state) => state.activityFilter
);
export const SelectRankingFilter = createSelector(
  [selectDomain],
  (state) => state.rankingFilter
);
export const SelectCategoryFilter = createSelector(
  [selectDomain],
  (state) => state.CategoryFilter
);
export const SelectcommissionFilter = createSelector(
  [selectDomain],
  (state) => state.commission
);
export const SelecRequestToAdminPendingForm = createSelector(
  [selectDomain],
  (state) => state.RequestToAdminPendingForm
);
export const SelecRequestToDeactiveForm = createSelector(
  [selectDomain],
  (state) => state.requestToDeactiveForm
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortColumnPending = createSelector(
  [selectDomain],
  (state) => state.sortColumnPending
);
export const SelectSortDirPending = createSelector(
  [selectDomain],
  (state) => state.sortDirPending
);
export const SelectSortColumnRejected = createSelector(
  [selectDomain],
  (state) => state.sortColumnRejected
);
export const SelectSortDirRejected = createSelector(
  [selectDomain],
  (state) => state.sortDirRejected
);


export const SelectRequestToActive = createSelector(
  [selectDomain],
  (state) => state.requestToActive
);
export const SelectRequestToDeactive = createSelector(
  [selectDomain],
  (state) => state.requestToDeactive
);
export const SelectRequestToPublishLoader = createSelector(
  [selectDomain],
  (state) => state.requestToPublishLoader
);
export const SelecthandleRequestToEdit = createSelector(
  [selectDomain],
  (state) => state.handleRequestToEdit
);