import { AxiosResponse } from "axios";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  getOnlineRetreatsExperienceData,
  getOnlineTeacherTrainingExperienceData,
} from "../../../../utils/request";
import { actions } from "./slice";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

// export function* doGetOnlineYogaRetreatsListRequest() {
//   yield delay(500);

//   try {
//     yield put(actions.setLoading(true));
//     const response: AxiosResponse = yield call(getOnlineRetreatsExperienceData);

//     yield put(actions.setLoading(false));

//     if (response && !response.data) {
//       CustomToast(response.data.message, "ERROR");

//       return;
//     }

//     yield put(actions.setOnlineYogaRetreatsListData(response.data));
//   } catch (error: any) {
//     yield put(actions.setLoading(false));
//     CatchBlockFunction(error);
//   }
// }
// export function* doGetOnlineTeacherTrainingListRequest() {
//   yield delay(500);

//   try {
//     yield put(actions.setLoading(true));
//     const response: AxiosResponse = yield call(
//       getOnlineTeacherTrainingExperienceData
//     );

//     yield put(actions.setLoading(false));
//     if (response && !response.data) {
//       CustomToast(response.data.message, "ERROR");

//       return;
//     }

//     yield put(actions.setOnlineTeacherTrainingList(response.data));
//   } catch (error: any) {
//     yield put(actions.setLoading(false));
//     CatchBlockFunction(error);
//   }
// }
export function* OnlineExperienceRepoSaga() {
  // yield takeLatest(
  //   actions.doGetOnlineYogaRetreatsList,
  //   doGetOnlineYogaRetreatsListRequest
  // );
  // yield takeLatest(
  //   actions.doGetOnlineTeacherTrainingList,
  //   doGetOnlineTeacherTrainingListRequest
  // );
}
