// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolMassage) {
      return state.schoolMassage;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelecPaymentNotEnabledYetList = createSelector(
  [selectDomain],
  (state) => state.PaymentNotEnabledYetList
);
export const SelectNewMassageList = createSelector(
  [selectDomain],
  (state) => state.NewMassageList
);
export const SelectFollowUPList = createSelector(
  [selectDomain],
  (state) => state.FollowUpList
);
export const SelectAllMassageList = createSelector(
  [selectDomain],
  (state) => state.AllMassage
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPaymentNotEnablePageNo = createSelector(
  [selectDomain],
  (state) => state.PaymentNotEnablePageNo
);
export const SelectFollowUpPageNo = createSelector(
  [selectDomain],
  (state) => state.FollowUpPageNo
);
export const SelectNewMessagePageNo = createSelector(
  [selectDomain],
  (state) => state.NewMessagePageNo
);
export const SelectPackageDetail = createSelector(
  [selectDomain],
  (state) => state.packageDetail
);
export const SelectMassagePackageForm = createSelector(
  [selectDomain],
  (state) => state.MassageForm
);
export const SelectAllMassage = createSelector(
  [selectDomain],
  (state) => state.AllMassage
);
export const SelectpackageList = createSelector(
  [selectDomain],
  (state) => state.PackageList
);
export const SelectAcceptOrDeclineBookingForm = createSelector(
  [selectDomain],
  (state) => state.AcceptOrDeclineBookingForm
);
export const SelectMassageForm = createSelector(
  [selectDomain],
  (state) => state.conversation
);
export const SelectFilterForm = createSelector(
  [selectDomain],
  (state) => state.filter
);
export const SelectAllMassageLength = createSelector(
  [selectDomain],
  (state) => state.AllMassageLength
);
export const SelectPaymentNotEnabledLength = createSelector(
  [selectDomain],
  (state) => state.PaymentNotEnableLength
);
export const SelectNewMessageLength = createSelector(
  [selectDomain],
  (state) => state.NewMessageLength
);
export const SelectFollowUPLength = createSelector(
  [selectDomain],
  (state) => state.FollowUpLength
);
export const SelectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const selectMessageLoading = createSelector(
  [selectDomain],
  (state) => state.messageLoading
);
