import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import queryString from "query-string";
import { AxiosResponse } from "axios";
import {
  SelecApprovePendingForm,
  SelecRejectedPendingForm,
  SelecRequestToDeactiveForm,
  SelecRequestToPublishForm,
  SelectActivityFilter,
  SelectCategoryFilter,
  SelectPageNo,
  SelectPageSize,
  SelectRankingFilter,
  SelectSchoolFilter,
  SelectSearch,
  SelectcommissionFilter,
  SelectcountryFilter,
  SelectSortColumnRejected,
  SelectSortDirRejected,
  SelectSortColumnPending,
  SelectSortDirPending,
  SelectSortDir,
  SelectSortColumn,
} from "./selector";
import {
  ApprovePendingRetreatRequestAPI,
  deleteRetreatListRequest,
  GetRetreatStatusPublishByIdRequest,
  RequestToPublishEditRequestAPI,
  RequestToPublishRetreatRequestAPI,
  GetStatusCountForRetreats,
  GetAllApprovedRetreat,
  GetAllRetreatslistRequest,
  UpdateApproveFeaturedList,
  RequestToDeactiveRetreatAPI,
  GetApprovedRetreatslistAdminRequest,
  GetRejectedRetreatslistAdminRequest,
} from "../../../../utils/request";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  approvePendingRequest,
  rejectedPendingRequest,
  requestToDeactiveInterFace,
  requestToPublishRequestInterFace,
  RequestTypeEnum,
} from "../types";
import { useRouter } from "next/router";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";

export function* getApproveRetreatsListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAllApprovedRetreat,
      `?pageNo=${pageNo}&pageSize=${1000000000000000}${searchText.length > 0 ? "&search=" + searchText : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    // yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setApproveRetreatsList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getRetreatsListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const schoolFilter: string = yield select(SelectSchoolFilter);
    const countryFilter: string = yield select(SelectcountryFilter);
    const CategoryFilter: string = yield select(SelectCategoryFilter);
    const rankingFilter: string = yield select(SelectRankingFilter);
    const SortColumn: string = yield select(SelectSortColumnPending);
    const SortDir: string = yield select(SelectSortDirPending);
    let data = {
      status: action.payload,
      rankingFilter: rankingFilter,
      countryFilter: countryFilter,
      schoolFilter: schoolFilter,
      search: searchText,
      activityFilter: CategoryFilter,
    };
    const response: AxiosResponse = yield call(
      GetAllRetreatslistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalList(response.data[0].meta.total));
    if (action.payload == RequestTypeEnum.REQUEST_TO_PUBLISH) {
      yield put(actions.setPendingRetreatsList(response.data[0].results));
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getApprovedRetreatsListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const schoolFilter: string = yield select(SelectSchoolFilter);
    const countryFilter: string = yield select(SelectcountryFilter);
    const CategoryFilter: string = yield select(SelectCategoryFilter);
    const rankingFilter: string = yield select(SelectRankingFilter);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    let data = {
      rankingFilter: rankingFilter,
      schoolFilter: schoolFilter,
      countryFilter: countryFilter,
      search: searchText,
      activityFilter: CategoryFilter,
    };

    const response: AxiosResponse = yield call(
      GetApprovedRetreatslistAdminRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalList(response.data[0].meta.total));
    yield put(actions.setApproveRetreatsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRejectedRetreatsListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const schoolFilter: string = yield select(SelectSchoolFilter);
    const countryFilter: string = yield select(SelectcountryFilter);
    const CategoryFilter: string = yield select(SelectCategoryFilter);
    const rankingFilter: string = yield select(SelectRankingFilter);
    const SortColumn: string = yield select(SelectSortColumnRejected);
    const SortDir: string = yield select(SelectSortDirRejected);
    let data = {
      rankingFilter: rankingFilter,
      schoolFilter: schoolFilter,
      countryFilter: countryFilter,
      search: searchText,
      activityFilter: CategoryFilter,
    };

    const response: AxiosResponse = yield call(
      GetRejectedRetreatslistAdminRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalList(response.data[0].meta.total));
    yield put(actions.setRejectedRetreatsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteAdminRetreatListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      deleteRetreatListRequest,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* requestToPublishRetreatRequest(action: { payload: any }) {
  yield delay(500);

  const form: requestToPublishRequestInterFace = yield select(
    SelecRequestToPublishForm
  );
  yield put(actions.setRequestToPublishLoader(true));
  try {
    const response: AxiosResponse = yield call(
      RequestToPublishRetreatRequestAPI,
      form.retreatId
    );
    yield put(actions.setRequestToPublishLoader(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setRequestToPublishLoader(false));
    CatchBlockFunction(error);
  }
}

export function* ApprovePendingRetreatSaga(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));

  const form: approvePendingRequest = yield select(SelecApprovePendingForm);

  try {
    const response: AxiosResponse = yield call(
      ApprovePendingRetreatRequestAPI,
      form
    );

    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.doGetPendingRetreatsList());
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* addUserAdminRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: approvePendingRequest = yield select(SelecApprovePendingForm);

  try {
    const response: AxiosResponse = yield call(
      ApprovePendingRetreatRequestAPI,
      form
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.doGetPendingRetreatsList());
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatsStatusRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetRetreatStatusPublishByIdRequest,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(
      actions.SetRetreatsStatusFormData({
        status: response.data.status,
        retreatId: response.data.retreatId,
        requestStatusFlag: response.data.requestStatusFlag,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatsStatusCountRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetStatusCountForRetreats,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(
      actions.SetRetreatsStatusCountFormData({
        countRequest: response.data.countRequest,
        approvedRequestCount: response.data.approvedRequestCount,
        seoActivityTitle: response.data.seoActivityTitle,
        seoTitle: response.data.seoTitle,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* requestToPublishEditRequest(action: {
  payload: { retreat_id: any; callback: any };
}) {
  yield delay(500);
  const form: requestToPublishRequestInterFace = yield select(
    SelecRequestToPublishForm
  );
  yield put(actions.setRequestToEdit(true));
  try {
    const response: AxiosResponse = yield call(
      RequestToPublishEditRequestAPI,
      form.retreatId
    );
    yield put(actions.setRequestToEdit(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setRequestToEdit(false));
    CatchBlockFunction(error);
  }
}
export function* UpdateApproveFeaturedChnageRequest(action: {
  payload: { id: string; featured: boolean; callback: any };
}) {
  yield delay(500);
  log(action.payload, "UpdateApproveFeaturedChnageRequest");
  try {
    const response: AxiosResponse = yield call(
      UpdateApproveFeaturedList,
      `?retreatId=${action.payload.id}&featured=${action.payload.featured}`
    );

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* RejectedRetreatRequest(action: { payload: any }) {
  yield delay(500);
  const form: rejectedPendingRequest = yield select(SelecRejectedPendingForm);
  try {
    const response: AxiosResponse = yield call(
      ApprovePendingRetreatRequestAPI,
      form
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.doGetRejectedRetreatsList());
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* requestToDeactiveRetreatRequest(action: {
  payload: { id: string; isActive: boolean; callback: any };
}) {
  yield delay(500);
  const form: requestToDeactiveInterFace = yield select(
    SelecRequestToDeactiveForm
  );
  yield put(actions.setRequestToDeactive(true));
  let data = {
    deActivationReason: form.deActivationReason,
  };
  try {
    const response: AxiosResponse = yield call(
      RequestToDeactiveRetreatAPI,
      `?retreatId=${action.payload.id}&isActive=${action.payload.isActive}`,
      data
    );
    yield put(actions.setRequestToDeactive(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setRequestToDeactive(false));
    CatchBlockFunction(error);
  }
}
export function* requestToActiveRetreatRequest(action: {
  payload: { id: string; isActive: boolean; callback: any };
}) {
  yield delay(500);
  const form: requestToDeactiveInterFace = yield select(
    SelecRequestToDeactiveForm
  );
  let data = {
    deActivationReason: form.deActivationReason,
  };
  yield put(actions.setRequestToActive(true));
  try {
    const response: AxiosResponse = yield call(
      RequestToDeactiveRetreatAPI,
      `?retreatId=${action.payload.id}&isActive=${action.payload.isActive}`,
      data
    );
    yield put(actions.setRequestToActive(false));

    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setRequestToActive(false));

    CatchBlockFunction(error);
  }
}
export function* adminRepoSagaRetreats() {
  //-------------------- Approve ---------------------
  yield takeLatest(
    actions.doGetApproveRetreatsList,
    getApproveRetreatsListRequest
  );

  //-------------------- pending ---------------------

  yield takeLatest(actions.deleteAdminRetreats, deleteAdminRetreatListRequest);
  yield takeLatest(actions.ApprovePendingRetreat, ApprovePendingRetreatSaga);
  yield takeLatest(
    actions.RequestToPublishRetreat,
    requestToPublishRetreatRequest
  );
  yield takeLatest(actions.doGetRetreatsStatus, getRetreatsStatusRequest);
  yield takeLatest(
    actions.doRetreatRequestToPublishEdit,
    requestToPublishEditRequest
  );

  // .......... status count ...................
  yield takeLatest(
    actions.doGetRetreatsStatusCount,
    getRetreatsStatusCountRequest
  );
  // .......... All Retreats List ...................
  yield takeLatest(actions.doGetAllRetreatsList, getRetreatsListRequest);

  // .......... Set Featured Change List ...................
  yield takeLatest(
    actions.FeaturedChangeRetreatsFormValue,
    UpdateApproveFeaturedChnageRequest
  );

  /// rejected retreats
  yield takeLatest(actions.RejectedPendingRetreat, RejectedRetreatRequest);

  /// deactive Retreats
  yield takeLatest(actions.doGetIsActive, requestToActiveRetreatRequest);
  
  yield takeLatest(actions.doGetIsDeactive, requestToDeactiveRetreatRequest);
  yield takeLatest(
    actions.doGetApprovedRetreatsList,
    getApprovedRetreatsListRequest
  );
  yield takeLatest(
    actions.doGetNewRejectedRetreatsList,
    getRejectedRetreatsListRequest
  );
}
