export interface ApprovedReviewsInterface {
  id: string;
  userId: string;
  bookingId: string;
  firstName: string;
  lastName: string;
  email: string;
  school: string;
  retreatId: string;
  retreatTitle: string;
  overallImpression: number;
  createdAt: string;
  updatedAt: string;
  status: ReviewStatusEnum;
}
export interface PendingReviewsInterface {
  id: string;
  userId: string;
  bookingId: string;
  firstName: string;
  lastName: string;
  email: string;
  school: string;
  retreatId: string;
  retreatTitle: string;
  overallImpression: number;
  createdAt: string;
  updatedAt: string;
  status: ReviewStatusEnum;
}
export interface ReviewsInterface {
  id: string;
  valueForMoney: number;
  accommodationAndFacilities: number;
  foodRating: number;
  locationRating: number;
  qualityOfActivity: number;
  likes: string;
  dislikes: string;
  overallImpression: number;
  retreatId: string;
  updatedAt: string;
  createdAt: string;
  bookingId: string;
  status: ReviewStatusEnum;
  reply: string;
  reviewStatus: boolean;
}
export enum StatusFilterEnum {
  InActive = 0,
  Active = 1,
  All = 2,
}
export enum OverallImpressionFilter {
  All = 0,
}
export enum ReviewStatusEnum {
  APPROVED = 0,
  PENDING = 1,
}

export interface AdminReviewsState {
  // approvedReviewFormData: ApprovedReviewsInterface;
  pendingReviewsFormData: PendingReviewsInterface;
  reviewFormData: ReviewsInterface;
  approvedReviewsList: ApprovedReviewsInterface[];
  pendingReviewsList: PendingReviewsInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pendingReviewPageNo: number;
  pageSize: number;
  pendingReviewPageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  schoolFilter: string[];
  ListingsListFilter: string[];
  StatusFilter: StatusFilterEnum;
  totalPendingReview: number;
  totalApprovedReview: number;
  openDelete: boolean;
  openEdit: boolean;
  openPendingDelete: boolean;
  openPendingEdit: boolean;
  createdOnFilter: number | 0;
  date: any | "";
  overallImpressionFilter: any | "";
  sortColumn: string;
  sortDir: string;
}

export type InitialAdminReviewsState = AdminReviewsState;
