import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AddRecentlyViewedData,
  DoGetCouponData,
  getFeaturedRetreatsAndTeacherTraingingCard,
  getFeaturedRetreatsCard,
  getFeaturedTeacherTrainingCard,
  GetFeatureRetreatDetailData,
  GetFrequentlyRetreatDetailData,
  GetRecentlyViewedRetreatDetail,
  GetReviewDetailRequest,
  GetSimilarRetreatDetail,
} from "../../../../utils/request";
import { actions } from "./slice";
import {
  SelectPageNo,
  SelectRecentViewedDataRequest,
  selectCouponForm,
} from "./selector";
import { GetCouponInterface, RecentViewedDataRequestInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";

export function* getFeaturedRetreatsCardRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getFeaturedRetreatsCard);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setFeaturedRetreatsCardList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
// export function* getFeaturedRetreatsDetailsDataRequest(action: {
//   payload: string;
// }) {
//   yield delay(500);
//   try {
//     yield put(actions.setLoading(true));
//     const response: AxiosResponse = yield call(
//       GetFeatureRetreatDetailData,
//       action.payload
//     );

//     yield put(actions.setLoading(false));

//     if (response && !response.data) {
//       CustomToast(response.data.message, "ERROR");

//       return;
//     }
//     yield put(actions.setRetreatDetailData(response.data.data));
//   } catch (error: any) {
//     yield put(actions.setLoading(false));
//     CatchBlockFunction(error);
//   }
// }

export function* getFeaturedTeacherTrainingCardRequest() {
  yield delay(500);

  try {
    // yield put(actions.setLoading(true));
    // const response: AxiosResponse = yield call(getFeaturedTeacherTrainingCard);

    // yield put(actions.setLoading(false));

    // if (response && !response.data) {
    //   CustomToast(response.data.message, "ERROR");
    //   return;
    // }
    // yield put(actions.setFeaturedTeacherTrainingList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getFrequentlyRetreatDetailDataRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetFrequentlyRetreatDetailData,
      action.payload
    );

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setFrequentlyRetreatDetailData(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getSimilarRetreatDetailDataRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetSimilarRetreatDetail,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSimilarlyRetreatDetailData(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getRecentlyViewedRetreatRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const data = {
      userId: action.payload,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetRecentlyViewedRetreatDetail,
      data
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setRecentlyRetreatDetailData(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getReviewDetailDataRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetReviewDetailRequest,
      `?retreatId=${action.payload}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    // yield put(actions.setTotalReview(response.data.data[0].meta.total));
    yield put(actions.setReviewListData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* addRecentlyViewedDataRequest(action: {
  payload: {userid:string , retreatId :string, callback: any };
}) {
  yield delay(500);
  try {
    const data = {
      userId: action.payload.userid,
      retreatId: action.payload.retreatId,
    };
    const response: AxiosResponse = yield call(AddRecentlyViewedData, data);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCouponRequest() {
  yield delay(500);

  const form: GetCouponInterface = yield select(selectCouponForm);
  try {
    const response: AxiosResponse = yield call(DoGetCouponData, form);

    if (response.data.status == false) {
      CustomToast(response.data.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.data.message, "SUCCESS");
    }
    yield put(actions.setCouponResponce(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* featuredRetreatsCardRepoSaga() {
  yield takeLatest(
    actions.doGetFeaturedRetreatsCardList,
    getFeaturedRetreatsCardRequest
  );
  // yield takeLatest(
  //   actions.doGetFeaturedRetreatsDetailData,
  //   getFeaturedRetreatsDetailsDataRequest
  // );
  ///........ Teacher Training ..............
  yield takeLatest(
    actions.doGetFeaturedTeacherTrainingList,
    getFeaturedTeacherTrainingCardRequest
  );

  //......Frequently Req........
  yield takeLatest(
    actions.doGetFeaturedRetreatsDetailList,
    getFrequentlyRetreatDetailDataRequest
  );
  //......Similarly Req........
  yield takeLatest(
    actions.doGetSimilarlyRetreatsDetailList,
    getSimilarRetreatDetailDataRequest
  );
  //......RecentlyViewed Req........
  yield takeLatest(
    actions.doGetRecentlyRetreatsDetailList,
    getRecentlyViewedRetreatRequest
  );

  //......Review data Req........
  yield takeLatest(actions.doGetReviewDetailList, getReviewDetailDataRequest);
  yield takeLatest(actions.doaddRecentlyViewed, addRecentlyViewedDataRequest);
  yield takeLatest(actions.GetCouponRequest, getCouponRequest);
}
