import { createSlice } from "../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, IUserData } from "../types";
import { LoginMessage, SignupMessage } from "../../../Messages";
import { set } from "lodash";

export const initialState: AuthState = {
  signUpFormShowHide: false,
  BecomeAPartnerFormShowHide: false,
  PartnerSignInFormShowHide: false,
  ResetPasswordFormShowHide: false,
  ShowVetificationFormShowHide: false,
  SignInFormShowHide: false,
  loading: false,
  loginFrom: {
    email: "",
    password: "",
    Errors: [],
    Success: "",
    keeplogin: false,
  },
  PartnerList: [],
  PartnerFormData: {
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    password: "",
    webUrl: "",
    userType: "",
    tmc: false,
    Errors: [],
    Success: "",
  },
  UserLogin: [],
  UserLoginFormData: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userType: "user",
    Errors: [],
    Success: "",
    acceptNewsLetter: false,
  },
  token: "",
  isLogin: false,
  schoolId: "",
  userData: {
    name: "",
    profilePic: "",
    lastname: "",
    email: "",
    role: 0,
    businessName: "",
    id: "",
    googleId: "",
    isAffiliate: false,
    affiliateCode: "",
    isProfileFill: true,
    currencyCode: "",
  },
  forgetpasswordFormData: { id: "", email: "" },
  forgetPaswordList: [],
  resetPasswordFormData: { id: "", password: "", confirmPassword: "" },
  resetPasswordList: [],
  activeCurrency: "",
  resetPasswordConfirmationShow: false,
  resetEmailAddress: "",
  activeLanguage: "",
  loadingProfile: false,
  loginCount: 0,
};

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    setUpdateTocken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setUpdateSectionTocken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      sessionStorage.setItem("token", action.payload);
    },
    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setloginCount: (state, action: PayloadAction<number>) => {
      state.loginCount = action.payload;
    },
    setactiveCurrency: (state, action: PayloadAction<string>) => {
      state.activeCurrency = action.payload;
    },
    setactiveLanguage: (state, action: PayloadAction<string>) => {
      state.activeLanguage = action.payload;
    },

    setProfileData: (state, action: PayloadAction<IUserData>) => {
      state.userData = action.payload;
    },
    setresetEmailAddress: (state, action: PayloadAction<any>) => {
      state.resetEmailAddress = action.payload;
    },

    googleLogin: (
      state,
      action: PayloadAction<{ data: any; callback: (data: any) => void }>
    ) => {
      state.loading = true;
      state.loginFrom.Errors = [];
    },
    ConnectWithgoogle: (
      state,
      action: PayloadAction<{ data: any; callback: (data: any) => void }>
    ) => {},
    doLogin: (
      state,
      action: PayloadAction<{ callback: (data: any) => void }>
    ) => {
      state.loading = true;
      state.loginFrom.Errors = [];
    },

    doSocialLogin: (
      state,
      action: PayloadAction<{
        googleid: string;
        email: string;
        callback: () => void;
      }>
    ) => {
      state.loading = true;
      state.UserLoginFormData.Errors = [];
    },

    doLogOut: (state) => {},
    dogetLoginCount: (state) => {},
    addLoginError: (state, action: PayloadAction<string>) => {
      state.loginFrom.Errors.push(action.payload);
    },
    setloadingProfile: (state, action: PayloadAction<boolean>) => {
      state.loadingProfile = action.payload;
    },
    loginSuccess: (state) => {
      state.loginFrom.Success = LoginMessage.LOGIN_SUCCESS_MESSAGE;
    },
    signupSuccess: (state) => {
      state.loginFrom.Success = SignupMessage.SIGNUP_SUCCESS_MESSAGE;
    },

    updateLoginFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `loginFrom.${action.payload.key}`, action.payload.value);
      state.loginFrom.Errors = [];
    },

    clearFrom: (state) => {
      state.loginFrom.email = "";
      state.loginFrom.password = "";
    },
    clearPartnerFrom: (state) => {
      state.PartnerFormData.firstName = "";
      state.PartnerFormData.lastName = "";
      state.PartnerFormData.businessName = "";
      state.PartnerFormData.webUrl = "";
      state.PartnerFormData.email = "";
      state.PartnerFormData.password = "";
      state.PartnerFormData.tmc = false;
    },
    clearUserFrom: (state) => {
      state.UserLoginFormData.firstName = "";
      state.UserLoginFormData.lastName = "";
      state.UserLoginFormData.email = "";
      state.UserLoginFormData.password = "";
      state.UserLoginFormData.acceptNewsLetter = false;
    },
    // partner Sign Up -----

    updatePartnerFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `PartnerFormData.${action.payload.key}`, action.payload.value);
    },

    doPartnerSignUp: (
      state,
      action: PayloadAction<{ role: any; callback: () => void }>
    ) => {},
    doPartnerAddAdmin: (
      state,
      action: PayloadAction<{ role: any; callback: () => void }>
    ) => {},
    // User Sign Up -----

    updateUserFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `UserLoginFormData.${action.payload.key}`,
        action.payload.value
      );
    },

    doUserSignUp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    //.......... Admin ..............
    doAdminLogin: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {
      state.loading = true;
      state.loginFrom.Errors = [];
    },
    getUserProfile: (
      state,
      action: PayloadAction<{
        token: string;
        callback: (data: { role: number; id: string }) => void;
      }>
    ) => {},
    getSchoolProfile: (state, action: PayloadAction<any>) => {},
    getSchoolProfileById: (state) => {},

    clearProfileData: (state) => {
      state.userData.name = "";
      state.userData.lastname = "";
      state.userData.profilePic = "";
      state.userData.email = "";
      state.userData.role = 0;
      state.userData.businessName = "";
      state.userData.id = "";
    },
    updateAdminFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `loginFrom.${action.payload.key}`, action.payload.value);
    },
    setSchoolId: (state, action: PayloadAction<string>) => {
      state.schoolId = action.payload;
    },
    ShowHideSignUp: (state, action: PayloadAction<boolean>) => {
      state.signUpFormShowHide = action.payload;
    },
    PartnerSignInFormShowHide: (state, action: PayloadAction<boolean>) => {
      state.PartnerSignInFormShowHide = action.payload;
    },
    SignInFormShowHide: (state, action: PayloadAction<boolean>) => {
      state.SignInFormShowHide = action.payload;
    },
    ResetPasswordFormShowHide: (state, action: PayloadAction<boolean>) => {
      state.ResetPasswordFormShowHide = action.payload;
    },
    ShowVetificationFormShowHide: (state, action: PayloadAction<boolean>) => {
      state.ShowVetificationFormShowHide = action.payload;
    },
    BecomeAPartnerFormShowHide: (state, action: PayloadAction<boolean>) => {
      state.BecomeAPartnerFormShowHide = action.payload;
    },
    /// Forget Password.......**********
    updateForgetPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `forgetpasswordFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    sendForgetPassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    /// Reset Password.......**********
    updateResetPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `resetPasswordFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateResetPassword: (
      state,
      action: PayloadAction<{ token: string; callback: () => void }>
    ) => {},
    clearRessetPasswordFrom: (state) => {
      state.resetPasswordFormData.id = "";
      state.resetPasswordFormData.password = "";
      state.resetPasswordFormData.confirmPassword = "";
    },
    clearForgetPasswordFrom: (state) => {
      state.forgetpasswordFormData.id = "";
      state.forgetpasswordFormData.email = "";
    },
    toggleSendResetPassword: (state) => {
      state.resetPasswordConfirmationShow =
        !state.resetPasswordConfirmationShow;
    },
  },
});

export const { reducer, actions, name: AuthLoginSliceKey } = useClinicSlice;
