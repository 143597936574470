import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AddWishlist,
  DeleteWishList,
  getAllWishList,
} from "../../../../utils/request";
import { WishListInterface } from "../types";
import { SelectPageNo, selectWishListFormData } from "./selector";
import { actions } from "./slice";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";

export function* doAddWishListRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: WishListInterface = yield select(selectWishListFormData);
  log(form, "form");
  try {
    const response: AxiosResponse = yield call(AddWishlist, form.id);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetWishListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAllWishList,
      `?pageNo=${PageNo}&pageSize=10`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTotalWishlist(response.data[0].meta.total));
    yield put(actions.setWishListData(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* DoRemoveWishListRequest(action: {
  payload: { id: string; callback: () => void };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      DeleteWishList,
      action.payload.id || action.payload
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.doGetWishlist());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* wishListRepoSaga() {
  yield takeLatest(actions.doAddWishlist, doAddWishListRequest);
  yield takeLatest(actions.doGetWishlist, doGetWishListRequest);
  yield takeLatest(actions.doRemoveWishList, DoRemoveWishListRequest);
}
