import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.UserBookingState) {
      return state.UserBookingState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const selectItinararyData = createSelector(
  [selectDomain],
  (state) => state.ItinararyForm
);
export const selecCOntactDetailForm = createSelector(
  [selectDomain],
  (state) => state.contactDetailForm
);
export const selecpriceDetailForm = createSelector(
  [selectDomain],
  (state) => state.priceDetailForm
);
export const selectSendVerificationOtpRequestForm = createSelector(
  [selectDomain],
  (state) => state.SendVerificationOtpRequest
);
export const selectotalPriceData = createSelector(
  [selectDomain],
  (state) => state.totalPriceData
);
export const selectAddCard = createSelector(
  [selectDomain],
  (state) => state.AddCard
);
export const selectdepositType = createSelector(
  [selectDomain],
  (state) => state.depositType
);
export const selectBookingId = createSelector(
  [selectDomain],
  (state) => state.BookingId
);
export const selectshowAddCard = createSelector(
  [selectDomain],
  (state) => state.showAddCard
);
export const selectPrivateKey = createSelector(
  [selectDomain],
  (state) => state.PrivateKey
);
export const selectUpComingBookingList = createSelector(
  [selectDomain],
  (state) => state.upcomingBookingList
);
export const selectPastBookingList = createSelector(
  [selectDomain],
  (state) => state.pastBookingList
);
export const selectTotalPastBookingLength = createSelector(
  [selectDomain],
  (state) => state.TotalpastBookingLength
);
export const selectTotalUpComingLength = createSelector(
  [selectDomain],
  (state) => state.TotalUpComingBookingLength
);
export const selectBookingsListPagination = createSelector(
  [selectDomain],
  (state) => state.BookingsListPagination
);
export const selectTransactionDetail = createSelector(
  [selectDomain],
  (state) => state.TransactionDetail
);
export const selectprogressBar = createSelector(
  [selectDomain],
  (state) => state.progressBar
);
export const selectvisitTab = createSelector(
  [selectDomain],
  (state) => state.visitTab
);
export const selectisCouponAppliedOrNot = createSelector(
  [selectDomain],
  (state) => state.isCouponAppliedOrNot
);
export const selectBookingLoader = createSelector(
  [selectDomain],
  (state) => state.Loader
);
export const selectPaySuccessLoader= createSelector(
  [selectDomain],
  (state) => state.paySuccessLoader
);
export const selectContactLoader= createSelector(
  [selectDomain],
  (state) => state.contactLoader
);