import { ListingTypeEnum } from "../types";

export enum DrinkTypeEnum {
  FIXED = 1,
  TEMPORARY = 2,
}
export enum MealTypeEnum {
  FIXED = 1,
  TEMPORARY = 2,
}
export interface DrinksStyleModel {
  id: string;
  name: string;
  listingType: ListingTypeEnum;
  type: DrinkTypeEnum;
  retreatId: string;
  schoolId: string;
}
export interface MealsStyleModel {
  id: string;
  name: string;
  listingType: ListingTypeEnum;
  type: MealTypeEnum;
  retreatId: string;
  schoolId: string;
}
export interface FoodAndDrinksInterface {
  id: string;
  retreatId: string;
  foodOverview: string;
  drinksStyleForm: DrinksStyleModel;
  drinksList: DrinksStyleModel[];
  mealsStyleForm: MealsStyleModel;
  mealsList: MealsStyleModel[];
  drinkOverview: string;
  isDraft: boolean;
  count: number;
}
export interface dailyScheduleModel {
  time: string;
  schedule: string;
}

export interface LocationInterface {
  id: string;
}
export interface AllActivityInterface {
  name: string;
}
export enum InclusionsTypeEnum {
  FIXED = 1,
  TEMPORARY = 2,
}
export interface InclusionsExclushionModel {
  name: string;
  id: "";
  listingType: ListingTypeEnum;
  type: InclusionsTypeEnum;
  schoolId: "";
  retreatId: string;
}
export interface InclusionsFormModel {
  Inclushion: InclusionsExclushionModel;
  inclushionList: InclusionsExclushionModel[];
  Exclushion: InclusionsExclushionModel;
  exclusionList: InclusionsExclushionModel[];
  retreatId: string;
  id: string;
  count: number;
}
export interface InclusionsExclusionsInterface {
  id: string;
  InclushionExclushionForm: InclusionsFormModel;
  InclushionExclushionList: InclusionsFormModel[];
  isDraft: boolean;
  count: number;
}
export interface SchoolListingOverviewState {
  AllMainActivityList: AllActivityInterface[];
  foodAndDrinksForm: FoodAndDrinksInterface;
  foodAndDrinksList: FoodAndDrinksInterface[];
  pageNo: number;
  DrinksFixedList: DrinksStyleModel[];
  DrinksTempList: DrinksStyleModel[];
  MealsFixedList: MealsStyleModel[];
  MealsTempList: MealsStyleModel[];
  ListingsId: any;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  offlineOrOnline: any;
  InclusionsFixedAdmin: InclusionsExclushionModel[];
  InclusionsTempSchool: InclusionsExclushionModel[];
  ExclusionFixedAdmin: InclusionsExclushionModel[];
  ExclusionTempSchool: InclusionsExclushionModel[];
  InclushionExlushionModalType: number;
  FoodDrinksModalType: number;
  InclusionsExclusionsForm: InclusionsExclusionsInterface;
  InclusionsExclusionsList: InclusionsExclusionsInterface[];
  loading: boolean;
  InclusionLoading: boolean;
}

export type InitialSchoolListingOverviewState = SchoolListingOverviewState;
