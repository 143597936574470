import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { adminMediaManagerRepoSaga } from "../../redux/Admin/MediaManager/redux/saga";
import {
  AdminMediaManagerSliceKey,
  reducer as AdminMediaManagerReducer,
} from "../../redux/Admin/MediaManager/redux/slice";

import { adminRepoSagaAdminSchool } from "../../redux/Admin/School/redux/saga";
import {
  AdminSchoolSliceKey,
  reducer as AdminSchoolReducer,
} from "../../redux/Admin/School/redux/slice";
import { adminCommissionRepoSaga } from "../../redux/Admin/SettingSection/Commission/redux/saga";
import {
  AdminCommissionSliceKey,
  reducer as AdminCommissionReducer,
} from "../../redux/Admin/SettingSection/Commission/redux/slice";
import { adminCurrenciesRepoSaga } from "../../redux/Admin/SettingSection/Currencies/redux/saga";
import {
  AdminCurrenciesSliceKey,
  reducer as AdminCurrenciesReducer,
} from "../../redux/Admin/SettingSection/Currencies/redux/slice";
import { adminLanguageRepoSaga } from "../../redux/Admin/SettingSection/Languages/redux/saga";
import {
  AdminLanguageSliceKey,
  reducer as AdminLanguageReducer,
} from "../../redux/Admin/SettingSection/Languages/redux/slice";
import { adminSiteSettingsRepoSaga } from "../../redux/Admin/SettingSection/SiteSetting/redux/saga";
import {
  AdminSiteSettingsSliceKey,
  reducer as AdminSiteSettingsReducer,
} from "../../redux/Admin/SettingSection/SiteSetting/redux/slice";
import { adminWelcomeBarRepoSaga } from "../../redux/Admin/SettingSection/WelcomeBar/redux/saga";
import {
  AdminWelcomeBarSliceKey,
  reducer as AdminWelcomeBarReducer,
} from "../../redux/Admin/SettingSection/WelcomeBar/redux/slice";

import { adminRepoSagaAdminUser } from "../../redux/Admin/User/redux/saga";
import {
  sliceKey as AdminUserSliceKey,
  reducer as AdminUserReducer,
} from "../../redux/Admin/User/redux/slice";
import { adminRepoSagaRetreats } from "../../redux/Admin/Retreats/redux/saga";
import {
  sliceKey as AdminRetreatsSliceKey,
  reducer as AdminRetreatsReducer,
} from "../../redux/Admin/Retreats/redux/slice";
import { adminReviewsRepoSaga } from "../../redux/Admin/Reviews/redux/saga";
import {
  AdminReviewsSliceKey,
  reducer as AdminReviewsReducer,
} from "../../redux/Admin/Reviews/redux/slice";
import {
  AdminPaymenstSliceKey,
  reducer as AdminPaymentsReducer,
} from "../../redux/Admin/Payments/redux/slice";
import { adminPaymentsRepoSaga } from "../../redux/Admin/Payments/redux/saga";
import { authRepoSaga } from "../../redux/Login/redux/saga";
import { AuthLoginSliceKey, reducer } from "../../redux/Login/redux/slice";
import { schoolInstructorsRepoSaga } from "../../redux/School/Instructors/redux/saga";
import {
  SchoolInstructorsSliceKey,
  reducer as SchoolInstructorsReducer,
} from "../../redux/School/Instructors/redux/slice";
import { SchoolMyMoneyRepoSaga } from "../../redux/School/MyMoney/redux/saga";
import {
  sliceKey as SchoolMyMoneySliceKey,
  reducer as SchoolMyMoneyReducer,
} from "../../redux/School/MyMoney/redux/slice";
import {
  SchoolPaymentsSliceKey,
  reducer as schoolPaymentsReducer,
} from "../../redux/School/SchoolPayments/redux/slice";
import { schoolPaymentsRepoSaga } from "../../redux/School/SchoolPayments/redux/saga";
import {
  SchoolUpdatePasswordSliceKey,
  reducer as schoolUpdatePasswordReducer,
} from "../../redux/School/SchoolUpdatePassword/redux/slice";
import { schoolUpdatePasswordRepoSaga } from "../../redux/School/SchoolUpdatePassword/redux/saga";
import { adminRepoSagaSchoolListings } from "../../redux/School/Listings/redux/saga";
import {
  sliceKey as SchoolListingsSliceKey,
  reducer as SchoolListingsReducer,
} from "../../redux/School/Listings/redux/slice";
import {
  SchoolCustomerSupportSliceKey,
  reducer as SchoolCustomerSupportReducer,
} from "../../redux/School/SchoolCustomerSupport/redux/slice";
import { schoolCustomerSupportRepoSaga } from "../../redux/School/SchoolCustomerSupport/redux/saga";

import {
  sliceKey as SchoolAccommodationSliceKey,
  reducer as SchoolAccommodationReducer,
} from "../../redux/School/Accommodation/redux/slice";
import { schoolAccommodationRepoSaga } from "../../redux/School/Accommodation/redux/saga";
import {
  sliceKey as SchoolListingsOtherSliceKey,
  reducer as SchoolListingsOtherReducer,
} from "../../redux/School/Listings/Other/redux/slice";
import { schoolListingsOtherRepoSaga } from "../../redux/School/Listings/Other/redux/saga";
import {
  sliceKey as SchoolListingsPackagesSliceKey,
  reducer as SchoolListingsPackagesReducer,
} from "../../redux/School/Listings/Packages/redux/slice";
import { schoolListingsPackagesRepoSaga } from "../../redux/School/Listings/Packages/redux/saga";
import { adminRepoSagaSchoolListingsMedia } from "../../redux/School/Listings/Media/redux/saga";
import {
  sliceKey as SchoolListingsMediaSliceKey,
  reducer as SchoolListingsMediaReducer,
} from "../../redux/School/Listings/Media/redux/slice";
import {
  sliceKey as SchoolListingsSectionOverviewSliceKey,
  reducer as SchoolListingsSectionOverviewReducer,
} from "../../redux/School/Listings/SectionOverview/redux/slice";
import { repoSagaSchoolListingsSectionOverview } from "../../redux/School/Listings/SectionOverview/redux/saga";
import { RepoSagaSchoolListingsGrabAttention } from "../../redux/School/Listings/GrabTheAttention/redux/saga";
import {
  sliceKey as SchoolListingsGrabAttenSliceKey,
  reducer as SchoolListingsGrabAttentionReducer,
} from "../../redux/School/Listings/GrabTheAttention/redux/slice";
import {
  sliceKey as SchoolListingsArrivalInformationAndInstructorSliceKey,
  reducer as SchoolListingsArrivalInformationAndInstructorReducer,
} from "../../redux/School/Listings/ArrivalInformation&Instructor/redux/slice";
import { repoSagaListingArrivalInformationAndInstructor } from "../../redux/School/Listings/ArrivalInformation&Instructor/redux/saga";
import {
  sliceKey as SchoolListingsLocationSliceKey,
  reducer as SchoolListingsLocationReducer,
} from "../../redux/School/Listings/Location/redux/slice";
import { repoSagaListingLocation } from "../../redux/School/Listings/Location/redux/saga";
// import { RepoSagaSchoolListingsCalender } from "../../redux/School/Listings/Calender/redux/saga";
import {
  sliceKey as SchoolListingsCalenderSliceKey,
  reducer as SchoolListingsCalendernReducer,
} from "../../redux/School/Listings/Calendar/redux/slice";
import { RepoSagaSchoolListingsCalender } from "../../redux/School/Listings/Calendar/redux/saga";
import {
  sliceKey as featuredRetreatsCardSliceKey,
  reducer as featuredRetreatsCardReducer,
} from "../../redux/Frontend/RetreatCard/redux/slice";
import { featuredRetreatsCardRepoSaga } from "../../redux/Frontend/RetreatCard/redux/saga";
import {
  sliceKey as schoolMediaManagerSliceKey,
  reducer as schoolMediaManagerReducer,
} from "../../redux/School/MediaManager/redux/slice";
import { schoolMediaManagerRepoSaga } from "../../redux/School/MediaManager/redux/saga";
import { NotificationRepoSaga } from "../../redux/Admin/Notification/redux/saga";
import {
  sliceKey as NotificationSliceKey,
  reducer as NotificationReducer,
} from "../../redux/Admin/Notification/redux/slice";
import { UploaderSaga } from "../../redux/Frontend/uploader/redux/saga";
import {
  SliceKey as UploaderSliceKey,
  reducer as UploaderReducer,
} from "../../redux/Frontend/uploader/redux/slice";
import {
  sliceKey as wishListSliceKey,
  reducer as wishListReducer,
} from "../../redux/Frontend/WishList/redux/slice";

import { wishListRepoSaga } from "../../redux/Frontend/WishList/redux/saga";
import {
  sliceKey as SchoolProfileSliceKey,
  reducer as SchoolProfileReducer,
} from "../../redux/Frontend/SchoolProfileHost/redux/slice";
import { SchoolProfileRepoSaga } from "../../redux/Frontend/SchoolProfileHost/redux/saga";
import { ListingFilterRepoSaga } from "../../redux/Frontend/ListingsFilter/redux/saga";

import {
  sliceKey as ListingFilterSliceKey,
  reducer as ListingFilterReducer,
} from "../../redux/Frontend/ListingsFilter/redux/slice";
import {
  sliceKey as CustomerHelpSliceKey,
  reducer as CustomerHelpReducer,
} from "../../redux/Frontend/Help/redux/slice";
import { CustomerHelpRepoSaga } from "../../redux/Frontend/Help/redux/saga";
import {
  sliceKey as RequestToReservationSliceKey,
  reducer as RequestToReservationReducer,
} from "../../redux/Frontend/RequestReservation/redux/slice";
import { RequestToReservationRepoSaga } from "../../redux/Frontend/RequestReservation/redux/saga";
import { UserInquiryMessageRepoSaga } from "../../redux/User/Message/redux/saga";
import {
  sliceKey as UserInquiryMessageSliceKey,
  reducer as UserInquiryMessageReducer,
} from "../../redux/User/Message/redux/slice";
import { SchoolMassageRepoSaga } from "../../redux/School/Massage/redux/saga";
import {
  sliceKey as SchoolMessageSliceKey,
  reducer as SchoolMessageReducer,
} from "../../redux/School/Massage/redux/slice";
import {
  sliceKey as UserBookingSliceKey,
  reducer as UserBookingReducer,
} from "../../redux/User/Bookings/redux/slice";
import { UserBookingRepoSaga } from "../../redux/User/Bookings/redux/saga";
import { BookingsSchholRepoSagaRetreats } from "../../redux/School/Bookings/redux/saga";
import {
  sliceKey as BookingSchoolSliceKey,
  reducer as BookingSchoolReducer,
} from "../../redux/School/Bookings/redux/slice";
import { AdminDashboardRepoSaga } from "../../redux/Admin/Dashboard/redux/saga";
import {
  sliceKey as AdminDashboardSliceKey,
  reducer as AdminDashboardReducer,
} from "../../redux/Admin/Dashboard/redux/slice";
import { adminRepoSagaBookings } from "../../redux/Admin/Bookings/redux/saga";
import {
  sliceKey as AdminBookingsSliceKey,
  reducer as AdminBookingsReducer,
} from "../../redux/Admin/Bookings/redux/slice";
import { SchoolCouponRepoSaga } from "../../redux/School/Coupon/redux/saga";
import {
  sliceKey as SchoolCouponSliceKey,
  reducer as SchoolCouponReducer,
} from "../../redux/School/Coupon/redux/slice";
import {
  sliceKey as SchoolReviewSliceKey,
  reducer as SchoolReviewReducer,
} from "../../redux/School/SchoolReview/redux/slice";
import { SchoolReviewRepoSaga } from "../../redux/School/SchoolReview/redux/saga";
import {
  sliceKey as CustomerReviewSliceKey,
  reducer as CustomerReviewReducer,
} from "../../redux/User/Review/redux/slice";

import { CustomerReviewRepoSaga } from "../../redux/User/Review/redux/saga";
import {
  sliceKey as OnlineExperienceSliceKey,
  reducer as OnlineExperienceReducer,
} from "../../redux/Frontend/ExperiencePage/redux/slice";
import {
  sliceKey as AffliateUserSliceKey,
  reducer as AffliateUserReducer,
} from "../../redux/Affiliate/redux/slice";
import { OnlineExperienceRepoSaga } from "../../redux/Frontend/ExperiencePage/redux/saga";
import { AffliateUserRepoSaga } from "../../redux/Affiliate/redux/saga";

import {
  sliceKey as EmailNewsLetterSliceKey,
  reducer as EmailNewsLetterReducer,
} from "../../redux/Admin/EmailNewsLetter/redux/slice";
import { EmailNewsLetterRepoSaga } from "../../redux/Admin/EmailNewsLetter/redux/saga";
import {
  sliceKey as AdminAffiliateSliceKey,
  reducer as AdminAffiliaterReducer,
} from "../../redux/Admin/Affiliate/redux/slice";
import { AdminAffiliateRepoSaga } from "../../redux/Admin/Affiliate/redux/saga";
import {
  sliceKey as SchoolDashboardSliceKey,
  reducer as SchoolDashboardReducer,
} from "../../redux/School/DashBoard/redux/slice";

import { SchoolDashboardRepoSaga } from "../../redux/School/DashBoard/redux/saga";
import {
  sliceKey as LandingPagesSliceKey,
  reducer as LandingPagesReducer,
} from "../../redux/Frontend/Landing/redux/slice";
import { landingPagesRepoSaga } from "../../redux/Frontend/Landing/redux/saga";
import { FakeCustomerReviewRepoSaga } from "../../redux/FakeReview/redux/saga";
import {
  sliceKey as FakeCustomerReviewSliceKey,
  reducer as FakeCustomerReviewReducer,
} from "../../redux/FakeReview/redux/slice";
import {
  sliceKey as LandingPagesAdminSliceKey,
  reducer as LandingPagesAdminReducer,
} from "../../redux/Admin/Landing/redux/slice";
import { LandingPagesAdminRepoSaga } from "../../redux/Admin/Landing/redux/saga";
interface Props {
  children: any;
}
export default function Injector(props: Props) {
  // Login ------------->
  useInjectReducer({ key: AuthLoginSliceKey, reducer: reducer });
  useInjectSaga({ key: AuthLoginSliceKey, saga: authRepoSaga });
  useInjectReducer({ key: UserBookingSliceKey, reducer: UserBookingReducer });
  useInjectSaga({ key: UserBookingSliceKey, saga: UserBookingRepoSaga });
  // Admin User ------------->
  useInjectReducer({ key: AdminUserSliceKey, reducer: AdminUserReducer });
  useInjectSaga({ key: AdminUserSliceKey, saga: adminRepoSagaAdminUser });

  //Admin Media Manager ---------------->
  useInjectReducer({
    key: AdminMediaManagerSliceKey,
    reducer: AdminMediaManagerReducer,
  });
  useInjectSaga({
    key: AdminMediaManagerSliceKey,
    saga: adminMediaManagerRepoSaga,
  });

  //Admin Setting Section ---------------->
  // language ----->
  useInjectReducer({
    key: AdminLanguageSliceKey,
    reducer: AdminLanguageReducer,
  });
  useInjectSaga({ key: AdminLanguageSliceKey, saga: adminLanguageRepoSaga });

  // Currencies ----->
  useInjectReducer({
    key: AdminCurrenciesSliceKey,
    reducer: AdminCurrenciesReducer,
  });
  useInjectSaga({
    key: AdminCurrenciesSliceKey,
    saga: adminCurrenciesRepoSaga,
  });

  // Commission ----->
  useInjectReducer({
    key: AdminCommissionSliceKey,
    reducer: AdminCommissionReducer,
  });
  useInjectSaga({
    key: AdminCommissionSliceKey,
    saga: adminCommissionRepoSaga,
  });

  // Welcome bar ----->
  useInjectReducer({
    key: AdminWelcomeBarSliceKey,
    reducer: AdminWelcomeBarReducer,
  });
  useInjectSaga({
    key: AdminWelcomeBarSliceKey,
    saga: adminWelcomeBarRepoSaga,
  });

  // Site Settings ----->
  useInjectReducer({
    key: AdminSiteSettingsSliceKey,
    reducer: AdminSiteSettingsReducer,
  });
  useInjectSaga({
    key: AdminSiteSettingsSliceKey,
    saga: adminSiteSettingsRepoSaga,
  });

  //Admin School ----------->
  useInjectReducer({ key: AdminSchoolSliceKey, reducer: AdminSchoolReducer });
  useInjectSaga({ key: AdminSchoolSliceKey, saga: adminRepoSagaAdminSchool });

  // adminRetreats
  useInjectReducer({
    key: AdminRetreatsSliceKey,
    reducer: AdminRetreatsReducer,
  });
  useInjectSaga({ key: AdminRetreatsSliceKey, saga: adminRepoSagaRetreats });

  // admin Reviews
  useInjectReducer({ key: AdminReviewsSliceKey, reducer: AdminReviewsReducer });
  useInjectSaga({ key: AdminReviewsSliceKey, saga: adminReviewsRepoSaga });

  // admin Payments
  useInjectReducer({
    key: AdminPaymenstSliceKey,
    reducer: AdminPaymentsReducer,
  });
  useInjectSaga({ key: AdminPaymenstSliceKey, saga: adminPaymentsRepoSaga });

  // School Instructors
  useInjectReducer({
    key: SchoolInstructorsSliceKey,
    reducer: SchoolInstructorsReducer,
  });
  useInjectSaga({
    key: SchoolInstructorsSliceKey,
    saga: schoolInstructorsRepoSaga,
  });

  //school My Money
  useInjectReducer({
    key: SchoolMyMoneySliceKey,
    reducer: SchoolMyMoneyReducer,
  });
  useInjectSaga({ key: SchoolMyMoneySliceKey, saga: SchoolMyMoneyRepoSaga });

  // School Payments
  useInjectReducer({
    key: SchoolPaymentsSliceKey,
    reducer: schoolPaymentsReducer,
  });
  useInjectSaga({ key: SchoolPaymentsSliceKey, saga: schoolPaymentsRepoSaga });

  // School Update Password
  useInjectReducer({
    key: SchoolUpdatePasswordSliceKey,
    reducer: schoolUpdatePasswordReducer,
  });
  useInjectSaga({
    key: SchoolUpdatePasswordSliceKey,
    saga: schoolUpdatePasswordRepoSaga,
  });

  // School Retreats
  useInjectReducer({
    key: SchoolListingsSliceKey,
    reducer: SchoolListingsReducer,
  });
  useInjectSaga({
    key: SchoolListingsSliceKey,
    saga: adminRepoSagaSchoolListings,
  });

  // School Retreats others
  useInjectReducer({
    key: SchoolListingsOtherSliceKey,
    reducer: SchoolListingsOtherReducer,
  });
  useInjectSaga({
    key: SchoolListingsOtherSliceKey,
    saga: schoolListingsOtherRepoSaga,
  });

  // School Retreats Packages
  useInjectReducer({
    key: SchoolListingsPackagesSliceKey,
    reducer: SchoolListingsPackagesReducer,
  });
  useInjectSaga({
    key: SchoolListingsPackagesSliceKey,
    saga: schoolListingsPackagesRepoSaga,
  });

  // School Customer Support
  useInjectReducer({
    key: SchoolCustomerSupportSliceKey,
    reducer: SchoolCustomerSupportReducer,
  });
  useInjectSaga({
    key: SchoolCustomerSupportSliceKey,
    saga: schoolCustomerSupportRepoSaga,
  });

  // School Accommodation
  useInjectReducer({
    key: SchoolAccommodationSliceKey,
    reducer: SchoolAccommodationReducer,
  });
  useInjectSaga({
    key: SchoolAccommodationSliceKey,
    saga: schoolAccommodationRepoSaga,
  });

  // Retreats Listings Media
  useInjectReducer({
    key: SchoolListingsMediaSliceKey,
    reducer: SchoolListingsMediaReducer,
  });
  useInjectSaga({
    key: SchoolListingsMediaSliceKey,
    saga: adminRepoSagaSchoolListingsMedia,
  });

  // Retreats Section Overview
  useInjectReducer({
    key: SchoolListingsSectionOverviewSliceKey,
    reducer: SchoolListingsSectionOverviewReducer,
  });
  useInjectSaga({
    key: SchoolListingsSectionOverviewSliceKey,
    saga: repoSagaSchoolListingsSectionOverview,
  });

  useInjectReducer({
    key: SchoolListingsGrabAttenSliceKey,
    reducer: SchoolListingsGrabAttentionReducer,
  });
  useInjectSaga({
    key: SchoolListingsGrabAttenSliceKey,
    saga: RepoSagaSchoolListingsGrabAttention,
  });

  // Retreats Arrival Information And Instructor
  useInjectReducer({
    key: SchoolListingsArrivalInformationAndInstructorSliceKey,
    reducer: SchoolListingsArrivalInformationAndInstructorReducer,
  });
  useInjectSaga({
    key: SchoolListingsArrivalInformationAndInstructorSliceKey,
    saga: repoSagaListingArrivalInformationAndInstructor,
  });

  // Retreats Location
  useInjectReducer({
    key: SchoolListingsLocationSliceKey,
    reducer: SchoolListingsLocationReducer,
  });
  useInjectSaga({
    key: SchoolListingsLocationSliceKey,
    saga: repoSagaListingLocation,
  });
  useInjectReducer({
    key: SchoolListingsCalenderSliceKey,
    reducer: SchoolListingsCalendernReducer,
  });
  useInjectSaga({
    key: SchoolListingsCalenderSliceKey,
    saga: RepoSagaSchoolListingsCalender,
  });

  // Retreats featured Card
  useInjectReducer({
    key: featuredRetreatsCardSliceKey,
    reducer: featuredRetreatsCardReducer,
  });
  useInjectSaga({
    key: featuredRetreatsCardSliceKey,
    saga: featuredRetreatsCardRepoSaga,
  });
  // School Media Manager
  useInjectReducer({
    key: schoolMediaManagerSliceKey,
    reducer: schoolMediaManagerReducer,
  });
  useInjectSaga({
    key: schoolMediaManagerSliceKey,
    saga: schoolMediaManagerRepoSaga,
  });

  useInjectReducer({
    key: NotificationSliceKey,
    reducer: NotificationReducer,
  });
  useInjectSaga({
    key: NotificationSliceKey,
    saga: NotificationRepoSaga,
  });
  useInjectReducer({
    key: UploaderSliceKey,
    reducer: UploaderReducer,
  });
  useInjectSaga({
    key: UploaderSliceKey,
    saga: UploaderSaga,
  });
  useInjectReducer({
    key: wishListSliceKey,
    reducer: wishListReducer,
  });
  useInjectSaga({
    key: wishListSliceKey,
    saga: wishListRepoSaga,
  });
  useInjectReducer({
    key: SchoolProfileSliceKey,
    reducer: SchoolProfileReducer,
  });
  useInjectSaga({
    key: SchoolProfileSliceKey,
    saga: SchoolProfileRepoSaga,
  });
  useInjectReducer({
    key: ListingFilterSliceKey,
    reducer: ListingFilterReducer,
  });
  useInjectSaga({
    key: ListingFilterSliceKey,
    saga: ListingFilterRepoSaga,
  });
  useInjectReducer({
    key: CustomerHelpSliceKey,
    reducer: CustomerHelpReducer,
  });
  useInjectSaga({
    key: CustomerHelpSliceKey,
    saga: CustomerHelpRepoSaga,
  });
  useInjectReducer({
    key: RequestToReservationSliceKey,
    reducer: RequestToReservationReducer,
  });
  useInjectSaga({
    key: RequestToReservationSliceKey,
    saga: RequestToReservationRepoSaga,
  });
  useInjectReducer({
    key: UserInquiryMessageSliceKey,
    reducer: UserInquiryMessageReducer,
  });
  useInjectSaga({
    key: UserInquiryMessageSliceKey,
    saga: UserInquiryMessageRepoSaga,
  });
  useInjectReducer({
    key: SchoolMessageSliceKey,
    reducer: SchoolMessageReducer,
  });
  useInjectSaga({
    key: SchoolMessageSliceKey,
    saga: SchoolMassageRepoSaga,
  });
  useInjectReducer({
    key: BookingSchoolSliceKey,
    reducer: BookingSchoolReducer,
  });
  useInjectSaga({
    key: BookingSchoolSliceKey,
    saga: BookingsSchholRepoSagaRetreats,
  });
  useInjectReducer({
    key: AdminDashboardSliceKey,
    reducer: AdminDashboardReducer,
  });
  useInjectSaga({
    key: AdminDashboardSliceKey,
    saga: AdminDashboardRepoSaga,
  });
  useInjectReducer({
    key: AdminBookingsSliceKey,
    reducer: AdminBookingsReducer,
  });
  useInjectSaga({
    key: AdminBookingsSliceKey,
    saga: adminRepoSagaBookings,
  });
  useInjectReducer({
    key: SchoolCouponSliceKey,
    reducer: SchoolCouponReducer,
  });
  useInjectSaga({
    key: SchoolCouponSliceKey,
    saga: SchoolCouponRepoSaga,
  });
  useInjectReducer({
    key: SchoolReviewSliceKey,
    reducer: SchoolReviewReducer,
  });
  useInjectSaga({
    key: SchoolReviewSliceKey,
    saga: SchoolReviewRepoSaga,
  });
  useInjectReducer({
    key: CustomerReviewSliceKey,
    reducer: CustomerReviewReducer,
  });
  useInjectSaga({
    key: CustomerReviewSliceKey,
    saga: CustomerReviewRepoSaga,
  });
  useInjectReducer({
    key: FakeCustomerReviewSliceKey,
    reducer: FakeCustomerReviewReducer,
  });
  useInjectSaga({
    key: FakeCustomerReviewSliceKey,
    saga: FakeCustomerReviewRepoSaga,
  });
  
  useInjectReducer({
    key: OnlineExperienceSliceKey,
    reducer: OnlineExperienceReducer,
  });
  useInjectSaga({
    key: OnlineExperienceSliceKey,
    saga: OnlineExperienceRepoSaga,
  });
  useInjectReducer({
    key: AffliateUserSliceKey,
    reducer: AffliateUserReducer,
  });
  useInjectSaga({
    key: AffliateUserSliceKey,
    saga: AffliateUserRepoSaga,
  });
  useInjectReducer({
    key: EmailNewsLetterSliceKey,
    reducer: EmailNewsLetterReducer,
  });
  useInjectSaga({
    key: EmailNewsLetterSliceKey,
    saga: EmailNewsLetterRepoSaga,
  });
  useInjectReducer({
    key: AdminAffiliateSliceKey,
    reducer: AdminAffiliaterReducer,
  });
  useInjectSaga({
    key: AdminAffiliateSliceKey,
    saga: AdminAffiliateRepoSaga,
  });
  useInjectReducer({
    key: SchoolDashboardSliceKey,
    reducer: SchoolDashboardReducer,
  });
  useInjectSaga({
    key: SchoolDashboardSliceKey,
    saga: SchoolDashboardRepoSaga,
  });
  useInjectReducer({
    key: LandingPagesSliceKey,
    reducer: LandingPagesReducer,
  });
  useInjectSaga({
    key: LandingPagesSliceKey,
    saga: landingPagesRepoSaga,
  });
  useInjectReducer({
    key: LandingPagesAdminSliceKey,
    reducer: LandingPagesAdminReducer,
  });
  useInjectSaga({
    key: LandingPagesAdminSliceKey,
    saga: LandingPagesAdminRepoSaga,
  });
  return props.children;
}
