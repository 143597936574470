import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolMediaManager) {
      return state.schoolMediaManager;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolMediaManagerForm = createSelector(
  [selectDomain],
  (state) => state.mediaManagerForm
);
export const selectSchoolMediaManagerListData = createSelector(
  [selectDomain],
  (state) => state.mediaManagerListData
);
export const selectCardShowModal = createSelector(
  [selectDomain],
  (state) => state.cardShow
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectTotalList = createSelector(
  [selectDomain],
  (state) => state.totalList
);

export const SelectDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);
export const SelectMediaEditModal = createSelector(
  [selectDomain],
  (state) => state.editModal
);
export const SelectTotalMedia = createSelector(
  [selectDomain],
  (state) => state.totalMedia
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
