export enum AvailabilityEnum {
  SELECTED_DATES = 0,
  INSTANTLY_BOOKABLE = 1,
  AVAILABLE_ON_REQUEST = 2,
  NOT_AVAILABLE = 3,
}

export interface calenderInterface {
  packageId: string;
  retreatId: string;
  dateRange: [];
  availability: AvailabilityEnum;
}
export interface IDateRange {
  state: string;
  start: string;
  end: string;
  availbility?: AvailabilityEnum;
}
export interface ISavedDateRange {
  packageId: string;
  availbility: AvailabilityEnum;
  state: string;
  start: string;
  end: string;
  retreatId: any;
}
export interface SchoolListingCalenderState {
  calenderForm: calenderInterface;
  calenderList: calenderInterface[];
  laoding: boolean;
  submit: boolean;
  showMonth: boolean;
  selectionStart?: string;
  selectionDate?: IDateRange;
  dateRanges: IDateRange[];
  savedRange: ISavedDateRange[];
  selectedDateRange?: IDateRange[];
  checkAvailable: AvailabilityEnum;
  isDraft: boolean;
  isSelecting: boolean;
  loading:boolean
}

export type InitialSchoolListingCalenderState = SchoolListingCalenderState;
