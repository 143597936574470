import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  SelectInvoicePageNo,
  SelectPageNo,
  SelectSearch,
  SelectSortColumn,
  SelectSortColumnInvoice,
  SelectSortDir,
  SelectSortDirInvoice,
} from "./selector";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetInvoicePDFRequest,
  GetPaymentPDFRequest,
  getPaymentsInvoiceListRequest,
  getPaymentsListRequest,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import Link from "next/link";
import { BASEURL, BASE_URL, BASE_URLAPI } from "../../../../environment";

export function* getPaymentListRequest(action: {
  payload: { school: string; callback: any };
}) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectPageNo);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPaymentsListRequest,
      `?schoolId=${action.payload.school}&pageNo=${PageNo}&pageSize=10${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalPayments(response.data[0].meta.total));
    yield put(actions.setPaymentList(response.data[0].results));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getInvoicesListRequest(action: {
  payload: { school: string; callback: any };
}) {
  yield delay(500);
  try {
    const PageNo: number = yield select(SelectInvoicePageNo);
    const SortColumn: string = yield select(SelectSortColumnInvoice);
    const SortDir: string = yield select(SelectSortDirInvoice);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getPaymentsInvoiceListRequest,
      `?schoolId=${action.payload.school}&pageNo=${PageNo}&pageSize=10${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setTotalInvoice(response.data[0].meta.total));
    yield put(actions.setInvoicesList(response.data[0].results));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* dogetExportInvoice(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetInvoicePDFRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    window.open(
      BASE_URLAPI + "/csv/" + response.data.filename.filename,
      "_blank",
      "noopener,noreferrer"
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* dogetExportPayment(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetPaymentPDFRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    window.open(BASEURL + "/csv/" + response.data.filename, "_blank");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* SchoolMyMoneyRepoSaga() {
  yield takeLatest(actions.doGetInvoicesList, getInvoicesListRequest);
  yield takeLatest(actions.doGetPaymentList, getPaymentListRequest);
  yield takeLatest(actions.doExportInvoice, dogetExportInvoice);
  yield takeLatest(actions.doExportPayment, dogetExportPayment);
}
