import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  GetNotificationRequest,
  MarkAsAllRead,
  MarkAsRead,
} from "../../../../utils/request";
import CustomToast from "../../../../components/UI/CustomToast";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { SelecNotificationPageNo } from "./selector";
export function* getNotificationForAdminRequest(action: {
  payload: { schoolId: string };
}) {
  yield delay(500);
  const PageNo: number = yield select(SelecNotificationPageNo);
  try {
    const response: AxiosResponse = yield call(
      GetNotificationRequest,
      `?pageSize=10&pageNo=${PageNo}&schoolId=${
        action.payload.schoolId && action.payload.schoolId.length > 0
          ? action.payload.schoolId
          : ""
      }`
    );
    if (response && !response.data) {
      return;
    }
    yield put(actions.setTotalNotifiction(response.data[0].meta.total));
    if (response.data[0].counts.length > 0) {
      yield put(
        actions.setTotalNewNotifiction(
          response.data[0].counts[0].newNotification
        )
      );
    } else {
      yield put(actions.setTotalNewNotifiction(response.data[0].counts));
    }

    yield put(actions.setNotificationForAdmin(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* MarkAsAllReadRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(MarkAsAllRead);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* MarkAsReadRequest(action: {
  payload: { id: any; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(MarkAsRead, action.payload.id);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* NotificationRepoSaga() {
  yield takeLatest(
    actions.doGetNotificationList,
    getNotificationForAdminRequest
  );
  yield takeLatest(actions.MarkAsAllRead, MarkAsAllReadRequest);
  yield takeLatest(actions.MarkAsRead, MarkAsReadRequest);
}
