import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";
import { log } from "../../../../utils/logger";

const selectDomain = (state: RootState) => {


  if (state) {
    if (state.SchoolCouponState) {
      return state.SchoolCouponState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};
export const SelectCouponForm = createSelector(
  [selectDomain],
  (state) => state.couponForm
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectcouponList = createSelector(
  [selectDomain],
  (state) => state.couponList
);
export const SelecttotalCouponCount = createSelector(
  [selectDomain],
  (state) => state.totalCouponCount
);
export const SelectRefferalsList = createSelector(
  [selectDomain],
  (state) => state.refferalsList
);
export const SelectTotalRefferalsList = createSelector(
  [selectDomain],
  (state) => state.totalRefferals
);
export const SelectPromotionPageNo = createSelector(
  [selectDomain],
  (state) => state.promotionPageNo
);
export const SelectPromotionPageSize = createSelector(
  [selectDomain],
  (state) => state.promotionPageSize
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
