import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AvailabilityEnum,
  calenderInterface,
  IDateRange,
  ISavedDateRange,
  SchoolListingCalenderState,
} from "../types";
import { stat } from "fs";
export const initialState: SchoolListingCalenderState = {
  calenderForm: {
    availability: 1,
    dateRange: [],
    packageId: "",
    retreatId: "",
  },
  calenderList: [],
  laoding: false,
  submit: false,
  showMonth: false,
  dateRanges: [],
  savedRange: [],
  checkAvailable: AvailabilityEnum.SELECTED_DATES,
  isDraft: false,
  isSelecting: false,
  loading: false,
};

export const useClinicSlice = createSlice({
  name: "schoolListingCalenderState",
  initialState,
  reducers: {
    updateListingsCalendarFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `calenderForm.${action.payload.key}`, action.payload.value);
    },
    clearCalendarForm: (state) => {
      state.calenderForm.availability = AvailabilityEnum.AVAILABLE_ON_REQUEST;
      (state.calenderForm.dateRange = []),
        (state.calenderForm.packageId = ""),
        (state.calenderForm.retreatId = "");
    },
    AddNewCalender: (state) => {},
    SetListingCalendarFormDataById: (
      state,
      action: PayloadAction<ISavedDateRange[]>
    ) => {
      state.savedRange = action.payload.reverse();
    },
    doGetCalendarFormDataByID: (
      state,
      action: PayloadAction<{ packageId: string; retreatId: string }>
    ) => {},
    toggelSubmit: (state) => {
      state.submit = !state.submit;
    },
    toggelLoading: (state) => {
      state.laoding = !state.laoding;
    },
    toggelIsSelecting: (state) => {
      state.isSelecting = !state.isSelecting;
    },
    toggelMonth: (state) => {
      state.showMonth = !state.showMonth;
    },
    setShowMonth: (state, action: PayloadAction<boolean>) => {
      state.showMonth = action.payload;
    },
    setDateRange: (state, action: PayloadAction<Array<IDateRange>>) => {
      state.dateRanges = action.payload;
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<{
        selectedDate: IDateRange;
        selectedDurationDate?: IDateRange;
      }>
    ) => {
      state.selectedDateRange = [];
      state.selectedDateRange.push(action.payload.selectedDate);
      if (action.payload.selectedDurationDate) {
        state.selectedDateRange.push(action.payload.selectedDurationDate);
      }
    },
    setSelectionDateRange: (
      state,
      action: PayloadAction<IDateRange | undefined>
    ) => {
      state.selectionDate = action.payload;
    },
    setSelectionStartDate: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectionStart = action.payload;
    },
    setSavedDateRange: (state, action: PayloadAction<ISavedDateRange>) => {
      state.savedRange.push(action.payload);
      state.selectedDateRange = undefined;
      state.checkAvailable = AvailabilityEnum.SELECTED_DATES;
    },
    setAvailbility: (state, action: PayloadAction<AvailabilityEnum>) => {
      state.checkAvailable = action.payload;
    },
    clearSelection:(state) => {
      state.selectedDateRange = undefined;
      state.selectionDate=undefined;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearform: (state) => {
      state.selectionStart = "";
      state.savedRange = [];
      state.selectionDate = undefined;
      state.selectedDateRange = undefined;
      state.checkAvailable = AvailabilityEnum.SELECTED_DATES;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
