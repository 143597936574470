import { PayloadAction } from "@reduxjs/toolkit";
import { AdminUserState, UserInterface } from "../types";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
export const initialState: AdminUserState = {
  loading: false,
  pageSize: 15,
  totalRow: 0,
  search: "",
  pageNo: 1,
  statusFilter: 2,
  createdOnFilter: 0,
  date: null,
  UserList: [],
  UserFormData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    mobileNumber: "",
    currency: "",
    country: "",
    status: 0,
    gender: 1,
    description: "",
    profilePic: "",
    language: "English",
    lastLoginTime: "",
    ipAddress: "",
    verified: "",
    currencyCode: "",
    isPhoneNoValid: false,
    phoneNoVerify: false,
    Verificationtoken: "",
    countryCode: "",
    mobileNumberCheck: "",
  },
  updatePassword: {
    id: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  updatePasswordList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  editModal: false,
  deleteModal: false,
  showPassword: false,
  cropShow: false,
  userStatusShow: false,
  userCreatedOnShow: false,
  currentPassword: false,
  newPassword: false,
  showOTPField: false,
  OTP: "",
  sortColumn: "",
  sortDir: "",
  updateAdminPassword: {
    password: "",
    confirmPassword: "",
  },
};

export const useClinicSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    updateData: (
      state,
      action: PayloadAction<{ key: string; value: any; id: any }>
    ) => {
      state.UserList[action.payload.id] = action.payload.value;
    },
    updateUserFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `UserFormData.${action.payload.key}`, action.payload.value);
    },
    AddNewAdminUser: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    clearFrom: (state) => {
      state.UserFormData.id = "";
      state.UserFormData.firstName = "";
      state.UserFormData.lastName = "";
      state.UserFormData.email = "";
      state.UserFormData.mobileNumber = "";
      state.UserFormData.dob = "";
      state.UserFormData.currency = "USD";
      state.UserFormData.country = "";
      state.UserFormData.status = 0;
      state.UserFormData.isPhoneNoValid = false;
      state.UserFormData.phoneNoVerify = false;
      state.UserFormData.Verificationtoken = "";
    },
    clearUpdatePasswordFrom: (state) => {
      state.updatePassword.currentPassword = "";
      state.updatePassword.confirmNewPassword = "";
      state.updatePassword.email = "";
      state.updatePassword.id = "";
      state.updatePassword.newPassword = "";
      state.showPassword = false;
      state.newPassword = false;
      state.currentPassword = false;
    },
    deleteUserProfile: (state, action: PayloadAction<any>) => {},
    doupdateSchoolProfileDataCurrency: (
      state,
      action: PayloadAction<{ currency: any; callback: () => void }>
    ) => {},
    EditAdminUserForm: (state, action: PayloadAction<any>) => {},
    doGetUserFormById: (state, action: PayloadAction<any>) => {},
    doGetUserProfile: (state) => {},

    setNewUserForEdit: (state, action: PayloadAction<UserInterface>) => {
      state.UserFormData = action.payload;
    },

    doGetUserList: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setUserList: (state, action: PayloadAction<Array<UserInterface>>) => {
      state.UserList = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<number>) => {
      state.statusFilter = action.payload;
    },
    setSreatedOnFilter: (state, action: PayloadAction<any>) => {
      state.createdOnFilter = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    setUserForEdit: (state, action: PayloadAction<UserInterface>) => {
      state.UserFormData = action.payload;
      state.UserFormData.mobileNumberCheck = action.payload?.mobileNumber;
    },

    updateUserData: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    toggalUserEditModal: (state) => {
      state.editModal = !state.editModal;
    },
    toggalUserDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },

    ///.... user update password .....
    userUpdatePasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `updatePassword.${action.payload.key}`, action.payload.value);
    },
    doUpdateUserPassword: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    toggalShowPassword: (state) => {
      state.showPassword = !state.showPassword;
    },
    toggalCropShowModal: (state) => {
      state.cropShow = !state.cropShow;
    },
    doAddProfileImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    toggalUserStatusShow: (state) => {
      state.userStatusShow == !state.userStatusShow;
    },
    toggalUserCreatedOnShow: (state) => {
      state.userCreatedOnShow = !state.userCreatedOnShow;
    },

    updateStatusFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `statusFilter`, action.payload.value);
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    toggalShowCurrentPassword: (state) => {
      state.currentPassword = !state.currentPassword;
    },
    toggalShowNewPassword: (state) => {
      state.newPassword = !state.newPassword;
    },
    setVerificationToken: (state, action: PayloadAction<string>) => {
      state.UserFormData.Verificationtoken = action.payload;
    },
    setShowOTPField: (state, action: PayloadAction<boolean>) => {
      state.showOTPField = action.payload;
    },

    doVerifiedMobileNo: (
      state,
      action: PayloadAction<{ OTPTYPE: number; callback: () => void }>
    ) => {},
    setOTP: (state, action: PayloadAction<any>) => {
      state.OTP = action.payload;
    },
    doVerifiedOTP: (
      state,
      action: PayloadAction<{ OTP: string; callback: () => void }>
    ) => {},
    clearOtPField: (state) => {
      state.OTP = "";
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doUpdateAdminPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `updateAdminPassword.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdateAdminPassword: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    clearAdminPasswordForm:(state)=>{
      state.updateAdminPassword.confirmPassword="",
      state.updateAdminPassword.password=""
    }
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
