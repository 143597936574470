import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.customerHelpSupport) {
      return state.customerHelpSupport;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectCustomerSupportForm = createSelector(
  [selectDomain],
  (state) => state.customerSupportFormData
);
export const selectCustomerSupportListData = createSelector(
  [selectDomain],
  (state) => state.customerSupportList
);
export const selectStillMakingRetreatsPlanForm = createSelector(
  [selectDomain],
  (state) => state.stillMakingRetreatsPlanFormData
);
export const selectStillMakingRetreatsPlanList = createSelector(
  [selectDomain],
  (state) => state.stillMakingRetreatsPlanList
);
export const selectContextListListData = createSelector(
  [selectDomain],
  (state) => state.contextList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
