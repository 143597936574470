import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  AccountDeactivate,
  AddBookingContinue,
  AddBookingPayment,
  AddContactAndItinararyData,
  DoGetPriceData,
  GetPackageContetctData,
  GetPackageItinararyData,
  GetPastBookingUserData,
  GetTransactionDetailData,
  GetUpComingBookingUserData,
  UpdateBookingPayment,
} from "../../../../utils/request";
import {
  selecCOntactDetailForm,
  selecpriceDetailForm,
  selectAddCard,
  selectBookingId,
  selectBookingsListPagination,
  selectItinararyData,
  selectdepositType,
  selectisCouponAppliedOrNot,
  selectotalPriceData,
} from "./selector";
import {
  AddCardInterface,
  ContactDetailInterface,
  GetPriceInterface,
  ItinararyFormInterface,
  PaginationInterface,
  PaymentDepositTypeEnum,
} from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* getItinararyDetailRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoader(true));
    const response: AxiosResponse = yield call(
      GetPackageItinararyData,
      action.payload.id
    );
    yield put(actions.setLoader(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setNItinararyDetail(response.data.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getContactDetailRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoader(true));
    const response: AxiosResponse = yield call(
      GetPackageContetctData,
      action.payload.id
    );  yield put(actions.setLoader(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setContactDetail(response.data.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoader(false));
    CatchBlockFunction(error);
  }
}
export function* getPriceRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const isCouponApplied: boolean = yield select(selectisCouponAppliedOrNot);
  const form: GetPriceInterface = yield select(selecpriceDetailForm);

  try {
    const response: AxiosResponse = yield call(DoGetPriceData, form);
    if (response.data.status == false && form.couponCode !== "") {
      CustomToast(response.data.data.message, "ERROR");
      yield call(action?.payload?.callback);
      return;
    } else if (response.data.status == true && isCouponApplied != true) {
      CustomToast(response.data.data.message, "SUCCESS");
      yield put(actions.setisCouponAppliedOrNot(true));
    }
    yield put(actions.setPriceDetail(response.data.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddItinararyAndContatctRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const Contactform: ContactDetailInterface = yield select(
    selecCOntactDetailForm
  );
  const Itinararyform: ItinararyFormInterface = yield select(
    selectItinararyData
  );
  const depositType: PaymentDepositTypeEnum = yield select(selectdepositType);
  if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Contactform.email)
  ) {
    CustomToast("Enter Valid Email ID", "ERROR");
    return;
  }
  const form: GetPriceInterface = yield select(selecpriceDetailForm);

  try {
    yield put(actions.setContactLoader(true));
    const data = {
      inquiryId: Itinararyform.id,
      arrivalDate: Itinararyform.arrivalDate,
      packageId: form.packageId,
      couponCode:
        Itinararyform.couponCode == undefined
          ? form.couponCode
          : Itinararyform.couponCode,
      email: Contactform.email,
      phoneNo: Contactform.phoneNo,
      depositType: depositType,
      phoneNoVerify: Contactform.phoneNoVerify,
      whatsAppNumber: Contactform.whatsAppNumber,
    };
    yield put(actions.setContactLoader(false));
    const response: AxiosResponse = yield call(
      AddContactAndItinararyData,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      yield put(actions.setContactLoader(false));
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setBookingId(response.data.bookingId));
    yield call(action?.payload?.callback(response.data.bookingId));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddContinueBookingRequest(action: {
  payload: { bookingId: string; callback: any };
}) {
  yield delay(500);
  const form: AddCardInterface = yield select(selectAddCard);

  if (!/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(form.accountHolderName)) {
    CustomToast("Enter Valid Account Holder Name", "ERROR");
    return;
  }
  if (form.billingAddress.length > 100) {
    CustomToast("100 characters allowed only in address", "ERROR");
    return;
  }
  if (!(form.postalCode.length > 5 && form.postalCode.length <= 7)) {
    CustomToast("Enter Valid ZIP Code", "ERROR");
    return;
  }
  try {
    const data = {
      bookingId: action.payload.bookingId,
      paymentMethod: form.paymentMethod,
      accountHolderName: form.accountHolderName,
      billingAddress: form.billingAddress,
      postalCode: form.postalCode,
    };
    const response: AxiosResponse = yield call(AddBookingContinue, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setPrivateKey(response.data.data));
    yield put(actions.setshowAddCard(true));
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddPaymentBookingRequest(action: {
  payload: {  bookingId: string; address: any; callback: any };
}) {
  yield delay(500);

  const form: AddCardInterface = yield select(selectAddCard);
 
  const data = {
    bookingId: action.payload.bookingId,
    paymentMethod: form.paymentMethod,
    accountHolderName: action.payload.address.name,
    address: {
      city: action.payload?.address?.address?.city,
      country: action.payload?.address?.address?.country,
      line1: action.payload?.address?.address?.line1,
      line2: action.payload?.address?.address?.line2,
      postal_code: action.payload?.address?.address?.postal_code,
      state: action.payload?.address?.address?.state,
    },
  };
  try {
    yield put(actions.setPaySuccessLoader(true));
    const response: AxiosResponse = yield call(AddBookingPayment, data);
    yield put(actions.setPaySuccessLoader(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      yield call(action?.payload?.callback(false));
      return;
    }
    yield call(action?.payload?.callback(response.data));
  } catch (error: any) {
    yield put(actions.setPaySuccessLoader(false));
    CatchBlockFunction(error);
  }
}

export function* UpdatePaymentBookingRequest(action: {
  payload: {  paymentId: string;};
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(UpdateBookingPayment, action.payload.paymentId);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddCreditCardRequest() {
  yield delay(500);
  const form: AddCardInterface = yield select(selectAddCard);
  try {
    const response: AxiosResponse = yield call(DoGetPriceData, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* GetUpComingBooking() {
  yield delay(500);
  const form: PaginationInterface = yield select(selectBookingsListPagination);
  try {
    const response: AxiosResponse = yield call(
      GetUpComingBookingUserData,
      `?pageNo=${form.UpComingBookingsPageNo}&pageSize=${5}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setUpcomingBookingsList(response.data[0].results));
    yield put(actions.setPageSizeUpcoming(response.data[0].meta.total));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* GetPastBookings() {
  yield delay(500);
  const form: PaginationInterface = yield select(selectBookingsListPagination);
  try {
    const response: AxiosResponse = yield call(
      GetPastBookingUserData,
      `?pageNo=${form.PastBookingsPageNo}&pageSize=${5}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setPastBookingsList(response.data[0].results));
    yield put(actions.setPageSizePast(response.data[0].meta.total));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getTransactionDetailRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setPaySuccessLoader(true));
    const response: AxiosResponse = yield call(
      GetTransactionDetailData,
      action.payload.id
    );
    yield put(actions.setPaySuccessLoader(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setTransactionDetail(response.data));
    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setPaySuccessLoader(false));
    
    CatchBlockFunction(error);
  }
}
export function* AccountDeactivateSagaRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  
  try {
    const response: AxiosResponse = yield call(
      AccountDeactivate,
      
      `?token=${action.payload.id}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UserBookingRepoSaga() {
  yield takeLatest(actions.dogetItinararyDetail, getItinararyDetailRequest);
  yield takeLatest(actions.dogetContactDetail, getContactDetailRequest);
  yield takeLatest(actions.GetPriceRequest, getPriceRequest);
  yield takeLatest(actions.AddCardRequest, AddCreditCardRequest);
  yield takeLatest(
    actions.AddItinararyAndContatctDetail,
    AddItinararyAndContatctRequest
  );
  yield takeLatest(actions.doContinueDetail, AddContinueBookingRequest);
  yield takeLatest(actions.GetUpComingBookingRequest, GetUpComingBooking);
  yield takeLatest(actions.GetPastBookingsRequest, GetPastBookings);
  yield takeLatest(
    actions.GetTransactionDetailRequest,
    getTransactionDetailRequest
  );
  yield takeLatest(
    actions.AccountDeactivateRequest,
    AccountDeactivateSagaRequest
  );
  yield takeLatest(actions.doPayment, AddPaymentBookingRequest);
  yield takeLatest(actions.doUpdatePayment, UpdatePaymentBookingRequest);

}
