import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import React from "react";
import { SiteSettingInterface } from "../types";
import { actions } from "./slice";
import { actions as actionsModal } from "../../../../School/Listings/redux/slice";
import CustomToast from "../../../../../components/UI/CustomToast";
import { SelectPageNo, SelectSearch, selectSiteSettingForm } from "./selector";
import { AxiosResponse } from "axios";
import {
  AddSiteSetting,
  GetSiteSettingRequest,
  getTimeZoneRequest,
  uplodeSiteSettingImageRequest,
} from "../../../../../utils/request";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
import { log } from "../../../../../utils/logger";

export function* SiteSettingsAddRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: SiteSettingInterface = yield select(selectSiteSettingForm);

  if (form.siteName.length == 0) {
    CustomToast("please fill Site Name", "ERROR");
    return;
  }
  if (form.currency.length == 0) {
    CustomToast("please fill Default Currency", "ERROR");
    return;
  }
  if (form.minimumPrice === 0) {
    CustomToast("please fill Minimum Price", "ERROR");
    return;
  }
  if (form.maximumPrice == 0) {
    CustomToast("please fill Maximum Price", "ERROR");
    return;
  }
  if (form.language.length == 0) {
    CustomToast("please fill Default Language", "ERROR");
    return;
  }
  if (form.timezone.length == 0) {
    CustomToast("please fill Timezone", "ERROR");
    return;
  }
  // if (form.dateFormat.length == 0) {
  //   CustomToast("please fill Date Format", "ERROR");
  //   return;
  // }
  if (form.customerSupportEmail.length == 0) {
    CustomToast("please fill Customer Support Email", "ERROR");
    return;
  }
  if (form.customerPhoneNo.length == 0) {
    CustomToast("please fill Customer Support Phone", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(AddSiteSetting, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getSiteSettingListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetSiteSettingRequest);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setDataInForm(response.data));
    yield put(actions.setDataInList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* LogoFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeSiteSettingImageRequest,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    log(response, "logo file");

    yield put(
      actions.updateSiteSettingFormValue({
        key: "logo",
        value: response.data.data.name,
      })
    );

    yield put(actionsModal.toggelCropClose());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FaviconFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeSiteSettingImageRequest,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    log(response, "favicon file");

    yield put(
      actions.updateSiteSettingFormValue({
        key: "favicon",
        value: response.data.data.name,
      })
    );

    yield put(actionsModal.toggelCropClose());
    // yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* HomePageFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeSiteSettingImageRequest,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    log(response, "homepage Image file");

    yield put(
      actions.updateSiteSettingFormValue({
        key: "homepageImage",
        value: response.data.data.name,
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getTimezoneListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);

    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getTimeZoneRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTimezoneList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* adminSiteSettingsRepoSaga() {
  yield takeLatest(actions.AddSiteSettings, SiteSettingsAddRequest);
  yield takeLatest(actions.doGetSiteSettingList, getSiteSettingListRequest);
  yield takeLatest(actions.doGetTimezoneList, getTimezoneListRequest);
  yield takeLatest(actions.doAddLogoImage, LogoFileUploadRequest);
  yield takeLatest(actions.doAddFaviconImage, FaviconFileUploadRequest);
  yield takeLatest(actions.doAddHomePageImage, HomePageFileUploadRequest);
}
