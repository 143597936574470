import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationInterface, NotificationState } from "../types";

export const initialState: NotificationState = {
  adminNotification: [],
  pageNo: 1,
  totalNotifiction: 0,
  totalNewNotifiction: 0,
};

export const useClinicSlice = createSlice({
  name: "NotificationState",
  initialState,
  reducers: {
    setNotificationForAdmin: (
      state,
      action: PayloadAction<Array<NotificationInterface>>
    ) => {
      state.adminNotification = action.payload;
    },
    doGetNotificationList: (
      state,
      action: PayloadAction<{ schoolId: string }>
    ) => {},
    MarkAsAllRead: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    MarkAsRead: (
      state,
      action: PayloadAction<{ id: String; callback: () => void }>
    ) => {},
    setNotificationpageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalNotifiction: (state, action: PayloadAction<number>) => {
      state.totalNotifiction = action.payload;
    },
    setTotalNewNotifiction: (state, action: PayloadAction<number>) => {
      state.totalNewNotifiction = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
