import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.frontendWishList) {
      return state.frontendWishList;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectWishListFormData = createSelector(
  [selectDomain],
  (state) => state.wishListFormData
);
export const selectWishListDataList = createSelector(
  [selectDomain],
  (state) => state.wishListDataList
);
export const selectTotalWishlist = createSelector(
  [selectDomain],
  (state) => state.totalWishlist
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectWishLisCheck = createSelector(
  [selectDomain],
  (state) => state.wishListIcon
);
