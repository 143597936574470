import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { AdminCommissionState, CommissionInterface } from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: AdminCommissionState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  commissionFormData: {
    id: "",
    offlineMaximumCommision: "",
    offlineMinimumCommision: "",
    onlineMaximumCommision: "",
    onlineMinimumCommision: "",
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
};

export const useClinicSlice = createSlice({
  name: "adminCommission",
  initialState,
  reducers: {
    updateCommissionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      log(
        "action.payload.key,action.payload.value",
        action.payload.key,
        action.payload.value
      );
      set(
        state,
        `commissionFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    clearFrom: (state) => {
      state.commissionFormData.id = "";
      state.commissionFormData.offlineMaximumCommision = "";
      state.commissionFormData.offlineMinimumCommision = "";
      state.commissionFormData.onlineMaximumCommision = "";
      state.commissionFormData.onlineMinimumCommision = "";
    },
    AddCommission: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetCommission: (state) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setDataInForm: (state, action: PayloadAction<CommissionInterface>) => {
      state.commissionFormData = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: AdminCommissionSliceKey,
} = useClinicSlice;
