import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { ActivityEnum, LandingInterface } from "../types";
import { actions } from "./slice";
// import { actions as actionsModal } from "../../../School/Listings/redux/slice";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  SelectPageNo,
  SelectPageSize,
  SelectSortColumn,
  SelectSortDir,
  selectForm,
} from "./selector";
import { AxiosResponse } from "axios";
import {
  uplodeSiteSettingImageRequest,
  doGetCountryRequest,
  doGetCityRequest,
  doUpdateLandingRequest,
  doGetLandingRequest,
  doGetLandingDataById,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { log } from "../../../../utils/logger";

// export function* doAddRequest(action: { payload: { callback: any } }) {
//   yield delay(500);

//   const form: LandingInterface = yield select(selectForm);

//   if (form.countryId.length == 0) {
//     CustomToast("please select country", "ERROR");
//     return;
//   }

//   try {
//     const response: AxiosResponse = yield call(doAddLandingRequest, form);
//     if (response.data.status == false) {
//       CustomToast(response.data.message, "ERROR");

//       return;
//     } else {
//       CustomToast(response.data.message, "SUCCESS");
//     }

//     yield call(action?.payload?.callback());
//   } catch (error: any) {
//     CatchBlockFunction(error);
//   }
// }
export function* LandingPageFileUploadRequest(action: {
  payload: { data: any; type: number; callback: any; name: string };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeSiteSettingImageRequest,
      action.payload.data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    log(response, "banner Photo file");
    yield put(
      actions.updateFormValue({
        key: action.payload.name,
        value: response.data.data.name,
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetCountryListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetCountryRequest);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setCountryList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetCityListRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetCityRequest,
      `?country=${action.payload}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setCityList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetLandingRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setTotalList(response.data[0].meta.total));
    yield put(actions.setList(response.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDataByIdRequest(action: {
  payload: {
    countryId: string;
    cityId: string;
    category: ActivityEnum;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetLandingDataById,
      `?activity=${action.payload.category}&countryId=${action.payload.countryId}&cityId=${action.payload.cityId}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: LandingInterface = yield select(selectForm);

  if (form.countryId.length == 0) {
    CustomToast("please select country", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(doUpdateLandingRequest, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* LandingPagesAdminRepoSaga() {
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doAddBannerImage, LandingPageFileUploadRequest);
  yield takeLatest(actions.doGetCountryList, doGetCountryListRequest);
  yield takeLatest(actions.doGetCityList, doGetCityListRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetById, doGetDataByIdRequest);
}
