import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AdminSiteSettingState,
  AllTimezoneInterface,
  SiteSettingInterface,
} from "../types";

export const initialState: AdminSiteSettingState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  siteSettingList: [],
  siteSettingFormData: {
    id: "",
    siteName: "",
    logo: "",
    favicon: "",
    currency: "",
    minimumPrice: "",
    maximumPrice: "",
    language: "",
    maintenanceMode: 2,
    timezone: "",
    homepageImage: "",
    dateFormat: "",
    customerSupportEmail: "",
    customerPhoneNo: "",
    currencyCode: "",
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  allTimezoneList: [],
  cropShow: false,
};

export const useClinicSlice = createSlice({
  name: "adminSiteSetting",
  initialState,
  reducers: {
    updateSiteSettingFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      //@ts-ignore
      state.siteSettingFormData[action.payload.key] = action.payload.value;
    },

    clearFrom: (state) => {
      state.siteSettingFormData.id = "";
      state.siteSettingFormData.siteName = "";
      state.siteSettingFormData.logo = "";
      state.siteSettingFormData.favicon = "";
      state.siteSettingFormData.currency = "";
      state.siteSettingFormData.minimumPrice = "";
      state.siteSettingFormData.maximumPrice = "";
      state.siteSettingFormData.language = "";
      state.siteSettingFormData.maintenanceMode = 2;
      state.siteSettingFormData.timezone = "";
      state.siteSettingFormData.homepageImage = "";
      state.siteSettingFormData.dateFormat = "";
      state.siteSettingFormData.customerSupportEmail = "";
      state.siteSettingFormData.customerPhoneNo = "";
    },

    AddSiteSettings: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setDataInForm: (state, action: PayloadAction<SiteSettingInterface>) => {
      state.siteSettingFormData = action.payload;
    },
    toggelCropSow: (state) => {
      state.cropShow = !state.cropShow;
    },
    setDataInList: (
      state,
      action: PayloadAction<Array<SiteSettingInterface>>
    ) => {
      state.siteSettingList = action.payload;
    },
    doGetSiteSettingList: (state) => {},
    setTimezoneList: (
      state,
      action: PayloadAction<Array<AllTimezoneInterface>>
    ) => {
      state.allTimezoneList = action.payload;
    },

    doGetTimezoneList: (state) => {},
    doAddLogoImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddFaviconImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddHomePageImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: AdminSiteSettingsSliceKey,
} = useClinicSlice;
