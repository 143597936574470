import { PayloadAction } from "@reduxjs/toolkit";
import {
  RolesInterface,
  AssociationInterface,
  SchoolInstructorsInterface,
  SchoolInstructorsState,
  SkillsListInterface,
  SkillsModalInterface,
  CertificateModalInterface,
  GenderEnum,
} from "../types";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import { log } from "../../../../utils/logger";

export const initialState: SchoolInstructorsState = {
  loading: false,
  visitTab: [],
  SelctedInstructroListId: [],
  InstructorListForListing: {
    instructorList: [],
    idselected: "",
    retreatId: "",
  },
  InstructorListingSelectedList: [],
  InstructorListForListingList: [],
  InstructorListForSchool: [],
  CertificateModal: false,
  pageSize: 10,
  totalRow: 0,
  certificateEditIndex: -1,
  skillEditIndex: -1,
  search: "",
  SchoolList: [],
  InstructorSkills: [],
  ALLSkillsList: [],
  SchoolFormData: {
    id: "",
    profilePic: "",
    fullName: "",
    jobrole: [],
    gender: GenderEnum.FEMALE,
    email: "",
    phoneno: "",
    skypeId: "",
    biography: "",
    token: "",
  },
  Association: [],
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  certificateFormData: {
    association: "",
    id: "",
    isExpire: 0,
    level: "",
    uploadCertificate: "",
    yearofissue: "",
    date: "",
  },
  instructorId: "",
  certificateList: [],
  CertificateLevel: [],

  certificate: {
    CertificatesModalForm: {
      ref_id: "",
      id: "",
      certificateName: "",
      associationId: "",
      association: "",
      certificateFile: "",
      certificateLevel: "",
      certificateIssueYear: 0,
      certificateExpiry: 0,
      certificateExpiryDate: "",
    },

    CertificatesModalFormList: [],
    id: "",
  },
  skills: {
    SkillsModalList: [],
    id: "",
    SkillsModalForm: {
      id: "",
      ref_id: "",
      skill: "",
      practicingSince: 0,
      skillsName: "",
    },
  },
  OTP: "",
  allRolesList: [],
  totalInstructor: 0,
  count: 0,
  progress: 0,
  showOTPField: false,
  otpStatus: false,
  TotalRequiredFiled: {
    basicDetailCount: 0,
    SkillsCount: 0,
    CertificateCount: 0,
  },
  isDraft: false,
  InstructorReqListForListingList: [],
  ListingLoader: false,
  loader: false,
};

export const useClinicSlice = createSlice({
  name: "schoolInstructors",
  initialState,
  reducers: {
    updateTotalCount: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `TotalRequiredFiled.${action.payload.key}`,
        action.payload.value
      );
    },
    updateSchoolInstructorsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `SchoolFormData.${action.payload.key}`, action.payload.value);
    },
    setVisitTab: (state, action: PayloadAction<any>) => {
      state.visitTab.push(action.payload);
    },

    clearVisitTab: (state) => {
      state.visitTab = [];
    },
    updateCertificatesModalFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `certificate.CertificatesModalForm.${action.payload.key}`,
        action.payload.value
      );
    },
    setPrrogressData: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setShowOTPField: (state, action: PayloadAction<boolean>) => {
      state.showOTPField = action.payload;
    },
    setListingLoader: (state, action: PayloadAction<boolean>) => {
      state.ListingLoader = action.payload;
    },
    updateSkillModalFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `skills.SkillsModalForm.${action.payload.key}`,
        action.payload.value
      );
    },

    AddNewCertificateSchoolInstructor: (state) => {
      state.certificate.CertificatesModalFormList.push(
        state.certificate.CertificatesModalForm
      );
      log(state.certificate.CertificatesModalForm, "CertificatesModal");
    },

    EditCertificateSchoolInstructor: (state, action: PayloadAction<any>) => {
      // state.certificate.CertificatesModalFormList = [];
      state.certificate.CertificatesModalFormList.push(action.payload);
      log(state.certificate.CertificatesModalForm, "CertificatesModal");
    },
    AddNewSkiilsSchoolInstructor: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.skills.SkillsModalList.push(state.skills.SkillsModalForm);
    },

    updateReSkillsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `Certificates.${action.payload.key}`, action.payload.value);
    },

    updateCertificateModalFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `Skills.${action.payload.key}`, action.payload.value);
    },

    doGetInstructorListBySchoolIDList: (
      state,
      action: PayloadAction<any>
    ) => {},
    doGetInstructorListByIDList: (state, action: PayloadAction<any>) => {},

    setInstructorSkillsList: (
      state,
      action: PayloadAction<Array<SkillsModalInterface>>
    ) => {
      state.skills.SkillsModalList = action.payload;
    },
    setInstructorCertificateList: (
      state,
      action: PayloadAction<Array<CertificateModalInterface>>
    ) => {
      state.certificate.CertificatesModalFormList = action.payload;
    },
    setInstructorOtpToken: (state, action: PayloadAction<string>) => {
      state.SchoolFormData.token = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setInstructorForEdit: (
      state,
      action: PayloadAction<SchoolInstructorsInterface>
    ) => {
      state.SchoolFormData = action.payload;
    },

    setInstructorListOfThePerticulerSchool: (
      state,
      action: PayloadAction<Array<SchoolInstructorsInterface>>
    ) => {
      state.InstructorListForSchool = action.payload;
    },
    setTotalInstructor: (state, action: PayloadAction<number>) => {
      state.totalInstructor = action.payload;
    },
    setOTP: (state, action: PayloadAction<any>) => {
      state.OTP = action.payload;
    },
    DeleteSkiilsSchool: (state, action: PayloadAction<any>) => {},
    DeleteInstructorOfTheSchool: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    AddCertificateFormDetails: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      state.certificateList.push(state.certificateFormData);
    },
    clearFrom: (state) => {
      state.SchoolFormData.id = "";
      state.SchoolFormData.profilePic = "";
      state.SchoolFormData.fullName = "";
      state.SchoolFormData.jobrole = [];
      state.SchoolFormData.gender = 1;
      state.SchoolFormData.email = "";
      state.SchoolFormData.phoneno = "";
      state.SchoolFormData.skypeId = "";
      state.SchoolFormData.biography = "";
    },
    clearCertificatesModalForm: (state) => {
      state.certificate.CertificatesModalForm.id = "";
      state.certificate.CertificatesModalForm.ref_id = "";
      state.certificate.CertificatesModalForm.associationId = "";
      state.certificate.CertificatesModalForm.certificateLevel = "";
      state.certificate.CertificatesModalForm.certificateFile = "";
      state.certificate.CertificatesModalForm.certificateIssueYear = 0;
      state.certificate.CertificatesModalForm.certificateExpiry = 0;
      state.certificateEditIndex = -1;
    },
    clearSkillsModalForm: (state) => {
      state.skills.SkillsModalForm.ref_id = "";
      state.skills.SkillsModalForm.id = "";
      state.skills.SkillsModalForm.skill = "";
      state.skills.SkillsModalForm.practicingSince = 0;
      state.skillEditIndex = -1;
    },
    clearSkillsModalList: (state) => {
      state.skills.SkillsModalList = [];
      state.certificate.CertificatesModalFormList = [];
    },

    DeleteCertificatesModalList: (state, action: PayloadAction<number>) => {
      state.certificate.CertificatesModalFormList.splice(action.payload, 1);
    },
    DeleteSkillsModalList: (state, action: PayloadAction<any>) => {
      log(action.payload, "DeleteCertificatesModalList");
      state.skills.SkillsModalList.splice(action.payload, 1);
    },
    setdataCertificateSchoolInstructor: (
      state,
      action: PayloadAction<{ data: CertificateModalInterface; index: number }>
    ) => {
      state.certificateEditIndex = action.payload.index;
      state.certificate.CertificatesModalForm = action.payload.data;
    },
    setdataSkillschoolInstructor: (
      state,
      action: PayloadAction<{ data: SkillsModalInterface; index: number }>
    ) => {
      state.skillEditIndex = action.payload.index;
      state.skills.SkillsModalForm = action.payload.data;
    },
    updateCertificateSchoolInstructor: (state) => {
      if (state.certificateEditIndex > -1) {
        state.certificate.CertificatesModalFormList[
          state.certificateEditIndex
        ] = state.certificate.CertificatesModalForm;
        state.certificateEditIndex = -1;
      } else {
        state.certificate.CertificatesModalFormList.push(
          state.certificate.CertificatesModalForm
        );
      }
      state.certificateEditIndex = -1;
    },
    updateSkillsSchoolInstructor: (state) => {
      if (state.skillEditIndex > -1) {
        state.skills.SkillsModalList[state.skillEditIndex] =
          state.skills.SkillsModalForm;
        state.skillEditIndex = -1;
      } else {
        state.skills.SkillsModalList.push(state.skills.SkillsModalForm);
      }
    },
    doAddInstructorsImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddInstructorsCertificateImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},

    AddInstructorsBasicDetails: (
      state,
      action: PayloadAction<{
        schoolId: string;
        callback: (instructorId: number) => void;
      }>
    ) => {},
    AddInstructorsSkills: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddInstructorsCertificates: (
      state,
      action: PayloadAction<{ schoolId: string; callback: () => void }>
    ) => {
      log("Your Instructors Certificates add");
      // state.SchoolList.push(state.SchoolFormData);
    },

    languageEdit: (state, action: PayloadAction<any>) => {},
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    updateLanguageData: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.SchoolList.push(state.SchoolFormData);
    },

    setDayList: (
      state,
      action: PayloadAction<Array<SchoolInstructorsInterface>>
    ) => {
      state.SchoolList = action.payload;
    },
    setAssociationList: (
      state,
      action: PayloadAction<Array<AssociationInterface>>
    ) => {
      state.Association = action.payload;
    },
    setCertificateLavelList: (
      state,
      action: PayloadAction<Array<AssociationInterface>>
    ) => {
      state.CertificateLevel = action.payload;
    },
    setSkillsList: (
      state,
      action: PayloadAction<Array<SkillsListInterface>>
    ) => {
      state.ALLSkillsList = action.payload;
    },
    doGetAssociationList: (state) => {},
    doGetCertificateLevelList: (state) => {},
    doGetAllSkilsList: (state) => {},
    doVerifiedMobileNo: (
      state,
      action: PayloadAction<{ OTPTYPE: number; callback: () => void }>
    ) => {},
    doVerifiedOTP: (
      state,
      action: PayloadAction<{ OTP: string; callback: () => void }>
    ) => {},
    doGetCertificateList: (state, action: PayloadAction<any>) => {},
    doGetSkillsList: (state, action: PayloadAction<any>) => {},
    doGetInstructorList: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setCertificateModalValueTrue: (state) => {
      state.CertificateModal = true;
    },
    setCertificateModalValueFalse: (state) => {
      state.CertificateModal = false;
    },
    SetInstructorID: (state, action: PayloadAction<string>) => {
      state.instructorId = action.payload;
    },
    setRolesList: (state, action: PayloadAction<Array<RolesInterface>>) => {
      state.allRolesList = action.payload;
    },
    doGetRolesList: (state) => {},
    DeleteInstroctorFromList: (state, action: PayloadAction<any>) => {
      log(action.payload, "DeleteFaqModalList");
      state.InstructorListForListing.instructorList.splice(
        state.InstructorListForListing.instructorList.findIndex(
          (obj) => obj === action.payload.idselected
        ),
        1
      );
    },
    SelectInstructorForListings: (state, action: PayloadAction<any>) => {
      state.InstructorListForListing.retreatId = action.payload?.retreatId;
      state.InstructorListForListing.instructorList.push(
        action.payload.idselected
      );
    },
    InstructorListingSelected: (state, action: PayloadAction<any>) => {
      state.InstructorListForListing.instructorList = action.payload;
    },
    InstructorReqListingSelected: (state, action: PayloadAction<any>) => {
      state.InstructorReqListForListingList = action.payload;
    },
    AddInstructorFormList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      // state.SchoolList.push(state.SchoolFormData);
    },
    updateSchoolInstructorListFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `InstructorListForListing.${action.payload.key}`,
        action.payload.value
      );
    },
    dogetinstructorlistdrag: (state, action: PayloadAction<any>) => {},
    InstructorCount: (state) => {
      state.count = state.count + 1;
    },
    clearInstructorListingList: (state) => {
      state.InstructorListForListing.retreatId = "";
      state.InstructorListForListing.instructorList = [];
      state.InstructorListForListing.idselected = "";
    },
    setInstructorOtpStatus: (state, action: PayloadAction<boolean>) => {
      state.otpStatus = action.payload;
    },
    setInstructorIsDraft: (state, action: PayloadAction<boolean>) => {
      state.isDraft = action.payload;
    },
  },
});

export const {
  reducer,
  actions,
  name: SchoolInstructorsSliceKey,
} = useClinicSlice;
