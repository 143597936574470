import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { frontendWishListState, WishListInterface } from "../types";
import { log } from "../../../../utils/logger";
import { CancellationPolicyTypeEnum } from "../../ListingsFilter/types";

export const initialState: frontendWishListState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  wishListIcon: false,
  wishListDataList: [],
  wishListFormData: {
    id: "",
    retreatId: "",
    retreatTitle: "",
    activity: "",
    programDuration: 0,
    spokenLanguage: [],
    cancellationPolicy: CancellationPolicyTypeEnum.ADD_YOUR_OWN,
    cleanAndSafetyMeasure: 0,
    rating: 0,
    noOfReviews: 0,
    couponAvailble: 0,
    freeGift: 0,
    popular: 0,
    interestedPeople: 0,
    createdAt: "",
    retreatType: 0,
  },
  totalWishlist: 0,
};

export const useClinicSlice = createSlice({
  name: "frontendWishList",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },
    updateWishListFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `wishListFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateWishListPageNumber: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `pageNo`, action.payload.value);
    },

    doAddWishlist: (state, action: PayloadAction<{ callback: () => void }>) => {
      log("Your Wishlist add");
    },
    doGetWishlist: (state) => {},

    doRemoveWishList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setWishListData: (
      state,
      action: PayloadAction<Array<WishListInterface>>
    ) => {
      state.wishListDataList = action.payload;
    },
    toggalWishListIcon: (state) => {
      state.wishListIcon = !state.wishListIcon;
    },
    setTotalWishlist: (state, action: PayloadAction<number>) => {
      state.totalWishlist = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
