import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.schoolListingPackages) {
      return state.schoolListingPackages;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolListingRankingForm = createSelector(
  [selectDomain],
  (state) => state.rankingFormData
);
export const selectSchoolListingRankingList = createSelector(
  [selectDomain],
  (state) => state.rankingList
);
export const selectmainPagePriceLoader = createSelector(
  [selectDomain],
  (state) => state.mainPagePriceLoader
);
export const selectBookingLoader = createSelector(
  [selectDomain],
  (state) => state.bookingLoader
);
export const selectSchoolListingBookingPolicyForm = createSelector(
  [selectDomain],
  (state) => state.bookingPolicyFormData
);
export const selectSchoolListingBookingPolicyList = createSelector(
  [selectDomain],
  (state) => state.bookingPolicyList
);
export const selectSchoolListingPriceForm = createSelector(
  [selectDomain],
  (state) => state.priceFormData
);
export const selectSchoolListingPriceImagesForm = createSelector(
  [selectDomain],
  (state) => state.priceFormData?.photos
);
export const selectSchoolListingPriceList = createSelector(
  [selectDomain],
  (state) => state.priceList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const SelecPriceModalType = createSelector(
  [selectDomain],
  (state) => state.PriceModalType
);
export const SelectRankingModalType = createSelector(
  [selectDomain],
  (state) => state.RankingModalType
);
export const SelectBookingModalType = createSelector(
  [selectDomain],
  (state) => state.BookingModalType
);
export const SelectPhotoCrop = createSelector(
  [selectDomain],
  (state) => state.CropModal
);
export const SelectRankingDraft = createSelector(
  [selectDomain],
  (state) => state.rankingFormData.isDraft
);
export const SelectBookingDraft = createSelector(
  [selectDomain],
  (state) => state.bookingPolicyFormData?.isDraft
);
export const SelectPriceDraft = createSelector(
  [selectDomain],
  (state) => state.priceFormData?.isDraft
);
export const SelectImageArray = createSelector(
  [selectDomain],
  (state) => state.imageArray
);
export const SelectBookingPolicyData = createSelector(
  [selectDomain],
  (state) => state.bookingPolicyData
);
export const SelectRankingData = createSelector(
  [selectDomain],
  (state) => state.rankingData
);
export const SelectListingPackagePrice = createSelector(
  [selectDomain],
  (state) => state.listingPackagePrice
);
export const SelectPriceLoading = createSelector(
  [selectDomain],
  (state) => state.priceLoading
);
export const SelectUploadFiles = createSelector(
  [selectDomain],
  (state) => state.uploadFiles
);
export const SelectUploadingCurrentIndex = createSelector(
  [selectDomain],
  (state) => state.uploadingCurrentIndex
);
