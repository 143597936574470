import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  selectForgetPaswordFrom,
  selectLoginForm,
  selectPartnerForm,
  selectResetPasswordForm,
  selectUserForm,
} from "./selector";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  ForgetPasswordInterface,
  PartnerSignUpInterface,
  ResetPasswordInterface,
  UserSignUpInterface,
} from "../types";
import cookie from "cookie";
import CustomToast from "../../../components/UI/CustomToast";
import {
  AddSchoolAdminRequest,
  AdminLoginRequest,
  ForgetPasswordRequest,
  GetLoginCountData,
  GetPartnerProfileRequest,
  LoginRequest,
  MeProfileRequest,
  ResetPasswordRequest,
  SchoolSignUpRequest,
  UserNewSignUpRequest,
  googleConnectRequest,
  googleLoginRequest,
} from "../../../utils/request";
import { actions as actionadmin } from "../../../redux/Admin/School/redux/slice";
import { actions as actionsForSchool } from "../../../redux/Admin/School/redux/slice";
import CatchBlockFunction from "../../../hooks/CatchBlockFunction";

export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const { email, password, keeplogin } = yield select(selectLoginForm);
  if (email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (password.length === 0) {
    CustomToast("Please Enter Password", "ERROR");
    return;
  }
  let data = {
    email: email,
    password: btoa(password),
    keeplogin: keeplogin,
  };
  try {
    const response: AxiosResponse = yield call(LoginRequest, data);

    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUpdateTocken(response.data.token));

    yield put(actions.loginSuccess());
    yield put(actions.setisLogin(true));
    yield call(action.payload.callback(response.data.token));
  } catch (error: any) {
    yield put(actions.setisLogin(false));
    CatchBlockFunction(error);
  }
}
export function* GoogleLoginRequest(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      googleLoginRequest,
      action.payload.data
    );

    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setUpdateTocken(response.data.token));
    yield put(actions.loginSuccess());
    yield put(actions.setisLogin(true));
    yield put(actions.ShowHideSignUp(false));
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback(response.data.token));
  } catch (error: any) {
    yield put(actions.setisLogin(false));
    CatchBlockFunction(error);
  }
}
export function* GoogleConnectRequest(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      googleConnectRequest,
      action.payload.data
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setisLogin(true));
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setisLogin(false));
    CatchBlockFunction(error);
  }
}
export function* loginSocialRequest(action: {
  payload: { googleid: string; email: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.googleid.length === 0) {
    toast.error("Google login not valid");
    return;
  }
  let data = {
    social_type: 1,
    socialid: action.payload.googleid,
    email: action.payload.email,
  };

  try {
    yield call(action?.payload?.callback());
    yield put(actions.loginSuccess());
    yield put(actions.setisLogin(true));
  } catch (error: any) {
    yield put(actions.setisLogin(false));
    CatchBlockFunction(error);
  }
}
export function* PartnerSignUpRequest(action: {
  payload: { role: any; callback: any };
}) {
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  yield delay(500);
  const form: PartnerSignUpInterface = yield select(selectPartnerForm);
  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  if (form.businessName.length === 0) {
    CustomToast("Please Enter Business Name", "ERROR");
    return;
  }
  if (form.webUrl.length === 0) {
    CustomToast("Please Enter Website Url", "ERROR");
    return;
  }

  if (form.password.length === 0) {
    CustomToast("Please Enter Password", "ERROR");
    return;
  }
  if (form.password.length > 0) {
    if (!regex.test(form.password)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }

  if (action.payload.role !== 1) {
    if (form.tmc === false) {
      CustomToast("Please Select Terms of Service", "ERROR");
      return;
    }
  }
  const data = {
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    password: btoa(form.password),
    businessName: form.businessName,
    webUrl: form.webUrl,
  };
  try {
    const response: AxiosResponse = yield call(SchoolSignUpRequest, data);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.signupSuccess());
    yield put(actionadmin.doGetSchoolPartnerList());
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearPartnerFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* PartnerAdminAddRequest(action: {
  payload: { role: any; callback: any };
}) {
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

  yield delay(500);
  const form: PartnerSignUpInterface = yield select(selectPartnerForm);
  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  if (form.businessName.length === 0) {
    CustomToast("Please Enter Business Name", "ERROR");
    return;
  }
  if (form.webUrl.length === 0) {
    CustomToast("Please Enter Website Url", "ERROR");
    return;
  }

  if (!urlRegex.test(form.webUrl)) {
    CustomToast("Enter Valid WebUrl", "ERROR");
    return;
  }

  const data = {
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    businessName: form.businessName,
    webUrl: form.webUrl,
  };
  try {
    const response: AxiosResponse = yield call(AddSchoolAdminRequest, data);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.signupSuccess());
    yield put(actionadmin.doGetSchoolPartnerList());
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.clearPartnerFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UserSignUpRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  const form: UserSignUpInterface = yield select(selectUserForm);

  if (form.firstName.length === 0) {
    CustomToast("Please Enter First Name", "ERROR");
    return;
  }
  if (form.lastName.length === 0) {
    CustomToast("Please Enter Last Name", "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (form.password.length === 0) {
    CustomToast("Please Enter Password", "ERROR");
    return;
  }
  if (form.password.length > 0) {
    if (!regex.test(form.password)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }

  const data = {
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    acceptNewsLetter: form.acceptNewsLetter,
    password: btoa(form.password),
  };

  try {
    const response: AxiosResponse = yield call(UserNewSignUpRequest, data);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUpdateTocken(response.data.tocken));
    yield put(actions.signupSuccess());
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* meProfileRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.token) {
    try {
      yield put(actions.setloadingProfile(true));
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }

      yield put(
        actions.setProfileData({
          name: response.data.firstName,
          lastname: response.data.lastName,
          profilePic: response.data.profilePic,
          email: response.data.email,
          role: response.data.role,
          businessName: response.data.businessName,
          id: response.data.id,
          isAffiliate: response.data.isAffiliate,
          isProfileFill: response.data.isProfileFill,
          googleId: response?.data?.googleId,
          affiliateCode: response?.data?.affiliateCode,
          currencyCode: response?.data?.currency?.code,
        })
      );
      if (response.data.role != 1) {
        yield put(actions.setSchoolId(response.data.id));
      }
      yield put(actions.setisLogin(true));
      yield call(
        action?.payload?.callback({
          role: response.data.role,
          id: response.data.id,
        })
      );
    } catch (error: any) {
      yield put(actions.setloadingProfile(false));
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}
export function* meLogOutRequest() {
  // yield delay(500);

  localStorage.removeItem("token");
  localStorage.clear();
  // cookie.removeItem("token");
  yield put(actions.setisLogin(false));
  yield put(actions.clearProfileData());
}
export function* loginAdminRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const { email, password } = yield select(selectLoginForm);
  if (email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }

  if (password.length === 0) {
    CustomToast("Please Enter Password", "ERROR");
    return;
  }
  let data = {
    email: email,
    password: btoa(password),
  };
  try {
    const response: AxiosResponse = yield call(AdminLoginRequest, data);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setProfileData({
        name: response?.data?.data?.firstName,
        lastname: response?.data?.data?.lastName,
        profilePic: response?.data?.data?.profilePic,
        email: response?.data?.data?.email,
        role: response?.data?.data?.role,
        businessName: response?.data?.data?.businessName,
        id: response?.data?.data?.id,
        googleId: response?.data?.googleId,
        isAffiliate: response.data.data?.isAffiliate,
        isProfileFill: response.data.isProfileFill,
        affiliateCode: response?.data?.data?.affiliateCode,
        currencyCode: "",
      })
    );
    yield put(actions.setUpdateTocken(response.data.data.token));
    yield put(actions.setisLogin(true));
    CustomToast(response?.data?.message, "SUCCESS");
    yield call(action?.payload?.callback(response.data.data.token));
  } catch (error: any) {
    yield put(actions.setisLogin(false));
    CatchBlockFunction(error);
  }
}
export function* SchoolProfileRequest(action: { payload: any }) {
  yield delay(500);
  if (localStorage.getItem("token")) {
    try {
      yield put(actions.setloadingProfile(true));
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && response.data.status == false) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(actions.setloadingProfile(false));
      yield put(actionsForSchool.getSchoolProfileForSchool(action.payload));
      yield put(actions.setSchoolId(action.payload));
      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
      yield put(actions.setisLogin(false));
      yield put(actions.clearProfileData());
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}
export function* SchoolIdProfileRequest(action: { payload: any }) {
  yield delay(500);

  if (localStorage.getItem("token")) {
    try {
      const response: AxiosResponse = yield call(GetPartnerProfileRequest);

      if (response && response.data.status == false) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(
        actions.setProfileData({
          name: response.data.firstName,
          lastname: response.data.lastName,
          profilePic: response.data.profilePic,
          email: response.data.email,
          role: response.data.role,
          businessName: response.data.businessName,
          id: "",
          googleId: response?.data?.googleId,
          isAffiliate: response.data.isAffiliate,
          isProfileFill: response.data.isProfileFill,
          affiliateCode: response?.data?.affiliateCode,
          currencyCode: response?.data?.currency.code,
        })
      );
      yield put(actionsForSchool.setSchoolForEdit(response.data));
      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}

export function* ForgePasswordDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ForgetPasswordInterface = yield select(selectForgetPaswordFrom);
  if (form.email.length == 0) {
    CustomToast("Please Enter Your Valid Email", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      ForgetPasswordRequest,
      `?email=${form.email}`
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setresetEmailAddress(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ResetPasswordDataRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
  const form: ResetPasswordInterface = yield select(selectResetPasswordForm);
  if (form.password.length == 0) {
    CustomToast("Please Enter password", "ERROR");
    return;
  }
  if (form.password.length > 0) {
    if (!regex.test(form.password)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  if (form.confirmPassword.length == 0) {
    CustomToast("Please Enter Confirm Password", "ERROR");
    return;
  }
  if (form.confirmPassword.length > 0) {
    if (!regex.test(form.confirmPassword)) {
      CustomToast("Enter Valid Password", "ERROR");
      return;
    }
  }
  const data = {
    token: action.payload.token,
    password: btoa(form.password),
    confirmPassword: btoa(form.confirmPassword),
  };
  try {
    const response: AxiosResponse = yield call(ResetPasswordRequest, data);

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* GetLoginCountRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetLoginCountData);
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setloginCount(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.dogetLoginCount, GetLoginCountRequest);
  yield takeLatest(actions.googleLogin, GoogleLoginRequest);
  yield takeLatest(actions.ConnectWithgoogle, GoogleConnectRequest);
  yield takeLatest(actions.doSocialLogin, loginSocialRequest);
  yield takeLatest(actions.doPartnerSignUp, PartnerSignUpRequest);
  yield takeLatest(actions.doPartnerAddAdmin, PartnerAdminAddRequest);
  yield takeLatest(actions.doUserSignUp, UserSignUpRequest);
  yield takeLatest(actions.doAdminLogin, loginAdminRequest);
  yield takeLatest(actions.getUserProfile, meProfileRequest);
  yield takeLatest(actions.getSchoolProfile, SchoolProfileRequest);
  yield takeLatest(actions.getSchoolProfileById, SchoolIdProfileRequest);
  yield takeLatest(actions.doLogOut, meLogOutRequest);
  yield takeLatest(actions.sendForgetPassword, ForgePasswordDataRequest);
  yield takeLatest(actions.updateResetPassword, ResetPasswordDataRequest);
}
