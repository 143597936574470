// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types";
import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.SchoolListingGrabState) {
      return state.SchoolListingGrabState;
    }
    return initialState;
  } else {
    return initialState;
  }
};
export const SelectGrabModalType = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttModalType
);
export const selectGrabForms = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionForm
);
export const SelectGrabDraft = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionForm.isDraft
);
export const selectBeniftsForms = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionForm?.retreatBenefitsFrom
);
export const SelectBeniftsFixedAdminnList = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionBenifitsFixedAdmin
);
export const SelectBeniftsstempSchoolList = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionBenifitsTempSchool
);
export const SelectGiftsFixedAdminnList = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionGiftsFixedAdmin
);
export const SelectGiftsstempSchoolList = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionGiftsTempSchool
);
export const selectGiftsForms = createSelector(
  [selectDomain],
  (state) => state.GrabTheAttentionForm?.specialGiftFrom
);
export const SelectGrabloading = createSelector(
  [selectDomain],
  (state) => state.loading
);