import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { LandingState, PopularRetreatActivityEnum } from "../types";

export const initialState: LandingState = {
  landingForm: {
    search: "  ",
    activity: PopularRetreatActivityEnum.YOGA_RETREAT,
  },
  landingFormData: [],
  landingCityData: [],
  landingCountyData: [],
  landingDiscriptionData: [],
  landingPopularRetreatsData: [],
  landingPopularDestinationsData: [],
};

export const useClinicSlice = createSlice({
  name: "landingState",
  initialState,
  reducers: {
    getLandingData: (
      state,
      action: PayloadAction<{
        name: string;
        activity: any;
        callback: () => void;
      }>
    ) => {},
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `ItineraryFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    setCityList: (state, action: PayloadAction<any>) => {
      state.landingCityData = action.payload;
    },
    setCountryList: (state, action: PayloadAction<any>) => {
      state.landingCountyData = action.payload;
    },
    setlandingDataList: (state, action: PayloadAction<any>) => {
      state.landingDiscriptionData = action.payload;
    },
    setlandingPopularRetreatsData: (state, action: PayloadAction<any>) => {
      state.landingPopularRetreatsData = action.payload;
    },
    setlandingPopularDestinationsData: (state, action: PayloadAction<any>) => {
      state.landingPopularDestinationsData = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
