import { AxiosResponse } from "axios";
import {
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  schoolHostReviewdata,
  schoolprofileInstructordata,
  schoolprofileOnlineRetreatsdata,
  schoolprofileTeacherTrainingdata,
  schoolprofileYogaRetreatsdata,
  viewschoolprofiledata,
} from "../../../../utils/request";
import { actions } from "./slice";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { SelectPageNo } from "./selector";

// export function* doGetSchoolprofileHostRequest(action: { payload: string }) {
//   yield delay(500);
//   try {
//     yield put(actions.setLoading(true));
//     const response: AxiosResponse = yield call(
//       viewschoolprofiledata,
//       action.payload
//     );
//     yield put(actions.setLoading(false));
//     if (response && !response.data) {
//       CustomToast(response.data.message, "ERROR");

//       return;
//     }
//     yield put(actions.setViewProfileHostData(response.data.school[0]));
//   } catch (error: any) {
//     yield put(actions.setLoading(false));
//     CatchBlockFunction(error);
//   }
// }
export function* doGetSchoolprofileYogaRetreatsRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      schoolprofileYogaRetreatsdata,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setViewProfileHostRetreatsData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSchoolprofileOnlineRetreatsRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      schoolprofileOnlineRetreatsdata,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setViewProfileHostOnlineRetreatsData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSchoolprofileTeacherTraingRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      schoolprofileTeacherTrainingdata,
      action.payload
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(
      actions.setViewProfileHostTeacherTrainingData(response.data.data)
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSchoolprofileInstructorsRequest(action: {
  payload: string;
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      schoolprofileInstructordata,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setViewProfileHostInstructorData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));

    CatchBlockFunction(error);
  }
}
export function* doGetSchoolReviewRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      schoolHostReviewdata,
      // action.payload
      `?schoolId=${action.payload}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setTotalReview(response.data.data[0].meta.total));
    yield put(actions.schoolReviewListData(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));

    CatchBlockFunction(error);
  }
}
export function* SchoolProfileRepoSaga() {
  // yield takeLatest(
  //   actions.doGetdoGetSchoolprofileHost,
  //   doGetSchoolprofileHostRequest
  // );
  yield takeLatest(
    actions.doGetdoGetSchoolprofileHostYogaRetreats,
    doGetSchoolprofileYogaRetreatsRequest
  );
  yield takeLatest(
    actions.doGetdoGetSchoolprofileHostOnlineRetreats,
    doGetSchoolprofileOnlineRetreatsRequest
  );
  yield takeLatest(
    actions.doGetdoGetSchoolprofileHostTeacherTraining,
    doGetSchoolprofileTeacherTraingRequest
  );
  yield takeLatest(
    actions.doGetSchoolprofileHostInstrucors,
    doGetSchoolprofileInstructorsRequest
  );
  yield takeLatest(actions.doGetSchoolHostReview, doGetSchoolReviewRequest);
}
