import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  LandingPageState,
  LandingInterface,
  DescriptionInterface,
  ActivityEnum,
  FaqInterface,
} from "../types";

export const initialState: LandingPageState = {
  list: [],
  form: {
    id: "",
    name: "",
    countryId: "",
    cityId: "",
    activity: ActivityEnum.YOGA_RETREAT,
    photo: "",
    bannerPhoto: "",
    videoThumbnail: "",
    video: "",
    description: [],
    faq: [],
    city: "",
    country: "",
    heading: "",
    subHeading: "",
    cardHeading: "",
    photoHeading: "",
    photoSubHeading: "",
    popularTextHeading: "",
  },
  retreatDescriptionForm: {
    title: "",
    description: "",
    id: "",
  },
  faqForm: {
    question: "",
    answer: "",
    id: "",
  },
  editIndex: 0,
  editFaqIndex: -1,
  editDescriptionIndex: -1,
  loading: false,
  isAdd: false,
  pageNo: 1,
  pageSize: 15,
  totalRow: 0,
  search: "",
  disableCheck: false,
  cropShow: false,
  countryList: [],
  cityList: [],
  sortColumn: "",
  sortDir: "",
  openFaq: false,
  openDescription: false,
};

export const useClinicSlice = createSlice({
  name: "landingPageState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleOpenFaq: (state) => {
      state.openFaq = !state.openFaq;
    },
    toggleOpenDescription: (state) => {
      state.openDescription = !state.openDescription;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: any; value: any }>
    ) => {
      //@ts-ignore
      state.form[action.payload.key] = action.payload.value;
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<LandingInterface>>) => {
      state.list = action.payload;
    },
    setData: (state, action: PayloadAction<LandingInterface>) => {
      state.form = action.payload;
    },
    clearFrom: (state) => {
      state.form.id = "";
      state.form.name = "";
      state.form.countryId = "";
      state.form.cityId = "";
      state.form.activity = ActivityEnum.YOGA_RETREAT;
      state.form.photo = "";
      state.form.bannerPhoto = "";
      state.form.videoThumbnail = "";
      state.form.video = "";
      state.form.description = [];
      state.form.faq = [];
      // state.form.faq1 = [];
      state.form.heading = "";
      state.form.subHeading = "";
      state.form.cardHeading = "";
      state.form.photoHeading = "";
      state.form.photoSubHeading = "";
      state.form.popularTextHeading = "";
    },

    updateDescriptionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `retreatDescriptionForm.${action.payload.key}`,
        action.payload.value
      );
    },

    doGetDescriptionList: (state) => {},
    setDescriptionList: (
      state,
      action: PayloadAction<Array<DescriptionInterface>>
    ) => {
      state.form.description = action.payload;
    },
    doDeleteDescription: (state, action: PayloadAction<any>) => {
      state.form.description.splice(action.payload, 1);
    },
    clearDescriptionFrom: (state) => {
      state.retreatDescriptionForm.id = "";
      state.retreatDescriptionForm.title = "";
      state.retreatDescriptionForm.description = "";
    },

    updateFaqFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `faqForm.${action.payload.key}`, action.payload.value);
    },
    setDataInFaqForm: (
      state,
      action: PayloadAction<{ data: FaqInterface; index: number }>
    ) => {
      state.editFaqIndex = action.payload.index;
      state.faqForm = action.payload.data;
    },
    doAddFaq: (state) => {
      if (state.editFaqIndex > -1) {
        state.form.faq[state.editFaqIndex] = state.faqForm;
        state.editFaqIndex = -1;
      } else {
        state.form.faq.push(state.faqForm);
      }
      state.editFaqIndex = -1;
    },
    setDataInDescriptionForm: (
      state,
      action: PayloadAction<{ data: DescriptionInterface; index: number }>
    ) => {
      state.editDescriptionIndex = action.payload.index;
      state.retreatDescriptionForm = action.payload.data;
    },
    doAddDescription: (state) => {
      if (state.editDescriptionIndex > -1) {
        state.form.description[state.editDescriptionIndex] =
          state.retreatDescriptionForm;
        state.editDescriptionIndex = -1;
      } else {
        state.form.description.push(state.retreatDescriptionForm);
      }
      state.editDescriptionIndex = -1;
    },
    doGetFaqList: (state) => {},
    setFaqList: (state, action: PayloadAction<Array<FaqInterface>>) => {
      state.form.faq = action.payload;
    },
    doDeleteFaq: (state, action: PayloadAction<any>) => {
      state.form.faq.splice(action.payload, 1);
    },
    clearFaqFrom: (state) => {
      state.faqForm.question = "";
      state.faqForm.answer = "";
      state.faqForm.id = "";
    },
    toggelCropSow: (state) => {
      state.cropShow = !state.cropShow;
    },

    doAddLogoImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    doAddBannerImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: any;
        name: string;
      }>
    ) => {},
    doGetCountryList: (state) => {},
    doGetList: (state) => {},
    setCountryList: (state, action: PayloadAction<Array<LandingInterface>>) => {
      state.countryList = action.payload;
    },
    doGetCityList: (state, action: PayloadAction<string>) => {},
    doGetById: (
      state,
      action: PayloadAction<{
        countryId: string;
        cityId: string;
        category: ActivityEnum;
        callback: () => void;
      }>
    ) => {},
    setCityList: (state, action: PayloadAction<Array<LandingInterface>>) => {
      state.cityList = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setFaqDragList: (state, action: PayloadAction<Array<FaqInterface>>) => {
      state.form.faq = action.payload;
    },
    setDescriptionDragList: (
      state,
      action: PayloadAction<Array<DescriptionInterface>>
    ) => {
      state.form.description = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
