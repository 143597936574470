import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.adminDashboard) {
      return state.adminDashboard;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectAdminDashboardTopDataList = createSelector(
  [selectDomain],
  (state) => state.adminDashboardTopDataList
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const selectBookingData = createSelector(
  [selectDomain],
  (state) => state.bookingGraph
);
export const selectBookingCategoryList = createSelector(
  [selectDomain],
  (state) => state.bookingCategoryChart
);
export const selectBookingByCountryList = createSelector(
  [selectDomain],
  (state) => state.bookingByCountryChart
);

export const selectbookingBreakdown = createSelector(
  [selectDomain],
  (state) => state.bookingBreakdown
);
export const selectbookingPerformance = createSelector(
  [selectDomain],
  (state) => state.bookingPerformance
);
export const selectcountries = createSelector(
  [selectDomain],
  (state) => state.countries
);
export const selectdataType = createSelector(
  [selectDomain],
  (state) => state.dataType
);
export const selectnumberOfBookingsByCountry = createSelector(
  [selectDomain],
  (state) => state.numberOfBookingsByCountry
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectDateFilter1 = createSelector(
  [selectDomain],
  (state) => state.date1
);
