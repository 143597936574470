import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.accommodationState) {
      return state.accommodationState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectSchoolAccommodationForm = createSelector(
  [selectDomain],
  (state) => state.accommodationFormData
);
export const selectSchoolAccommodationList = createSelector(
  [selectDomain],
  (state) => state.accommodationList
);
export const selectPhotosAccommodationForm = createSelector(
  [selectDomain],
  (state) => state.accommodationFormData.accommodationPhotos
);
export const selectAccommodationCategoryList = createSelector(
  [selectDomain],
  (state) => state.AccommodationCategoryList
);
export const selectAccommodationRoomTypeList = createSelector(
  [selectDomain],
  (state) => state.AccommodationRoomTypeList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectTotalAccommodation = createSelector(
  [selectDomain],
  (state) => state.totalAccommodation
);
export const SelectImageArray = createSelector(
  [selectDomain],
  (state) => state.imageArray
);
export const SelectUploadFiles = createSelector(
  [selectDomain],
  (state) => state.uploadFiles
);
export const SelectUploadingCurrentIndex = createSelector(
  [selectDomain],
  (state) => state.uploadingCurrentIndex
);
export const SelectCropShow = createSelector(
  [selectDomain],
  (state) => state.Crop
);
export const selectAccomodationLoader = createSelector(
  [selectDomain],
  (state) => state.accomodationLoader
);