import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../types";
import { initialState } from "./slice";
const selectDomain = (state?: RootState) =>
  state?.schoolListingCalenderState || initialState;
export const SelectCalendarForm = createSelector(
  [selectDomain],
  (state) => state.calenderForm
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.laoding
);
export const SelectSubmit = createSelector(
  [selectDomain],
  (state) => state.submit
);
export const SelectShowMonth = createSelector(
  [selectDomain],
  (state) => state.showMonth
);
export const SelectDateRange = createSelector(
  [selectDomain],
  (state) => state.dateRanges
);
export const SelectCalendarloading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectCheckAvailable = createSelector(
  [selectDomain],
  (state) => state.checkAvailable
);
export const SelectSavedRange = createSelector(
  [selectDomain],
  (state) => state.savedRange
);
export const SelectSelectedRange = createSelector(
  [selectDomain],
  (state) => state.selectedDateRange
);
export const SelectCalanderDraft = createSelector(
  [selectDomain],
  (state) => state.isDraft
);
export const SelectIsSelecting = createSelector(
  [selectDomain],
  (state) => state.isSelecting
);
export const SelectDateSelection = createSelector(
  [selectDomain],
  (state) => state.selectionDate
);
export const SelectSelectionStart = createSelector(
  [selectDomain],
  (state) => state.selectionStart
);
