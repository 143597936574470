import { createSlice } from "../../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";

import { set } from "lodash";
import { UploaderState } from "../types";

export const initialState: UploaderState = {
  isUpload: false,
  progress: 0,
  progressBar: 0,
  imageUploadeProgress: 0,
  count: 0,
  isDraft: false,
  draftModal: false,
  tabData: "",
  draftTab: "",
  totalField: 0,
  CountListForRequiredField: {
    ArrivalCount: 0,
    BookingCount: 0,
    GrabCount: 0,
    IncluExclusCount: 0,
    Instcurtor: 0,
    ItinararyCount: 0,
    locationCount: 0,
    OverviewCount: 0,
    PriceCount: 0,
    RankingCount: 0,
    photos: 0,
  },
  TotalRequiredFiled: {
    ArrivalCount: 0,
    BookingCount: 0,
    GrabCount: 0,
    IncluExclusCount: 0,
    Instcurtor: 0,
    ItinararyCount: 0,
    locationCount: 0,
    OverviewCount: 0,
    PriceCount: 0,
    RankingCount: 0,
    photos: 0,
  },
};

export const useClinicSlice = createSlice({
  name: "UploaderState",
  initialState,
  reducers: {
    setPrrogressData: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setClearProgress: (state) => {
      state.progress = 0;
    },
    setClearimageUploadeProgress: (state) => {
      state.imageUploadeProgress = 0;
    },
    updateProgress: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.progress = action.payload.value;
    },
    updateImageProgress: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.imageUploadeProgress = action.payload.value;
    },
    toggeldraftModalShow: (state) => {
      state.draftModal = !state.draftModal;
    },
    setTabData: (state, action: PayloadAction<any>) => {
      state.tabData = action.payload;
    },
    setDraftTabData: (state, action: PayloadAction<string>) => {
      state.draftTab = action.payload;
    },
    updateIsDraft: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.isDraft = action.payload.value;
    },
    setDraftTab: (state) => {
      state.isDraft = !state.isDraft;
    },
    updateCount: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CountListForRequiredField.${action.payload.key}`,
        action.payload.value
      );
    },
    updateTotalCount: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `TotalRequiredFiled.${action.payload.key}`,
        action.payload.value
      );
    },
  },
});

export const { reducer, actions, name: SliceKey } = useClinicSlice;
