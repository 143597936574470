import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsVoucher } from "../../Payments/redux/slice";
import queryString from "query-string";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetCancelledBookingsAdminData,
  GetCurrentBookingsAdminData,
  GetPastBookingsAdminData,
  doCancelBookingRequest,
  GetVoucherDataByIdRequest,
  GetCancelVoucherDataByIdRequest,
  AddVoucherCode,
  AddCancelBookingVoucherCode,
} from "../../../../utils/request";
import {
  SelectCancelBookingReason,
  SelectCancelledBookingsPagination,
  SelectCreatedOnFilter,
  SelectCurrentBookingsPagination,
  SelectDateFilter,
  SelectListingFIlter,
  SelectPastBookingsPagination,
  SelectSchoolFilter,
  SelectSearch,
  SelectSortColumn,
  SelectSortColumnCancel,
  SelectSortColumnPast,
  SelectSortDir,
  SelectSortDirCancel,
  SelectSortDirPast,
  SelectVoucherCodesFormData,
} from "./selector";
import { BookingPaginationInterface, VoucherCodesInterface } from "../types";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
export function* getCurrentBookingsListRequest() {
  yield delay(500);
  const listing: [] = yield select(SelectListingFIlter);
  const school: [] = yield select(SelectSchoolFilter);
  const searchText: string = yield select(SelectSearch);
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  const SortColumn: string = yield select(SelectSortColumn);
  const SortDir: string = yield select(SelectSortDir);
  const PageNoPageSize: BookingPaginationInterface = yield select(
    SelectCurrentBookingsPagination
  );
  const deta = {
    schoolFilter: school || "",
    retreatFilter: listing || "",
    bookedOnFilter: createdOnFilter || "",
    startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
    endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
    search: searchText || "",
  };
  try {
    const response: AxiosResponse = yield call(
      GetCurrentBookingsAdminData,
      `?pageNo=${PageNoPageSize.pageNo}&pageSize=${PageNoPageSize.pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      deta
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalCurrentBookingsList(response.data[0].meta.total));
    yield put(actions.setCurrentBookingsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getPastBookingsListRequest() {
  yield delay(500);
  const listing: [] = yield select(SelectListingFIlter);
  const school: [] = yield select(SelectSchoolFilter);
  const searchText: string = yield select(SelectSearch);
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  const SortColumn: string = yield select(SelectSortColumnPast);
  const SortDir: string = yield select(SelectSortDirPast);
  const PageNoPageSize: BookingPaginationInterface = yield select(
    SelectPastBookingsPagination
  );
  const deta = {
    schoolFilter: school || "",
    retreatFilter: listing || "",
    bookedOnFilter: createdOnFilter || "",
    startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
    endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
    search: searchText || "",
    pageSize: 10,
    pageNo: 1,
  };
  try {
    const response: AxiosResponse = yield call(
      GetPastBookingsAdminData,
      `?pageNo=${PageNoPageSize.pageNo}&pageSize=${PageNoPageSize.pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      deta
    );

    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setTotalPastBookingsList(response.data[0].meta.total));
    yield put(actions.setPastBookingsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCancelledBookingsListRequest() {
  yield delay(500);
  const listing: [] = yield select(SelectListingFIlter);
  const school: [] = yield select(SelectSchoolFilter);
  const searchText: string = yield select(SelectSearch);
  const createdOnFilter: string = yield select(SelectCreatedOnFilter);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  const SortColumn: string = yield select(SelectSortColumnCancel);
  const SortDir: string = yield select(SelectSortDirCancel);
  const PageNoPageSize: BookingPaginationInterface = yield select(
    SelectCancelledBookingsPagination
  );

  try {
    const data = {
      schoolFilter: school || "",
      retreatFilter: listing || "",
      bookedOnFilter: createdOnFilter || "",
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
      search: searchText || "",
    };
    const response: AxiosResponse = yield call(
      GetCancelledBookingsAdminData,
      `?pageNo=${PageNoPageSize.pageNo}&pageSize=${PageNoPageSize.pageSize}${
        SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`,
      data
    );
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(
      actions.setTotalCancelledBookingsList(response.data[0].meta.total)
    );
    yield put(actions.setCancelledBookingsList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* handleCancelBookingRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const reason: string = yield select(SelectCancelBookingReason);
  const data = {
    reason: reason,
    id: action.payload.id,
  };
  try {
    const response: AxiosResponse = yield call(doCancelBookingRequest, data);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCancelBookingByIdDataRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCancelVoucherDataByIdRequest,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setVoucherCodesFormData(response.data));

    yield call(action.payload.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* addCancelBookingVoucherCodeDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: VoucherCodesInterface = yield select(SelectVoucherCodesFormData);
  if (form.voucherName == "") {
    CustomToast("Please Enter Voucher Name", "ERROR");
    return;
  }

  if (form.amount.length === 0) {
    CustomToast("Please Enter Amount", "ERROR");
    return;
  }

  if (form.email.length === 0) {
    CustomToast("Please Enter Email", "ERROR");
    return;
  }
  if (form.note == "") {
    CustomToast("Please Enter Note", "ERROR");
    return;
  }
  if (form.status == null) {
    CustomToast("Please Select Status", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      AddCancelBookingVoucherCode,
      form
    );
    if (response && !response.data.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminRepoSagaBookings() {
  yield takeLatest(
    actions.doGetCurrentBookingsList,
    getCurrentBookingsListRequest
  );
  yield takeLatest(actions.doGetPastBookingsList, getPastBookingsListRequest);
  yield takeLatest(
    actions.doGetCancelledBookingsList,
    getCancelledBookingsListRequest
  );
  yield takeLatest(actions.handleCancelBooking, handleCancelBookingRequest);
  yield takeLatest(
    actions.doGetCancelBookingDataById,
    getCancelBookingByIdDataRequest
  );
  yield takeLatest(
    actions.AddCancelBookingVoucherCode,
    addCancelBookingVoucherCodeDataRequest
  );
}
