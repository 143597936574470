import { string } from "yup/lib/locale";

export enum MassageFilter {
  ALL_MESSAGES = 1,
  READY_TO_BOOK = 2,
  UNREAD = 3,
  BOOKED = 4,
}
export enum MessageSort {
  NO_GROUPING = 1,
  GROUP_BY_ARRIVAL_MONTH = 2,
  GROUP_BY_ORGANIZER = 3,
  GROUP_BY_COUNTRY = 4,
}
export interface inquiryCardDetailInterface {
  arrivalDate: string;
  departureDate: string;
  id: string;
  location: string;
  price: number;
  retreatId: string;
  seoActivityTitle: string;
  seoTitle: string;
  retreatTitle: string;
  spokenLanguage: [];
  status: number;
  currencyCode: string;
}
export interface AllMessageCountListInterface {
  id: string;
  allMessageCount: number;
  readyToBookCount: number;
  readAtCount: number;
  bookedCount: number;
}
export enum InquiryStageEnum {
  INQUIRY_RECEIVED = 1,
  ENABLE_PAYMENT = 2,
  BOOKING_CONFIRMATION = 3,
  ARRIVAL = 4,
  DEPARTURE = 5,
  CANCEL = 6,
}
export interface UserInquiryMessageState {
  InquiryMassageCard: [];
  userFirstName: string;
  userLastName: string;
  userProfilePic: string;
  businessName: string;
  businessProfilepic: string;
  totalInquiryCard: number;
  inquiryCardDetail: inquiryCardDetailInterface;
  MessageList: [];
  MeassageFilter: MassageFilter;
  MessageSort: MessageSort;
  editIndex: number;
  loading: boolean;
  loader: boolean;
  pageNo: number;
  pageSize: number;
  messagePageNo: number;
  totaMessageCount: number;
  messagePageSize: number;
  totalRow: number;
  search: string;
  hasMore: boolean;
  allMessageCountList: AllMessageCountListInterface[];
}

export type InitialCustomerSupportState = UserInquiryMessageState;
