import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.userInquiryMessageState) {
      return state.userInquiryMessageState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectMessageloader = createSelector(
  [selectDomain],
  (state) => state.loader
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totaMessageCount
);
export const SelectTotalInquiryRow = createSelector(
  [selectDomain],
  (state) => state.totalInquiryCard
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectMessageSort = createSelector(
  [selectDomain],
  (state) => state.MessageSort
);
export const selectMessageFilter = createSelector(
  [selectDomain],
  (state) => state.MeassageFilter
);
export const selectInquiryMessageCard = createSelector(
  [selectDomain],
  (state) => state.InquiryMassageCard
);
export const selectMessageList = createSelector(
  [selectDomain],
  (state) => state.MessageList
);
export const selectinquiryCardDetail = createSelector(
  [selectDomain],
  (state) => state.inquiryCardDetail
);
export const selectmessagePageSize = createSelector(
  [selectDomain],
  (state) => state.messagePageSize
);
export const selectmessagePageNo = createSelector(
  [selectDomain],
  (state) => state.messagePageNo
);
export const selecttotaMessageCount = createSelector(
  [selectDomain],
  (state) => state.totaMessageCount
);
export const selectbusinessName = createSelector(
  [selectDomain],
  (state) => state.businessName
);
export const selectbusinessProfilepic = createSelector(
  [selectDomain],
  (state) => state.businessProfilepic
);
export const selectuserFirstName = createSelector(
  [selectDomain],
  (state) => state.userFirstName
);
export const selectuserLastName = createSelector(
  [selectDomain],
  (state) => state.userLastName
);
export const selectuserProfilePic = createSelector(
  [selectDomain],
  (state) => state.userProfilePic
);
export const SelectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const SelectAllMessageCount = createSelector(
  [selectDomain],
  (state) => state.allMessageCountList
);
