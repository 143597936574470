import { number } from "yup";

export enum CertificateExpiryEnum {
  NO = 0,
  YES = 1,
}
export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  CUSTOM = 3,
  PREFER_NOT_TO_SAY = 4,
}

export interface CertificteInterface {
  id: string;
  association: string;
  uploadCertificate: string;
  level: string;
  yearofissue: string;
  isExpire: CertificateExpiryEnum;
  date: string;
}
export interface SkiilsInterface {
  id: string;
  skills: string;
  practicingSince: number;
}
export interface SchoolInstructorsInterface {
  id: string;
  profilePic: string;
  fullName: string;
  jobrole: string[];
  gender: GenderEnum;
  email: string;
  phoneno: string;
  skypeId: string;
  biography: string;
  token: string;
}
export interface AssociationInterface {
  id: string;
  name: string;
}
export interface CertificateLavelInterface {
  id: string;
  name: string;
}
export interface SkillsModalInterface {
  id: string;
  skill: string;
  practicingSince: number;
  ref_id: string;
  skillsName: string;
}
export interface CertificateModalInterface {
  ref_id: string;
  id: string;
  associationId: string;
  association: string;
  certificateName: string;
  certificateFile: string;
  certificateLevel: string;
  certificateIssueYear: number;
  certificateExpiry: CertificateExpiryEnum;
  certificateExpiryDate: string;
}
export interface certificateMainInterface {
  id: string;
  CertificatesModalForm: CertificateModalInterface;
  CertificatesModalFormList: CertificateModalInterface[];
}
export interface SkillsMainInterface {
  id: string;
  SkillsModalForm: SkillsModalInterface;
  SkillsModalList: SkillsModalInterface[];
}

export interface InstructorSkillsList {
  id: string;
  skill: string;
  practicingSince: number;
  ref_id: string;
}
export interface SkillsListInterface {
  id: string;
  name: string;
}

export interface RolesInterface {
  id: string;
  name: string;
}
export interface InstructorListingsInterfaceForm {
  retreatId: any;
  instructorList: string[];
  idselected: string;
}
export interface InstructorListingsInterfacelist {
  retreatId: string;
  instructorList: [];
}
export interface CountListForRequiredFieldInterface {
  basicDetailCount: number;
  SkillsCount: number;
  CertificateCount: number;
}
export interface SchoolInstructorsState {
  SchoolList: SchoolInstructorsInterface[];
  SchoolFormData: SchoolInstructorsInterface;
  certificateFormData: CertificteInterface;
  certificateList: CertificteInterface[];
  OTP: string;
  showOTPField: boolean;
  certificateEditIndex: number;
  Association: AssociationInterface[];
  CertificateLevel: CertificateLavelInterface[];
  ALLSkillsList: SkillsListInterface[];
  CertificateModal: boolean;
  skillEditIndex: number;
  skills: SkillsMainInterface;
  certificate: certificateMainInterface;
  instructorId: string;
  InstructorSkills: InstructorSkillsList[];
  InstructorListForSchool: SchoolInstructorsInterface[];
  InstructorListForListing: InstructorListingsInterfaceForm;
  InstructorListForListingList: InstructorListingsInterfacelist[];
  InstructorListingSelectedList: any[];
  SelctedInstructroListId: any[];
  allRolesList: RolesInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  totalInstructor: number;
  count: number;
  progress: number;
  otpStatus: boolean;
  visitTab: any;
  isDraft: boolean;
  TotalRequiredFiled: CountListForRequiredFieldInterface;
  InstructorReqListForListingList: any[];
  ListingLoader:boolean
  loader:boolean
}

export type InitialAdminUserState = SchoolInstructorsState;
