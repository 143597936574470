export interface retreatPhotos {
  photo: string;
  fileName: string;
}
export enum FileUplodType {
  RETREAT = 1,
  ACCMODATION = 2,
}
export interface foodPhotos {
  photo: string;
  fileName: string;
}
export interface MediaFormInterface {
  retreatPhotos: retreatPhotos[];
  foodPhotos: foodPhotos[];
  retreatId: string;
  id: string;
  isDraft: boolean;
  count: number;
}
export interface retreatVideos {
  video: string;
  fileName: string;
}
export interface foodVideos {
  video: string;
  fileName: string;
}
export interface MediaVideoFormInterface {
  retreatVideos: retreatVideos[];
  foodVideos: foodVideos[];
  retreatId: string;
  id: string;
  isDraft: boolean;
  count: number;
}
export interface FileUploadItems {
  file: File;
  isCroping: boolean;
  isDome: boolean;
}
export interface SchoolListingMediaState {
  MediaImageForm: MediaFormInterface;
  MediaVideoForm: MediaVideoFormInterface;
  loading: boolean;
  ImagesModalType: number;
  VideoModalType: number;
  foodCrop: boolean;
  retreatsCrop: boolean;
  imageArray: string[];
  RetreatimageArray: string[];
  uploadFileType: FileUplodType;
  uploadFiles: FileUploadItems[];
  uploadingCurrentIndex: number;
  Videoloader: boolean;
}
export type InitialMileStoneStata = SchoolListingMediaState;
