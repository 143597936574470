import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.landingPageState) {
      return state.landingPageState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.disableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectCropShow = createSelector(
  [selectDomain],
  (state) => state.cropShow
);
export const selectDescriptionList = createSelector(
  [selectDomain],
  (state) => state.form.description
);
export const selectDescriptionForm = createSelector(
  [selectDomain],
  (state) => state.retreatDescriptionForm
);
export const selectFaqList = createSelector(
  [selectDomain],
  (state) => state.form.faq
);
export const selectFaqForm = createSelector(
  [selectDomain],
  (state) => state.faqForm
);
export const selectCountryList = createSelector(
  [selectDomain],
  (state) => state.countryList
);
export const selectCityList = createSelector(
  [selectDomain],
  (state) => state.cityList
);
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectOpenFaq = createSelector(
  [selectDomain],
  (state) => state.openFaq
);
export const SelectOpenDescription = createSelector(
  [selectDomain],
  (state) => state.openDescription
);
export const SelectEditDescriptionIndex = createSelector(
  [selectDomain],
  (state) => state.editDescriptionIndex
);
export const SelectEditFaqIndex = createSelector(
  [selectDomain],
  (state) => state.editFaqIndex
);
