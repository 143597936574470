import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AllAirportInterface,
  AllHostInterface,
  ArrivalInformationInterface,
  SchoolListingArrivalInformationState,
  SpokenLanguagesModel,
} from "../types";
import { ListingTypeEnum } from "../../types";
import { log } from "../../../../../utils/logger";

export const initialState: SchoolListingArrivalInformationState = {
  ArrivalModalType: 0,
  InstructorModalType: 0,
  ArrivalInformationForm: {
    isDraft: false,
    id: "",
    retreatId: "",
    checkInTime: "",
    checkOutTime: "",
    host: "",
    spokenLanguagesForm: {
      listingType: ListingTypeEnum.BOTH,
      name: "",
      id: "",
      type: 2,
      schoolId: "",
      retreatId: "",
    },
    spokenLanguageList: [],
    nearestAirport: "",
    nearestAirportSearch: "",
    pickupInformation: "",
    count: 0,
  },
  ArrivalInformationList: [],
  pageNo: 1,
  ListingsId: "",
  pageSize: 0,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  offlineOrOnline: "",
  spokenLanguagesFixedList: [],
  spokenLanguagesTempList: [],
  loading: false,
  allAirportList: [],
  allHostList: [],
  InstructorListForm: {
    id: "",
    retreatId: "",
  },
  InstructorListData: [],
  searchOpen: false,
};

export const useClinicSlice = createSlice({
  name: "arrivalInformationState",
  initialState,
  reducers: {
    SetArrivalModalType: (state, action: PayloadAction<number>) => {
      state.ArrivalModalType = action.payload;
    },
    SetInstructorModalType: (state, action: PayloadAction<number>) => {
      state.InstructorModalType = action.payload;
    },
    updateListingsSpokenLanguagesFormStyleFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `ArrivalInformationForm.spokenLanguagesForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateSchoolArrivalInformationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `ArrivalInformationForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateSchoolInstructorListFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `InstructorListForm.${action.payload.key}`,
        action.payload.value
      );
    },

    clearSpokenform: (state) => {
      state.ArrivalInformationForm.spokenLanguagesForm.name = "";
      state.ArrivalInformationForm.spokenLanguagesForm.id = "";
      state.ArrivalInformationForm.spokenLanguagesForm.retreatId = "";
    },
    SelectSpokenlanguageStyleType: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.ArrivalInformationForm.spokenLanguageList.push(
        action.payload.value
      );
    },
    DeleteSpokenlanguageStyleType: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state.ArrivalInformationForm.spokenLanguageList.splice(
        state.ArrivalInformationForm.spokenLanguageList.indexOf(
          action.payload.value
        ),
        1
      );
    },
    AddNewSpokenLanguageStyle: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      // state.OverviewForm.retreatStyles.push(state.OverviewForm.retreatStylesForm);
    },

    AddArrivalInformation: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      log("Your language add");
      // state.SchoolList.push(state.SchoolFormData);
    },

    SetArrivalInformationFormDataById: (
      state,
      action: PayloadAction<ArrivalInformationInterface>
    ) => {
      state.ArrivalInformationForm = action.payload;
      state.ArrivalInformationForm.spokenLanguagesForm = {
        listingType: ListingTypeEnum.BOTH,
        id: "",
        name: "",
        retreatId: "",
        type: 2,
        schoolId: "",
      };
    },
    doGetArrivalInformationDataByID: (state) => {},
    doGetListingArrivalInformationDataSchoolDataByID: (
      state,
      action: PayloadAction<any>
    ) => {},
    setListingsID: (state, action: PayloadAction) => {
      state.ListingsId = action.payload;
    },

    getFixedSpokenLanguageTypeList: (state) => {},
    getTempSpokenLanguageList: (state, action: PayloadAction<any>) => {},

    setSpokenLanguagesFixedList: (
      state,
      action: PayloadAction<Array<SpokenLanguagesModel>>
    ) => {
      state.spokenLanguagesFixedList = action.payload;
    },
    setSpokenLanguagesTempList: (
      state,
      action: PayloadAction<Array<SpokenLanguagesModel>>
    ) => {
      state.spokenLanguagesTempList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAirportList: (
      state,
      action: PayloadAction<Array<AllAirportInterface>>
    ) => {
      state.allAirportList = action.payload;
    },

    doGetAirportList: (state, action: PayloadAction<string>) => {},
    doGetSchoolHostList: (state, action: PayloadAction<string>) => {
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },
    setHostList: (state, action: PayloadAction<Array<AllHostInterface>>) => {
      state.allHostList = action.payload;
    },
    ArrivalInfoPillsEditRequest: (
      state,
      action: PayloadAction<SpokenLanguagesModel>
    ) => {
      state.ArrivalInformationForm.spokenLanguagesForm = action.payload;
    },
    setarrivalDraft: (state, action: PayloadAction<boolean>) => {
      state.ArrivalInformationForm.isDraft = action.payload;
    },
    ArrivalCount: (state) => {
      state.ArrivalInformationForm.count =
        state.ArrivalInformationForm.count + 1;
    },
    DeletePills: (
      state,
      action: PayloadAction<{ id: string; callback: any; retreatid: string }>
    ) => {},
    clearArrivalInformationForm: (state) => {
      state.ArrivalInformationForm.id = "";
      state.ArrivalInformationForm.checkInTime = "";
      state.ArrivalInformationForm.checkOutTime = "";
      state.ArrivalInformationForm.host = "";
      state.ArrivalInformationForm.spokenLanguageList = [];
      state.ArrivalInformationForm.nearestAirport = "";
      state.ArrivalInformationForm.pickupInformation = "";
      state.ArrivalInformationForm.isDraft = false;
      state.ArrivalInformationForm.count = null || 0;
    },
    ariportToggleOpen: (state) => {
      state.searchOpen = !state.searchOpen;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
