import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import React from "react";
import { WelcomeBarInterface } from "../types";
import { actions } from "./slice";
import { selectWelcomeBarForm } from "./selector";
import CustomToast from "../../../../../components/UI/CustomToast";
import { AxiosResponse } from "axios";
import {
  AddWelcomeBar,
  GetWelcomeBarRequest,
} from "../../../../../utils/request";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

export function* WelcomeBarAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: WelcomeBarInterface = yield select(selectWelcomeBarForm);

  if (form.barMessage.length == 0) {
    CustomToast("please fill Message", "ERROR");
    return;
  }
  if (form.linkTo.length == 0) {
    CustomToast("please fill Link", "ERROR");
    return;
  }
  if (form.backgroundColor.length == 0) {
    CustomToast("please fill Background Color", "ERROR");
    return;
  }
  if (form.textColor.length == 0) {
    CustomToast("please fill Text Color", "ERROR");
    return;
  }
  if (form.barHeight == 0) {
    CustomToast("please fill Bar Height", "ERROR");
    return;
  }
  if (form.fontSize == 0) {
    CustomToast("please fill Font Size", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(AddWelcomeBar, form);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getGetWellcomeBarListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetWelcomeBarRequest);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setDataInForm(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* adminWelcomeBarRepoSaga() {
  yield takeLatest(actions.AddWelcomeBar, WelcomeBarAddRequest);
  yield takeLatest(actions.doGetWellcomeBarList, getGetWellcomeBarListRequest);
}
