import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { AdminCurrenciesState, CurrenciesInterface } from "../types";
import { log } from "../../../../../utils/logger";

export const initialState: AdminCurrenciesState = {
  loading: false,
  CurrenciesList: [],
  CurrenciesFormData: {
    id: "",
    name: "",
    code: "",
    rate: 0,
    symbol: "",
    status: 1,
  },
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  dissableCheck: false,
};

export const useClinicSlice = createSlice({
  name: "adminCurrencies",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      log("Loading");
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      log("pageNo");
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      log("pageNo");
      state.search = action.payload;
    },
    setCurrencyList: (state, action: PayloadAction<Array<any>>) => {
      state.CurrenciesList = action.payload;
    },
    doGetALLCurrencyList: (state) => {},
    setCurrencyForEdit: (state, action: PayloadAction<CurrenciesInterface>) => {
      state.CurrenciesFormData = action.payload;
    },
    getCurrencyByIDForEdit: (state, action: PayloadAction<string>) => {},
    updateCurrencyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CurrenciesFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    updateDataHelp: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
  },
});

export const {
  reducer,
  actions,
  name: AdminCurrenciesSliceKey,
} = useClinicSlice;
