import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  UserInquiryDetail,
  UserMessageCardList,
  UserMessageList,
} from "../../../../utils/request";
import {
  SelectPageNo,
  SelectPageSize,
  selectInquiryMessageCard,
  selectMessageFilter,
  selectMessageList,
  selectMessageSort,
  selectmessagePageNo,
  selectmessagePageSize,
} from "./selector";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { yellow } from "@mui/material/colors";
export function* getBookingInquiryListRequest() {
  yield delay(500);
  try {
    yield put(actions.setloader(true));
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const MessageFilter: number = yield select(selectMessageFilter);
    const MessageSort: number = yield select(selectMessageSort);

    const response: AxiosResponse = yield call(
      UserMessageCardList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&messageTypeFilter=${MessageFilter}&messageSortFilter=${MessageSort}`
    );
    yield put(actions.setloader(false));
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.settotalInquiryCard(response.data.data[0].meta.total));
    yield put(actions.setInquiryMassageCard(response.data.data[0].results));
    yield put(actions.setAllMessageCount(response.data.counts[0]));
    if (response.data[0].results.length > 0) {
    } else {
    }
  } catch (error: any) {
    yield put(actions.setloader(false));
    CatchBlockFunction(error);
  }
}
export function* getMessageListByIDRequest(action: {
  payload: { id: string; schoolId: string; pageNo?: number };
}) {
  yield delay(500);
  try {
    const MessageList: [] = yield select(selectMessageList);
    let pageNo: number = yield select(selectmessagePageNo);
    let pageSize: number = yield select(selectmessagePageSize);
    if (action.payload.pageNo) {
      pageNo = action.payload.pageNo;
    }

    const response: AxiosResponse = yield call(
      UserMessageList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&schoolId=${action.payload.schoolId}&id=${action.payload.id}`
    );

    if (pageNo == 1) {
      yield put(actions.setTotalMessageRow(response.data.data.meta.total));
      yield put(actions.setMessageList(response.data.data.results));
    } else {
      const concatdata = MessageList.concat(response.data.data.results);
      yield put(actions.setTotalMessageRow(response.data.data.meta.total));
      yield put(actions.setMessageList(concatdata));
    }
    yield put(actions.setHasMore(response.data.data.results.length > 0));
    if (response.data.data.results.length != 0) {
      yield put(actions.setUserName(response.data.data.userFirstName));
      yield put(actions.setUserLastName(response.data.data.userLastName));
      yield put(actions.setUserProfile(response.data.data.userProfilePic));
      yield put(actions.setSchoolName(response.data.data.businessName));
      yield put(actions.setSchoolProfile(response.data.data.schoolProfilePic));
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getInquiryCardDetailRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      UserInquiryDetail,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setinquiryCardDetail(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UserInquiryMessageRepoSaga() {
  yield takeLatest(actions.doGetMessageCardList, getBookingInquiryListRequest);
  yield takeLatest(actions.doGetMessageList, getMessageListByIDRequest);
  yield takeLatest(actions.doGetInquiryDetail, getInquiryCardDetailRequest);
}
