import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../../../components/UI/CustomToast";
import {
  getAllCurrencyRequest,
  getCurrencyByIDRequest,
  UpdateCurrencyRequest,
} from "../../../../../utils/request";
import { CurrenciesInterface } from "../types";
import {
  selectAdminCurrenciesForm,
  SelectPageNo,
  SelectSearch,
} from "./selector";
import { actions } from "./slice";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";
import { log } from "../../../../../utils/logger";

export function* getALLCurrencyListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getAllCurrencyRequest);

    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setCurrencyList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getCurrencyByIdRequest(action: { payload: any }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getCurrencyByIDRequest,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setCurrencyForEdit(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateData(action: { payload: any }) {
  yield delay(500);

  const form: CurrenciesInterface = yield select(selectAdminCurrenciesForm);
  log(form, "formupdatevalue");

  try {
    const response: AxiosResponse = yield call(UpdateCurrencyRequest, form);
    if (response.data.status == false) {
      CustomToast(response.data.message, "ERROR");

      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.doGetALLCurrencyList());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* adminCurrenciesRepoSaga() {
  yield takeLatest(actions.doGetALLCurrencyList, getALLCurrencyListRequest);
  yield takeLatest(actions.getCurrencyByIDForEdit, getCurrencyByIdRequest);
  yield takeLatest(actions.updateDataHelp, updateData);
}
