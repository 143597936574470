import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash.set";
import {
  AllActivityInterface,
  DrinksStyleModel,
  FoodAndDrinksInterface,
  InclusionsExclushionModel,
  InclusionsExclusionsInterface,
  InclusionsFormModel,
  InclusionsTypeEnum,
  MealsStyleModel,
  MealTypeEnum,
  SchoolListingOverviewState,
} from "../types";
import { ListingTypeEnum } from "../../types";

export const initialState: SchoolListingOverviewState = {
  FoodDrinksModalType: 0,
  InclushionExlushionModalType: 0,

  foodAndDrinksForm: {
    isDraft: false,
    drinksStyleForm: {
      listingType: ListingTypeEnum.BOTH,
      schoolId: "",
      id: "",
      name: "",
      type: 2,
      retreatId: "",
    },
    mealsStyleForm: {
      listingType: ListingTypeEnum.BOTH,
      schoolId: "",
      id: "",
      name: "",
      type: MealTypeEnum.TEMPORARY,
      retreatId: "",
    },
    id: "",
    retreatId: "",
    foodOverview: "",
    drinksList: [],
    mealsList: [],
    drinkOverview: "",
    count: 0,
  },
  foodAndDrinksList: [],
  ListingsId: "",
  AllMainActivityList: [],
  InclusionsExclusionsForm: {
    isDraft: false,
    id: "",
    InclushionExclushionList: [],
    InclushionExclushionForm: {
      Exclushion: {
        listingType: ListingTypeEnum.BOTH,
        schoolId: "",
        id: "",
        name: "",
        retreatId: "",
        type: 2,
      },
      exclusionList: [],
      inclushionList: [],
      Inclushion: {
        listingType: ListingTypeEnum.BOTH,
        schoolId: "",
        id: "",
        name: "",
        retreatId: "",
        type: 2,
      },
      retreatId: "",
      id: "",
      count: 0,
    },
    count: 0,
  },
  InclusionsExclusionsList: [],
  InclusionsFixedAdmin: [],
  InclusionsTempSchool: [],
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  dissableCheck: false,
  offlineOrOnline: "",
  DrinksFixedList: [],
  DrinksTempList: [],
  MealsFixedList: [],
  MealsTempList: [],
  ExclusionFixedAdmin: [],
  ExclusionTempSchool: [],
  loading: false,
  InclusionLoading: false,
};

export const useClinicSlice = createSlice({
  name: "schoolListingOverview",
  initialState,
  reducers: {
    SetInclushionExclushionModalType: (
      state,
      action: PayloadAction<number>
    ) => {
      state.InclushionExlushionModalType = action.payload;
    },
    SetFoodDrinksModalType: (state, action: PayloadAction<number>) => {
      state.FoodDrinksModalType = action.payload;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInclusionLoading: (state, action: PayloadAction<boolean>) => {
      state.InclusionLoading = action.payload;
    },
    doGetFoodAndDrinksDataByID: (state, action: PayloadAction<any>) => {},
    SetFoodAndDrinkDataById: (
      state,
      action: PayloadAction<FoodAndDrinksInterface>
    ) => {
      state.foodAndDrinksForm = action.payload;
      state.foodAndDrinksForm = {
        drinksStyleForm: {
          listingType: ListingTypeEnum.BOTH,
          id: "",
          name: "",
          retreatId: "",
          type: 2,
          schoolId: "",
        },
        count: 0,
        mealsStyleForm: {
          listingType: ListingTypeEnum.BOTH,
          id: "",
          name: "",
          retreatId: "",
          type: 2,
          schoolId: "",
        },
        isDraft: false,
        drinkOverview: action.payload.drinkOverview,
        drinksList: action.payload.drinksList,
        foodOverview: action.payload.foodOverview,
        id: action.payload.id || "",
        mealsList: action.payload.mealsList,
        retreatId: action.payload.retreatId || "",
      };
    },
    doGetInclusionandExclusionDataByID: (state) => {},
    doGetInclusionandExclusionSchoolDataByID: (
      state,
      action: PayloadAction<any>
    ) => {},
    SetdoGetInclusionandExclusionById: (
      state,
      action: PayloadAction<InclusionsFormModel>
    ) => {
      (state.InclusionsExclusionsForm.isDraft = false),
        (state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion = {
          id: "",
          listingType: ListingTypeEnum.BOTH,
          name: "",
          retreatId: "",
          type: 2,
          schoolId: "",
        }),
        (state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion = {
          id: "",
          name: "",
          listingType: ListingTypeEnum.BOTH,
          retreatId: "",
          type: 2,
          schoolId: "",
        }),
        (state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList =
          action.payload.exclusionList);
      state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList =
        action.payload.inclushionList;
    },

    setMainActivityList: (
      state,
      action: PayloadAction<Array<AllActivityInterface>>
    ) => {
      state.AllMainActivityList = action.payload;
    },
    setListingsID: (state, action: PayloadAction) => {
      state.ListingsId = action.payload;
    },
    doGetALLActivityList: (state) => {},
    getFixedDrinksTypeList: (state) => {},
    getFixedMealsTypeList: (state) => {},
    getTempDrinksTypeSchoolList: (state, action: PayloadAction<any>) => {},
    getTempMealsTypeSchoolList: (state, action: PayloadAction<any>) => {},
    setDrinksTypeFixedList: (
      state,
      action: PayloadAction<Array<DrinksStyleModel>>
    ) => {
      state.DrinksFixedList = action.payload;
    },
    setDrinksTypeTempList: (
      state,
      action: PayloadAction<Array<DrinksStyleModel>>
    ) => {
      state.DrinksTempList = action.payload;
    },
    setMealsTypeFixedList: (
      state,
      action: PayloadAction<Array<MealsStyleModel>>
    ) => {
      state.MealsFixedList = action.payload;
    },
    setMealsTypeTempList: (
      state,
      action: PayloadAction<Array<MealsStyleModel>>
    ) => {
      state.MealsTempList = action.payload;
    },
    AddListings: (state, action: PayloadAction<{ callback: () => void }>) => {},
    AddListingsFoodAndDrinks: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateListingsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CreateListingForm.${action.payload.key}`,
        action.payload.value
      );
    },

    addImage: (
      state,
      action: PayloadAction<{ data: any; type: number; callback: any }>
    ) => {},
    updateListingsFoodAndDrinksFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `foodAndDrinksForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateListingsMealsStyleFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `foodAndDrinksForm.mealsStyleForm.${action.payload.key}`,
        action.payload.value
      );
    },
    updateListingsDrinksStyleFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `foodAndDrinksForm.drinksStyleForm.${action.payload.key}`,
        action.payload.value
      );
    },
    SelectMealsStyleType: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.foodAndDrinksForm.mealsList.indexOf(action.payload.value) !== -1
      ) {
        state.foodAndDrinksForm.mealsList.splice(
          state.foodAndDrinksForm.mealsList.indexOf(action.payload.value),
          1
        );
      } else {
        state.foodAndDrinksForm.mealsList.push(action.payload.value);
      }
    },
    SelectDrinksStyleType: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.foodAndDrinksForm.drinksList.indexOf(action.payload.value) !== -1
      ) {
        state.foodAndDrinksForm.drinksList.splice(
          state.foodAndDrinksForm.drinksList.indexOf(action.payload.value),
          1
        );
      } else {
        state.foodAndDrinksForm.drinksList.push(action.payload.value);
      }
    },
    AddNewDrinksRetreatStyle: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddNewMealsRetreatStyle: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      // state.OverviewForm.retreatStyles.push(state.OverviewForm.retreatStylesForm);
    },

    clearMealsform: (state) => {
      state.foodAndDrinksForm.mealsStyleForm.name = "";
      state.foodAndDrinksForm.mealsStyleForm.id = "";
      state.foodAndDrinksForm.mealsStyleForm.retreatId = "";
    },
    clearDrinksform: (state) => {
      state.foodAndDrinksForm.drinksStyleForm.name = "";
      state.foodAndDrinksForm.drinksStyleForm.id = "";
      state.foodAndDrinksForm.drinksStyleForm.retreatId = "";
    },

    // -------------- Inclusion&&Exclusion_Work ----------

    getRetreatListingsInclusionsAdminList: (state) => {},
    getRetreatListingsInclusionsSchoolList: (
      state,
      action: PayloadAction<any>
    ) => {},
    setRetreatListingsInclusionsAdminList: (
      state,
      action: PayloadAction<Array<InclusionsExclushionModel>>
    ) => {
      state.InclusionsFixedAdmin = action.payload;
    },
    setRetreatListingsInclusionsdSchoolList: (
      state,
      action: PayloadAction<Array<InclusionsExclushionModel>>
    ) => {
      state.InclusionsTempSchool = action.payload;
    },

    AddNewListingsInclusions: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    AddNewListingsExclusion: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateRetreatListingsInclusionsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      //
      // state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion[
      //   action.payload.key
      // ] = action.payload.value;
      set(
        state,
        `InclusionsExclusionsForm.InclushionExclushionForm.Inclushion.${action.payload.key}`,
        action.payload.value
      );
    },
    updateRetreatListingsExclusionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `InclusionsExclusionsForm.InclushionExclushionForm.Exclushion.${action.payload.key}`,
        action.payload.value
      );
    },
    setRetreatListingsExclusionAdminList: (
      state,
      action: PayloadAction<Array<InclusionsExclushionModel>>
    ) => {
      state.ExclusionFixedAdmin = action.payload;
    },
    setRetreatListingsExclusionSchoolList: (
      state,
      action: PayloadAction<Array<InclusionsExclushionModel>>
    ) => {
      state.ExclusionTempSchool = action.payload;
    },
    getRetreatListingsExclusionAdminList: (state) => {},
    getRetreatListingsExclusionSchoolList: (
      state,
      action: PayloadAction<any>
    ) => {},
    SelectInclusionList: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList.indexOf(
          action.payload.value
        ) !== -1
      ) {
        state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList.splice(
          state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList.indexOf(
            action.payload.value
          ),
          1
        );
      } else {
        state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList.push(
          action.payload.value
        );
      }
    },
    SelectExclusionList: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      if (
        state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList.indexOf(
          action.payload.value
        ) !== -1
      ) {
        state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList.splice(
          state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList.indexOf(
            action.payload.value
          ),
          1
        );
      } else {
        state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList.push(
          action.payload.value
        );
      }
    },
    updateListingsInclusionandExclusionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `InclusionsExclusionsForm.InclushionExclushionForm.${action.payload.key}`,
        action.payload.value
      );
    },
    AddSchoolInclusionandExclusion: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    clearInclusionsform: (state) => {
      state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion.name =
        "";
      state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion.retreatId =
        "";
      state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion.type = 2;
    },
    clearExclusionform: (state) => {
      state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion.name =
        "";
      (state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion.id =
        ""),
        (state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion.retreatId =
          ""),
        (state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion.type =
          InclusionsTypeEnum.TEMPORARY);
    },
    MealsPillsEditRequest: (state, action: PayloadAction<MealsStyleModel>) => {
      state.foodAndDrinksForm.mealsStyleForm = action.payload;
    },
    DrinksPillsEditRequest: (
      state,
      action: PayloadAction<DrinksStyleModel>
    ) => {
      state.foodAndDrinksForm.drinksStyleForm = action.payload;
    },
    InclusiveEditRequest: (
      state,
      action: PayloadAction<InclusionsExclushionModel>
    ) => {
      state.InclusionsExclusionsForm.InclushionExclushionForm.Inclushion =
        action.payload;
    },
    ExclusivePillsEditRequest: (
      state,
      action: PayloadAction<InclusionsExclushionModel>
    ) => {
      state.InclusionsExclusionsForm.InclushionExclushionForm.Exclushion =
        action.payload;
    },
    DeleteDrinksPills: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    DeleteMealsPills: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    DeleteInclushion: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    DeleteExclushion: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    setFoodAndDrinksIsDraft: (state, action: PayloadAction<boolean>) => {
      state.foodAndDrinksForm.isDraft = action.payload;
    },
    setInclshionAndExclushionIsDraft: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.InclusionsExclusionsForm.isDraft = action.payload;
    },

    incluhionCount: (state) => {
      state.InclusionsExclusionsForm.count =
        state.InclusionsExclusionsForm.count + 1;
    },
    FoodAndDrinksCount: (state) => {
      state.InclusionsExclusionsForm.count =
        state.InclusionsExclusionsForm.count + 1;
    },
    clearFoodAndDrinksFrom: (state) => {
      state.foodAndDrinksForm.id = "";
      state.foodAndDrinksForm.foodOverview = "";
      state.foodAndDrinksForm.drinksList = [];
      state.foodAndDrinksForm.mealsList = [];
      state.foodAndDrinksForm.drinkOverview = "";
      state.foodAndDrinksForm.isDraft = false;
      state.foodAndDrinksForm.count = null || 0;
    },
    clearInclusionsAndExclusionsFrom: (state) => {
      state.InclusionsExclusionsForm.id = "";
      state.InclusionsExclusionsForm.InclushionExclushionForm.exclusionList =
        [];
      state.InclusionsExclusionsForm.InclushionExclushionForm.inclushionList =
        [];
      state.foodAndDrinksForm.isDraft = false;
      state.foodAndDrinksForm.count = null || 0;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
