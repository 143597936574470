import { ReCaptchaProvider } from "next-recaptcha-v3";
import { useRouter } from "next/router";
import React, {  useEffect, useState } from "react";

function ReCaptchaWarper({ children }: any) {
  const router = useRouter();
  const [showReCaptcha, setShowReCaptcha] = useState(false);

  useEffect(() => {
    setShowReCaptcha(router.pathname === "/help");
  }, [router.pathname]);
  return (
    <>
      {showReCaptcha ? (
        <ReCaptchaProvider reCaptchaKey="6LeLQWIpAAAAAPgFczduTOq-0VrjicivRp21QUk9">
          {children}
        </ReCaptchaProvider>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default ReCaptchaWarper;
