import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";
import { initialState } from "./slice";
const selectDomain = (state: RootState) => {
  if (state) {
    if (state.affiliatState) {
      return state.affiliatState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectAffiateFormData = createSelector(
  [selectDomain],
  (state) => state.affiateFormData
);
export const selectAllAffiateList = createSelector(
  [selectDomain],
  (state) => state.affliateList
);
export const selectStatusChangeForm = createSelector(
  [selectDomain],
  (state) => state.StatusChangeForm
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const selectEditindex = createSelector(
  [selectDomain],
  (state) => state.editIndex
);
export const selectDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);
export const selectEditModalShow = createSelector(
  [selectDomain],
  (state) => state.editModalShow
);
export const selectAffliateDataList = createSelector(
  [selectDomain],
  (state) => state.AffliateDataList
);
export const selectAllAffiliateDashboardPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardPageNo
);
export const selectAllAffiliateDashboardPageSize = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardPageSize
);
export const selectAllAffiliateDashboardTotalRow = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardTotalRow
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectAffliateTotalAmountList = createSelector(
  [selectDomain],
  (state) => state.AffliateTotalAmountList
);
export const SelectAffiliateDashboardData = createSelector(
  [selectDomain],
  (state) => state.affiliateDashboardData
);
export const SelectAffliateDashboardTotalAmountList = createSelector(
  [selectDomain],
  (state) => state.AffliateDashboardTotalAmountList
);
export const SelectAffliateNameDataList = createSelector(
  [selectDomain],
  (state) => state.AffliateNameDataList
);
export const SelectAffiliatePartnerTotalRow = createSelector(
  [selectDomain],
  (state) => state.affiliatePartnerTotalRow
);
export const SelectAffiliatePartnerPageSize = createSelector(
  [selectDomain],
  (state) => state.affiliatePartnerPageSize
);
export const SelectAffiliatePartnerPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliatePartnerPageNo
);
export const SelectAffiliatesPaymentList = createSelector(
  [selectDomain],
  (state) => state.affiliatesPaymentList
);
export const SelectAffiliatePaymentTotalRow = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentTotalRow
);
export const SelectAffiliatePaymentPageSize = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentPageSize
);
export const SelectAffiliatePaymentPageNo = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentPageNo
);
export const SelectAffiliatePaymentSearch = createSelector(
  [selectDomain],
  (state) => state.affiliatePaymentSearch
);
export const SelectSchoolFilter = createSelector(
  [selectDomain],
  (state) => state.SchoolListFilter
);
//* **************** *//
export const SelectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const SelectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const SelectSortColumnAffiliateDashboard = createSelector(
  [selectDomain],
  (state) => state.sortColumnAffiliateDashboard
);
export const SelectSortDirAffiliateDashboard = createSelector(
  [selectDomain],
  (state) => state.sortDirAffiliateDashboard
);
export const SelectSortColumnPayment = createSelector(
  [selectDomain],
  (state) => state.sortColumnPayment
);
export const SelectSortDirPayment = createSelector(
  [selectDomain],
  (state) => state.sortDirPayment
);
export const SelectSortColumnPartner = createSelector(
  [selectDomain],
  (state) => state.sortColumnPartner
);
export const SelectSortDirPartner = createSelector(
  [selectDomain],
  (state) => state.sortDirPartner
);
export const SelectPayDetailsModalShow = createSelector(
  [selectDomain],
  (state) => state.payDetailsModalShow
);
