import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    if (state.mediaManager) {
      return state.mediaManager;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectCreatedOn = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectSelectDate = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectSchoolFilter = createSelector(
  [selectDomain],
  (state) => state.SchoolListFilter
);
export const SelectListingFIlter = createSelector(
  [selectDomain],
  (state) => state.ListingsListFilter
);
export const SelectStatus = createSelector(
  [selectDomain],
  (state) => state.StatusFilter
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectListingList = createSelector(
  [selectDomain],
  (state) => state.listingList
);
export const SelectAllPartnerList = createSelector(
  [selectDomain],
  (state) => state.allPartnerList
);
