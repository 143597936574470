import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { RequestTypeEnum } from "../../Retreats/types";
import {
  AdminBookingState,
  StatusTypeEnum,
  VoucherCodesInterface,
} from "../types";

export const initialState: AdminBookingState = {
  CurrentBookingsList: [],
  PastBookingsList: [],
  CancelledBookingsList: [],
  CancelledBookingsPagination: {
    pageNo: 1,
    pageSize: 15,
  },
  CurrentBookingsPagination: {
    pageNo: 1,
    pageSize: 15,
  },
  PastBookingsPagination: {
    pageNo: 1,
    pageSize: 15,
  },
  TotalCancelledBookingsList: 0,
  TotalCurrentBookingsList: 0,
  TotalPastBookingsList: 0,
  schoolFilter: [],
  search: "",
  ListingsListFilter: [],
  date: "",
  createdOnFilter: 0,
  loading: false,
  cancelBookingReason: "",
  showVoucher: false,
  voucherCodesList: [],
  voucherCodesFormData: {
    id: "",
    voucherName: "",
    amount: "",
    validTill: "",
    userId: "",
    email: "",
    note: "",
    status: StatusTypeEnum.All,
    firstName: "",
    lastName: "",
  },
  sortColumn: "",
  sortDir: "",
  sortColumnPast: "",
  sortDirPast: "",
  sortColumnCancel: "",
  sortDirCancel: "",
};

export const useClinicSlice = createSlice({
  name: "AdminBookingState",
  initialState,
  reducers: {
    doGetCurrentBookingsList: (state) => {},
    doGetPastBookingsList: (state) => {},
    doGetCancelledBookingsList: (state) => {},
    handleCancelBooking: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    setCancelledBookingsList: (state, action: PayloadAction<[]>) => {
      state.CancelledBookingsList = action.payload;
    },
    setcancelBookingReason: (state, action: PayloadAction<string>) => {
      state.cancelBookingReason = action.payload;
    },

    setPastBookingsList: (state, action: PayloadAction<any>) => {
      state.PastBookingsList = action.payload;
    },
    setCurrentBookingsList: (state, action: PayloadAction<any>) => {
      state.CurrentBookingsList = action.payload;
    },
    setTotalCancelledBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalCancelledBookingsList = action.payload;
    },
    setTotalPastBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalPastBookingsList = action.payload;
    },
    setTotalCurrentBookingsList: (state, action: PayloadAction<number>) => {
      state.TotalCurrentBookingsList = action.payload;
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.schoolFilter = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    ListingFilter: (state, action: PayloadAction<any>) => {
      state.ListingsListFilter = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    updateCurrentPaginationValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CurrentBookingsPagination.${action.payload.key}`,
        action.payload.value
      );
    },
    updatePastPaginationValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `PastBookingsPagination.${action.payload.key}`,
        action.payload.value
      );
    },
    updateCancelledPaginationValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `CancelledBookingsPagination.${action.payload.key}`,
        action.payload.value
      );
    },
    doGetCancelBookingDataById: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleShowVoucher: (state) => {
      state.showVoucher = !state.showVoucher;
    },
    updateVoucherCodeFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `voucherCodesFormData.${action.payload.key}`,
        action.payload.value
      );
    },
    AddCancelBookingVoucherCode: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doClearVoucherForm: (state) => {
      state.voucherCodesFormData.amount = "";
      state.voucherCodesFormData.email = "";
      state.voucherCodesFormData.id = "";
      state.voucherCodesFormData.note = "";
      state.voucherCodesFormData.status = StatusTypeEnum.All;
      state.voucherCodesFormData.userId = "";
      state.voucherCodesFormData.validTill = "";
      state.voucherCodesFormData.voucherName = "";
    },
    doClearVoucherDateForm: (state) => {
      state.voucherCodesFormData.validTill = "";
    },
    setVoucherCodesFormData: (
      state,
      action: PayloadAction<VoucherCodesInterface>
    ) => {
      state.voucherCodesFormData = action.payload;
    },

    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setPastSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnPast = action.payload;
    },
    setPastSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirPast = action.payload;
    },
    setCancelSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnCancel = action.payload;
    },
    setCancelSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirCancel = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
