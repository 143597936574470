export interface LandingInterface {
  id: string;
  name: string;
  countryId: string;
  cityId: string;
  country: string;
  city: string;
  activity: ActivityEnum;
  photo: string;
  bannerPhoto: string;
  videoThumbnail: string;
  video: string;
  description: DescriptionInterface[];
  faq: FaqInterface[];
  // faq1: FaqInterface[];
  heading: string;
  subHeading: string;
  cardHeading: string;
  photoHeading: string;
  photoSubHeading: string;
  popularTextHeading: string;
}
export interface DescriptionInterface {
  id: string;
  title: string;
  description: string;
}
export interface FaqInterface {
  id: string;
  question: string;
  answer: string;
}
export enum ActivityEnum {
  YOGA_RETREAT = 1,
  YOGA_TEACHER_TRAINING = 2,
  ONLINE_YOGA_TEACHER_TRAINING = 3,
}
export interface LandingPageState {
  list: LandingInterface[];
  form: LandingInterface;
  retreatDescriptionForm: DescriptionInterface;
  faqForm: FaqInterface;
  countryList: LandingInterface[];
  cityList: LandingInterface[];
  editIndex: number;
  editFaqIndex: number;
  editDescriptionIndex: number;
  loading: boolean;
  isAdd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  disableCheck: boolean;
  cropShow: boolean;
  sortColumn: string;
  sortDir: string;
  openFaq: boolean;
  openDescription: boolean;
}

export type InitialLandingPageState = LandingPageState;
