export interface mediaInterface {
  Id: string;
  Media_Name: string;
  School_Name: string;
  Assigned_Listing: string;
  Added_On: string;
  Media_Status: string;
}
export interface listingListInterface {
  retreatId: string;
  retreatTitle: string;
}
export enum StatusFilterEnum {
  Approved = 2,
  Pending = 1,
  All = 5,
}
export interface AdminMediaManagerState {
  allPartnerList: any[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  dissableCheck: boolean;
  createdOnFilter: number;
  date: any;
  SchoolListFilter: string[];
  ListingsListFilter: string[];
  listingList: listingListInterface[];
  StatusFilter: StatusFilterEnum;
  search: string;
}

export type InitialAdminUserState = AdminMediaManagerState;
