import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import {
  AdminDashboardInterface,
  AdminDashboardState,
  bookingBreakdownInterface,
} from "../types";

export const initialState: AdminDashboardState = {
  bookingGraph: [10, 8, 12, 7, 9, 0, 0, 10, 12, 0, 16, 13],
  bookingCategoryChart: [
    { x: "Yoga Teacher Training", y: 35 },
    { x: "Retreats", y: 55 },
    { x: "Online Yoga Teacher Training", y: 10 },
    { x: "Online Retreats", y: 10 },
  ],
  bookingByCountryChart: [
    {
      img: "/images/admindashboard/australia.svg",
      country: "US",
      percentage: "20%",
      booking: 35,
    },
    {
      img: "/images/admindashboard/canada.svg",
      country: "Canada",
      percentage: "56%",
      booking: 56,
    },
    {
      img: "/images/admindashboard/russia.svg",
      country: "Russia",
      percentage: "15%",
      booking: 87,
    },
    {
      img: "/images/admindashboard/china.svg",
      country: "China",
      percentage: "59%",
      booking: 99,
    },
    {
      img: "/images/admindashboard/australia.svg",
      country: "Australia",
      percentage: "71%",
      booking: 35,
    },
    {
      img: "/images/admindashboard/france.svg",
      country: "France",
      percentage: "50%",
      booking: 33,
    },
  ],
  adminDashboardTopDataList: [],
  bookingBreakdown: {
    onlineYogaTeacherTraining: 0,
    onlineYogaretreats: 0,
    yogaTeacherTraining: 0,
    yogaretreats: 0,
  },
  bookingPerformance: [],
  countries: [],
  dataType: [],
  numberOfBookingsByCountry: [],
  date: null,
  date1: null,
  createdOnFilter: 0,
};

export const useClinicSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    setAdminDashboardDataList: (
      state,
      action: PayloadAction<Array<AdminDashboardInterface>>
    ) => {
      state.adminDashboardTopDataList = action.payload;
    },
    setbookingBreakdown: (
      state,
      action: PayloadAction<bookingBreakdownInterface>
    ) => {
      state.bookingBreakdown = action.payload;
    },
    setbookingPerformance: (state, action: PayloadAction<[]>) => {
      state.bookingPerformance = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
      console.log(state.date, "state.date");
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    setcountries: (state, action: PayloadAction<[]>) => {
      state.countries = action.payload;
    },
    setdataType: (state, action: PayloadAction<[]>) => {
      state.dataType = action.payload;
    },
    setnumberOfBookingsByCountry: (state, action: PayloadAction<[]>) => {
      state.numberOfBookingsByCountry = action.payload;
    },

    doGetAdminDashboardDetails: (
      state,
      action: PayloadAction<{
        dateFilter: string;
        startDate: string;
        endDate: string;
        callback: () => void;
      }>
    ) => {},
    doGetAdminDashboardGraphDetails: (
      state,
      action: PayloadAction<{
        dateFilter: string;
        callback: () => void;
      }>
    ) => {},
    doGetBookingCategoryList: (state) => {},
    doGetBookingByCountryList: (state) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
