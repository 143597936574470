export interface PaymentNotEnabledYetInterface {
  id: string;
  username: string;
  status: string;
  totalPrice: string;
  description: string;
  title: string;
  arrivalDate: string;
  inqueryDate: string;
  updatedAt: string;
  currency: string;
  lastSeen: string;
  replied: string;
  shortName: string;
  newMassage: string;
}

export interface FollowUpInterface {
  id: string;
  username: string;
  status: string;
  totalPrice: string;
  description: string;
  title: string;
  arrivalDate: string;
  inqueryDate: string;
  inqueryId: string;
  updatedAt: string;
  currency: string;
  lastSeen: string;
  replied: string;
  shortName: string;
  newMassage: string;
}

export interface NewMassageInterface {
  id: string;
  username: string;
  status: string;
  totalPrice: string;
  description: string;
  title: string;
  inqueryId: string;
  arrivalDate: string;
  inqueryDate: string;
  updatedAt: string;
  currency: string;
  lastSeen: string;
  replied: string;
  shortName: string;
  newMassage: string;
}
export enum MessageTypeEnum {
  TEXT = 1,
  FILE = 2,
  TRIP_DETAILS_CHANGED = 3,
  PACKAGE_DETAILS_CHANGED = 4,
  BOOKING_POLICY_DETAILS_CHANGED = 5,
  PACKAGE_CHANGED = 6,
  RETREAT_BOOKED = 7,
  BOOKING_CANCELLED = 8,
  AVAILABILITY_ACCEPTED = 9,
  AVAILABILITY_DENIED = 10,
  PACKAGE_CHANGED_WITH_PRICE = 11,
}
export interface AllMassageInterface {
  id: string;
  firstName: string;
  status: string;
  type: string;
  inqueryId: string;
  inqueryDate: string;
  title: string;
  arrivalDate: string;
  totalPrice: string;
}
export interface messageRequest {
  message: string;
  inquiryId: string;
  senderId: string;
  receiverId: string;
  messageType: MessageTypeEnum;
}
export enum BookingAvailabilityEnum {
  ACCEPT = 1,
  DECLINE = 2,
  PENDING = 3,
}
export enum InquiryStatusEnum {
  OPEN = 1,
  DUPLICATE = 2,
  BOOKED = 3,
  NOT_AVAILABLE = 4,
  CANCEL = 7,
}
export enum FilterStatusEnum {
  OPEN = 1,
  DUPLICATE = 2,
  BOOKED = 3,
  NOT_AVAILABLE = 4,
  ALL = 5,
  FAILED = 6,
  CANCELLED = 7,
}
export interface MassageFormInterface {
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  profilePic: string;
  email: string;
  phoneNo: string;
  userId: string;
  schoolId: string;
  status: number;
  retreatId: string;
  currencyCode: string;
  tripDetails: {
    arrivalDate: string;
    departureDate: string;
    acceptOrDeclineAvailablity: number;
  };
  packageDetails: {
    packageId: string;
    title: string;
    noOfPeople: number;
    programDuration: number;
    price: number;
    roomAccommodationName: string;
    roomAccommodationType: string;
    roomAccommodation: string;
    retreatType: 2;
    actualPrice: number;
  };
  bookingPolicy: {
    requiredDeposit: number;
    remainingAmount: number;
    cancellationPolicy: number;
    newPolicy: string;
    paymentSchedule: number;
    paymentDaysBeforeArrival: number;
  };
}
export interface AcceptOrDeclineBookingrequest {
  inquiryId: string;
  holdingTime: number;
  deactiveInquiryReason: string;
  acceptOrDeclineAvailablity: BookingAvailabilityEnum;
}
export interface messageRequest {
  message: string;
  inquiryId: string;
  senderId: string;
  receiverId: string;
  attachmentFile: any;
}
export interface FilterInterface {
  email: string;
  name: string;
  title: string;
  status: FilterStatusEnum[];
  pageSize: number;
  pageNumber: number;
}
export interface SchoolMassageState {
  filter: FilterInterface;
  PaymentNotEnabledYetList: PaymentNotEnabledYetInterface[];
  FollowUpList: FollowUpInterface[];
  NewMassageList: NewMassageInterface[];
  AllMassage: AllMassageInterface[];
  packageDetail: any;
  conversation: messageRequest;
  AcceptOrDeclineBookingForm: AcceptOrDeclineBookingrequest;
  loading: boolean;
  MassageForm: MassageFormInterface;
  PackageList: [];
  PaymentNotEnableLength: number;
  NewMessageLength: number;
  FollowUpLength: number;
  PaymentNotEnablePageNo: number;
  NewMessagePageNo: number;
  FollowUpPageNo: number;
  AllMassageLength: number;
  hasMore: boolean;
  sortColumn: string;
  sortDir: string;
  messageLoading:boolean;
}

export type InitialSchoolMassageState = SchoolMassageState;
