export enum StatusTypeEnum {
  InActive = 0,
  Active = 1,
  All = 2,
}

export enum AdminPaymentStatusEnum {
  PAID = 1,
  UNPAID = 2,
}

export interface PaymentsInterface {
  id: string;
  transactionid: string;
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  saving: string;
  couponPercent: number;
  amount_received: number;
  commisionAmount: number;
  referralCode: string;
  admin_paymentGatewayCharge: number;
  commisionPercent: number;
  remainingAmount: number;
  status: AdminPaymentStatusEnum;
  subtotalprice: string;
  vat_tax: string;
  totalprice: string;
  paymentMethod: string;
  payment_date: string;
  payment_status: string;
  grand_total: string;
  retreatBookingId: string;
  invoiceNumber: string;
  subTotal: number;
  tax: number;
  currencyCode: string;
}
export interface AffiliateInterface {
  id: string;
  businessname: string;
  retreatoverviews: string;
  currency: string;
  subtotalprice: string;
  vat_tax: string;
  totalprice: string;
  paymentoption: string;
  paiddate: string;
}
export interface EarningReportsInterface {
  status: AdminPaymentStatusEnum;
  id: string;
  retreatBookingId: string;
  invoiceNumber: string;
  email: string;
  saving: number;
  amount_received: number;
  commisionAmount: number;
  commisionPercent: number;
  admin_paymentGatewayCharge: number;
  remainingAmount: number;
  failedAmount: number;
  paidAmount: number;
  pendingAmount: number;
  couponPercent: number;
  firstName: string;
  lastName: string;
  currency: string;
  grand_total: string;
  currencyCode: string;
  paymentMethod: PaymentMethodFilterEnum;
  payment_status: PaymentStatusFilterEnum;
  payment_date: string;
}
export enum CouponDiscountTypeEnum {
  PERCENTAGE = 1,
  DOLLAR = 2,
}
export interface DiscountCodesInterface {
  id: string;
  schoolname: string;
  name: string;
  couponcode: string;
  expdate: string;
  take: string;
  offfor: string;
  quantity: number;
  status: string;
  discountType: CouponDiscountTypeEnum;
  couponName: string;
  couponCode: string;
  expiryDate: string;
  discount: 10;
  listing: string;
  remaining: number;
  createdAt: string;
}
export interface VoucherCodesInterface {
  id: string;
  voucherName: string;
  amount: any;
  firstName: string;
  lastName: string;
  validTill: string;
  userId: string;
  email: string;
  note: string;
  status: StatusTypeEnum;
}

export enum StatusFilterEnum {
  InActive = 0,
  Active = 1,
  All = 2,
}
export enum PaymentStatusFilterEnum {
  FAILED = 0,
  SUCCESS = 1,
  ALL = 2,
}
export enum PaymentMethodFilterEnum {
  CREDIT_CARD = 1,
}
export interface UserListInterface {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}
export interface TopDataInterface {
  failedAmount: number;
  numberOfTransaction: number;
  paidAmount: number;
  pendingAmount: number;
  total_price: number;
}
export interface AdminPaymentsState {
  PaymentsList: PaymentsInterface[];
  AffiliatesList: AffiliateInterface[];
  EarningReportsList: EarningReportsInterface[];
  discountCodesList: DiscountCodesInterface[];
  user: UserListInterface[];
  voucherCodesList: VoucherCodesInterface[];
  voucherCodesFormData: VoucherCodesInterface;
  topData: TopDataInterface;
  VoucherForUserData: any;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  totalPayments: number;
  PaymentspageNo: number;
  PaymentspageSize: number;
  totalAffiliate: number;
  AffiliatepageNo: number;
  AffiliatepageSize: number;
  totalEarningReport: number;
  EarningReportpageNo: number;
  EarningReportpageSize: number;
  totalVoucher: number;
  VoucherpageNo: number;
  VoucherpageSize: number;
  totalDiscount: number;
  DiscountpageNo: number;
  DiscountpageSize: number;
  showVoucher: boolean;
  SchoolListFilter: [];
  ListingsListFilter: [];
  StatusFilter: StatusFilterEnum;
  paymentStatus: PaymentStatusFilterEnum[];
  paymentMethod: PaymentMethodFilterEnum;
  currencyFilter: any;
  startdate: string;
  endDate: string;
  sortColumn: string;
  sortDir: string;
  sortColumnDiscount: string;
  sortDirDiscount: string;
  sortColumnVoucher: string;
  sortDirVoucher: string;
  sortColumnEarning: string;
  sortDirEarning: string;
}

export type InitialAdminPaymentsState = AdminPaymentsState;
