import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../types/RootState";

import { initialState } from "./slice";

const selectDomain = (state?: RootState) => state?.authState || initialState;
export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginFrom
);
export const selectIsLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const selectSignUpForm = createSelector(
  [selectDomain],
  (state) => state.signUpFormShowHide
);
export const PartnerSignInForm = createSelector(
  [selectDomain],
  (state) => state.PartnerSignInFormShowHide
);
export const SignInFormForm = createSelector(
  [selectDomain],
  (state) => state.SignInFormShowHide
);
export const ResetPasswordForm = createSelector(
  [selectDomain],
  (state) => state.ResetPasswordFormShowHide
);
export const ShowVetificationForm = createSelector(
  [selectDomain],
  (state) => state.ShowVetificationFormShowHide
);
export const BecomeAPartnerForm = createSelector(
  [selectDomain],
  (state) => state.BecomeAPartnerFormShowHide
);
export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectUserisAffiliate = createSelector(
  [selectDomain],
  (state) => state.userData.isAffiliate
);
export const selectUserEmail = createSelector(
  [selectDomain],
  (state) => state.userData.email
);
export const selectloginCount = createSelector(
  [selectDomain],
  (state) => state.loginCount
);
export const selectPartnerForm = createSelector(
  [selectDomain],
  (state) => state.PartnerFormData
);
export const selectPartnerListData = createSelector(
  [selectDomain],
  (state) => state.PartnerList
);

export const selectUserForm = createSelector(
  [selectDomain],
  (state) => state.UserLoginFormData
);
export const selectUserListData = createSelector(
  [selectDomain],
  (state) => state.UserLogin
);
export const selectSchoolId = createSelector(
  [selectDomain],
  (state) => state.schoolId
);
export const selectUserRole = createSelector(
  [selectDomain],
  (state) => state.userData.role
);
export const selectUserCurrencyCode = createSelector(
  [selectDomain],
  (state) => state.userData.currencyCode
);
export const selectUserID = createSelector(
  [selectDomain],
  (state) => state.userData.id
);
export const selectForgetPaswordList = createSelector(
  [selectDomain],
  (state) => state.forgetPaswordList
);
export const selectForgetPaswordFrom = createSelector(
  [selectDomain],
  (state) => state.forgetpasswordFormData
);
export const selectResetPasswordList = createSelector(
  [selectDomain],
  (state) => state.resetPasswordList
);
export const selectResetPasswordForm = createSelector(
  [selectDomain],
  (state) => state.resetPasswordFormData
);
export const selectActiveCurrency = createSelector(
  [selectDomain],
  (state) => state.activeCurrency
);
export const selectResetPasswordSendEmail = createSelector(
  [selectDomain],
  (state) => state.resetPasswordConfirmationShow
);
export const selectResetEmailAddress = createSelector(
  [selectDomain],
  (state) => state.resetEmailAddress
);
export const selectloadingProfile = createSelector(
  [selectDomain],
  (state) => state.loadingProfile
);
