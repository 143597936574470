import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../../components/UI/CustomToast";
import { actions as actionResponce } from "../../redux/slice";
import {
  AddListingArrivalInformation,
  AddListingInstructorList,
  CreateSpokenLanguageRetreatStyle,
  DeleteSpokenlanguageRequest,
  getAirportRequest,
  GetArrivalInformationFormdataByIdRequest,
  getFixedSchoolSpokenLanguage,
  getSchoolHost,
  getTempSchoolSpokenLanguage,
} from "../../../../../utils/request";
import { ArrivalInformationInterface, InstructorListInterface } from "../types";
import {
  SelectArrivalInformationForm,
  selectLanguagesForm,
  SelectSearch,
} from "./selector";
import { selectInstructorOfTheSchoolListData } from "../../../Instructors/redux/selector";
import CatchBlockFunction from "../../../../../hooks/CatchBlockFunction";

export function* getRetreatSpokenLanguageFixedListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getFixedSchoolSpokenLanguage);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setSpokenLanguagesFixedList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getRetreatSpokenLanguageTempRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getTempSchoolSpokenLanguage,
      action.payload
    );

    if (response && !response.data.spokenLanguage) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setSpokenLanguagesTempList(response.data.spokenLanguage));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewSpokenLanguageRetreatStyleRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ArrivalInformationInterface = yield select(selectLanguagesForm);
  try {
    const response: AxiosResponse = yield call(
      CreateSpokenLanguageRetreatStyle,
      form
    );
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AddNewListingArrivalInformationRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ArrivalInformationInterface = yield select(
    SelectArrivalInformationForm
  );
  try {
    const response: AxiosResponse = yield call(
      AddListingArrivalInformation,
      form
    );
    yield put(actions.setLoading(false));
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getArrivalInformationByIdRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      GetArrivalInformationFormdataByIdRequest,
      ""
    );

    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.SetArrivalInformationFormDataById(response.data?.data[0])
    );
    yield put(actions.doGetAirportList(response.data?.data[0].nearestAirport));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "arrivalInfo_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getListingArrivalInformationBySchoolIdRequest(action: {
  payload: any;
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetArrivalInformationFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    yield put(actions.SetArrivalModalType(response.data.model));
    if (response && !response.data?.data[0]) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.SetArrivalInformationFormDataById(response.data?.data[0])
    );

    yield put(actions.doGetAirportList(response.data?.data[0].nearestAirport));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "arrivalInfo_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getLocationSchoolByIdRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetArrivalInformationFormdataByIdRequest,
      action.payload
    );
    yield put(actions.setLoading(false));
    yield put(actions.SetArrivalModalType(response.data.model));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.SetArrivalInformationFormDataById(response.data.data[0]));
    yield put(actions.doGetAirportList(response.data.data[0].nearestAirport));
    yield put(
      actionResponce.updateApiResponceValue({
        key: "arrivalInfo_API_Responce",
        value: true,
      })
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* AddInstructorFormListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: InstructorListInterface = yield select(
    selectInstructorOfTheSchoolListData
  );
  try {
    const response: AxiosResponse = yield call(AddListingInstructorList, form);
    if (response && response.data.status == false) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getActiveHostListRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      getSchoolHost,
      `?schoolId=${action.payload}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }
    yield put(actions.setHostList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAirportListRequest(action: { payload?: string }) {
  yield delay(1000);

  try {
    yield put(actions.setAirportList([]));
    if (!action.payload) {
      return;
    }

    const response: AxiosResponse = yield call(
      getAirportRequest,
      `?${action.payload.length > 0 ? "&search=" + action.payload : ""}`
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");

      return;
    }

    yield put(actions.setAirportList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DeletePillsRequest(action: {
  payload: { id: string; callback: any; retreatid: string };
}) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      DeleteSpokenlanguageRequest,
      action.payload.id
    );

    if (response && !response.data) {
      CustomToast("There is Some Error", "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    // yield put(actions.getTempSpokenLanguageList(action.payload.retreatid));

    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* repoSagaListingArrivalInformationAndInstructor() {
  yield takeLatest(actions.DeletePills, DeletePillsRequest);
  yield takeLatest(
    actions.getFixedSpokenLanguageTypeList,
    getRetreatSpokenLanguageFixedListRequest
  );
  yield takeLatest(
    actions.getTempSpokenLanguageList,
    getRetreatSpokenLanguageTempRequest
  );
  yield takeLatest(
    actions.AddNewSpokenLanguageStyle,
    AddNewSpokenLanguageRetreatStyleRequest
  );
  yield takeLatest(
    actions.AddArrivalInformation,
    AddNewListingArrivalInformationRequest
  );
  yield takeLatest(
    actions.doGetArrivalInformationDataByID,
    getArrivalInformationByIdRequest
  );
  /// for school Edit
  yield takeLatest(
    actions.doGetListingArrivalInformationDataSchoolDataByID,
    getListingArrivalInformationBySchoolIdRequest
  );
  //instructor
  yield takeLatest(actions.doGetAirportList, getAirportListRequest);
  yield takeLatest(actions.doGetSchoolHostList, getActiveHostListRequest);
}
