import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { createSlice } from "../../../../utils/@reduxjs/toolkit";
import {
  AdminAffiliateState,
  AdminAffliateInterface,
  AffiliatePaymentInterface,
  AffiliatePaymentStatusEnum,
  UserStatusEnum,
  getAffiliateDataInterface,
} from "../types";
import { paymentTypeEnum } from "../../../Affiliate/types";

export const initialState: AdminAffiliateState = {
  affliateList: [],
  affiateFormData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    instagramLink: "",
    websiteLink: "",
    termsAndConditions: false,
    youtubeLink: "",
    Errors: [],
    Success: "",
    status: UserStatusEnum.InActive,
    affiliateStatus: UserStatusEnum.InActive,
    bankName: "",
    bankAccountNo: "",
    bankId: "",
    country: "",
    currency: "",
    paymentType: paymentTypeEnum.WISE,
    accountHolderName: "",
    beneficiaryName: ""
  },
  editIndex: 0,
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 15,
  totalRow: 0,
  search: "",
  dissableCheck: false,
  deleteModal: false,
  editModalShow: false,
  AffliateDataList: [],
  affiliateDashboardPageNo: 0,
  affiliateDashboardPageSize: 15,
  affiliateDashboardTotalRow: 0,
  createdOnFilter: 0,
  date: null,
  AffliateTotalAmountList: [],
  AffliateDataForm: {
    createdOnFilter: 0,
    endDate: "",
    id: "",
    pageNo: 0,
    pageSize: 15,
    startDate: "",
    status: AffiliatePaymentStatusEnum.PENDING,
    createdAt: "",
    price: 0,
    retreatBookingId: "",
    retreatTitle: "",
  },
  affiliateDashboardData: [],
  AffliateDashboardTotalAmountList: [],
  AffliateNameDataForm: { id: "", firstName: "", lastName: "" },
  AffliateNameDataList: [],
  affiliatePartnerPageNo: 0,
  affiliatePartnerPageSize: 15,
  affiliatePartnerTotalRow: 0,
  affiliatePaymentPageNo: 1,
  affiliatePaymentPageSize: 15,
  affiliatePaymentTotalRow: 0,
  affiliatePaymentSearch: "",
  affiliatesPaymentList: [],
  SchoolListFilter: [],
  sortColumn: "",
  sortDir: "",
  sortColumnAffiliateDashboard: "",
  sortDirAffiliateDashboard: "",
  sortColumnPayment: "",
  sortDirPayment: "",
  sortColumnPartner: "",
  sortDirPartner: "",
  StatusChangeForm: {
    id: "",
    status: false,
  },
  payDetailsModalShow:false,
};

export const useClinicSlice = createSlice({
  name: "affiliatState",
  initialState,
  reducers: {
    statusChangeFormValue: (
      state,
      action: PayloadAction<{
        id: string;
        status: boolean;
        callback: () => void;
      }>
    ) => {},
    clearFrom: (state) => {
      state.affiateFormData.id = "";
      state.affiateFormData.firstName = "";
      state.affiateFormData.lastName = "";
      state.affiateFormData.email = "";
      state.affiateFormData.instagramLink = "";
      state.affiateFormData.websiteLink = "";
      state.affiateFormData.youtubeLink = "";
      state.affiateFormData.termsAndConditions = false;
      state.affiateFormData.bankName = "";
      state.affiateFormData.bankAccountNo = "";
      state.affiateFormData.bankId = "";
      state.affiateFormData.country = "";
      state.affiateFormData.currency = "";
      state.affiateFormData.paymentType = paymentTypeEnum.WISE;
      state.affiateFormData.accountHolderName = "";
      state.affiateFormData.beneficiaryName = "";
    },

    updateAffiliateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `affiateFormData.${action.payload.key}`, action.payload.value);
    },
    updatestatusValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `StatusChangeForm.${action.payload.key}`,
        action.payload.value
      );
    },
    doGetAllAffiliateList: (state) => {},
    doUpdateAffiliate: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setAllAffiliateList: (
      state,
      action: PayloadAction<Array<AdminAffliateInterface>>
    ) => {
      state.affliateList = action.payload;
    },
    setAffiliateData: (
      state,
      action: PayloadAction<AdminAffliateInterface>
    ) => {
      state.affiateFormData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleEditModal: (state) => {
      state.editModalShow = !state.editModalShow;
    },
    togglePaymewntDetailsModal: (state) => {
      state.payDetailsModalShow = !state.payDetailsModalShow;
    },
    deleteAdminAffiliate: (state, action: PayloadAction<any>) => {},
    doEditAdminAffiliate: (state, action: PayloadAction<any>) => {},
    doShowAdminAffiliatePaymentDetails: (state, action: PayloadAction<any>) => {},

    doGetAdminAffiliateData: (
      state,
      action: PayloadAction<{ id: any; status: string; callback: () => void }>
    ) => {},
    setAffiliateListData: (
      state,
      action: PayloadAction<Array<getAffiliateDataInterface>>
    ) => {
      state.AffliateDataList = action.payload;
    },
    setTotalAffiliateTotalRow: (state, action: PayloadAction<number>) => {
      state.affiliateDashboardTotalRow = action.payload;
    },
    setAffliateTotalAmountList: (state, action: PayloadAction<any>) => {
      state.AffliateTotalAmountList = action.payload;
    },
    setAffliateNameList: (state, action: PayloadAction<any>) => {
      state.AffliateNameDataList = action.payload;
    },
    doGetAdminAffiliateDashboardData: (
      state,
      action: PayloadAction<{ status: any; callback: () => void }>
    ) => {},
    setAffiliateDashboardData: (state, action: PayloadAction<Array<any>>) => {
      state.affiliateDashboardData = action.payload;
    },
    setTotalAffiliateDashboardTotalRow: (
      state,
      action: PayloadAction<number>
    ) => {
      state.affiliateDashboardTotalRow = action.payload;
    },
    setTotalAffiliateDashboardTotalAmountList: (
      state,
      action: PayloadAction<any>
    ) => {
      state.AffliateDashboardTotalAmountList = action.payload;
    },
    setAffiliateDashboardPageNo: (state, action: PayloadAction<number>) => {
      state.affiliateDashboardPageNo = action.payload;
    },
    setAffiliateDashboardPageSize: (state, action: PayloadAction<number>) => {
      state.affiliateDashboardPageSize = action.payload;
    },
    setAffiliatePartnerPageNo: (state, action: PayloadAction<number>) => {
      state.affiliatePartnerPageNo = action.payload;
    },
    setAffiliatePartnerPageSize: (state, action: PayloadAction<number>) => {
      state.affiliatePartnerPageSize = action.payload;
    },
    setAffiliatePartnerTotalRow: (state, action: PayloadAction<number>) => {
      state.affiliatePartnerTotalRow = action.payload;
    },
    doUpdateAdminAffiliateData: (
      state,
      action: PayloadAction<{ id: any; status: string; callback: () => void }>
    ) => {},
    doGetAdminAffiliateRecordPayment: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    setAffiliatesPaymentList: (
      state,
      action: PayloadAction<Array<AffiliatePaymentInterface>>
    ) => {
      state.affiliatesPaymentList = action.payload;
    },
    doGetAdminAffiliatePaymentList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setTotalList: (state, action: PayloadAction<number>) => {
      state.affiliatePaymentTotalRow = action.payload;
    },
    setAffiliatePaymentPageNo: (state, action: PayloadAction<number>) => {
      state.affiliatePaymentPageNo = action.payload;
    },
    setAffiliatePaymentPageSize: (state, action: PayloadAction<number>) => {
      state.affiliatePaymentPageSize = action.payload;
    },
    setAffiliatePaymentSearch: (state, action: PayloadAction<string>) => {
      state.affiliatePaymentSearch = action.payload;
    },
    SchoolFilter: (state, action: PayloadAction<any>) => {
      state.SchoolListFilter = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setAffiliatePaymentSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnPayment = action.payload;
    },
    setAffiliatePaymentSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirPayment = action.payload;
    },
    setAffiliateDashboardSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumnAffiliateDashboard = action.payload;
    },
    setAffiliateDashboardSortDir: (state, action: PayloadAction<string>) => {
      state.sortDirAffiliateDashboard = action.payload;
    },
    setAffiliatePartnerDashboardSortColumn: (
      state,
      action: PayloadAction<string>
    ) => {
      state.sortColumnPartner = action.payload;
    },
    setAffiliatePartnerDashboardSortDir: (
      state,
      action: PayloadAction<string>
    ) => {
      state.sortDirPartner = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
