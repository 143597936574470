import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CustomToast from "../../../../components/UI/CustomToast";
import {
  GetAdminDashboardData,
  GetAdminDashboardGraphData,
} from "../../../../utils/request";
import CatchBlockFunction from "../../../../hooks/CatchBlockFunction";
import { SelectCreatedOnFilter, SelectDateFilter } from "./selector";

export function* getAdminDashboardDataListRequest(action: {
  payload: {
    dateFilter: string;
    startDate: string;
    endDate: string;
    callback: any;
  };
}) {
  yield delay(500);
  const DateFilter: { value: Array<any> } = yield select(SelectDateFilter);
  try {
    const data = {
      startDate: DateFilter ? DateFilter.value[0]?.startDate : "",
      endDate: DateFilter ? DateFilter.value[0]?.endDate : "",
    };
    const response: AxiosResponse = yield call(
      GetAdminDashboardData,
      `?dateFilter=${
        action.payload.dateFilter.length > 0 ? action.payload.dateFilter : "0"
      }&startDate=${
        action.payload.startDate.length > 0 ? action.payload.startDate : ""
      }&endDate=${
        action.payload.endDate.length > 0 ? action.payload.endDate : ""
      }`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setAdminDashboardDataList(response.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getAdminDashboardGraphDataListRequest(action: {
  payload: { dateFilter: string; callback: any };
}) {
  yield delay(500);
  const DateFilter: number = yield select(SelectCreatedOnFilter);
  try {
    const response: AxiosResponse = yield call(
      GetAdminDashboardGraphData,
      `?dateFilter=${DateFilter}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setbookingBreakdown(response.data.bookingBreakdown));
    yield put(actions.setbookingPerformance(response.data.bookingPerformance));
    yield put(actions.setcountries(response.data.countries));
    yield put(actions.setdataType(response.data.dataType));
    yield put(
      actions.setnumberOfBookingsByCountry(
        response.data.numberOfBookingsByCountry
      )
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* AdminDashboardRepoSaga() {
  yield takeLatest(
    actions.doGetAdminDashboardDetails,
    getAdminDashboardDataListRequest
  );
  yield takeLatest(
    actions.doGetAdminDashboardGraphDetails,
    getAdminDashboardGraphDataListRequest
  );
}
